import {createDrawerNavigator, DrawerContentScrollView,  DrawerItemList, DrawerItem} from '@react-navigation/drawer';
import {createStackNavigator} from '@react-navigation/stack';
import {StyleSheet,Platform, Text,View, TouchableOpacity} from 'react-native';
import COLORS from '../constants/colors';
import colors from '../constants/colors';
import ROUTES from '../constants/routes';
import routes from '../constants/routes';
import AsyncStorage from '@react-native-async-storage/async-storage';

// menu screens
import Balance from '../screens/Balance';
import ClientesScreen from '../screens/ClientesScreen';
import CreditosScreen from '../screens/CreditosScreen';
import HomeScreen from '../screens/HomeScreen';
import Listados from '../screens/Listados';

import Acciones from '../screens/AccionesScreen';

// pruebas
import AsignarCredito from '../screens/AsignarCredito';
import ClienteNuevo from '../screens/ClienteNuevo';
import RecaudoDelDia from '../screens/RecaudoDelDia';
import VerCreditosCliente from '../screens/VerCreditosCliente';

import CreditosPorFecha from '../screens/CreditosPorFecha';
import RecaudoPorFecha from '../screens/RecaudoPorFecha';

import VerCliente from '../screens/VerCliente';
import VerCredito from '../screens/VerCredito';

import Recaudar from '../screens/Recaudar';

// Admin
import Delegados from '../screens/admin/Delegados';
import Productos from '../screens/admin/Productos';
import DelegarCuentas from '../screens/admin/DelegarCuentas';
import HomeAdmin from '../screens/admin/HomeAdmin';
import VerLiquidaciones from '../screens/admin/Liquidaciones';
import OpcionesDelegado from '../screens/admin/OpcionesDelegado';
import OpcionesRuta from '../screens/admin/OpcionesRuta';
import Reportes from '../screens/admin/Reportes';

// Opciones Ruta
import RestringirFunciones from '../screens/admin/RestringirFunciones';

// Reportes Admin
import CarteraActual from '../screens/admin/reportes/CarteraActual';
import ClientesInactivosPorCalificacion from '../screens/admin/reportes/ClientesInactivosPorCalificacion';
import CrecimientoCartera from '../screens/admin/reportes/CrecimientoCartera';
import CreditosActivos from '../screens/admin/reportes/CreditosActivos';
import CreditosNoEnrutados from '../screens/admin/reportes/CreditosNoEnrutados';
import CreditosPorFechaRep from '../screens/admin/reportes/CreditosPorFechaRep';
import CreditosSegunEstado from '../screens/admin/reportes/CreditosSegunEstado';
import CreditosVencidos from '../screens/admin/reportes/CreditosVencidos';
import EntregasSalidasPorFecha from '../screens/admin/reportes/EntregasSalidasPorFecha';
import GastosPorFecha from '../screens/admin/reportes/GastosPorFecha';
import InversionesPorFecha from '../screens/admin/reportes/InversionesPorFecha';
import ListaRecaudoDelDia from '../screens/admin/reportes/ListaRecaudoDelDia';
import NoRecaudadoPorFecha from '../screens/admin/reportes/NoRecaudadoPorFecha';
import RecaudadoPorFecha from '../screens/admin/reportes/RecaudadoPorFecha';
import ResumenPorFecha from '../screens/admin/reportes/ResumenPorFecha';

// Edicion datos Admin
import AgregarClienteCredito from '../screens/admin/edicion-datos/AgregarClienteCredito';
import AgregarCredito from '../screens/admin/edicion-datos/AgregarCredito';
import BorrarAbonoPagos from '../screens/admin/edicion-datos/BorrarAbonoPagos';
import BorrarGastosEntregasIngresos from '../screens/admin/edicion-datos/BorrarGastosEntregasIngresos';
import BorrarModificarCredito from '../screens/admin/edicion-datos/BorrarModificarCredito';
import CambiarDatosAcceso from '../screens/admin/edicion-datos/CambiarDatosAcceso';
import CambiarSuperclave from '../screens/admin/edicion-datos/CambiarSuperclave';

import React, {useEffect} from 'react';
import {Image} from 'react-native-elements';
import Icon from 'react-native-vector-icons/MaterialIcons';
import EnrutarEn from '../screens/EnrutarEn';
import VerAbonos from '../screens/VerAbonos';
import CrearUsuario from '../screens/admin/CrearUsuario';
import DetalleLiquidacion from '../screens/admin/DetalleLiquidacion';
import EdicionDatos from '../screens/admin/EdicionDatos';
import EditarUsuarioAdmin from '../screens/admin/EditarUsuarioAdmin';
import LiquidarRuta from '../screens/admin/LiquidarRuta';
import OpcionesCuenta from '../screens/admin/OpcionesCuenta';
import AsignarCreditoAdmin from '../screens/admin/edicion-datos/AsignarCreditoAdmin';
import OpcionesCredito from '../screens/admin/edicion-datos/OpcionesCredito';
import {useNavigation} from "@react-navigation/native";
import VerRutaALiquidar from "../screens/admin/VerRutaALiquidar";
import stylesMenu from "../constants/stylesMenu";
import Ruta from "../screens/RutaScreen";
import ReporteUsuariosCreados from "../screens/admin/ReporteUsuariosCreados";
import DetallePagos from "../screens/DetallePagos";
import NetInfo from '@react-native-community/netinfo';
import { useIconContext} from './IconContext';


const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();
const StackListados = createStackNavigator();
const StackBalance = createStackNavigator();
const StackCreditos = createStackNavigator();
const StackHome = createStackNavigator();
const StackReportes = createStackNavigator();
const StackDelegados = createStackNavigator();
const StackProductos = createStackNavigator();
const StackOpcionesRuta = createStackNavigator();
const StackLiquidaciones = createStackNavigator();
const StackEdicionDatos = createStackNavigator();
const StackAdministrador = createStackNavigator();
const StackLiquidarRuta = createStackNavigator();

function HomeStack() {
    return (
        <StackHome.Navigator initialRouteName={routes.HOME}>
            <StackHome.Screen name={routes.HOME}
                              options={{headerShown: false}} component={HomeScreen}/>
            <StackHome.Screen name={routes.RECAUDAR}
                              options={{
                                headerShown: true,
                                headerTitleAlign: 'center',
                                headerBackImage: () => (
                                    <View
                                        style={stylesMenu.backButton}
                                    >
                                        <Icon name="reply" size={25} color={colors.verde_primary}/>
                                    </View>
                                ),
                                }}  component={Recaudar}/>
            <StackHome.Screen 
                name={routes.RECAUDO_DEL_DIA}
                options={{
                    headerShown: true,
                    headerTitleAlign: 'center',
                    headerTitle:'',
                    headerShadowVisible: false,
                    headerStyle: {
                        backgroundColor: colors.white,
                        // borderBottomColor:'#222222'
                    },
                    headerBackImage: () => (
                        <View
                            style={stylesMenu.backButton}
                        >
                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                        </View>
                    ),
                }}  
                component={RecaudoDelDia}
            />

            <StackHome.Screen name={routes.VER_ABONOS}
                                  options={{
                                    headerShown: true,
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                                        </View>
                                    ),
                                    }}  component={VerAbonos}/>
        </StackHome.Navigator>
    );
}

function ClienteStack({navigation}) {
    return (
        <Stack.Navigator initialRouteName={routes.CLIENTES}>
            <Stack.Screen name={routes.CLIENTES}
                          options={{headerShown: false}} component={ClientesScreen}/>
            <Stack.Screen name={routes.CREAR_CLIENTE}
                          options={{
                            headerShown: true,
                            headerTitleAlign: 'center',
                            headerLeft: () => (
                                <TouchableOpacity
                                    style={stylesMenu.backButton}
                                    onPress={() => navigation.navigate(routes.CLIENTES, {screen: routes.CLIENTES})}
                                >
                                    <Icon name="reply" size={25} color={colors.verde_primary}/>
                                    <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                </TouchableOpacity>
                            ),
                            }}  component={ClienteNuevo}/>
            <Stack.Screen name={routes.ASIGNAR_CREDITO}
                          options={{
                            headerShown: true,
                            headerTitleAlign: 'center',
                            headerBackImage: () => (
                                <View
                                    style={[stylesMenu.backButton,{marginLeft:0, width:50}]}
                                >
                                    <Icon name="reply" size={25} color={colors.verde_primary}/>
                                </View>
                            ),
                            }}  component={AsignarCredito}/>
            <Stack.Screen name={routes.ENRUTAR_EN}
                          options={{
                            headerShown: true,
                            headerTitleAlign: 'center',
                            headerLeft: () => (
                                <TouchableOpacity
                                    style={stylesMenu.backButton}
                                    onPress={() => navigation.navigate(routes.CLIENTES, {screen: routes.CLIENTES})}
                                >
                                    <Icon name="reply" size={25} color={colors.verde_primary}/>
                                    <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                </TouchableOpacity>
                            ),
                            }} component={EnrutarEn}/>
            <Stack.Screen name={routes.VER_CLIENTE}
                          options={{
                            headerShown: true,
                            headerTitleAlign: 'center',
                            headerTitle:'',
                            headerShadowVisible: false,
                            headerStyle: {
                                backgroundColor: colors.white,
                                // borderBottomColor:'#222222',
                            },
                            // headerTitleStyle:{color:colors.negro},
                            headerBackImage: () => (
                                <View
                                    style={[stylesMenu.backButton,{marginLeft:0, width:50}]}
                                >
                                    <Icon name="reply" size={25} color={colors.verde_primary}/>
                                </View>
                            ),
                            }}  component={VerCliente}/>
            <Stack.Screen name={routes.VER_CREDITOS_CLIENTE}
                          options={{
                            headerShown: true,
                            headerTitleAlign: 'center',
                            headerBackImage: () => (
                                <View
                                    style={stylesMenu.backButton}
                                >
                                    <Icon name="reply" size={25} color={colors.verde_primary}/>
                                    <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                </View>
                            ),
                            }}  component={VerCreditosCliente}/>
        </Stack.Navigator>
    );
}

function CreditosStack() {
    return (
        <StackCreditos.Navigator initialRouteName={routes.CREDITOS}>
            <StackCreditos.Screen name={routes.CREDITOS}
                                  options={{headerShown: false}} component={CreditosScreen}/>
            <StackCreditos.Screen name={routes.VER_ABONOS}
                                  options={{
                                    headerShown: true,
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                                            <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                        </View>
                                    ),
                                    }}  component={VerAbonos}/>
            <StackCreditos.Screen name={routes.VER_CREDITO}
                                  options={{
                                    headerShown: true,
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                        style={[stylesMenu.backButton,{marginLeft:0, width:50}]}
                                        >
                                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                                        </View>
                                    )
                                    }}  component={VerCredito}/>
            <Stack.Screen name={routes.ASIGNAR_CREDITO}
                          options={{
                            headerShown: true,
                            headerTitleAlign: 'center',
                            headerBackImage: () => (
                                <View
                                    style={stylesMenu.backButton}
                                >
                                    <Icon name="reply" size={25} color={colors.verde_primary}/>
                                    <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                </View>
                            ),
                            }}  component={AsignarCredito}/>
            <StackCreditos.Screen 
                name={routes.RECAUDAR}
                options={{
                    headerShown: true,
                    headerTitleAlign: 'center',
                    headerBackImage: () => (
                        <View
                        style={[stylesMenu.backButton,{marginLeft:0, width:50}]}
                        >
                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                        </View>
                    )
                }}  
                component={Recaudar}/>
        </StackCreditos.Navigator>
    );
}

function ListadosStack({navigation}) {
    return (
        <StackListados.Navigator initialRouteName={routes.LISTADOS}>
            <StackListados.Screen name={routes.LISTADOS}
                                  options={{headerShown: false}} component={Listados}/>
            <StackListados.Screen name={routes.CREDITOS_POR_FECHA}
                                  options={{
                                    headerShown: true,
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                                            <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                        </View>
                                    ),
                                    }}   component={CreditosPorFecha}/>
            <StackListados.Screen name={routes.RECAUDO_POR_FECHA}
                                  options={{
                                    headerShown: true,
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                                            <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                        </View>
                                    ),
                                    }}  component={RecaudoPorFecha}/>
        </StackListados.Navigator>
    );
}

function BalanceStack({navigation}) {
    return (
        <StackBalance.Navigator initialRouteName={routes.LISTADOS}>
            <StackBalance.Screen name={routes.BALANCE}
                                 options={{headerShown: false}} component={Balance}/>
            <StackBalance.Screen 
                name={routes.DETALLE_PAGOS}
                options={{
                    headerShown: true,
                    headerTitleAlign: 'center',
                    headerBackImage: () => (
                        <View
                        style={[stylesMenu.backButton,{marginLeft:0, width:50}]}
                        >
                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                        </View>
                    ),
                }} 
                component={DetallePagos}
            />
        </StackBalance.Navigator>
    );
}

//####### Admin ########
function AdministradorStack({navigation}) {
    return (
        <StackAdministrador.Navigator initialRouteName={routes.HOME_ADMIN}>
            <StackAdministrador.Screen name={routes.HOME_ADMIN}
                                       options={{headerShown: false}}
                                       component={HomeAdmin}/>
            <StackAdministrador.Screen name={routes.OPCIONES_RUTA}
                                       options={{headerShown: false}}
                                       component={OpcionesRutaStack}/>
            <StackAdministrador.Screen name={routes.OPCIONES_CUENTA}
                                       options={({route}) => ({
                                           headerShown: true,
                                           headerTintColor: COLORS.white,
                                           headerStyle: {
                                               backgroundColor: COLORS.verde_primary,
                                           },
                                           headerTitleAlign: 'center',
                                           headerLeft: () => (
                                               <TouchableOpacity
                                                   style={stylesMenu.backButton}
                                                   onPress={() => navigation.navigate(routes.HOME_ADMIN, {screen: routes.HOME_ADMIN})}
                                               >
                                                   <Icon name="reply" size={25} color={colors.white}/>
                                               </TouchableOpacity>
                                           ),
                                       })}
                                       component={OpcionesCuenta}/>
            <StackAdministrador.Screen name={routes.REPORTE_USUARIOS_CREADOS}
                                       options={({route}) => ({
                                           headerShown: true,
                                           headerTintColor: COLORS.white,
                                           headerStyle: {
                                               backgroundColor: COLORS.verde_primary,
                                           },
                                           title:'Usuarios asignados',
                                           headerTitleAlign: 'center',
                                           headerLeft: () => (
                                            <TouchableOpacity
                                                style={stylesMenu.backButton}
                                                onPress={() => navigation.navigate(routes.HOME_ADMIN, {screen: routes.HOME_ADMIN})}
                                            >
                                                <Icon name="reply" size={25} color={colors.white}/>
                                            </TouchableOpacity>
                                           ),
                                       })}
                                       component={ReporteUsuariosCreados}/>
        </StackAdministrador.Navigator>
    )
}

// Opciones de Ruta
function OpcionesRutaStack({navigation}) {
    return (
        <StackOpcionesRuta.Navigator initialRouteName={routes.OPCIONES_RUTA}>
            <StackOpcionesRuta.Screen name={routes.OPCIONES_RUTA}
                                      options={({route}) => ({
                                        headerShown: true,
                                        headerTintColor: COLORS.white,
                                        headerStyle: {
                                            backgroundColor: COLORS.verde_primary,
                                        },
                                        headerTitleAlign: 'center',
                                        headerLeft: () => (
                                            <TouchableOpacity
                                                style={stylesMenu.backButton}
                                                onPress={() => navigation.goBack()}
                                            >
                                                <Icon name="reply" size={25} color={colors.white}/>
                                            </TouchableOpacity>
                                        ),
                                    })}
                                      component={OpcionesRuta}/>
            <StackOpcionesRuta.Screen name={routes.LIQUIDAR_RUTA}
                                      options={{headerShown: false}}
                                      component={LiquidarRutaStack}/>
            <StackOpcionesRuta.Screen name={routes.REPORTES}
                                      options={{headerShown: false}}
                                      component={ReportesStack}/>
            <StackOpcionesRuta.Screen name={routes.LIQUIDACIONES}
                                      options={{headerShown: false}}
                                      component={LiquidacionesStack}/>
            <StackOpcionesRuta.Screen name={routes.EDICION_DATOS}
                                      options={{headerShown: false}}
                                      component={EdicionDatosStack}/>
            <StackOpcionesRuta.Screen name={routes.RESTRINGIR_ACCIONES_RUTA}
                                      options={{headerShown: false}}
                                      component={RestringirFunciones}/>
        </StackOpcionesRuta.Navigator>
    )
}

// Liquidaciones
function LiquidacionesStack({navigation}) {
    return (
        <StackOpcionesRuta.Navigator initialRouteName={routes.LIQUIDACIONES}>
            <StackListados.Screen name={routes.LIQUIDACIONES}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.goBack()}
                                          >
                                            <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={VerLiquidaciones}/>
            <StackListados.Screen name={routes.DETALLE_LIQUIDACION}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.LIQUIDACIONES, {screen: routes.LIQUIDACIONES})}
                                          >
                                            <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={DetalleLiquidacion}/>
        </StackOpcionesRuta.Navigator>
    )
}

// Liquidar
function LiquidarRutaStack({navigation}) {
    return (
        <StackLiquidarRuta.Navigator initialRouteName={routes.LIQUIDAR_RUTA}>
            <StackLiquidarRuta.Screen name={routes.LIQUIDAR_RUTA}
                                      options={({route}) => ({
                                          headerShown: true,
                                          headerTintColor: COLORS.white,
                                          headerStyle: {
                                              backgroundColor: COLORS.verde_primary,
                                          },
                                          headerTitleAlign: 'center',
                                          headerLeft: () => (
                                              <TouchableOpacity
                                                  style={stylesMenu.backButton}
                                                  onPress={() => navigation.goBack()}
                                              >
                                                <Icon name="reply" size={25} color={colors.white}/>
                                              </TouchableOpacity>
                                          ),
                                      })}
                                      component={LiquidarRuta}/>
            <StackLiquidarRuta.Screen name={routes.VER_LIQUIDAR_RUTA}
                                      options={({route}) => ({
                                          headerShown: true,
                                          headerTintColor: COLORS.white,
                                          headerStyle: {
                                              backgroundColor: COLORS.verde_primary,
                                          },
                                          headerTitleAlign: 'center',
                                          headerLeft: () => (
                                              <TouchableOpacity
                                                  style={stylesMenu.backButton}
                                                  onPress={() => navigation.navigate(routes.LIQUIDAR_RUTA, {screen: routes.LIQUIDAR_RUTA})}
                                              >
                                                <Icon name="reply" size={25} color={colors.white}/>
                                              </TouchableOpacity>
                                          ),
                                      })}
                                      component={VerRutaALiquidar}/>
        </StackLiquidarRuta.Navigator>
    )
}

// Reportes
function ReportesStack({navigation}) {
    return (
        <StackReportes.Navigator initialRouteName={routes.REPORTES}>
            <StackReportes.Screen name={routes.REPORTES}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.goBack()}
                                          >
                                              <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={Reportes}/>
            <StackReportes.Screen name={routes.RESUMEN_POR_FECHA}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={ResumenPorFecha}/>
            <StackReportes.Screen name={routes.RECAUDADO_POR_FECHA}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={RecaudadoPorFecha}/>
            <StackReportes.Screen name={routes.CREDITOS_POR_FECHA_REP}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={CreditosPorFechaRep}/>
            <StackReportes.Screen name={routes.CREDITOS_VENCIDOS}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={CreditosVencidos}/>
            <StackReportes.Screen name={routes.CREDITOS_SEGUN_ESTADO}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={CreditosSegunEstado}/>
            <StackReportes.Screen name={routes.CREDITOS_NO_ENRUTADOS}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={CreditosNoEnrutados}/>
            <StackReportes.Screen name={routes.CREDITOS_ACTIVOS}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={CreditosActivos}/>
            <StackReportes.Screen name={routes.CLIENTES_INACTIVOS_POR_CALIFICACION}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={ClientesInactivosPorCalificacion}/>
            <StackReportes.Screen name={routes.GASTOS_POR_FECHA}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={GastosPorFecha}/>
            <StackReportes.Screen name={routes.ENTREGAS_SALIDAS_POR_FECHA}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={EntregasSalidasPorFecha}/>
            <StackReportes.Screen name={routes.INVERSIONES_POR_FECHA}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={InversionesPorFecha}/>
            <StackReportes.Screen name={routes.LISTA_RECAUDO_DEL_DIA}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={ListaRecaudoDelDia}/>



            <StackReportes.Screen name={routes.NO_RECAUDADOS_POR_FECHA}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={NoRecaudadoPorFecha}/>
            <StackReportes.Screen name={routes.CRECIMIENTO_CARTERA}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={CrecimientoCartera}/>
            <StackReportes.Screen name={routes.CARTERA_ACTUAL}
                                  options={({route}) => ({
                                      headerShown: true,
                                      headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                      headerTitleAlign: 'center',
                                      headerLeft: () => (
                                          <TouchableOpacity
                                              style={stylesMenu.backButton}
                                              onPress={() => navigation.navigate(routes.REPORTES, {screen: routes.REPORTES})}
                                          >
                                          <Icon name="reply" size={25} color={colors.white}/>
                                          </TouchableOpacity>
                                      ),
                                  })}
                                  component={CarteraActual}/>
            <StackReportes.Screen name={routes.RECAUDAR}
                              options={{
                                headerShown: true,
                                headerTintColor: COLORS.white,
                                  headerStyle: {
                                      backgroundColor: COLORS.verde_primary,
                                  },
                                headerTitleAlign: 'center',
                                headerBackImage: () => (
                                    <View
                                        style={stylesMenu.backButton}
                                    >
                                        <Icon name="reply" size={25} color={colors.white}/>
                                    </View>
                                ),
                                }}  component={Recaudar}/>
            <StackReportes.Screen name={routes.VER_ABONOS}
                                  options={{
                                    headerShown: true,
                                    headerTintColor: COLORS.white,
                                      headerStyle: {
                                          backgroundColor: COLORS.verde_primary,
                                      },
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                        <Icon name="reply" size={25} color={colors.white}/>
                                        </View>
                                    ),
                                    }}  component={VerAbonos}/>
        </StackReportes.Navigator>
    )
}

// Edicion de datos
function EdicionDatosStack({navigation}) {
    return (
        <StackEdicionDatos.Navigator initialRouteName={routes.EDICION_DATOS}>
            <StackDelegados.Screen name={routes.EDICION_DATOS}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.goBack()}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={EdicionDatos}/>
            <StackDelegados.Screen name={routes.AGREGAR_CREDITO}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={AgregarCredito}/>
            <StackDelegados.Screen name={routes.AGREGAR_CLIENTE_CREDITO}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={AgregarClienteCredito}/>
            <StackDelegados.Screen name={routes.ASIGNAR_CREDITO_ADMIN}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={AsignarCreditoAdmin}/>

            <StackDelegados.Screen name={routes.BORRAR_MODIFICAR_CREDITOS}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={BorrarModificarCredito}/>


            <StackDelegados.Screen name={routes.VER_CREDITO}
                                  options={{
                                    headerShown: true,
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                        <Icon name="reply" size={25} color={colors.white}/>
                                        </View>
                                    ),
                                    }}  component={VerCredito}/>

            <StackDelegados.Screen name={routes.VER_ABONOS}
                                  options={{
                                    headerShown: true,
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                        <Icon name="reply" size={25} color={colors.white}/>
                                        </View>
                                    ),
                                    }}  component={VerAbonos}/>

            <StackDelegados.Screen name={routes.RECAUDAR}
                              options={{
                                headerShown: true,
                                headerTintColor: COLORS.white,
                                headerStyle: {
                                    backgroundColor: COLORS.verde_primary,
                                },
                                headerTitleAlign: 'center',
                                headerBackImage: () => (
                                    <View
                                        style={stylesMenu.backButton}
                                    >
                                    <Icon name="reply" size={25} color={colors.white}/>
                                    </View>
                                ),
                                }}  component={Recaudar}/>


            <StackDelegados.Screen name={routes.EDITAR_CREDITO}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={OpcionesCredito}/>

            <StackDelegados.Screen name={routes.BORRAR_ABONOS_PAGOS}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={BorrarAbonoPagos}/>
            <StackDelegados.Screen name={routes.BORRAR_GASTOS_ENTREGAS_INGRESOS}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={BorrarGastosEntregasIngresos}/>

            <StackDelegados.Screen name={routes.CAMBIAR_SUPERCLAVE}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={CambiarSuperclave}/>
            <StackDelegados.Screen name={routes.CAMBIAR_DATOS_ACCESO}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.EDICION_DATOS, {screen: routes.EDICION_DATOS})}
                                           >
                                           <Icon name="reply" size={25} color={colors.white}/>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={CambiarDatosAcceso}/>
        </StackEdicionDatos.Navigator>
    )
}

// Delegados
function DelegadosStack({navigation}) {
    return (
        <StackDelegados.Navigator initialRouteName={routes.DELEGADOS}>
            <StackDelegados.Screen name={routes.DELEGADOS}
                                    initialParams={{notReload:false}}
                                   options={({route}) => ({
                                    headerShown: true,
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    headerLeft: () => (
                                        <TouchableOpacity
                                            style={stylesMenu.backButton}
                                            onPress={() => navigation.navigate(routes.HOME_ADMIN, {screen: routes.HOME_ADMIN})}
                                        >
                                            <Icon name="reply" size={25} color={colors.white}/>
                                        </TouchableOpacity>
                                    ),
                                })}
                                   component={Delegados}/>
            <StackDelegados.Screen name={routes.DELEGAR_CUENTAS}
                                   options={({route}) => ({
                                    headerShown: true,
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    
                                    headerLeft: () => (
                                        <TouchableOpacity
                                            style={stylesMenu.backButton}
                                            onPress={() => {
                                                navigation.navigate(routes.DELEGADOS, {screen: routes.DELEGADOS, params:{notReload:true}})
                                            }}
                                        >
                                            <Icon name="reply" size={25} color={colors.white}/>
                                        </TouchableOpacity>
                                    ),
                                })}
                                   component={DelegarCuentas}
                                   />
            <StackDelegados.Screen name={routes.OPCIONES_DELEGADO}
                                   options={{headerShown: false}} component={OpcionesDelegado}/>
        </StackDelegados.Navigator>
    )
}

// Productos
function ProductosStack({navigation}) {
    return (
        <StackProductos.Navigator initialRouteName={routes.PRODUCTOS}>
            <StackProductos.Screen name={routes.PRODUCTOS}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.HOME_ADMIN, {screen: routes.HOME_ADMIN})}
                                           >
                                               <Icon name="reply" size={25} color={colors.verde_primary}/>
                                               <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={Productos}/>
            <StackProductos.Screen name={routes.DELEGAR_CUENTAS}
                                   options={({route}) => ({
                                       headerShown: true,
                                       headerTintColor: COLORS.white,
                                       headerStyle: {
                                           backgroundColor: COLORS.verde_primary,
                                       },
                                       headerTitleAlign: 'center',
                                       headerLeft: () => (
                                           <TouchableOpacity
                                               style={stylesMenu.backButton}
                                               onPress={() => navigation.navigate(routes.PRODUCTOS, {screen: routes.PRODUCTOS})}
                                           >
                                               <Icon name="reply" size={25} color={colors.verde_primary}/>
                                               <Text style={{textAlignVertical: 'center', marginLeft: 2}}>Atrás</Text>
                                           </TouchableOpacity>
                                       ),
                                   })}
                                   component={DelegarCuentas}/>
            <StackProductos.Screen name={routes.OPCIONES_DELEGADO}
                                   options={{headerShown: false}} component={OpcionesDelegado}/>
        </StackProductos.Navigator>
    )
}

const obtenerPrivilegio = async () => {
    try {
        const privilegio = await AsyncStorage.getItem('privilegio');
        return privilegio;
    } catch (error) {
        console.log('Error al obtener el privilegio:', error);
        return null;
    }
};



function MenuNavigator() {
    const [isConnected, setIsConnected] = React.useState(true);
    const { isSync } = useIconContext();
    const [ valSync, setValSync ] = React.useState("0deg");

    React.useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
          setIsConnected(state.isConnected);
        });
    
        return () => {
          unsubscribe();
        };
    }, []);

    const [privilegio, setPrivilegio] = React.useState<string | null>('');
    const [isLoading, setIsLoading] = React.useState(true);

    const navigation = useNavigation();

    useEffect(() => {
        const getPrivilegio = async () => {
            const valor = await obtenerPrivilegio();
            setPrivilegio(valor);
            setIsLoading(false);
        };
        getPrivilegio();
    }, []);

    const logout = async() => {
        navigation.replace('Login');
        await AsyncStorage.clear()
    };

    useEffect(() => {
        let intervalId;

        const cargando = async () => {
            if (isSync) {
            intervalId = setInterval(() => {
                setValSync((prevValSync) => (prevValSync === "0deg" ? "90deg" : prevValSync === "90deg" ? "180deg" : prevValSync === "180deg" ? "270deg" : "0deg"));
            }, 500); // Cambia el intervalo de tiempo según tus necesidades
            } else {
            clearInterval(intervalId); // Detener la ejecución periódica si no está sincronizando
            setValSync("0deg");
            }
        };

        cargando();

        return () => {
            clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
        };
    }, [isSync]);

    if (isLoading) {
        return null;
    }

    const CustomDrawerContent = (props)=> {
        return (
          <DrawerContentScrollView {...props}>
            
                <DrawerItemList {...props} />
                <DrawerItem
                    style={{
                        backgroundColor: colors.rojo,
                    }}
                    labelStyle={{
                        color: colors.white,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                    icon={()=><Icon name="logout" size={25} color={colors.white}/>}
                    label="Cerrar sesión"
                    onPress={()=>{logout()}}
                />
          </DrawerContentScrollView>
        );
    }

    return (
        <Drawer.Navigator screenOptions={{ drawerType: "back", }} drawerContent={props => <CustomDrawerContent {...props} />}>
            {
                privilegio != 'U' ? (
                    <>
                        {/*********  OPCIONES ADMINISTRADOR **********/}
                        <Drawer.Screen name={'Principal'} component={AdministradorStack}
                                       options={({navigation}) => ({
                                           headerShown: navigation.getState().routes[0].state?.routes == undefined ||
                                               navigation.getState().routes[0].state?.routes?.length == 1,
                                           headerTintColor: COLORS.white,
                                           headerStyle: {
                                               backgroundColor: COLORS.verde_primary,
                                           },
                                           headerTitleAlign: 'center',
                                           drawerIcon: () => (
                                               <Icon name="home" size={25} color={colors.verde_primary}/>
                                           ),
                                           drawerActiveTintColor: colors.verde_primary,
                                           drawerActiveBackgroundColor: colors.verde_shadow
                                       })}></Drawer.Screen>

                        <Drawer.Screen name={ROUTES.EDITAR_USUARIO_ADMIN} component={EditarUsuarioAdmin}
                                       options={() => ({
                                           headerTintColor: COLORS.white,
                                           headerStyle: {
                                               backgroundColor: COLORS.verde_primary,
                                           },
                                           headerTitleAlign: 'center',
                                           headerTitle: 'Usuario',
                                           headerLeft: () => (
                                               <TouchableOpacity
                                                   style={stylesMenu.backButton}
                                                   onPress={() => navigation.navigate(routes.HOME_ADMIN, {screen: routes.HOME_ADMIN})}
                                               >
                                                   <Icon name="reply" size={25} color={colors.white}/>
                                               </TouchableOpacity>
                                           ),
                                           drawerIcon: () => (
                                               <Icon name="person" size={25} color={colors.verde_primary}/>
                                           ),
                                           drawerActiveTintColor: colors.verde_primary,
                                           drawerActiveBackgroundColor: colors.verde_shadow
                                       })}></Drawer.Screen>

                        {
                            privilegio != 'A' ? (
                                <Drawer.Screen name={ROUTES.CREAR_USUARIO} component={CrearUsuario}
                                               options={() => ({
                                                   headerTintColor: COLORS.white,
                                                   headerStyle: {
                                                       backgroundColor: COLORS.verde_primary,
                                                   },
                                                   headerTitleAlign: 'center',
                                                   headerLeft: () => (
                                                       <TouchableOpacity
                                                           style={stylesMenu.backButton}
                                                           onPress={() => navigation.navigate(routes.HOME_ADMIN, {screen: routes.HOME_ADMIN})}
                                                       >
                                                           <Icon name="reply" size={25} color={colors.white}/>
                                                       </TouchableOpacity>
                                                   ),
                                                   drawerIcon: () => (
                                                       <Icon name="person" size={25} color={colors.verde_primary}/>
                                                   ),
                                                   drawerActiveTintColor: colors.verde_primary,
                                                   drawerActiveBackgroundColor: colors.verde_shadow
                                               })}></Drawer.Screen>
                            ) : (
                                <></>
                            )
                        }
                        {
                            privilegio != 'A' && privilegio != 'M' ? (
                                <Drawer.Screen name={ROUTES.DELEGADOS} component={DelegadosStack}
                                               options={() => ({
                                                   headerShown: false,
                                                   headerTintColor: COLORS.white,
                                                   headerStyle: {
                                                       backgroundColor: COLORS.verde_primary,
                                                   },
                                                   headerTitleAlign: 'center',
                                                   headerLeft: () => null,
                                                   drawerIcon: () => (
                                                       <Icon name="supervisor-account" size={25}
                                                             color={colors.verde_primary}/>
                                                   ),
                                                   drawerActiveTintColor: colors.verde_primary,
                                                   drawerActiveBackgroundColor: colors.verde_shadow
                                               })}></Drawer.Screen>
                            ) : (
                                <></>
                            )
                        }
                        {/* {
                            privilegio != 'A' && privilegio != 'M' ? (
                                <Drawer.Screen name={ROUTES.LISTA_PRODUCTOS} component={ProductosStack}
                                               options={() => ({
                                                   headerShown: false,
                                                   headerTintColor: COLORS.white,
                                                   headerStyle: {
                                                       backgroundColor: COLORS.verde_primary,
                                                   },
                                                   headerTitleAlign: 'center',
                                                   headerLeft: () => null,
                                                   drawerIcon: () => (
                                                       <Icon name="category" size={25}
                                                             color={colors.verde_primary}/>
                                                   ),
                                                   drawerActiveTintColor: colors.verde_primary,
                                                   drawerActiveBackgroundColor: colors.verde_shadow
                                               })}></Drawer.Screen>
                            ) : (
                                <></>
                            )
                        } */}
                    </>
                ) : (
                    <>
                        <Drawer.Screen name={ROUTES.HOME} component={HomeStack} options={() => ({
                            headerTintColor: COLORS.white,
                            headerStyle: {
                                backgroundColor: COLORS.verde_primary,
                            },
                            headerTitleAlign: 'center',
                            drawerIcon: () => (
                                <Image style={{width: 25, height: 25, marginLeft: 1}}
                                       resizeMode="center"
                                       source={require('../assets/drawer-icons/CASA.png')}></Image>
                            ),
                            headerTitle: () => (
                                <View style={{flexDirection:'row'}}>
                                    { Platform.OS !== 'web' ?
                                    (<Icon style={{ marginRight: 10 , marginTop : 3}} size={15} name={isConnected ? 'wifi' : 'wifi-off'} color={isConnected ? colors.white : colors.rojo}></Icon>):(<></>)
                                    }
                                    <Text style={{color:"white",fontSize:20,fontWeight:"600"}}>{ROUTES.HOME}</Text>
                                    { isSync && Platform.OS !== 'web' ?
                                    (<Icon style={{ marginLeft: 8 , marginTop : 5, transform: [{ rotate: valSync }],width:15,height:15 }} size={15} name='sync' color={colors.white}></Icon>):(<></>)
                                    }
                                </View>
                            ),
                            drawerActiveTintColor: colors.verde_primary,
                            drawerActiveBackgroundColor: colors.verde_shadow
                        })}></Drawer.Screen>
                        {
                            isConnected ? (
                                <Drawer.Screen name={ROUTES.CLIENTES} component={ClienteStack} options={() => ({
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    drawerIcon: () => (
                                        <Image style={{width: 25, height: 25, marginLeft: 1}}
                                            resizeMode="center"
                                            source={require('../assets/drawer-icons/CLIENTES.png')}></Image>
                                    ),
                                    drawerActiveTintColor: colors.verde_primary,
                                    drawerActiveBackgroundColor: colors.verde_shadow,
                                    freezeOnBlur: false
                                })}></Drawer.Screen>
                            ):(<></>)
                        }
                        {
                            isConnected ? (
                                <Drawer.Screen name={ROUTES.CREDITOS} component={CreditosStack} options={() => ({
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    drawerIcon: () => (
                                        <Image style={{width: 25, height: 25, marginLeft: 1}}
                                            resizeMode="center"
                                            source={require('../assets/drawer-icons/CREDITOS.png')}></Image>
                                    ),
                                    drawerActiveTintColor: colors.verde_primary,
                                    drawerActiveBackgroundColor: colors.verde_shadow
                                })}></Drawer.Screen>
                            ):(<></>)
                        }
                        {
                            isConnected ? (
                                <Drawer.Screen name={ROUTES.LISTADOS} component={ListadosStack} options={() => ({
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    drawerIcon: () => (
                                        <Image style={{width: 25, height: 25, marginLeft: 1}}
                                            resizeMode="center"
                                            source={require('../assets/drawer-icons/LISTADOS.png')}></Image>
                                    ),
                                    drawerActiveTintColor: colors.verde_primary,
                                    drawerActiveBackgroundColor: colors.verde_shadow
                                })}></Drawer.Screen>
                            ):(<></>)
                        }
                        {
                            isConnected ? (
                                <Drawer.Screen name={ROUTES.RUTA} component={Ruta} options={() => ({
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    drawerIcon: () => (
                                        <Image style={{width: 25, height: 25, marginLeft: 1}}
                                            resizeMode="center"
                                            source={require('../assets/drawer-icons/RUTAS.png')}></Image>
                                    ),
                                    drawerActiveTintColor: colors.verde_primary,
                                    drawerActiveBackgroundColor: colors.verde_shadow
                                })}></Drawer.Screen>
                            ):(<></>)
                        }
                        {
                            isConnected ? (
                                <Drawer.Screen name={ROUTES.BALANCE} component={BalanceStack} options={() => ({
                                    headerTintColor: COLORS.white,
                                    headerStyle: {
                                        backgroundColor: COLORS.verde_primary,
                                    },
                                    headerTitleAlign: 'center',
                                    drawerIcon: () => (
                                        <Image style={{width: 25, height: 25, marginLeft: 1}}
                                            resizeMode="center"
                                            source={require('../assets/drawer-icons/BALANCE.png')}></Image>
                                    ),
                                    drawerActiveTintColor: colors.verde_primary,
                                    drawerActiveBackgroundColor: colors.verde_shadow
                                })}></Drawer.Screen>
                            ):(<></>)
                        }
                        {/* <Drawer.Screen name={ROUTES.AGREGAR_A_LISTA} component={AgregarLista} options={() => ({
                    headerTintColor: COLORS.white,
                    headerStyle: {
                        backgroundColor: COLORS.verde_primary,
                    },
                    headerTitleAlign: 'center',
                    drawerIcon: () => (
                        <Image style={{ width: 25, height: 25, marginLeft: 1 }}
                            resizeMode="center"
                            source={require('../assets/drawer-icons/AGREGAR-LISTA.png')}></Image>
                    ),
                    drawerActiveTintColor: colors.verde_primary,
                    drawerActiveBackgroundColor: colors.verde_shadow
                })}></Drawer.Screen> */}
                        
                    </>
                )
            }
        </Drawer.Navigator>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    }
});

export default MenuNavigator;
