import React, {useEffect, useRef, useState} from 'react';
import {Linking, Modal, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View, RefreshControl, Image, Dimensions, Pressable} from 'react-native';
import {Icon} from 'react-native-elements';

import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import colors from '../constants/colors';
import axios from 'axios';
import routes from '../constants/routes';
import {SelectList} from 'react-native-dropdown-select-list';
import urlsGenerales from '../constants/urlsGenerales';
import {StringUtils} from '../shared/StringUtils';
import ModalOperacionAdmin from "../components/ModalOperacionAdmin";
import {diasSemana, textoSpinner, tiposPlazoCredito} from '../shared/TiposDocumento';
import Spinner from "react-native-loading-spinner-overlay";
import {AuthenticationService} from "../shared/AuthenticationService";
import ModalCalendar from "../components/ModalCalendar";
import {DateUtils} from "../shared/DateUtils";
import CustomAlert from '../shared/CustomAlert';
import { StorageUtil } from '../shared/StorageUtil';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import moment from 'moment';


const windowWidth = Dimensions.get("window").width;

export default function VerCredito() {

    const route = useRoute();
    const {codCredito} = route.params;
    const insets = useSafeAreaInsets()
    const navigation = useNavigation();
    const [loading, setLoading] = useState(false);
    const [credito, setCredito] = useState({});
    const [fecha, setFecha] = useState(DateUtils.getDateStringYYYYMMDD());
    const [mostrarFechaDesde, setMostrarFechaDesde] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleAdmin, setModalVisibleAdmin] = useState(false);
    const [modalConfirmarVisible, setModalConfirmarVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [saldo, setSaldo] = useState(0);
    const [valorCredito, setValorCredito] = useState('');
    const [interes, setInteres] = useState('');
    const [cantCuotas, setCantCuotas] = useState('1');
    const [valorCuota, setValorCuota] = useState('');
    const [diaFrecuencia, setDiaFrecuencia] = useState('');
    const [diaFrecuencia2, setDiaFrecuencia2] = useState('');
    const [totalDeuda, setTotalDeuda] = useState('');
    const [valorCuotaAdi, setValorCuotaAdi] = useState(0);
    const [frecuencia, setFrecuencia] = useState(0);
    const [diaSemana, setDiaSemana] = useState('');
    const [diaQuincenaInicio, setDiaQuincenaInicio] = useState('');
    const [diaQuincenaFin, setDiaQuincenaFin] = useState('');
    const [diaMes, setDiaMes] = useState('');
    const [refreshing, setRefreshing] = useState(false);
    const [errorCapital, setErrorCapital] = useState('');
    const [errorInteres, setErrorInteres] = useState('');
    const [errorCuotas, setErrorCuotas] = useState('');
    const [errorFrecuencia, setErrorFrecuencia] = useState('');

    const fechaDesdeRef = useRef(null);


    const showModalConfirmCrear = () => {
        if (valorCredito.trim() === '' || interes.trim() === '' || String(frecuencia).trim()==='') {
            if(valorCredito.trim() === ''){
                setErrorCapital('Campo requerido')
            }
            if(interes.trim() === ''){
                setErrorInteres('Campo requerido')
            }
            if(String(frecuencia).trim() === ''){
                setErrorFrecuencia('Campo requerido')
            }
            return;
        }
        setModalConfirmarVisible(true);
    }

    const aceptarPlazo = () => {
        switch (frecuencia+"") {
            case '0':
                setDiaFrecuencia('0');
                setDiaFrecuencia2("0");
                break;
            case '1':
                const descDiaSemana = diaSemana;
                setDiaFrecuencia(descDiaSemana);
                setDiaFrecuencia2("0");
                break;
            case '2':
                setDiaFrecuencia(diaQuincenaInicio);
                setDiaFrecuencia2(diaQuincenaFin);
                break;
            case '3':
                setDiaFrecuencia(diaMes.toString());
                setDiaFrecuencia2("0");
                break;
        }
        setModalVisible(false);
    }

    const mostrarModal = () => {
        setErrorFrecuencia('')

        if (valorCredito.trim() === '' || interes.trim() === '') {
            if(valorCredito.trim() === ''){
                setErrorCapital('Campo requerido')
            }
            if(interes.trim() === ''){
                setErrorInteres('Campo requerido')
            }
            return;
        }


        const interesMultiplicar = (Number(interes) + 100) / 100;
        const valorDeudaTotal = Math.floor(Number(valorCredito) * interesMultiplicar);
        const valorCuotaInicial = Math.floor(valorDeudaTotal / Number(cantCuotas));

        setValorCuota(valorCuotaInicial.toFixed(1).toString());
        setModalVisible(true);
    };

    const verAbonos = () => {
        navigation.navigate(routes.VER_ABONOS, {codCredito: credito.codCredito})
    }

    const pagar = () => {
        navigation.navigate(routes.RECAUDAR, {recaudo: credito});
    }

    const handleFechaDesde = (fechaDesdeModal: string) => {
        setMostrarFechaDesde(false);
        setFecha(fechaDesdeModal);
    }

    const handlePressModificar = () => {
        setModalConfirmarVisible(false);
        setModalVisibleAdmin(true);
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {

        setModalVisibleAdmin(false);

        setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            setModalVisibleAdmin(false);
            CustomAlert.errorCredenciales();
            setLoading(false);
            return;
        }
        setLoading(false);

        guardarModif(usuario, clave);
    }

    const showSpinner = () => {
        setLoading(true);
    };

    // Función para ocultar el spinner
    const hideSpinner = () => {
        setLoading(false);
    };

    const changeCantCuotas = (text: string) => {
        setErrorCuotas('')
        let nrocta = text
        if(text== null  || text == undefined || text == '0'){
            nrocta = '1'
        }
        setCantCuotas(nrocta);
        const valorCuotaTemp = Math.floor(Number(totalDeuda) / Number(nrocta));
        const valorCuotaAdicional = Number(totalDeuda) % Number(nrocta);
        setValorCuota(valorCuotaTemp.toFixed(1).toString());
        setValorCuotaAdi(valorCuotaAdicional);
    }

    const changeValorCredito = (text: string) => {
        setErrorCapital('')
        setValorCredito(text);
        const interesMultiplicar = (Number(interes) + 100) / 100;
        const valorCuotaInicial = Math.round(Number(text) * interesMultiplicar);
        setValorCuota(valorCuotaInicial.toFixed(2).toString());
        setTotalDeuda(valorCuotaInicial.toFixed(2).toString());
    }

    const changeInteres = (text: string) => {
        setErrorInteres('')
        setInteres(text);
        const interesMultiplicar = (Number(text) + 100) / 100;
        const valorCuotaInicial = Math.round(Number(valorCredito) * interesMultiplicar);
        setValorCuota(valorCuotaInicial.toFixed(2).toString());
        setTotalDeuda(valorCuotaInicial.toFixed(2).toString());
    }

    const guardarModif = async (usu,pass) => {
        if (loading) {
            showSpinner();
            return;
        }

        const formData = new FormData();
        formData.append('usu', usu);
        formData.append('pass', pass);
        formData.append('codUsuario', credito.codUsuario);
        formData.append('codCliente', credito.codCliente);
        formData.append('codCredito', credito.codCredito);
        formData.append('fecha', fecha);
        formData.append('valorCredito', valorCredito);
        formData.append('interes', interes);
        formData.append('cantCuotas', cantCuotas);
        formData.append('valorCuota', valorCuota);
        formData.append('codFrecuencia', frecuencia+"");
        formData.append('diaFrecuencia', diaFrecuencia);
        formData.append('diaFrecuencia2', diaFrecuencia2);
        formData.append('totalDeuda', totalDeuda);

        try {
            setLoading(true);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.EDITAR_CREDITO_V2, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data.mensaje && response.data.mensaje == "Actualizado") {
                await fetchData(true)
            }else{
                CustomAlert.show({
                    title: 'Mensaje',
                    message:  response.data.mensaje
                });
            }

        } catch (error) {
            console.log('Se cae en error -> ', urlsGenerales.EDITAR_CREDITO);
            console.error(error);
            hideSpinner();
        } finally {
            hideSpinner();
        }
    }

    useEffect(() => {
        fetchData();
    }, [])
    
    const fetchData = async (update?) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('codCredito', codCredito!);
            const token = await StorageUtil.getTokenStorage(); 
            const response = await axios.post(urlsGenerales.DETALLE_CREDITO_INFO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            setCredito(response.data);
            
            setFecha(response.data.fecha);
            setValorCredito(response.data.valorCredito);
            setInteres(response.data.interes);
            setValorCuota(response.data.valorCuota);
            setTotalDeuda(response.data.totalDeuda);
            setSaldo(response.data.saldoCredito);
            setFrecuencia(response.data.codFrecuencia);
            setDiaFrecuencia(response.data.diaFrecuencia);
            setDiaQuincenaInicio(response.data.diaFrecuencia);
            setDiaFrecuencia2(response.data.diaFrecuencia2);
            setDiaQuincenaFin(response.data.diaFrecuencia2);
            setDiaMes(response.data.diaFrecuencia);
            setCantCuotas(response.data.cantCuotas);
            


        } catch (error) {
            console.log('Error en -> ', urlsGenerales.DETALLE_CREDITO)
            console.log(error);
        } finally {
            setLoading(false);
            if(update){
                CustomAlert.show({
                    title: 'Mensaje',
                    message: 'Se actualizaron los datos del Credito.'
                });
            }
        }
    };

    const [modalTicketVenta, setModalTicketVenta] = useState(false);
    const [modalCronogramaPagos, setModalCronogramaPagos] = useState(false);

    const onRefresh = async() => {
        await setRefreshing(true);
        await fetchData()
        await setRefreshing(false)
    }

    const randomString = ()=>{
        return Math.random().toString(36).substring(7);
    } 

    useEffect(() => {
        navigation.setOptions({
            headerTitle:`Crédito #${codCredito}`
        });
    }, [navigation,codCredito]); 

    useFocusEffect(
        React.useCallback(() => {
            if(Object.keys(credito).length>0){ 
                const today = moment().format("YYYY-MM-DD");
                const beforeToday= moment().subtract(1, 'days').format("YYYY-MM-DD");
                const creditCreation = moment(credito.timestamp).format("YYYY-MM-DD");
                if(creditCreation == today || creditCreation == beforeToday){
                    console.log("Esta dentro del limite de fecha para modificar")
                    if(Number(credito.totalDeuda) != Number(credito.saldoCredito)){
                        console.log("Existen abonos en el credito")
                        setEdit(false)
                    }else{
                        console.log("Puede modificar el credito")
                        setEdit(true)
                    }
                }else{
                    console.log("La fecha de creacion del credito es muy antigua")
                    setEdit(false)
                }
            }else{
                setEdit(false)
            }
        }, [credito])
    );

    return (
        <>
            <ScrollView
                contentContainerStyle={styles.container}
                showsHorizontalScrollIndicator={false}
                
                showsVerticalScrollIndicator={false}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
            >

                <View style={styles.crtInfoUser}>
                    <View style={{display:'flex', alignSelf:'center',}}>
                        <Image 
                            style={[styles.circleStatus]}
                            resizeMode="center"
                            source={require('../assets/client.png')}
                        />
                    </View>
                    <View style={{flex:1, alignSelf:'center'}}>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.labelTitle}>{credito.nombre} {credito.apellido}</Text>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.labelSubTitle}>{credito.telefono}</Text>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.labelSubTitle}>{credito.direccion}</Text>
                    </View>
                </View>

                <View style={[styles.row,{marginBottom:16, borderTopWidth:0.5, borderBottomWidth:0.5, borderColor:'#22222230'}]}>
                    <TouchableOpacity style={[styles.botonFooter]}
                                    onPress={() => {
                                        setModalTicketVenta(true);
                                        console.log(urlsGenerales.S24b + '?codCredito=' + credito.codCredito  + '&random=' + randomString())
                                    }}>
                                    <View style={{display:'flex',height:40,width:40, borderRadius:20,justifyContent:'center', backgroundColor:colors.verde_primary,alignSelf:'center'}}>
                                        <Icon name='confirmation-number' color={colors.white} size={20} style={{alignSelf:'center'}}></Icon>
                                    </View>
                        <Text style={styles.labelBoton}>Ticket{'\n'}venta</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={[styles.botonFooter]}
                                    onPress={() => {
                                        setModalCronogramaPagos(true);
                                        console.log(urlsGenerales.S25b + '?codCredito=' + credito.codCredito  + '&random=' + randomString())
                                    }}>
                        
                        <View style={{display:'flex',height:40,width:40, borderRadius:20,justifyContent:'center', backgroundColor:colors.logo_azul,alignSelf:'center'}}>
                                        <Icon name='confirmation-number' color={colors.white} size={20} style={{alignSelf:'center'}}></Icon>
                                    </View>
                        <Text style={styles.labelBoton}>Cronograma{'\n'}pagos</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.botonFooter]}
                                    onPress={pagar}>
                        
                        <View style={{display:'flex',height:40,width:40, borderRadius:20,justifyContent:'center', backgroundColor:colors.logo_naranja,alignSelf:'center'}}>
                                        <Icon name='attach-money' color={colors.white} size={20} style={{alignSelf:'center'}}></Icon>
                                    </View>
                        <Text style={styles.labelBoton}>Abonar{'\n'}crédito</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.botonFooter]}
                                    onPress={verAbonos}>
                        
                        <View style={{display:'flex',height:40,width:40, borderRadius:20,justifyContent:'center', backgroundColor:colors.logo_gris,alignSelf:'center'}}>
                                        <Icon name='visibility' color={colors.white} size={20} style={{alignSelf:'center'}}></Icon>
                                    </View>
                        <Text style={styles.labelBoton}>Ver{'\n'}abonos</Text>
                    </TouchableOpacity>
                </View>
                
                {
                    !edit && !loading &&
                    <View style={[styles.row,{ paddingHorizontal:16, marginBottom:16}]}>
                        <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                        <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Lo sentimos ya no puede modificar este crédito.</Text>
                    </View>
                }
                    
                <View style={{paddingHorizontal:16}}>
                    <Text style={styles.textBox}>
                        {credito.detEstado}
                    </Text>
                </View>
                
                <View style={{paddingHorizontal:16}}>
                    <Text style={styles.labelInput}>Fecha</Text>
                    {edit ? 
                    <TextInput
                        style={styles.inputText}
                        placeholder='yyyy-mm-dd'
                        value={fecha}
                        onPressIn={() => setMostrarFechaDesde(true)}
                        ref={fechaDesdeRef}
                        onFocus={() => {
                            setMostrarFechaDesde(true);
                            // @ts-ignore
                            fechaDesdeRef.current.blur();
                        }}
                    >
                    </TextInput>
                    :
                    <TextInput
                        editable={false}
                        style={[styles.inputText,{borderColor:colors.gris_fondo_cell,backgroundColor:colors.gris_fondo_cell}]}
                        placeholder='yyyy-mm-dd'
                        value={fecha}
                        ref={fechaDesdeRef}
                    >
                    </TextInput>
                }
                    
                    <View>
                    <Text style={styles.labelInput}>Crédito</Text>
                    <TextInput
                        editable={false}
                        style={[styles.inputText,{borderColor:colors.gris_fondo_cell,backgroundColor:colors.gris_fondo_cell}]}
                        placeholder='0'
                        keyboardType='numeric'
                        value={valorCredito}
                        onChangeText={changeValorCredito}></TextInput>
                        
                        {
                            errorCapital.length>0?<Text style={styles.lblError}>{errorCapital}</Text>:null
                        }
                    </View>

                    <View style={styles.rowInput}>
                        <View style={{width:100,marginRight:16}}>
                            <Text style={styles.labelInput}>Interés (%)</Text>
                            <TextInput
                                editable={edit}
                                style={[styles.inputText,errorInteres.length>0?{borderColor:colors.rojo}:{},!edit?{borderColor:colors.gris_fondo_cell,backgroundColor:colors.gris_fondo_cell}:{}]}
                                placeholder='0'
                                keyboardType="numeric"
                                value={interes}
                                onChangeText={changeInteres}></TextInput>
                            {
                                errorInteres.length>0?<Text style={styles.lblError}>{errorInteres}</Text>:null
                            }
                        </View>
                        <View style={{flex:1}}>
                            <Text style={styles.labelInput}>Plazo</Text>
                            <TouchableOpacity
                                disabled={!edit}
                                style={[styles.inputText,{justifyContent:'center'},errorFrecuencia.length>0?{borderColor:colors.rojo}:{},!edit?{borderColor:colors.gris_fondo_cell,backgroundColor:colors.gris_fondo_cell}:{}]}
                                onPress={mostrarModal}>
                                <Text style={styles.labelPlazo}>{StringUtils.getDescPlazo(String(frecuencia), cantCuotas, diaFrecuencia,
                                    diaQuincenaInicio, diaQuincenaFin)}</Text>
                            </TouchableOpacity>
                            {
                                errorFrecuencia.length>0?<Text style={styles.lblError}>{errorFrecuencia}</Text>:null
                            }
                        </View>
                    </View>

                    <View style={styles.crtInfo}>
                        <View style={[styles.boxInfo,{backgroundColor:colors.gris_fondo_cell}]}>
                            {/* <View style={{flex:1}}>
                                <Icon name='attach-money' color={colors.black} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                            </View> */}
                            <Text style={[styles.lblPay,{color:colors.negro}]}>S/ {Number(valorCuota).toFixed(2)}</Text>
                            <Text style={[styles.lblInfo,{color:colors.negro}]}>Valor Cuota</Text>
                        </View>
                        <View style={{width:8,}}></View>
                        <View style={[styles.boxInfo,{backgroundColor:colors.gris_fondo_cell}]}>
                            {/* <View style={{flex:1}}>
                                <Icon name='attach-money' color={colors.black} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                            </View> */}
                            <Text style={[styles.lblPay,{color:colors.negro}]}>S/ {Number(totalDeuda).toFixed(2)}</Text>
                            <Text style={[styles.lblInfo,{color:colors.negro}]}>Total a Pagar</Text>
                        </View>
                        <View style={{width:8,}}></View>
                        <View style={[styles.boxInfo,{backgroundColor:colors.logo_naranja}]}>
                            {/* <View style={{flex:1}}>
                                <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                            </View> */}
                            <Text style={styles.lblPay}>S/ {Number(saldo).toFixed(2)}</Text>
                            <Text style={[styles.lblInfo]}>Saldo Actual</Text>
                        </View>
                    </View>
                    {edit && 
                        <TouchableOpacity
                                style={styles.btnLogin}
                                onPress={showModalConfirmCrear}>
                                <Text style={styles.btnLoginText}>
                                Actualizar crédito
                                </Text>
                        </TouchableOpacity>
                    }
                    
                </View>
            </ScrollView>

            <ModalCalendar
                visible={mostrarFechaDesde}
                onPress={handleFechaDesde}
                // maxDate={moment().subtract(1, 'days').format()}
                currentDate={moment(fecha).format()}
                onCancel={() => setMostrarFechaDesde(false)}
            />
            
            <ModalOperacionAdmin
                visible={modalVisibleAdmin}
                title='Aviso'
                subtitle='Ingrese sus credenciales para confirmar la operación'
                onCancel={() => setModalVisibleAdmin(false)}
                onConfirm={handleConfirmModal}
            />

            <Modal
                animationType="fade"
                transparent={true}
                visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalHeader}>Plazo</Text>
                        <View style={{borderBottomWidth: 1,height:1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>
                        <Text style={styles.titleModal}>Selecciona el plazo de pago para el crédito</Text>

                        <View style={{flexDirection: 'row',paddingHorizontal:16}}>
                            <View style={{flex:1, marginRight:16}}>
                                <Text style={styles.labelInput}>Cant cuotas</Text>
                                <TextInput 
                                    style={[styles.inputText,errorCuotas.length>0?{borderColor:colors.rojo}:{}]}
                                    placeholder='Ingrese detalle'
                                    keyboardType='numeric'
                                    value={cantCuotas}
                                    onChangeText={changeCantCuotas}
                                />
                                {
                                    errorCuotas.length>0?<Text style={styles.lblError}>{errorCuotas}</Text>:null
                                }
                            </View>
                            <View style={{flex:1, backgroundColor: colors.white}}>
                                <Text style={styles.labelInput}>Valor cuota</Text>
                                <TextInput 
                                    style={[styles.inputText,{borderColor:colors.gris_fondo_cell,backgroundColor:colors.gris_fondo_cell}]}
                                    editable={false}
                                    value={!cantCuotas||cantCuotas==0?Number(0).toFixed(2).toString():Number(valorCuota).toFixed(2).toString()}
                                />
                            </View>
                        </View>

                        {
                            (valorCuotaAdi != 0 && !Number.isNaN(valorCuotaAdi)) &&
                            <Text
                                style={{
                                    marginVertical: 4, 
                                    fontWeight: 'bold',
                                    fontSize: 12, 
                                    color: colors.verde_primary,
                                    paddingHorizontal:16,
                                }}
                            >[ Se adicionara una  cuota de S/ {valorCuotaAdi.toFixed(2)} ]</Text>
                        }

                        <View style={{paddingHorizontal:16}}>
                            <Text style={styles.labelInput}>Tipo</Text>
                            <View style={{
                                width: '100%',
                                marginTop: 4,
                            }}>
                                <SelectList
                                    data={tiposPlazoCredito}
                                    setSelected={setFrecuencia}
                                    boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                                    inputStyles={{alignSelf:'center',}}
                                    dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                                    search={false}
                                    defaultOption={tiposPlazoCredito[frecuencia?Number(frecuencia):0]}/>
                            </View>
                        </View>

                        {frecuencia == 1 &&
                            <View style={{
                                paddingHorizontal:16,
                            }}>
                                <Text style={[styles.labelInput,{marginBottom:4}]}>Los días</Text>
                                <SelectList
                                    data={diasSemana}
                                    setSelected={setDiaSemana}
                                    boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                                    inputStyles={{alignSelf:'center',}}
                                    dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                                    search={false}
                                    defaultOption={diasSemana[0]}/>
                            </View>
                        }

                        {frecuencia == 2 &&
                            <View style={{paddingHorizontal:16, marginTop:16}}>
                                <View style={{flexDirection: 'row',display:'flex'}}>
                                    <Text style={{alignSelf:'center', marginRight:'5%'}}>Los días</Text>
                                    <TextInput
                                        style={[styles.inputText,{width: '20%',}]}
                                        value={diaQuincenaInicio}
                                        onChangeText={setDiaQuincenaInicio}
                                        keyboardType='numeric'>
                                    </TextInput>
                                    <Text style={{alignSelf:'center', marginHorizontal:'5%'}}>
                                        y
                                    </Text>
                                    <TextInput
                                        style={[styles.inputText,{width: '20%',}]}
                                        value={diaQuincenaFin}
                                        onChangeText={setDiaQuincenaFin}
                                        keyboardType='numeric'>
                                    </TextInput>
                                </View>
                            </View>
                        }

                        {frecuencia == 3 &&
                            <View style={{flexDirection: 'row', paddingHorizontal:16, marginTop:16,}}>
                                <Text style={{alignSelf:'center', marginRight:'5%'}}>El día</Text>
                                <TextInput
                                    style={[styles.inputText,{width: '20%',}]}
                                    keyboardType='numeric'
                                    value={diaMes}
                                    onChangeText={setDiaMes}></TextInput>
                                <Text style={{alignSelf: 'center', marginHorizontal: '5%'}}>del mes</Text>
                            </View>
                        }

                        <View style={{ marginTop:32, paddingHorizontal:16}}>
                            <TouchableOpacity
                                style={[styles.btnLogin, {marginTop:0,marginBottom:0}]}
                                onPress={aceptarPlazo}>
                                <Text style={[styles.btnLoginText]}>Confirmar plazo</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal
                animationType="fade"
                transparent={true}
                visible={modalConfirmarVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalHeader}>Confirmar</Text>
                        <View style={{borderBottomWidth: 1,height:1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>
                        <Text style={[styles.titleModal,{textAlign:'center',}]}>Actualizar crédito a:</Text>
                        <Text  
                            numberOfLines={1} // Mostrar solo una línea
                            ellipsizeMode="tail" // Truncar con tres puntos suspensivos al final 
                            style={[styles.titleModal,{marginBottom:16,textAlign:'center', marginTop:8,fontWeight:'bold',fontSize:16, width:'90%', marginLeft:'5%'}]}>{credito.nombre}</Text>

                        <View style={styles.crtBoxConfirm}>
                            <View style={styles.rowBox}>
                                <Text style={styles.labelConfirmar}>Fecha</Text>
                                <Text style={[styles.labelConfirmar,{fontWeight:'bold',color:colors.negro}]}>{fecha}</Text>
                            </View>
                            <View style={styles.rowBox}>
                                <Text style={styles.labelConfirmar}>Crédito</Text>
                                <Text style={[styles.labelConfirmar,{fontWeight:'bold',color:colors.negro}]}>S/ {Number(valorCredito).toFixed(2)}</Text>
                            </View>
                            <View style={styles.rowBox}>
                                <Text style={styles.labelConfirmar}>Plazo</Text>
                                <Text style={[styles.labelConfirmar,{fontWeight:'bold',color:colors.negro}]}>{StringUtils.getDescPlazo(String(frecuencia), cantCuotas, diaFrecuencia,
                                diaQuincenaInicio, diaQuincenaFin)}</Text>
                            </View>
                            <View style={[styles.rowBox,{marginBottom:0}]}>
                                <Text style={styles.labelConfirmar}>Total</Text>
                                <Text style={[styles.labelConfirmar,{fontWeight:'bold',color:colors.negro}]}>S/ {Number(totalDeuda).toFixed(2)}</Text>
                            </View>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: 32, paddingHorizontal:16,justifyContent:'space-between'}}>
                            <TouchableOpacity
                                style={styles.btnEliminar}
                                onPress={() => setModalConfirmarVisible(false)}>
                                <Text style={{
                                    color: colors.negro,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}>Cancelar</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.btnAgregar}
                                onPress={handlePressModificar}>
                                <Text style={{
                                    color: colors.white,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}>Confirmar</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>

            {/* BOLETAS */}
            <Modal
                style={{flex:1}}
                visible={modalTicketVenta}
            >
                <View style={[styles.crtModalImg,{marginTop:insets.top, marginBottom:insets.bottom}]}>
                    <View style={styles.headerBox}>
                        <Text style={styles.headerText}>TICKET DE VENTA</Text>
                        <Pressable onPress={()=>setModalTicketVenta(false)} style={styles.btnClose}>
                            <Icon name='cancel' color={colors.black} size={28}></Icon>
                        </Pressable>
                    </View>
                    <Image 
                        style={styles.imgBoleta}
                        resizeMode="contain"
                        source={{uri:urlsGenerales.S24b + '?codCredito=' + credito.codCredito  + '&random=' + randomString()}} 
                    />
                </View>
            </Modal>
            <Modal 
                visible={modalCronogramaPagos} 
                style={{flex:1}}
            >
                <View style={[styles.crtModalImg,{marginTop:insets.top, marginBottom:insets.bottom}]}>
                    <View style={styles.headerBox}>
                        <Text style={styles.headerText}>CRONOGRAMA DE PAGOS</Text>
                        <Pressable onPress={()=>setModalCronogramaPagos(false)} style={styles.btnClose}>
                            <Icon name='cancel' color={colors.black} size={28}></Icon>
                        </Pressable>
                    </View>
                    <Image 
                        style={styles.imgBoleta}
                        resizeMode="contain"
                        source={{uri:urlsGenerales.S25b + '?codCredito=' + credito.codCredito   + '&random=' + randomString()}} 
                    />
                </View>
            </Modal>
            {/* BOLETAS */}

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </>
    )
};


const styles = StyleSheet.create({
    container:{
        paddingBottom:48
    },
    crtInfoUser:{
        padding:'5%',
        display:'flex',
        flexDirection:'row'
    },
    row:{
        display:'flex',
        flexDirection:'row',
        position:'relative',
    },
    circleStatus:{
        width:60,
        height:60,
        borderRadius:30,
        alignSelf:'center',
    },
    labelTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4,
        marginLeft:8,
    },
    labelSubTitle:{
        fontSize:12,
        marginLeft:8,
        marginBottom:2,
        fontWeight:'400'
    },
    botonFooter: {
        width: '25%',
        paddingVertical:16
    },
    labelBoton: {
        fontWeight: '400',
        marginTop:2,
        fontSize: 12,
        color: colors.negro,
        textAlign: 'center'
    },
    crtModalImg:{
        flex: 1,
        display:'flex',
        flexDirection:'column',
        backgroundColor:colors.gray
    },
    imgBoleta:{
        width: windowWidth* 0.8, // Adjust the percentage as needed
        // height: windowHeight,
        aspectRatio:0.5,
        alignSelf: 'center',
        // flex:1,
    },
    headerBox:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        height:40,
        backgroundColor:colors.white,
        paddingHorizontal: '5%',
        borderBottomWidth: 0.5,
        borderBottomColor: colors.logo_gris
    },
    btnClose:{
        display:'flex',
        width: 50,
        justifyContent:'center'
    },
    headerText:{
        fontSize:20,
        fontWeight: 'bold',
        color: colors.verde_primary,
        alignSelf: 'center'
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    rowInput:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    labelPlazo: {
        fontWeight: '500',
        fontSize: 14,
    },
    crtInfo:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        marginTop:32
    },
    boxInfo:{
        flex:1,
        padding:8, 
        paddingTop:16,
        borderRadius:8,
    },
    lblInfo:{
        fontSize: 12,
        color: colors.white,
        fontWeight: '400',
        marginTop: 4,
    },
    lblPay:{
        fontSize: 14,
        color: colors.white,
        fontWeight: 'bold'
    },
    lblError:{
        fontSize:12,
        color:colors.rojo,
        marginTop:2
    },
    centeredView: {
        paddingHorizontal:'5%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalView: {
        position:'relative',
        width:'100%',
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },
    modalHeader: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18, 
        marginTop:16, 
        paddingHorizontal:16,
    },
    
    titleModal: {
        fontSize: 14,
        textAlign: 'left',
        marginTop:16,
        paddingHorizontal:16,
    },
    btnLogin:{
        height:48,
        backgroundColor:colors.verde_primary,
        shadowColor: colors.negro,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.4,
        shadowRadius: 2.00,
        elevation: 4,
        borderRadius:4,
        display:'flex',
        justifyContent:'center',
        marginTop:32,
        marginBottom:40
    },
    btnLoginText:{
        fontWeight:'bold',
        alignSelf:'center',
        color:colors.white,
    },
    crtBoxConfirm:{
        backgroundColor:colors.gris_fondo_cell,
        marginHorizontal:'5%',
        padding:16,
        borderRadius:4,
        marginTop:8,
    },
    rowBox:{
        display:'flex',
        flexDirection:'row',
        marginBottom:8,
        justifyContent:'space-between'
    },
    labelConfirmar: {
        fontSize: 14,
    },
    btnAgregar: {
        backgroundColor: colors.verde_primary,
        height:48,
        borderRadius: 4,
        flex:1,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    btnEliminar: {
        backgroundColor: colors.white,
        height:48,
        flex:1,
        borderRadius: 4,
        marginRight:16,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    textBox: {
        backgroundColor: colors.gris_fondo_cell,
        flex:1,
        padding: 16,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16,
        color: colors.negro,
        borderRadius:4,
    },
})
