import React from 'react';
import {Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View, Image} from 'react-native';
import {Icon} from 'react-native-elements';

import {useNavigation, useRoute} from '@react-navigation/native';
import colors from '../../constants/colors';
import routes from '../../constants/routes';
import stylesAdmin from '../../constants/stylesAdmin';

export default function OpcionesRuta() {

    const route = useRoute();
    const navigation = useNavigation();

    const cobrador = route.params?.cobrador;

    const [nombreRuta, setNombreRuta] = React.useState(cobrador?.nombreHijo + ' ' + cobrador?.apellidoHijo);

    // OPCIONES MENÚ
    const liquidarRuta = () => {
        navigation.navigate(routes.LIQUIDAR_RUTA, {
            screen: routes.LIQUIDAR_RUTA,
            params: {cobrador: cobrador}
        });
    }

    const verReportes = () => {
        navigation.navigate(routes.REPORTES, {
            screen: routes.REPORTES,
            params: {cobrador: cobrador}
        });
    }

    const verLiquidaciones = () => {
        navigation.navigate(routes.LIQUIDACIONES);
    }

    const edicionDatos = () => {
        navigation.navigate(routes.EDICION_DATOS, {
            screen: routes.EDICION_DATOS,
            params: {cobrador: cobrador}
        });
    }

    // const restringirAcciones = () => {
    //     navigation.navigate(routes.RESTRINGIR_ACCIONES_RUTA);
    // }

    const cambiarRuta = () => {
        navigation.navigate(routes.HOME_ADMIN);
    }

    return (
        <ScrollView contentContainerStyle={styles.container}
                    showsHorizontalScrollIndicator={false}>

            <View>
                <View style={styles.boxInfo}>
                    <View style={{}}>
                        <Image 
                            style={[styles.circleStatus]}
                            resizeMode="center"
                            source={require('../../assets/client.png')}
                        />
                    </View>
                    <View style={{ marginLeft:16}}>
                        <Text style={{fontSize:14, color:colors.white}}>Ruta de</Text>
                        <Text style={{fontSize:16, color:colors.white, fontWeight:'bold'}}>{nombreRuta}</Text>
                    </View>
                </View>
                <View style={{paddingHorizontal:'5%', marginTop:-30}}>
                    <View style={[styles.row,styles.rowBtwMrg]}>
                        <TouchableOpacity style={styles.containerBtn} onPress={liquidarRuta}>
                            <Image style={styles.img} source={require('../../assets/bloquear.png')}></Image>
                            <Text style={styles.lblText}>Bloquear Caja</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.containerBtn} onPress={edicionDatos}>
                            <Image style={styles.img} source={require('../../assets/editar.png')}></Image>
                            <Text style={styles.lblText}>Modificar Datos</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={[styles.row,styles.rowBtwMrg]}>
                        <TouchableOpacity style={styles.containerBtn} onPress={verReportes}>
                            <Image style={styles.img} source={require('../../assets/reporte.png')}></Image>
                            <Text style={styles.lblText}>Reportes</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.containerBtn} onPress={verLiquidaciones}>
                            <Image style={styles.img} source={require('../../assets/liquidacion.png')}></Image>
                            <Text style={styles.lblText}>Liquidaciones</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            {/* <TouchableOpacity style={stylesAdmin.containetBtn}
                onPress={restringirAcciones}
            >
                <Text style={stylesAdmin.textBtn}>Restringir acciones de ruta</Text>
                <View style={stylesAdmin.containerIcon}>
                    <Icon name='edit' color={colors.logo_gris}></Icon>
                </View>
            </TouchableOpacity> */}

            {/* <View style={{backgroundColor: colors.verde_primary, width: '90%', height: '3%'}}></View>

            <TouchableOpacity style={[stylesAdmin.containetBtn,
                {backgroundColor: colors.logo_gris, marginBottom: '10%'}]}
                              onPress={cambiarRuta}>
                <Text style={[stylesAdmin.textBtn,
                    {fontWeight: 'bold', color: colors.white}]}>Cambiar de ruta</Text>
                <View style={stylesAdmin.containerIcon}>
                    <Icon name='keyboard-return' color={colors.white}></Icon>
                </View>
            </TouchableOpacity> */}
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    row:{
        display:'flex',
        flexDirection:'row'
    },
    rowBtwMrg:{
        justifyContent:'space-between',
        marginBottom:'5%'
    },
    containerBtn:{
        width:'47.5%',
        display:'flex',
        borderRadius:8,
        justifyContent:'center', 
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
        padding:16,
    },
    lblText:{
        textAlign:'center',
        fontSize:14,
        fontWeight:'600'
    },
    img:{
        width:64,
        height:64,
        alignSelf:'center',
        marginBottom:16
    },
    boxInfo:{
        backgroundColor:colors.verde_primary,
        height:100,
        paddingHorizontal:'5%',
        display:'flex',
        flexDirection:'row',
        paddingTop:16,
    },
    circleStatus:{
        width:40,
        height:40,
        borderRadius:20,
    }
})
