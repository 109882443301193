import React, {useState} from 'react';
import {FlatList, StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import Spinner from "react-native-loading-spinner-overlay";
import colors from "../../constants/colors";
import {textoSpinner} from "../../shared/TiposDocumento";
import stylesGenerales from "../../constants/stylesGenrales";
import {useFocusEffect, useRoute} from "@react-navigation/native";
import {StorageUtil} from "../../shared/StorageUtil";
import axios from "axios";
import urlsGenerales from "../../constants/urlsGenerales";
import { useNavigation } from '@react-navigation/native';
import routes from '../../constants/routes';
import {Icon} from 'react-native-elements';
import {TabView, TabBar} from 'react-native-tab-view';


const renderItem = ({item}) => {
    return (
        <View style={styles.ctrItem} key={item.codUsuario}>
            <Text style={{alignSelf:'center',fontSize:14}}>{item.nombre} {item.apellido}</Text>
            <View style={{marginLeft:4}}>
                <Text style={{marginBottom:2, fontSize:12, color:colors.logo_gris}}>Código</Text>
                <Text style={{fontWeight:'bold', fontSize:12, textAlign:'right', color:colors.logo_gris}}>{item.codUsuario}</Text>
            </View>
        </View>
    );
}

const Administrador = ({list}) => {
    return(
        <FlatList
            data={list}
            renderItem={renderItem}
            keyExtractor={(item) => item.codUsuario}
            ListEmptyComponent={()=>{
                return(
                    <Text style={{textAlign:'center'}}>No hay administradores registrados</Text>
                )
            }}
            contentContainerStyle={{paddingBottom:48, paddingTop:16}}
        />
    )
}

const Cobrador = ({list}) => {
    return(
        <FlatList
            data={list}
            renderItem={renderItem}
            keyExtractor={(item) => item.codUsuario}
            ListEmptyComponent={()=>{
                return(
                    <Text style={{textAlign:'center'}}>No hay cobradores registrados</Text>
                )
            }}
            contentContainerStyle={{paddingBottom:48, paddingTop:16}}
        />
    )
}

const RenderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{backgroundColor: colors.verde_primary}}
      activeColor={colors.verde_primary} // Color de la pestaña activa
      inactiveColor={colors.logo_gris} // Color de la pestaña inactiva
      style={{backgroundColor: colors.white}}
      labelStyle={{textTransform: 'capitalize', fontWeight: 'bold'}}
    />
);


const ReporteUsuariosCreados = () => {

    const navigation = useNavigation();

    const [loading, setLoading] = useState(false);

    const [cntAdmin, setCntAdmin] = useState(0);
    const [limitAdmin, setLimitAdmin] = useState(0);
    const [cntCobra, setCntCobra] = useState(0);
    const [limitCobra, setLimitCobra] = useState(0);

    const [listAdmins, setListAdmins] = useState([]);
    const [listCobra, setListCobra] = useState([]);
    const route = useRoute();
    const cobrador = route.params?.cobrador;
    
    const layout = useWindowDimensions();

    const [index, setIndex] = useState(0);
    const [routes_tab] = useState([
      {key: 'admin', title: 'Administradores'},
      {key: 'cob', title: 'Cobradores'},
    ]);

    const obtenerReporteUsuCreados = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            const codUsuario = cobrador.codUsuarioHijo;

            formData.append('codSuperAdmin', codUsuario!);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.REPORTE_USUARIOS_CREADOS, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                const reporte = response.data;

                setCntAdmin(reporte.cantAdminsActivos);
                setLimitAdmin(reporte.cantAdmins);

                setCntCobra(reporte.cantCobradoresActivos);
                setLimitCobra(reporte.cantCobradores);

                setListAdmins(reporte.listaAdmins);
                setListCobra(reporte.listaCobradores);
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.REPORTE_USUARIOS_CREADOS);
            console.log(error);

            setListAdmins([]);
            setListCobra([]);

        } finally {
            setLoading(false);
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                await obtenerReporteUsuCreados();
            };
            fetchData();
        }, [])
    );

    const renderScene = ({route}) => {
        switch (route.key) {
          case 'admin':
            return <Administrador  list={listAdmins}/>;
          case 'cob':
            return <Cobrador  list={listCobra}/>;
          default:
            return null;
        }
    };

    return (
        <View style={styles.container}>
            <View style={[styles.row,{paddingVertical:24,paddingHorizontal:'5%'}]}>
                <View style={styles.ctrIcon}>
                    <Icon name='people' size={28} color={colors.verde_primary} style={{alignSelf:'center'}}></Icon>
                </View>
                <View>
                    <Text style={styles.titleUser}>Usuarios creados</Text>
                    <Text style={styles.userText}>by {cobrador.nombreHijo +" "+ cobrador.apellidoHijo}</Text>
                </View>
            </View>
            <View style={[{paddingHorizontal:'5%'}]}>
                <Text style={{fontWeight:'bold'}}>Administradores</Text>
                <View style={[styles.row]}>
                    <View style={styles.itemRow}>
                        <View style={[styles.row,{alignItems:'flex-end'}]}>
                            <Icon name='person' size={18} color={colors.gris_borde_item_lista} style={{alignSelf:'center', marginRight:8}}></Icon>
                            <View>
                                <Text style={styles.lblItem}>Asignados</Text>
                                <Text style={styles.lblItemActive}>{limitAdmin}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.itemRow}>
                        <View style={[styles.row,{alignItems:'flex-end'}]}>
                            <Icon name='person' size={18} color={colors.gris_borde_item_lista} style={{alignSelf:'center', marginRight:8}}></Icon>
                            <View> 
                                <Text style={styles.lblItem}>Activos</Text>
                                <Text style={styles.lblItemActive}>{cntAdmin}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={{fontWeight:'bold'}}>Cobradores</Text>
                <View style={[styles.row]}>
                    <View style={styles.itemRow}>
                        <View style={[styles.row,{alignItems:'flex-end'}]}>
                            <Icon name='person' size={18} color={colors.gris_borde_item_lista} style={{alignSelf:'center', marginRight:8}}></Icon>
                            <View> 
                                <Text style={styles.lblItem}>Asignados</Text>
                                <Text style={styles.lblItemActive}>{limitCobra}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.itemRow}>
                        <View style={[styles.row,{alignItems:'flex-end'}]}>
                            <Icon name='person' size={18} color={colors.gris_borde_item_lista} style={{alignSelf:'center', marginRight:8}}></Icon>
                            <View> 
                                <Text style={styles.lblItem}>Activos</Text>
                                <Text style={styles.lblItemActive}>{cntCobra}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            
            <TabView
                navigationState={{index, routes:routes_tab}}
                renderScene={renderScene}
                onIndexChange={setIndex}
                initialLayout={{width: layout.width}}
                renderTabBar={props => (
                    <RenderTabBar
                        {...props}
                    />
                )}
            />

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </View>
    )
}

export default ReporteUsuariosCreados

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },

    labelTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginTop: '3%',
        marginBottom: '4%'
    },

    labelPerfil: {
        width: '50%',
        fontSize: 16,
        fontWeight: "normal",
        textAlignVertical: "center"
    },

    labelEstado: {
        width: '25%',
        fontSize: 16,
        fontWeight: "normal",
        textAlign: "center",
        padding: 10,
        margin: '1%'
    },

    labelCnt: {
        width: '25%',
        fontSize: 16,
        fontWeight: "normal",
        textAlign: "center",
        backgroundColor: colors.gris_fondo_cell,
        padding: 10,
        borderRadius: 10,
        margin: '1%'
    },

    labelListado: {
        fontSize: 17,
        fontWeight: "bold",
        width: '90%',
    },
    row:{
        display:'flex',
        flexDirection:'row'
    },
    ctrIcon:{
        height:50,
        width:50,
        borderRadius:25,
        backgroundColor:colors.white,
        display:'flex',
        justifyContent:'center'
    },
    titleUser:{
        fontSize:20,
        marginBottom:4,
        marginLeft:8,
        fontWeight:'bold'
    },
    userText:{
        fontSize:14,
        marginLeft:8,
        fontWeight:'400'
    },
    itemRow:{
        width:150,
        marginTop:8,
        marginBottom:16,
    },
    lblItem:{
        fontSize:12,
        color:colors.gris_borde_item_lista
    },
    lblItemActive:{
        fontSize:16,
        fontWeight:'bold',
        marginTop:2,
    },
    ctrItem:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    }
})
