import React from 'react';
import { ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';

import { useNavigation } from '@react-navigation/native';
import colors from '../../constants/colors';
import stylesAdmin from '../../constants/stylesAdmin';
import stylesGenerales from '../../constants/stylesGenrales';

export default function OpcionesDelegado() {

    const [estado, setEstado] = React.useState('Activo');
    const [mostrarActivar, setMostrarActivar] = React.useState(false);
    const [mostrarSuspender, setMostrarSuspender] = React.useState(true);
    const [mostrarEditar, setMostrarEditar] = React.useState(true);
    const [mostrarGuardar, setMostrarGuardar] = React.useState(false);

    const activar = () => {
        setEstado('Activo');
        setMostrarActivar(false);
        setMostrarSuspender(true);
    }

    const suspender = () => {
        setEstado('Suspendido');
        setMostrarSuspender(false);
        setMostrarActivar(true);
    }

    const editar = () => {
        setMostrarEditar(false);
        setMostrarGuardar(true);
    }

    const enviarPorWhats = () => {
        console.log('Seleccionó enviar por whatsapp')
    }

    const cancelar = () => {

        // Si no ha realizado ningún cambio, muestra los botones,
        // Si ha ralizado algún cambio en los input, regresa a su valor inicial

        setMostrarEditar(true);
        setMostrarGuardar(false);
    }

    const guardar = () => {

        // Valida los valores ingresados
        // Consumo servicio para guardar nuevos valores ingresados

        console.log('Guardar')
    }

    return (
        <ScrollView contentContainerStyle={styles.container}
            showsHorizontalScrollIndicator={false}>
            <Text style={styles.textNombre}>Euller</Text>

            <Text style={stylesAdmin.labelListado}>Detalles de usuario</Text>

            <Text style={styles.labelInput}>Nro. documento</Text>
            <TextInput style={stylesGenerales.inputText} editable={false}></TextInput>

            <Text style={styles.labelInput}>Nombre de usuario</Text>
            <TextInput style={stylesGenerales.inputText} editable={mostrarGuardar}></TextInput>

            <Text style={styles.labelInput}>Usuario</Text>
            <TextInput style={stylesGenerales.inputText} editable={mostrarGuardar}></TextInput>

            <Text style={styles.labelInput}>Contraseña</Text>
            <TextInput style={stylesGenerales.inputText} editable={mostrarGuardar}></TextInput>

            {
                mostrarEditar &&
                <View style={[stylesGenerales.row,
                { marginTop: '1%', marginBottom: '3%', justifyContent: 'space-between' }]}>
                    <TouchableOpacity
                        style={[stylesAdmin.btnOpcion, { marginTop: '2%', marginBottom: '4%', width: '45%' }]}
                        onPress={editar}
                    >
                        <Icon name='edit' color={colors.logo_gris}></Icon>
                        <Text style={[stylesAdmin.textBtnOpcion, { textAlign: 'center', width: '70%' }]}>Editar</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[stylesAdmin.btnOpcion, { marginTop: '2%', marginBottom: '4%', width: '50%' }]}
                        onPress={enviarPorWhats}
                    >
                        <Icon name='email' color={colors.logo_gris}></Icon>
                        <Text style={[stylesAdmin.textBtnOpcion, { textAlign: 'center', width: '85%' }]}>Enviar por WhatsApp</Text>
                    </TouchableOpacity>
                </View>
            }

            {
                mostrarGuardar &&
                <View style={[stylesGenerales.row,
                { marginTop: '1%', marginBottom: '3%', justifyContent: 'space-between' }]}>
                    <TouchableOpacity
                        style={[stylesAdmin.btnOpcion, { marginTop: '2%', marginBottom: '4%', width: '45%' }]}
                        onPress={cancelar}
                    >
                        <Icon name='cancel' color={colors.logo_gris}></Icon>
                        <Text style={[stylesAdmin.textBtnOpcion, { textAlign: 'center', width: '70%' }]}>Cancelar</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[stylesAdmin.btnOpcion, { marginTop: '2%', marginBottom: '4%', width: '50%' }]}
                        onPress={guardar}
                    >
                        <Icon name='check-circle' color={colors.logo_gris}></Icon>
                        <Text style={[stylesAdmin.textBtnOpcion, { textAlign: 'center', width: '85%' }]}>Guardar</Text>
                    </TouchableOpacity>
                </View>
            }

            <Text style={stylesAdmin.labelListado}>Estado de Usuario</Text>
            <Text style={styles.labelDescripcion}>Activa o Suspenda temporalmente el ingreso de este usuario delegado
                al sistema</Text>

            <Text style={styles.labelDescripcion}>El estado actual de este usuario es:
                <Text style={{ fontWeight: 'bold' }}> {estado}</Text>
            </Text>

            {
                mostrarSuspender &&
                <TouchableOpacity
                    style={[stylesAdmin.btnOpcion, { marginTop: '3%', marginBottom: '4%' }]}
                    onPress={suspender}
                >
                    <Icon name='block' color={colors.logo_gris}></Icon>
                    <Text style={stylesAdmin.textBtnOpcion}>Suspender</Text>
                </TouchableOpacity>
            }
            {
                mostrarActivar &&
                <TouchableOpacity
                    style={[stylesAdmin.btnOpcion, { marginTop: '3%', marginBottom: '4%' }]}
                    onPress={activar}
                >
                    <Icon name='block' color={colors.logo_gris}></Icon>
                    <Text style={stylesAdmin.textBtnOpcion}>Activar</Text>
                </TouchableOpacity>
            }

            <Text style={stylesAdmin.labelListado}>Eliminar Usuario</Text>
            <Text style={styles.labelDescripcion}>Elimina permanentemente este usuario delegado del sistema</Text>

            <TouchableOpacity
                style={[stylesAdmin.btnOpcion, { marginTop: '2%', marginBottom: '6%' }]}
                onPress={() => console.log('Eliminar')}
            >
                <Icon name='remove-circle-outline' color={colors.logo_gris}></Icon>
                <Text style={stylesAdmin.textBtnOpcion}>Eliminar</Text>
            </TouchableOpacity>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center'
    },

    textNombre: {
        width: '90%',
        paddingHorizontal: '3%',
        paddingVertical: '1%',
        textAlign: 'center',
        backgroundColor: '#AD129A',
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 19,
        marginBottom: '2%',
        marginTop: '4%'
    },

    labelInput: {
        fontWeight: 'bold',
        fontSize: 16,
        width: '90%',
        marginTop: '2%'
    },

    labelDescripcion: {
        width: '90%',
        fontSize: 16
    }
})