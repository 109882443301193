export default {
  LOGIN: "Login",
  RECOVER: "Recover",

  HOME: "CFP",
  CLIENTES: "Clientes",
  CREDITOS: "Créditos",
  RUTA: "Ruta",
  LISTADOS: "Listados",
  BALANCE: "Balance",
  AGREGAR_A_LISTA: "Agregar a lista",
  ACCIONES: "Acciones",

  // HOME
  RECAUDAR: "Recaudar",

  ASIGNAR_CREDITO: "Crédito",
  ENRUTAR_EN: "Enrutar en",
  CREAR_CLIENTE: "Crear cliente",
  VER_CREDITOS_CLIENTE: "Ver créditos",
  RECAUDO_DEL_DIA: "Recaudo del día",

  CREDITOS_POR_FECHA: "Créditos por fecha",
  RECAUDO_POR_FECHA: "Recaudo por fecha",

  VER_CLIENTE: "Ver Cliente",

  // CREDITOS
  VER_CREDITO: "Ver Crédito",
  VER_ABONOS: "Abonos",
  ABONOS_PRUEBA: "Abonos Prueba",

  // BALANCE
  DETALLE_PAGOS: "Detalle de pagos",

  // Admin
  HOME_ADMIN: "Principal",
  EDITAR_USUARIO_ADMIN: "Usuario",
  OPCIONES_RUTA: "Opciones de ruta",
  OPCIONES_CUENTA: "Opciones de cuenta",
  REPORTES: "Reportes",
  LIQUIDACIONES: "Liquidaciones",
  EDICION_DATOS: "Edicion de datos",
  RESTRINGIR_ACCIONES_RUTA: "Restringir funciones",

  REPORTE_USUARIOS_CREADOS: "Usuarios creados",

  CREAR_USUARIO: "Crear usuario",

  PRODUCTOS: "Productos",
  LISTA_PRODUCTOS: "Lista Productos",

  DELEGADOS: "Delegados",
  DELEGAR_CUENTAS: "Delegar cuentas",
  OPCIONES_DELEGADO: "Opciones del delegado",

  // Reportes
  RESUMEN_POR_FECHA: "Resumen por fecha",
  RECAUDADO_POR_FECHA: "Recaudado por fecha",
  CREDITOS_POR_FECHA_REP: "Créditos por fecha rep",
  CREDITOS_VENCIDOS: "Créditos vencidos",
  CREDITOS_SEGUN_ESTADO: "Créditos según estado",
  CREDITOS_NO_ENRUTADOS: "Créditos no enrutados",
  CREDITOS_ACTIVOS: "Creditos activos",
  CLIENTES_INACTIVOS_POR_CALIFICACION: "Clientes inactivos",
  GASTOS_POR_FECHA: "Gastos por fecha",
  ENTREGAS_SALIDAS_POR_FECHA: "Entregas/Salidas por fecha",
  INVERSIONES_POR_FECHA: "Inversiones por fecha",
  LISTA_RECAUDO_DEL_DIA: "Lista recaudo del día",
  NO_RECAUDADOS_POR_FECHA: "No recaudados por fecha",
  CRECIMIENTO_CARTERA: "Crecimiento cartera",
  CARTERA_ACTUAL: "Cartera actual",

  //Edicion datos
  LIQUIDAR_RUTA: "Bloquear caja",
  VER_LIQUIDAR_RUTA: "Ver caja a bloquear",
  DETALLE_LIQUIDACION: "Detalle de liquidación",

  AGREGAR_CREDITO: "Seleccionar cliente",
  ASIGNAR_CREDITO_ADMIN: "Asignar crédito admin",
  AGREGAR_CLIENTE_CREDITO: "Crear cliente + crédito",

  BORRAR_MODIFICAR_CREDITOS: "Borrar modificar créditos",
  EDITAR_CREDITO: "Editar crédito",

  BORRAR_ABONOS_PAGOS: "Modificar abonos",
  BORRAR_GASTOS_ENTREGAS_INGRESOS: "Modificar Gastos/Entradas",

  CAMBIAR_SUPERCLAVE: "Cambiar superclave",
  CAMBIAR_DATOS_ACCESO: "Cambiar datos de acceso de ruta",
};
