import React, {useEffect, useRef, useState} from 'react';
import {Linking, Modal, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View, Pressable, Image, Dimensions} from 'react-native';
import {Icon} from 'react-native-elements';

import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import colors from '../../constants/colors';
import axios from 'axios';
import routes from '../../constants/routes';
import stylesGenerales from '../../constants/stylesGenrales';
import {SelectList} from 'react-native-dropdown-select-list';
import urlsGenerales from '../../constants/urlsGenerales';
import {StringUtils} from '../../shared/StringUtils';
import ModalOperacionAdmin from "../../components/ModalOperacionAdmin";
import {diasSemana, textoSpinner, tiposPlazoCredito} from '../../shared/TiposDocumento';
import Spinner from "react-native-loading-spinner-overlay";
import stylesTablas from "../../constants/stylesTablas";
import {AuthenticationService} from "../../shared/AuthenticationService";
import ModalCalendar from "../../components/ModalCalendar";
import {DateUtils} from "../../shared/DateUtils";
import CustomAlert from '../../shared/CustomAlert';
import { StorageUtil } from '../../shared/StorageUtil';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const windowWidth = Dimensions.get("window").width;

export default function VerCreditoCliente() {

    const insets = useSafeAreaInsets();
    const route = useRoute();
    const {codCredito} = route.params;
    const navigation = useNavigation();
    const [mostrarBtn, setmostrarBtn] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [credito, setCredito] = React.useState({});
    const [fecha, setFecha] = React.useState(DateUtils.getDateStringYYYYMMDD());
    const [mostrarFechaDesde, setMostrarFechaDesde] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleAdmin, setModalVisibleAdmin] = useState(false);
    const [modalConfirmarVisible, setModalConfirmarVisible] = React.useState(false);
    const [btnCrearDisable, setBtnCrearDisable] = React.useState(false);
    const [saldo, setSaldo] = useState(0);
    const [valorCredito, setValorCredito] = React.useState('');
    const [interes, setInteres] = React.useState('');
    const [cantCuotas, setCantCuotas] = React.useState('1');
    const [valorCuota, setValorCuota] = React.useState('');
    const [diaFrecuencia, setDiaFrecuencia] = React.useState('');
    const [diaFrecuencia2, setDiaFrecuencia2] = React.useState('');
    const [totalDeuda, setTotalDeuda] = React.useState('');
    const [valorCuotaAdi, setValorCuotaAdi] = React.useState(0);
    const [valorFinal, setValorFinal] = React.useState('');
    const [frecuencia, setFrecuencia] = React.useState(0);
    const [diaSemana, setDiaSemana] = React.useState('');
    const [diaQuincenaInicio, setDiaQuincenaInicio] = React.useState('');
    const [diaQuincenaFin, setDiaQuincenaFin] = React.useState('');
    const [diaMes, setDiaMes] = React.useState('');

    const fechaDesdeRef = useRef(null);

    const cerrarModal = () => {
        setModalVisible(false);
    }

    const showModalConfirmCrear = () => {
        setModalConfirmarVisible(true);
    }

    const aceptarPlazo = () => {
        switch (frecuencia+"") {
            case '0':
                setDiaFrecuencia('0');
                setDiaFrecuencia2("0");
                break;
            case '1':
                const descDiaSemana = diaSemana;
                setDiaFrecuencia(descDiaSemana);
                setDiaFrecuencia2("0");
                break;
            case '2':
                setDiaFrecuencia(diaQuincenaInicio);
                setDiaFrecuencia2(diaQuincenaFin);
                break;
            case '3':
                setDiaFrecuencia(diaMes.toString());
                setDiaFrecuencia2("0");
                break;
        }
        setModalVisible(false);
    }

    const mostrarModal = () => {

        if (valorCredito.trim() === '') {
            CustomAlert.show({
                title: 'Mensaje',
                message: 'Ingrese valor de crédito'
            });
            return;
        }

        if (interes.trim() === '') {
            CustomAlert.show({
                title: 'Mensaje',
                message: 'Ingrese interés'
            });
            return;
        }

        const interesMultiplicar = (Number(interes) + 100) / 100;
        const valorDeudaTotal = Math.floor(Number(valorCredito) * interesMultiplicar);
        const valorCuotaInicial = Math.floor(valorDeudaTotal / Number(cantCuotas));

        setValorFinal(valorDeudaTotal.toFixed(1).toString());
        setValorCuota(valorCuotaInicial.toFixed(1).toString());
        setModalVisible(true);
    };

    const verAbonos = () => {
        navigation.navigate(routes.VER_ABONOS, {codCredito: credito.codCredito})
    }

    const pagar = () => {
        navigation.navigate(routes.RECAUDAR, {recaudo: credito});
    }

    const modificar = () => {
        setmostrarBtn(true);
    }

    const handleFechaDesde = (fechaDesdeModal: string) => {
        setMostrarFechaDesde(false);
        setFecha(fechaDesdeModal);
    }

    const cancelar = () => {
        
        setFecha(credito.fecha);
        setValorCredito(credito.valorCredito);
        setInteres(credito.interes);
        setValorCuota(credito.valorCuota);
        setTotalDeuda(credito.totalDeuda);
        setSaldo(credito.saldoCredito);
        setFrecuencia(credito.codFrecuencia);
        setDiaFrecuencia(credito.diaFrecuencia);
        setDiaQuincenaInicio(credito.diaFrecuencia);
        setDiaFrecuencia2(credito.diaFrecuencia2);
        setDiaQuincenaFin(credito.diaFrecuencia2);
        setDiaMes(credito.diaFrecuencia);
        setCantCuotas(credito.cantCuotas);
        setmostrarBtn(false);

    }

    const handlePressModificar = () => {
        setModalConfirmarVisible(false);
        setModalVisibleAdmin(true);
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {

        setModalVisibleAdmin(false);

        setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            setModalVisibleAdmin(false);
            CustomAlert.errorCredenciales();
            setLoading(false);
            return;
        }
        setLoading(false);

        guardarModif(usuario, clave);
    }

    const showSpinner = () => {
        setLoading(true);
    };

    // Función para ocultar el spinner
    const hideSpinner = () => {
        setLoading(false);
    };

    const changeCantCuotas = (text: string) => {
        setCantCuotas(text);
        const valorCuotaTemp = Math.floor(Number(totalDeuda) / Number(text));
        const valorCuotaAdicional = Number(totalDeuda) % Number(text);
        setValorCuota(valorCuotaTemp.toFixed(1).toString());
        setValorCuotaAdi(valorCuotaAdicional);
    }

    const changeValorCredito = (text: string) => {
        setValorCredito(text);
        const interesMultiplicar = (Number(interes) + 100) / 100;
        const valorCuotaInicial = Math.round(Number(text) * interesMultiplicar);
        setValorCuota(valorCuotaInicial.toFixed(1).toString());
        setValorFinal(valorCuotaInicial.toFixed(1).toString());
        setTotalDeuda(valorCuotaInicial.toFixed(1).toString());
    }

    const changeInteres = (text: string) => {
        setInteres(text);
        const interesMultiplicar = (Number(text) + 100) / 100;
        const valorCuotaInicial = Math.round(Number(valorCredito) * interesMultiplicar);
        setValorCuota(valorCuotaInicial.toFixed(1).toString());
        setValorFinal(valorCuotaInicial.toFixed(1).toString());
        setTotalDeuda(valorCuotaInicial.toFixed(1).toString());
    }

    const guardarModif = async (usu,pass) => {
        if (loading) {
            showSpinner();
            return;
        }

        setBtnCrearDisable(true);
        if (valorCredito.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese valor de credito'
            });
            setBtnCrearDisable(false);
            return;
        }

        if (interes.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese valor de interes'
            });
            setBtnCrearDisable(false);
            return;
        }

        const formData = new FormData();
        formData.append('usu', usu);
        formData.append('pass', pass);
        formData.append('codUsuario', credito.codUsuario);
        formData.append('codCliente', credito.codCliente);
        formData.append('codCredito', credito.codCredito);
        formData.append('fecha', fecha);
        formData.append('valorCredito', valorCredito);
        formData.append('interes', interes);
        formData.append('cantCuotas', cantCuotas);
        formData.append('valorCuota', valorCuota);
        formData.append('codFrecuencia', frecuencia+"");
        formData.append('diaFrecuencia', diaFrecuencia);
        formData.append('diaFrecuencia2', diaFrecuencia2);
        formData.append('totalDeuda', totalDeuda);

        try {
            setLoading(true);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.EDITAR_CREDITO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data.mensaje && response.data.mensaje == "Actualizado") {
                
                //se guardo correctamente
                credito.fecha = fecha;
                credito.valorCredito = valorCredito;
                credito.interes = interes;
                credito.valorCuota = valorCuota;
                credito.totalDeuda = totalDeuda;
                credito.saldoCredito = totalDeuda;
                credito.codFrecuencia = frecuencia;
                credito.diaFrecuencia = diaFrecuencia;
                credito.diaFrecuencia2 = diaFrecuencia2;
                credito.cantCuotas = cantCuotas;
                credito.codCredito = response.data.codCredito;
                credito.detEstado = response.data.detEstado;
                credito.estado = response.data.estado;
                credito.fecVencimiento = response.data.fecVencimiento;
                credito.listCuotas = response.data.listCuotas;
                credito.montoAlDia = response.data.montoAlDia;
                credito.totalDeuda = response.data.totalDeuda;
                credito.valorCuotaTotal = response.data.valorCuotaTotal;
                setmostrarBtn(false);
                CustomAlert.show({
                    title: 'Mensaje',
                    message: 'Se actualizaron los datos del Credito.'
                });
            }else{
                CustomAlert.show({
                    title: 'Mensaje',
                    message:  response.data.mensaje
                });
            }

        } catch (error) {
            console.log('Se cae en error -> ', urlsGenerales.EDITAR_CREDITO);
            console.error(error);
            setBtnCrearDisable(false);
            hideSpinner();
        } finally {
            setBtnCrearDisable(false);
            hideSpinner();
        }
    }

    // --- Detalle de crédito
    const ticketVenta = () => {
        Linking.openURL(urlsGenerales.S24 + '?codCredito=' + credito.codCredito)
            .then(() => {
                console.log('Se descargó el tk correctamente');
            })
            .catch((err: any) => console.error('Error al descargar tk: ', err));
    }

    const ticketVentaPng =  () => {
        Linking.openURL(urlsGenerales.S24b + '?codCredito=' + credito.codCredito)
            .then(() => {
                console.log('Se descargó el tk correctamente');
            })
            .catch((err: any) => console.error('Error al descargar tk: ', err));
    }

    const cronogramaPagos =  () => {
         Linking.openURL(urlsGenerales.S25 + '?codCredito=' + credito.codCredito)
            .then(() => {
                console.log('Se descargó el tk correctamente');
            })
            .catch((err: any) => console.error('Error al descargar tk: ', err));
    }


    const cronogramaPagosPng =  () => {
        Linking.openURL(urlsGenerales.S25b + '?codCredito=' + credito.codCredito)
        .then(() => {
            console.log('Se descargó el tk correctamente');
        })
        .catch((err: any) => console.error('Error al descargar tk: ', err));
    }
    
    useEffect(() => {
      console.log(credito.codCredito)
    }, [credito])
    
    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const formData = new FormData();
                    formData.append('codCredito', codCredito!);
                    const token = await StorageUtil.getTokenStorage(); 
                    const response = await axios.post(urlsGenerales.DETALLE_CREDITO, formData, {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    setCredito(response.data);
                    
                    setFecha(response.data.fecha);
                    setValorCredito(response.data.valorCredito);
                    setInteres(response.data.interes);
                    setValorCuota(response.data.valorCuota);
                    setTotalDeuda(response.data.totalDeuda);
                    setSaldo(response.data.saldoCredito);
                    setFrecuencia(response.data.codFrecuencia);
                    setDiaFrecuencia(response.data.diaFrecuencia);
                    setDiaQuincenaInicio(response.data.diaFrecuencia);
                    setDiaFrecuencia2(response.data.diaFrecuencia2);
                    setDiaQuincenaFin(response.data.diaFrecuencia2);
                    setDiaMes(response.data.diaFrecuencia);
                    setCantCuotas(response.data.cantCuotas);

                } catch (error) {
                    console.log('Error en -> ', urlsGenerales.DETALLE_CREDITO)
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }, [codCredito])
    );

    const [modalTicketVenta, setModalTicketVenta] = React.useState(false);
    const [modalCronogramaPagos, setModalCronogramaPagos] = React.useState(false);

    return (
        <ScrollView
            contentContainerStyle={styles.container}
            showsHorizontalScrollIndicator={false}>

            <Text style={styles.labelTitle}>{credito.nombre} {credito.apellido}</Text>
            <Text style={styles.labelSubTitle}>Tel. {credito.telefono}</Text>
            <Text style={styles.labelSubTitle}>Dir. {credito.direccion}</Text>

            <Text style={styles.labelDescrip}>Ticket - Detalle del crédito</Text>

            <Modal
                style={{flex:1}}
                visible={modalTicketVenta}>
                <View style={[styles.crtModalImg,{marginTop:insets.top, marginBottom:insets.bottom}]}>
                    <View style={styles.headerBox}>
                        <Text style={styles.headerText}>TICKET DE VENTA</Text>
                        <Pressable onPress={()=>setModalTicketVenta(false)} style={styles.btnClose}>
                            <Icon name='cancel' color={colors.black} size={28}></Icon>
                        </Pressable>
                    </View>
                    <Image 
                        style={styles.imgBoleta}
                        resizeMode="contain"
                        source={{uri:urlsGenerales.S24b + '?codCredito=' + credito.codCredito}} 
                    />
                </View>
            </Modal>
            
            <Modal
                style={{flex:1}}
                visible={modalCronogramaPagos}>
                <View style={[styles.crtModalImg,{marginTop:insets.top, marginBottom:insets.bottom}]}>
                    <View style={styles.headerBox}>
                        <Text style={styles.headerText}>CRONOGRAMA DE PAGOS</Text>
                        <Pressable onPress={()=>setModalCronogramaPagos(false)} style={styles.btnClose}>
                            <Icon name='cancel' color={colors.black} size={28}></Icon>
                        </Pressable>
                    </View>
                    <Image 
                        style={styles.imgBoleta}
                        resizeMode="contain"
                        source={{uri:urlsGenerales.S25b + '?codCredito=' + credito.codCredito}} 
                    />
                </View>
            </Modal>

            <View style={{flexDirection: 'row', width: '90%', alignItems: 'flex-start', marginTop: '3%'}}>
                <TouchableOpacity style={[styles.btn, {backgroundColor: colors.verde_primary}]}
                                  onPress={() => {
                                    setModalTicketVenta(true);
                                }}
                >
                    <Text style={stylesGenerales.textBtn}>Ticket venta</Text>
                    <Icon name='confirmation-number' color={colors.white} style={{paddingLeft: 5}}></Icon>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.btn, {backgroundColor: colors.logo_azul}]}
                                  onPress={() => {
                                    setModalCronogramaPagos(true);
                                }}
                >
                    <Text style={stylesGenerales.textBtn}>Cronograma de pagos</Text>
                    <Icon name='confirmation-number' color={colors.white} style={{paddingLeft: 5}}></Icon>
                </TouchableOpacity>
            </View>


            <Text style={styles.labelDescrip}>Abonos/Pagos del crédito</Text>

            <TouchableOpacity style={{
                width: '90%',
                padding: 10,
                backgroundColor: colors.verde_primary,
                marginTop: '2%',
                marginBottom: '5%',
                flexDirection: 'row',
                borderRadius: 10
            }}
                              onPress={verAbonos}>
                <Text style={[stylesGenerales.textBtn, {width: '92%', textAlign: 'center'}]}>
                    Ver Abonos/Pagos</Text>
                <Icon name='chevron-right' color={colors.white}></Icon>
            </TouchableOpacity>


            <View style={stylesGenerales.row}>
                <Text style={stylesTablas.leafLeft}>Id. Crédito</Text>
                <Text style={stylesTablas.leafRight}>{credito.codCredito}</Text>
            </View>

            <Text style={styles.textBox}>
                {credito.detEstado}
            </Text>

            <Text style={stylesGenerales.labelInput}>Fecha</Text>
            <TextInput
                style={stylesGenerales.inputText}
                placeholder='yyyy-mm-dd'
                editable={mostrarBtn}
                onChangeText={setFecha}
                value={fecha}
                onPressIn={() => setMostrarFechaDesde(true)}
                ref={fechaDesdeRef}
                onFocus={() => {
                    if(mostrarBtn){
                        setMostrarFechaDesde(true);
                        // @ts-ignore
                        fechaDesdeRef.current.blur();
                    }
                }}
            ></TextInput>

            <ModalCalendar
                visible={mostrarFechaDesde}
                onPress={handleFechaDesde}
                onCancel={() => setMostrarFechaDesde(false)}
            />

            <Text style={stylesGenerales.labelInput}>Valor del crédito</Text>
            <TextInput
                style={stylesGenerales.inputText}
                placeholder='0'
                editable={mostrarBtn}
                keyboardType='numeric'
                value={valorCredito}
                onChangeText={changeValorCredito}
                //onChangeText={setValorCredito}
                ></TextInput>

            <Text style={stylesGenerales.labelInput}>Interés (%)</Text>
            <TextInput
                style={stylesGenerales.inputText}
                placeholder='0'
                keyboardType="numeric"
                editable={mostrarBtn}
                value={interes}
                onChangeText={changeInteres}></TextInput>

            <Text style={stylesGenerales.labelInput}>Plazo</Text>
            <TouchableOpacity
                disabled={!mostrarBtn}
                style={{
                    width: '90%',
                    borderWidth: 0.5,
                    padding: 10,
                    borderRadius: 5,
                    alignItems: 'center',
                    marginTop: 8,
                    marginBottom: 5
                }}
                
                onPress={mostrarModal}>
                <Text style={[styles.labelPlazo,{color: mostrarBtn? "black" : colors.logo_gris}]}>{StringUtils.getDescPlazo(frecuencia, cantCuotas, diaFrecuencia,
                    diaQuincenaInicio, diaQuincenaFin)}</Text>
            </TouchableOpacity>

            <Text style={stylesGenerales.labelInput}>Valor cuota</Text>
            <TextInput
                style={stylesGenerales.inputText}
                placeholder='0'
                editable={false}
                value={valorCuota}></TextInput>

            {
                (valorCuotaAdi != 0 && !Number.isNaN(valorCuotaAdi)) &&
                <Text
                    style={{
                        width: '90%', marginVertical: '1%', fontWeight: 'bold',
                        fontSize: 16, color: colors.verde_primary
                    }}
                >[ + Cuota Adic. S/ {valorCuotaAdi} ]</Text>
            }

            <Text style={stylesGenerales.labelInput}>Total a pagar</Text>
            <TextInput
                style={stylesGenerales.inputText}
                placeholder='0'
                editable={false}
                value={totalDeuda}></TextInput>
            {!mostrarBtn &&
            <Text style={stylesGenerales.labelInput}>Saldo actual</Text>
            }
            {!mostrarBtn &&
            <TextInput
                style={stylesGenerales.inputText}
                placeholder='0'
                editable={false}
                value={saldo.toString()}></TextInput>
            }

            

            <Modal
                animationType="fade"
                transparent={true}
                visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalHeader}>Plazo</Text>
                        <Text style={styles.titleModal}>Selecciona el plazo de pago para el crédito</Text>

                        <View style={{flexDirection: 'row', width: '90%'}}>
                            <View style={{width: '50%'}}>
                                <Text style={stylesGenerales.labelInput}>Cant cuotas</Text>
                                <TextInput style={stylesGenerales.inputText}
                                           placeholder='Ingrese detalle'
                                           keyboardType='numeric'
                                           value={cantCuotas}
                                           onChangeText={changeCantCuotas}></TextInput>
                            </View>
                            <View style={{width: '50%', backgroundColor: colors.white}}>
                                <Text style={stylesGenerales.labelInput}>Valor cuota</Text>
                                <TextInput style={stylesGenerales.inputText}
                                           editable={false}
                                           value={valorCuota}></TextInput>
                            </View>
                        </View>

                        {
                            (valorCuotaAdi != 0 && !Number.isNaN(valorCuotaAdi)) &&
                            <Text
                                style={{
                                    width: '90%', marginVertical: '1%', fontWeight: 'bold',
                                    fontSize: 16, color: colors.verde_primary
                                }}
                            >[ + Cuota Adic. S/ {valorCuotaAdi} ]</Text>
                        }

                        <Text>Tipo</Text>
                        <View style={{
                            margin: 10,
                            width: '90%'
                        }}>
                            <SelectList
                                data={tiposPlazoCredito}
                                setSelected={setFrecuencia}
                                search={false}
                                defaultOption={tiposPlazoCredito[frecuencia]}/>
                        </View>

                        {frecuencia == 1 &&
                            <Text>Los días</Text>
                        }
                        {frecuencia == 1 &&
                            <View style={{
                                margin: 10,
                                width: '90%'
                            }}>
                                <SelectList
                                    data={diasSemana}
                                    setSelected={setDiaSemana}
                                    search={false}
                                    defaultOption={diasSemana[0]}/>
                            </View>}

                        {frecuencia == 2 &&
                            <Text>Los días</Text>
                        }
                        {frecuencia == 2 &&
                            <View style={{flexDirection: 'row'}}>
                                <TextInput
                                    style={{width: '30%', borderWidth: 0.5, borderRadius: 5, padding: 5}}
                                    value={diaQuincenaInicio}
                                    onChangeText={setDiaQuincenaInicio}
                                    keyboardType='numeric'>
                                </TextInput>
                                <Text style={{textAlignVertical: 'center', marginHorizontal: '4%'}}>
                                    y
                                </Text>
                                <TextInput
                                    style={{width: '30%', borderWidth: 0.5, borderRadius: 5, padding: 5}}
                                    value={diaQuincenaFin}
                                    onChangeText={setDiaQuincenaFin}
                                    keyboardType='numeric'>
                                </TextInput>
                            </View>
                        }

                        {frecuencia == 3 &&
                            <View style={{flexDirection: 'row'}}>
                                <Text style={{textAlignVertical: 'center', marginHorizontal: '4%'}}>El día</Text>
                                <TextInput
                                    style={{width: '20%', borderWidth: 0.5, borderRadius: 5, padding: 5}}
                                    keyboardType='numeric'
                                    value={diaMes}
                                    onChangeText={setDiaMes}></TextInput>
                                <Text style={{textAlignVertical: 'center', marginHorizontal: '4%'}}>del mes</Text>
                            </View>
                        }

                        <View style={{flexDirection: 'row', marginTop: '2%'}}>
                            <TouchableOpacity
                                style={styles.btnAgregar}
                                onPress={aceptarPlazo}>
                                <Icon name='add-circle' color={colors.white}></Icon>
                                <Text style={styles.btnLabel}>Aceptar</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={styles.btnEliminar}
                                onPress={cerrarModal}>
                                <Icon name='cancel' color={colors.white}></Icon>
                                <Text style={styles.btnLabel}>Cancelar</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal
                animationType="fade"
                transparent={true}
                visible={modalConfirmarVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalHeader}>Confirmar Crédito</Text>
                        <Text style={styles.titleModal}>Verifique el Crédito antes de guardarlo</Text>

                        <Text style={styles.labelConfirmar}>Fecha: {fecha}</Text>
                        <Text style={styles.labelConfirmar}>Crédito: S/ {valorCredito}</Text>
                        {frecuencia == 0 &&
                            <Text style={styles.labelConfirmar}>Plazo: Diario, {cantCuotas} cuota(s)</Text>}
                        {frecuencia == 1 &&
                            <Text style={styles.labelConfirmar}>Plazo: {cantCuotas} Semana(s),
                                los {diaFrecuencia}</Text>}
                        {frecuencia == 2 &&
                            <Text style={styles.labelConfirmar}>Plazo: {cantCuotas} Quincenas, los días {''}
                                {diaQuincenaInicio} y {diaQuincenaFin}</Text>}
                        {frecuencia == 3 &&
                            <Text style={styles.labelConfirmar}>Plazo: {cantCuotas} Mese(s), el día {diaFrecuencia}</Text>}
                        <Text style={styles.labelConfirmar}>Total: S/ {totalDeuda}</Text>

                        <View style={{flexDirection: 'row', marginTop: '4%'}}>
                            <TouchableOpacity
                                style={styles.btnAgregar}
                                onPress={handlePressModificar}>
                                <Text style={{
                                    color: colors.white,
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    paddingHorizontal: 10
                                }}>Confirmar</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={styles.btnEliminar}
                                onPress={() => setModalConfirmarVisible(false)}>
                                <Text style={{
                                    color: colors.white,
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    paddingHorizontal: 10
                                }}>Cancelar</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </ScrollView>
    )
};


const styles = StyleSheet.create({
    container: {
        // flex: 1,
        alignItems: 'center',
        // justifyContent: 'center'
    },

    labelTitle: {
        width: '90%',
        fontWeight: 'bold',
        fontSize: 23,
        marginTop: '4%',
        marginBottom: '1%'
    },

    labelSubTitle: {
        width: '90%',
        fontSize: 16
    },

    labelDescrip: {
        fontWeight: 'bold',
        width: '90%',
        fontSize: 18,
        marginTop: '6%'
    },

    btn: {
        borderRadius: 10,
        padding: 10,
        flexDirection: 'row',
        marginRight: 10
    },

    textBox: {
        backgroundColor: '#ccc',
        width: '90%',
        padding: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 20,
        color: colors.logo_azul,
        marginTop: '6%',
        marginBottom: '4%'
    },

    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        paddingBottom: 15,
        width: '80%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 5,
    },

    modalHeader: {
        backgroundColor: colors.verde_primary,
        width: '100%',
        padding: 10,
        fontWeight: 'bold',
        fontSize: 18,
        color: colors.white,
        textAlign: 'center',
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20
    },

    btnAgregar: {
        flexDirection: 'row',
        backgroundColor: colors.verde_primary,
        padding: 8,
        borderRadius: 5,
        margin: 5
    },

    btnAgregarDisable: {
        flexDirection: 'row',
        backgroundColor: colors.gris_fondo_cell,
        padding: 8,
        borderRadius: 5,
        margin: 5
    },

    btnEliminar: {
        flexDirection: 'row',
        backgroundColor: colors.logo_gris,
        padding: 8,
        borderRadius: 5,
        margin: 5
    },

    titleModal: {fontWeight: 'bold', fontSize: 18, marginTop: '5%', marginBottom: '2%'},

    labelPlazo: {
        fontWeight: 'bold',
        fontSize: 16
    },

    btnLabel: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        marginStart: 5
    },

    labelConfirmar: {
        fontSize: 16,
        width: '80%'
    },
    crtModalImg:{
        flex: 1,
        display:'flex',
        flexDirection:'column',
        backgroundColor:colors.gray
    },
    imgBoleta:{
        width: windowWidth* 0.9, // Adjust the percentage as needed
        // height: windowHeight,
        aspectRatio:0.5,
        alignSelf: 'center',
        // flex:1,
    },
    headerBox:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        height:40,
        backgroundColor:colors.white,
        paddingHorizontal: '5%',
        borderBottomWidth: 0.5,
        borderBottomColor: colors.logo_gris
    },
    btnClose:{
        display:'flex',
        width: 50,
        justifyContent:'center'
    },
    headerText:{
        fontSize:20,
        fontWeight: 'bold',
        color: colors.verde_primary,
        alignSelf: 'center'
    },
})
