import axios from "axios";
import urlsGenerales from "../constants/urlsGenerales";
import { StorageUtil } from "./StorageUtil";
import routes from "../constants/routes";
import AsyncStorage from '@react-native-async-storage/async-storage';

export class CajaService {

    static async validarEstadoCajaAbierta(codUsuario: string, navigation): Promise<any> {
        const cacheKey = 'estadoCajaAbierta';

        const currentHour = new Date().getHours();
        if (currentHour >= 22 || currentHour < 2) {
            const validate = await this.fetchAndCacheEstadoCajaAbierta(codUsuario, navigation, cacheKey);
            return validate
        }

        let cachedData = await AsyncStorage.getItem(cacheKey);
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            // Si el caché existe y no ha expirado, devolver el valor del caché
            if (Date.now() < parsedData.expiryTime) {
                return parsedData.value;
            }else{
                await AsyncStorage.removeItem(cacheKey)
            }
        }
        
        return await this.fetchAndCacheEstadoCajaAbierta(codUsuario, navigation, cacheKey);
    }

    static async fetchAndCacheEstadoCajaAbierta(codUsuario: string, navigation, cacheKey): Promise<any> {
        const formData = new FormData();
        formData.append('codUsuario', codUsuario);

        try {
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ES_CAJA_CERRADA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data) {
                const expiryTime = Date.now() + (10 * 60 * 1000); // Expira en 10 minutos

                if (response.data.resultado == 'ok') {
                    const value = true;
                    await AsyncStorage.setItem(cacheKey, JSON.stringify({ value, expiryTime }));
                    return true;
                } else if(response.data == 'redirigir') {
                    await AsyncStorage.clear();
                    setTimeout(() => {
                        navigation.replace(routes.LOGIN);
                    }, 200);
                    return 'redirigir';
                } else {
                    const value = false;
                    await AsyncStorage.setItem(cacheKey, JSON.stringify({ value, expiryTime }));
                    return false;
                }
            }
        } catch (error) {
            console.log('Error en -> ', urlsGenerales.ES_CAJA_CERRADA);
            console.error(error);
            return false;
        }
    }
}
