import React, {useEffect, useState} from 'react';
import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {SelectList} from 'react-native-dropdown-select-list';
import {Icon} from 'react-native-elements';
import colors from '../../../constants/colors';
import {useFocusEffect, useRoute} from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import {CreditoType} from "../../../types/CreditoType";
import {AbonoType} from "../../../types/AbonoType";
import ModalVerAbono from "../../../components/reportes/ModalVerAbonos";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';
type ClienteResponse = {
    malo: any[];
    regular: any[];
    bueno: any[];
    excelente: any[];
}

export default function ClientesInactivosPorCalificacion() {

    const navigation = useNavigation();

    const route = useRoute();
    const cobrador = route.params?.cobrador;
    const [loading, setLoading] = useState(false);

    const [cantidad, setCantidad] = useState(0);
    const [clienteResponse, setClienteResponse] = useState<ClienteResponse>({
        malo: [],
        regular: [],
        bueno: [],
        excelente: []
    });
    const [clienteList, setClientesList] = useState<any[]>([]);

    const [visibleAbonos, setVisibleAbonos] = useState(false);
    const [abonos, setAbonos] = useState<AbonoType[]>([]);
    const [creditoItem, setCreditoItem] = useState<CreditoType>({
        codCredito: '',
        codUsuario: '',
        codCliente: '',
        fecha: '',
        valorCredito: '',
        interes: '',
        cantCuotas: '',
        valorCuota: '',
        codFrecuencia: '',
        diaFrecuencia: '',
        diaFrecuencia2: '',
        totalDeuda: '',
        estado: '',
        timestamp: '',
        nombre: '',
        apellido: '',
        codDocumento: '',
        numDocumento: '',
        telefono: '',
        correo: '',
        direccion: '',
        alias: '',
    });

    const [searchText, setSearchText] = React.useState('');

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const [calificacion, setCalificacion] = React.useState('');
    const calificaciones = [
        {key: '0', value: 'Excelente'},
        {key: '1', value: 'Bueno'},
        {key: '2', value: 'Regular'},
        {key: '3', value: 'Malo'}
    ];

    useEffect(() => {
        switch (calificacion) {
            case '0':
                setCantidad(clienteResponse.excelente.length);
                setClientesList(clienteResponse.excelente.map(cliente => ({...cliente, descripcion: 'Excelente'})));
                break;
            case '1':
                setCantidad(clienteResponse.bueno.length);
                setClientesList(clienteResponse.bueno.map(cliente => ({...cliente, descripcion: 'Bueno'})));
                break;
            case '2':
                setCantidad(clienteResponse.regular.length);
                setClientesList(clienteResponse.regular.map(cliente => ({...cliente, descripcion: 'Regular'})));
                break;
            case '3':
                setCantidad(clienteResponse.malo.length);
                setClientesList(clienteResponse.malo.map(cliente => ({...cliente, descripcion: 'Malo'})));
                break;
            default:
                break;
        }
    }, [calificacion]);


    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 12, marginVertical: 20, textAlign:'center'}}>No existen clientes para mostrar</Text>
        );
    };

    const renderItem = ({item}) => {

        const verAbonos = async () => {
            // setCreditoItem(item);
            // await handleVerAbonos(item.codCredito);
            
            navigation.navigate(routes.RECAUDAR, {codCredito: item.codCredito,disabledFn:true});
        }

        return (
            <View style={{
                borderBottomWidth: 0.5,
                borderBottomColor: '#222222',
                marginBottom: 8,
                paddingVertical:8,
                paddingBottom:16,
                paddingHorizontal:'5%',
            }}
            >
                <View style={{flexDirection:'row',display:'flex'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={{alignSelf:'center',flex:1,fontSize:18,fontWeight:'bold',marginBottom:4}}>{item.nombre} {item.apellido}</Text>
                </View>

                <View style={styles.boxShadow}>
                    <Text style={{fontSize:16,fontWeight:'bold'}}>Información</Text>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3,marginTop:4}}>
                        <Text style={{textAlign:'left'}}>Saldo</Text>
                        <Text style={{fontSize:14, textAlign:'left'}}>{item.saldo}</Text>
                    </View>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3}}>
                        <Text style={{textAlign:'left'}}>Calificación</Text>
                        <Text style={{fontSize:14,fontWeight:'bold', textAlign:'left', color: item.descripcion == 'Excelente' ? colors.verde_primary :
                        item.descripcion == 'Bueno' ? colors.logo_azul :
                            item.descripcion == 'Malo' ? colors.rojo : colors.naranja}}>{item.descripcion}</Text>
                    </View>
                </View>

                <TouchableOpacity style={{
                    backgroundColor: colors.verde_primary,
                    padding:16,
                    borderRadius:4,
                    flex:1,
                    marginTop:8
                }}
                                  onPress={verAbonos}>
                    <Text style={{
                        color: colors.white,
                        fontWeight: 'bold',
                        fontSize: 14,
                        textAlign: 'center'
                    }}>Ver detalles</Text>
                </TouchableOpacity>
            </View>
        );
    };

    const obtenerAbonos = async (codCredito: string) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('codCredito', codCredito);

            console.log('codCredito -> ', codCredito);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_ABONOS_CREDITO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                setAbonos(response.data);
            }

        } catch (error) {
            console.log(error);
            console.log('Se cae en error -> ', urlsGenerales.LISTAR_ABONOS_CREDITO);
            setAbonos([]);

        } finally {
            setLoading(false);
        }
    }

    const handleVerAbonos = async (codCredito: string) => {
        await obtenerAbonos(codCredito);
        setVisibleAbonos(true);
    }

    const obtenerListClientes = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.REP_CLIENTES_INACTIVOS_POR_CALIFICACION, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            setClienteResponse(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.REP_CLIENTES_INACTIVOS_POR_CALIFICACION);
            console.log(error.response || error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await obtenerListClientes();
        };
        fetchData();
    }, [])
    

    return (
        <View style={styles.container}>

            <View style={{display:'flex',paddingHorizontal:'5%', marginBottom:16}}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({cantidad}{cantidad==1?' cliente inactivo':' cliente inactivos'})</Text></Text>
            <Text style={styles.lblDes}>Detalle y listado de cantidades de dinero entregadas a socios
                y/o administadores</Text>
                
            </View>
            <View style={styles.selectEstado}>
                <SelectList
                    data={calificaciones}
                    setSelected={setCalificacion}
                    search={false}
                    boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                    inputStyles={{alignSelf:'center',fontWeight:'bold'}}
                    dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                    defaultOption={calificaciones[0]}
                />
            </View>

            <View style={[styles.row,styles.mrg]}>
                <View style={styles.iconSearch}>
                    <Icon name='search' color={colors.negro}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    style={styles.inputSearch}
                    onChangeText={handleSearch}
                    value={searchText}
                    selectionColor={colors.negro}
                />
                {
                searchText !== '' &&
                    <TouchableOpacity
                        style={styles.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.negro}></Icon>
                    </TouchableOpacity>
                }
            </View>

            <FlatList
                data={clienteList.filter((item) => item.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.apellido.toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(index) => index.codCredito}
                ListEmptyComponent={renderEmpty}
                style={{marginTop: 15, paddingBottom:48}}
                contentContainerStyle={{paddingBottom:48}}
            />

            <ModalVerAbono
                visible={visibleAbonos}
                credito={creditoItem}
                abonos={abonos}
                onCancel={() => setVisibleAbonos(false)}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}

const styles = StyleSheet.create(
    {
        container: {
            flex: 1,
        },
        title:{
            fontWeight: 'bold',
            fontSize: 20,
            paddingTop:16,
            marginBottom:8,
        },
        lblDes:{
            fontSize:12,
        },
        selectEstado:{
            width:'100%',
            paddingHorizontal:16,
            marginBottom:0
        },
        row:{
            display:'flex',
            flexDirection:'row',
        },
        inputSearch:{
            height:48,
            borderRadius:4,
            backgroundColor: colors.white,
            width:'100%',
            color:colors.negro,
            paddingLeft:48,
            paddingRight:48,
            zIndex:1,
            fontWeight:'500'
        },
        iconSearch:{
            position:'absolute',
            zIndex:2,
            alignSelf:'center',
            paddingLeft:16,
        },
        mrg:{
            marginHorizontal:'5%',
            marginTop:16,
        },
        btnSearch:{
            position:'absolute',
            zIndex:2,
            alignSelf:'center',
            paddingRight:16,
            right:0,
        },
        boxShadow:{
            display:'flex',
            borderRadius:8,
            justifyContent:'center', 
            backgroundColor: colors.white,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.1,
            shadowRadius: 2,
            elevation: 2,
            padding:16,
            marginTop:8
        },
    }
)
