import React, { useState } from 'react';
import { FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { textoSpinner } from '../../../shared/TiposDocumento';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import axios from 'axios';
import urlsGenerales from '../../../constants/urlsGenerales';
import routes from '../../../constants/routes';
import { StorageUtil } from '../../../shared/StorageUtil';

export default function AgregarCredito() {

    const navigation = useNavigation();
    const route = useRoute();

    const cobrador = route.params?.cobrador;

    const [clientes, setClientes] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = React.useState('');

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const renderItem = ({ item }) => {

        const handlePress = () => {
            navigation.navigate(routes.ASIGNAR_CREDITO_ADMIN, { cliente: item });
        }

        return (
            <TouchableOpacity
                style={styles.crtItem}
                onPress={handlePress}>
                
                <View style={{flex:1,alignSelf:'center'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>{item.nombre} {item.apellido}</Text>
                    {item.numDocumento && item.numDocumento!== '' ? <Text style={styles.nrodoc}>{item.numDocumento}</Text> : null}
                </View>
                <Icon name='chevron-right' size={24} style={{alignSelf:'center'}}></Icon>

            </TouchableOpacity>
        );
    };

    const renderEmpty = () => {
        return (
                <Text style={{fontSize: 14, marginVertical: 20, textAlign:'center', fontWeight:'400'}}>{searchText!=''?'No se encontraron resultados':"No hay clientes registrados"}</Text>
        );
    };

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const formData = new FormData();
                    formData.append('codUsuario', cobrador.codUsuarioHijo);
                    const token = await StorageUtil.getTokenStorage();
                    const response = await axios.post(urlsGenerales.LISTAR_CLIENTES, formData, {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    setClientes(response.data);

                } catch (error) {
                    console.log('Error en -> ', urlsGenerales.LISTAR_CLIENTES);
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }, [])
    );

    return (
        <>
        <View style={styles.container}>
            <View style={[styles.mrg,{paddingVertical:24,paddingBottom:16}]}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({clientes.length}{clientes.length==1?' cliente':' clientes'})</Text></Text>
                <Text style={styles.des}>Seleccione un cliente para asignarle un crédito</Text>
            </View>
            <View style={[styles.row,styles.mrg]}>
                <View style={styles.iconSearch}>
                    <Icon name='search' color={colors.negro}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    style={styles.inputSearch}
                    onChangeText={handleSearch}
                    value={searchText}
                    selectionColor={colors.negro}
                />
                {
                searchText !== '' &&
                    <TouchableOpacity
                        style={styles.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.negro}></Icon>
                    </TouchableOpacity>
                }
            </View>
            <View style={[styles.row,styles.mrg,{marginTop:16}]}>
                <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Manten presionado para eliminar un cliente</Text>
            </View>

            {/* <Image style={styles.img} source={require('../../../assets/clientAdmin.png')}></Image>

            <Text style={stylesAdmin.labelTable}>
                Seleccione un cliente para asignarle un crédito</Text>

            <View style={[stylesGenerales.containerSearch, { marginBottom: '3%' }]}>
                <View style={stylesGenerales.btnSearch}>
                    <Icon name='search' color={colors.logo_gris}></Icon>
                </View>
                <TextInput placeholder='Buscar'
                    style={stylesGenerales.textInputSearch}
                    onChangeText={handleSearch}
                    value={searchText}></TextInput>
                {searchText !== '' &&
                    <TouchableOpacity style={stylesGenerales.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.logo_gris}></Icon>
                    </TouchableOpacity>}
            </View> */}

            {/* <TouchableOpacity
                style={{
                    width: '90%', flexDirection: 'row',
                    backgroundColor: colors.gris_fondo_cell,
                    paddingStart: '4%',
                    paddingEnd: '1%',
                    paddingVertical: '4%',
                    borderWidth: 0.5,
                    borderColor: colors.logo_gris
                }}
                onPress={seleccionarCliente}>
                <View style={{ width: '90%' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 20, margin: '1%' }}>
                        Francisco Levano</Text>
                    <Text style={{ fontWeight: 'normal', fontSize: 16, margin: '1%' }}>
                        Teléfono: 954411856</Text>
                    <Text style={{ fontWeight: 'normal', fontSize: 16, margin: '1%' }}>
                        Dirección: Av. 28 de Julio 234</Text>
                </View>
                <View style={{ width: '10%', justifyContent: 'center' }}>
                    <Icon name='chevron-right'
                        color={colors.white}
                        size={40}></Icon>
                </View>
            </TouchableOpacity> */}

            <FlatList
                 data={clientes.filter((item) => (item.nombre.trim()+" "+item.apellido.trim()).toLowerCase().includes(searchText.toLowerCase()))}
                 renderItem={renderItem}
                 keyExtractor={(item) => item.codCliente}
                 ListEmptyComponent={renderEmpty}
                 contentContainerStyle={{paddingTop:0,paddingBottom:48}}
                 style={{width: '100%', marginTop:24,}}
                 showsVerticalScrollIndicator={false}
            />
        </View>
        <Spinner
            visible={loading}
            color={colors.white}
        />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    mrg:{
        marginHorizontal:'5%'
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom:8
    },
    des:{
        fontSize:12,
    },
    label: {
        textAlign: 'left',
        width: 400
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor: colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontWeight:'300',
        fontSize:14,
    },
    nrodoc:{
        marginTop:4,
        fontSize:12,
        fontWeight:'200',
    }
})