import {StyleSheet} from 'react-native';
import colors from './colors';

const stylesAdmin = StyleSheet.create({

    // Botón Home Admin y Delegados
    containerBtnList: {
        flexDirection: 'row',
        width: '90%',
        borderWidth: 0.5,
        borderColor: colors.negro
    },

    btnFullList: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: '3%',
        paddingVertical: '2%',
        backgroundColor: colors.gris_fondo_cell
    },

    btnLargeList: {
        width: '90%',
        flexDirection: 'row',
        paddingHorizontal: '3%',
        paddingVertical: '2%',
        backgroundColor: colors.gris_fondo_cell
    },

    btnSmallList: {
        backgroundColor: colors.logo_gris, width: '10%', justifyContent: 'center'
    },

    // Botón en lista
    containetBtn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '90%',
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    textBtn: {
        fontSize: 15,
        paddingHorizontal: '3%',
        paddingVertical: '3%',
    },

    containerIcon: {justifyContent: 'center', paddingEnd: '4%'},

    textOpcion: {
        backgroundColor: colors.verde_primary,
        width: '90%',
        paddingHorizontal: '3%',
        paddingVertical: '2%',
        fontWeight: 'bold',
        fontSize: 16,
        color: colors.white
    },

    labelConexion: {
        width: '90%',
        paddingHorizontal: '3%',
        paddingVertical: '2%',
        textAlign: 'center',
        backgroundColor: colors.logo_gris,
        color: '#52E10E',
        fontWeight: 'bold',
        fontSize: 19
    },

    labelHagaClick: {
        width: '90%',
        fontSize: 16,
        textAlign: 'justify'
    },

    // Reportes
    labelTitle: {
        fontWeight: 'bold',
        fontSize: 25,
        marginTop: '5%',
        marginBottom: '2%',
        width: '90%'
    },

    labelListado: {
        fontWeight: 'bold',
        fontSize: 22,
        marginBottom: '3%',
        width: '90%'
    },

    labelTable: {
        width: '90%',
        fontWeight: 'bold',
        fontSize: 19,
        marginBottom: '1%'
    },

    cellBody: {
        paddingVertical: '1%',
        paddingHorizontal: '3%',
        fontWeight: 'bold',
        fontSize: 16,
        backgroundColor: colors.gris_fondo_cell,
        borderWidth: 0.5,
        borderColor: colors.logo_gris,
        textAlignVertical: "center"
    },

    cellHeader: {
        paddingVertical: '2%',
        paddingHorizontal: '3%',
        fontWeight: 'bold',
        fontSize: 17,
        color: colors.white,
        backgroundColor: colors.verde_primary,
        borderColor: colors.logo_gris,
        borderWidth: 0.5
    },

    // Delegados
    btnOpcion: {
        flexDirection: 'row',
        backgroundColor: colors.white,
        borderWidth: 0.5,
        borderColor: colors.negro,
        borderRadius: 5,
        padding: 10
    },

    textBtnOpcion: {marginLeft: 5, fontWeight: 'bold', fontSize: 16},

    emptyList: {
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#ebeeef',
        borderRadius: 10,
        paddingStart: '5%',
        borderWidth: 0.5,
        borderColor: '#2a2b2b',
    },
});

export default stylesAdmin;
