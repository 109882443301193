import React from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';

export default function CambiarDatosAcceso() {

    const aplicarCambios = () => {
        console.log('Aplicar cambios');
    }

    return (
        <View style={styles.container}>

            <Text style={stylesAdmin.labelTitle}>
                Opción de modificación de datos de acceso de la ruta</Text>
            <Text style={{ width: '90%', fontSize: 17, marginVertical: '3%' }}>
                Esta opción le permite cambiar los datos de acceso actuales del
                cobrador en la ruta.</Text>

            <Text style={stylesGenerales.labelInput}>Usuario</Text>
            <TextInput style={stylesGenerales.inputText}></TextInput>

            <Text style={stylesGenerales.labelInput}>Contraseña</Text>
            <TextInput style={stylesGenerales.inputText}></TextInput>

            <TouchableOpacity
                style={{
                    backgroundColor: colors.rojo,
                    flexDirection: 'row',
                    borderRadius: 10,
                    padding: 10,
                    marginTop: '2%',
                    marginBottom: '4%'
                }}
                onPress={aplicarCambios}>
                <Icon name='refresh' color={colors.white}></Icon>
                <Text style={{
                    color: colors.white,
                    fontWeight: 'bold',
                    fontSize: 15,
                    marginStart: '1%'
                }}>Aplicar cambios</Text>
            </TouchableOpacity>

        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center'
    },
})