import React, { useEffect, useState } from 'react';
import { SectionList, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import colors from '../../constants/colors';
import { useNavigation } from '@react-navigation/native';
import routes from '../../constants/routes';
import Spinner from "react-native-loading-spinner-overlay";
import { StorageUtil } from "../../shared/StorageUtil";
import axios from "axios";
import urlsGenerales from "../../constants/urlsGenerales";
import { NumberUtils } from "../../shared/NumberUtils";
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';

export default function VerLiquidaciones() {

    const navigator = useNavigation();

    const [loading, setLoading] = useState(false);
    const [liquidaciones, setLiquidaciones] = useState([]);

    const obtenerLiquidaciones = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            const cobradorStorage = await StorageUtil.getCobradorStorage();
            formData.append('codUsuario', cobradorStorage.codUsuarioHijo);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.VER_LIQUIDACIONES, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                return;
            }

            setLiquidaciones(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.VER_LIQUIDACIONES);
            console.log(error);
            setLiquidaciones([]);
        } finally {
            setLoading(false);
        }
    }

    const renderItem = ({ item }) => {
        return (
            <TouchableOpacity style={styles.btnLiquidacion}
                onPress={() => navigator.navigate(routes.DETALLE_LIQUIDACION, { liquidacion: item })}
            >   
            <View style={{flex:1, alignSelf:'center'}}>
                <Text style={{fontSize:12, fontWeight:'400'}}>#{item.codCaja}</Text>
                <Text style={{fontWeight:'bold', fontSize:12}}>{formatDate(item.fecha)}</Text>
            </View>
            <View style={{alignSelf:'center'}}>
                <Text style={[styles.lblPay, {textAlign:'right'}]}>Total caja</Text>
                <Text style={styles.lblMount}>S/ {NumberUtils.formatThousands(item.total)}</Text>
            </View>
            </TouchableOpacity>
        )
    };



    const renderSectionHeader = ({ section }) => {
        return (
            <View style={styles.sectionHeader}>
                <Text style={styles.sectionHeaderText}>{section.title}</Text>
            </View>
        );
    };

    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 12, marginVertical: 20, textAlign:'center'}}>No hay liquidaciones registradas</Text>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            await obtenerLiquidaciones();
        };
        fetchData();
    }, [])

    // Agrupar liquidaciones por año y mes
    const liquidacionesAgrupadas = liquidaciones.reduce((acc, liquidacion) => {
        const year = liquidacion.fecha.split('-')[0];
        const month = liquidacion.fecha.split('-')[1];
        const monthYear = `${month}/${year}`;
        if (!acc[monthYear]) {
            acc[monthYear] = [];
        }
        acc[monthYear].push(liquidacion);
        return acc;
    }, {});

    const months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Setiembre','Octubre','Noviembre','Diciembre']

    const sections = Object.keys(liquidacionesAgrupadas).map((monthYear) => ({
        title: months[Number(monthYear.split('/')[0])-1] + ' del ' + monthYear.split('/')[1] ,
        data: liquidacionesAgrupadas[monthYear]
    }));

    const formatDate = (dateString) => {
        const date = parseISO(dateString);
        const formattedDate = format(date, 'EEEE dd', { locale: es });
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    };


    return (
        <View style={styles.container}>

            <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({liquidaciones.length}{liquidaciones.length==1?' caja':' cajas'})</Text></Text>

            <Text style={{ fontSize: 12, marginHorizontal:'5%' }}>Listado de los cierres de caja hechos en el sistema (seleccione una caja para ver su detalle)
            </Text>

            <SectionList
                sections={sections}
                renderItem={renderItem}
                renderSectionHeader={renderSectionHeader}
                keyExtractor={(item, index) => index.toString()}
                ListEmptyComponent={renderEmpty}
                style={{marginTop:16,paddingBottom:48}}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
        marginHorizontal:'5%'
    },
    btnLiquidacion: {
        flexDirection: 'row',
        padding: 12,
        paddingHorizontal:'5%',
        borderBottomWidth: 0.5,
        borderBottomColor: '#22222250',
    },
    sectionHeader: {
        backgroundColor: colors.verde_shadow,
        padding: 16,
        paddingHorizontal:'5%',
    },

    sectionHeaderText: {
        fontSize: 16,
        fontWeight: '600',
    },
    lblPay:{
        fontSize:12,
        color:colors.negro,
        marginBottom:4
    },
    lblMount:{
        fontSize:16,
        fontWeight:'bold',
        color:colors.logo_azul,
    },
});
