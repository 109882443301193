import {useNavigation} from '@react-navigation/native';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {StyleSheet,Platform, Text ,Switch ,TextInput ,TouchableOpacity, View, ScrollView, } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../constants/colors';
import urlsGenerales from '../constants/urlsGenerales';
import {DateUtils} from "../shared/DateUtils";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import Checkbox from 'expo-checkbox';
import packageJson from '../package.json';
import CustomAlert from '../shared/CustomAlert';
import NetInfo from '@react-native-community/netinfo';
import {Icon} from 'react-native-elements';
import LottieView from 'lottie-react-native';
import bs from "./../assets/bsweb.json";
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import jwtDecode from "jwt-decode";

let Lottie;
if (Platform.OS === 'web') {
    // En el caso de la web, Lottie se importa de lottie-react
    Lottie = require("lottie-react").default;
} else {
    // Para otras plataformas, puede que tengas que usar otra librería o abordar de manera diferente la animación
    // Puedes mostrar algún mensaje indicando que la animación no está disponible en la plataforma actual
    Lottie = () => (
        <View>
            <Text>La animación no está disponible en esta plataforma</Text>
        </View>
    );
}

export default function Login() {

    const [isConnected, setIsConnected] = useState(true);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isFinish, setIsFinish] = useState(false);
    const insets = useSafeAreaInsets()

    const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
          setIsConnected(state.isConnected);
        });
    
        return () => {
          unsubscribe();
        };
    }, []);

    useEffect(() => {
        const validar = async() => {
            const type = await AsyncStorage.getItem('type');
            const token = await AsyncStorage.getItem('token');
            if(token){
                try {
                    const decoded:any = jwtDecode(token);
                    if (decoded.exp > Date.now() / 1000) {
                        if(type){
                            navigation.replace('MenuCliente');
                        }else{
                            navigation.replace('Menu');
                        }
                    } else {
                        await AsyncStorage.clear()
                    }
                } catch (error) {
                    await AsyncStorage.clear()
                }
            }else{
                await AsyncStorage.clear()
            }
        }
        validar()
    }, [])
    

    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [rememberUser, setRememberUser] = React.useState(false);

    const [mostrarMsgError, setMostrarMsgError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const navigation = useNavigation();
    const cambiarCheck = () => {
        setRememberUser(!rememberUser);
    };

    
    if(Platform.OS !== 'web'){
        React.useEffect(() => {
            retrieveUserData();
        }, []);

        const retrieveUserData = async () => {
            await SecureStore.deleteItemAsync('listLoginResponse');
            try {
              const savedUsername = await SecureStore.getItemAsync('username');
              const savedPassword = await SecureStore.getItemAsync('password');
              const savedRecordar = await SecureStore.getItemAsync('recordar');
              if (savedUsername && savedPassword && savedRecordar) {
                setUser(savedUsername);
                setPass(savedPassword);
                const savedRecordarBoolean = JSON.parse(savedRecordar);
                setRememberUser(savedRecordarBoolean);
              }
            } catch (error) {
              console.error('Error retrieving user data:', error);
            }
        };
    }
    
    const guardoMemoria = async (v_data) => {
        let listLoginResponseFinal = [];

        const listLoginResponseJson = await SecureStore.getItemAsync('listLoginResponse');
        if(listLoginResponseJson){
            const listLoginResponse = JSON.parse(listLoginResponseJson);
            let agregado = false;
            listLoginResponse.forEach((loginResponse) => {
                if(v_data['usu'] === loginResponse['usu'] && v_data['pass'] === loginResponse['pass'] && v_data['codUsuario'] === loginResponse['codUsuario']){
                    listLoginResponseFinal.push(v_data);
                    agregado = true;
                }else{
                    listLoginResponseFinal.push(loginResponse);
                }
                
            });
            if(!agregado){
                listLoginResponseFinal.push(v_data);
            }
            
        }else{
            listLoginResponseFinal.push(v_data);
        }

        await SecureStore.setItemAsync('listLoginResponse', JSON.stringify(listLoginResponseFinal));
    }

    function quitarTildesMinus(cadena) {
        return cadena.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    function realizarLoginSwitch(){
        isEnabled? realizarLoginCliente() : realizarLogin();
    }

    const validateInfo = async() => {
        const userStorage = await AsyncStorage.getItem('usu')
        console.log("userstorage",userStorage)
        if(!userStorage){
            await AsyncStorage.removeItem('dataCollect')
            await AsyncStorage.removeItem('dataListRoute')
            await AsyncStorage.removeItem('listDetail')
        }else{
            if(userStorage.toLowerCase() !== user.toLowerCase()){
                await AsyncStorage.removeItem('dataCollect')
                await AsyncStorage.removeItem('dataListRoute')
                await AsyncStorage.removeItem('listDetail')
            }
        }
    }

    const realizarLogin = async () => {

        const formData = new FormData();
        formData.append('usu', user);
        formData.append('pass', pass);
        
        setMostrarMsgError(false);

        try {
            showSpinner();
            let response = {data:{}};
            let logueado = false;

            //const isConnected = false;

            if(isConnected){
                response = await axios.post(urlsGenerales.LOGIN, formData, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                logueado = true;
                validateInfo()
            }else{
                
                const listLoginResponseJson = await SecureStore.getItemAsync('listLoginResponse');
                if(listLoginResponseJson){
                    const listLoginResponse = JSON.parse(listLoginResponseJson);
                    listLoginResponse.forEach((loginResponse) => {
                        if(quitarTildesMinus(user) === quitarTildesMinus(loginResponse['usu']) && quitarTildesMinus(pass) === quitarTildesMinus(loginResponse['pass'])){
                            response.data = loginResponse;
                            logueado = true;
                            return false;
                        }
                        
                    });
                    
                }
                
            }

            if(logueado){
                if (rememberUser) {
                    try {
                        await SecureStore.setItemAsync('username', user);
                        await SecureStore.setItemAsync('password', pass);
                        await SecureStore.setItemAsync('recordar', rememberUser+"");
                    } catch (error) {
                        console.error('Error saving user data:', error);
                    }
                }else{
                    try {
                        await SecureStore.setItemAsync('username', "");
                        await SecureStore.setItemAsync('password', "");
                        await SecureStore.setItemAsync('recordar', rememberUser+"");
                    } catch (error) {
                    console.error('Error saving user data:', error);
                    }
                }
            }
            

            if (logueado && response.data) {
                guardoMemoria(response.data);
                const privilegio = response.data.privilegio;
                const codUsuario = response.data.codUsuario;
                const usuario = response.data.usu;
                const password = response.data.pass;
                const nombre = response.data.nombre;
                const apellido = response.data.apellido;
                const telefono = response.data.telefono;
                const correo = response.data.correo;
                
                if(response.data.privilegio === 'S'){
                    const cantAdmins = response.data.cantAdmins;
                    const cantUsus = response.data.cantUsus;
                    await AsyncStorage.setItem('cantAdmins', cantAdmins);
                    await AsyncStorage.setItem('cantUsus', cantUsus);
                }

                const codTipoCuenta = response.data.codTipoCuenta;
                const diaPago = response.data.diaPago;
                const fechaVencimiento = response.data.fechaVencimiento;
                await AsyncStorage.setItem('codTipoCuenta', codTipoCuenta);
                await AsyncStorage.setItem('diaPago', diaPago);
                await AsyncStorage.setItem('fechaVencimiento', fechaVencimiento);
                await AsyncStorage.setItem('token',response.data.token);
                await AsyncStorage.setItem('privilegio', privilegio);
                await AsyncStorage.setItem('codUsuario', codUsuario);
                await AsyncStorage.setItem('usu', usuario);
                await AsyncStorage.setItem('pass', password);
                await AsyncStorage.setItem('nombre', nombre);
                await AsyncStorage.setItem('apellido', apellido);
                await AsyncStorage.setItem('telefono', telefono);
                await AsyncStorage.setItem('correo', correo);
                await AsyncStorage.setItem('ultimoSync', "");

                let entrar = true;
                if(privilegio !== 'M' && response.data.codTipoCuenta === "2"){
                    if(response.data.fechaVencimiento < DateUtils.getDateStringYYYYMMDD()){
                        entrar = false;
                        CustomAlert.show({
                            title: 'Cuenta Bloqueada',
                            message: 'Su cuenta "Demo" vencio el "'+response.data.fechaVencimiento+ '".\n\nSi quiere seguir usando la aplicación solicite una extensión de su demo ó suscribase al servicio.\n\n! Vuelva Pronto ¡ :)'
                        });
                    }
                }

                if(entrar){
                    navigation.replace('Menu');
                }
                
            }else{
                validateInfo()
                setMostrarMsgError(true);
            }
        } catch (error) {
            console.error(error);
            setMostrarMsgError(true);
            hideSpinner();

        } finally {
            hideSpinner();
        }
    }

    const realizarLoginCliente = async () => {
        /* if (rememberUser) {
            try {
              await SecureStore.setItemAsync('username', user);
              await SecureStore.setItemAsync('password', pass);
              await SecureStore.setItemAsync('recordar', rememberUser+"");
            } catch (error) {
              console.error('Error saving user data:', error);
            }
        }else{
            try {
                await SecureStore.setItemAsync('username', "");
                await SecureStore.setItemAsync('password', "");
                await SecureStore.setItemAsync('recordar', rememberUser+"");
            } catch (error) {
            console.error('Error saving user data:', error);
            }
        }
 */
        const formData = new FormData();
        formData.append('usu', user);
        formData.append('pass', pass);

        try {
            showSpinner();
            let response = {data:{}};
            let logueado = false;

            //const isConnected = false;

            if(isConnected){
                logueado = true;
                response = await axios.post(urlsGenerales.LOGIN_CLIENTE, formData, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }
            

            if (logueado && response.data) {
                
                const nombre = response.data.nombre;
                const apellido = response.data.apellido;
                const telefono = response.data.telefono;
                const correo = response.data.correo;
                const direccion = response.data.direccion;
                const alias = response.data.alias;
                const numDocumento = response.data.numDocumento;
                const codCliente = response.data.codCliente;


                await AsyncStorage.setItem('token',response.data.token);
                await AsyncStorage.setItem('type',"cliente");
                await AsyncStorage.setItem('nombre', nombre);
                await AsyncStorage.setItem('apellido', apellido);
                await AsyncStorage.setItem('telefono', telefono);
                await AsyncStorage.setItem('correo', correo);
                await AsyncStorage.setItem('direccion', direccion);
                await AsyncStorage.setItem('alias', alias);
                await AsyncStorage.setItem('numDocumento', numDocumento);
                await AsyncStorage.setItem('codCliente', codCliente);
                await AsyncStorage.setItem('ultimoSync', "");

                navigation.replace('MenuCliente');
                
            }else{
                setMostrarMsgError(true);
            }
        } catch (error) {
            console.error(error);
            setMostrarMsgError(true);
            hideSpinner();

        } finally {
            hideSpinner();
        }
    }

    // Función para mostrar el spinner
    const showSpinner = () => {
        setLoading(true);
    };

    // Función para ocultar el spinner
    const hideSpinner = () => {
        setLoading(false);
    };

    

    return (
        <>
        <View style={styles.container}>
            <View style={[styles.crtAnimation,{paddingTop:Platform.OS !== 'web'?insets.top:0}]}>
                <View style={[styles.crtBanner,{marginTop:Platform.OS !== 'web'?insets.top:16}]}>
                    <Text style={{fontWeight:'bold',color:colors.white, fontStyle:'italic',fontSize:24}}>CFP</Text>
                    <Text style={[styles.labelVersion,{color:colors.white}]}>V. {packageJson.version}</Text>
                </View>
                {
                    Platform.OS !== 'web'?
                    <LottieView
                        style={{
                            width: '100%',
                            height: 180,
                            alignSelf:'center',
                            flex:1,
                            marginBottom:-8
                        }} 
                        autoPlay={isFinish?false:true}
                        loop={false}
                        onAnimationFinish={()=>{
                            setIsFinish(true)
                        }}
                        source={require('./../assets/bs.json')}
                    />
                    :
                    <Lottie animationData={bs} style={{
                        width: '100%',
                        height: 180,
                        alignSelf:'center',
                        flex:1,
                        marginBottom:-17
                    }} 
                    loop={false} />
                }
            </View>
            <ScrollView 
                style={styles.crtLogin} 
                alwaysBounceHorizontal={false}
                alwaysBounceVertical={false}
                bounces={false}
            >   
            <View style={styles.contentLogin}>
                
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom:16 }}>
                        <View style={{flexDirection:'row', alignSelf:'center', alignItems: 'center'}}>
                            <Switch
                                trackColor={{false: '#767577', true: '#81b0ff'}}
                                thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
                                ios_backgroundColor="#3e3e3e"
                                onValueChange={toggleSwitch}
                                value={isEnabled}
                            />
                            <Text style={{fontWeight:'bold', color:colors.negro}} > {isEnabled? 'Cliente':'Asesor'}</Text>
                        </View>
                    </View>
                    <Text style={styles.labelInput}>{isEnabled?'Nro de celular':'Usuario'}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={isEnabled?'Ingresa tu Nro de celular':'Ingresa tu usuario'}
                        value={user}
                        onChangeText={setUser}
                    >
                    </TextInput>

                    <Text style={styles.labelInput}>{isEnabled?'Nro de documento':'Contraseña'}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder='Ingresa tu contraseña'
                        value={pass}
                        onChangeText={setPass}
                        onSubmitEditing={realizarLogin}
                        secureTextEntry={true}
                        autoCorrect={false}>
                    </TextInput>
                    {
                        mostrarMsgError &&
                        <View
                            style={{marginVertical:16,}}
                        >
                            <Text style={{
                                textAlign: 'left', fontSize: 15, color:colors.rojo
                            }}>
                                Usuario y/o Contraseña incorrecto(s) {isConnected? "":"\nNo tenemos conexión a internet"}
                            </Text>

                        </View>
                    }

                    { Platform.OS !== "web" &&
                        <TouchableOpacity
                            onPress={cambiarCheck}
                        >
                            <View style={{ flexDirection: 'row' ,alignItems: 'center', marginTop:8}}>
                                <Checkbox
                                color={colors.verde_primary}
                                value={rememberUser}
                                onValueChange={cambiarCheck}
                                style={{ marginRight: 8 }}
                                />
                                <Text>Recordar Usuario</Text>
                            </View>
                        </TouchableOpacity>
                    }

                    <TouchableOpacity
                        style={styles.btnLogin}
                        onPress={realizarLoginSwitch}>
                        <Text style={styles.btnLoginText}>
                            INGRESAR
                        </Text>
                    </TouchableOpacity>

                    <View style={{alignSelf:'center',flex:1,flexDirection:'row', marginTop:40}}>
                        <Text style={{alignSelf:'center'}}>Internet </Text>
                        <Icon style={{alignSelf:'center'}} size={24} name={isConnected ? 'wifi' : 'wifi-off'} color={isConnected ? colors.verde_primary : colors.rojo}></Icon>
                    </View>
                </View>
            </ScrollView>
        </View>
        
        <Spinner
            visible={loading}
            color={colors.white}
        />
        </>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexGrow:1,
        backgroundColor:colors.verde_primary,
    },
    crtAnimation:{
        backgroundColor:colors.verde_primary,
        position:'relative',
        height:200,
        maxWidth:500,
        width:'100%',
        alignSelf:'center'
    },
    crtLogin: {
        flexGrow:1,
        borderTopLeftRadius:24,
        borderTopRightRadius:24,
        backgroundColor: colors.white,
    },
    contentLogin:{
        width:'100%',
        maxWidth:500,
        paddingHorizontal:'5%',
        alignSelf:'center',
        paddingVertical:24,
        zIndex:10
    },
    input: {
        height: 48,
        marginVertical: 10,
        borderWidth: 0.5,
        paddingHorizontal: 16,
        borderRadius: 4
    },

    inputHash: {
        height: 45,
        width: 320,
        margin: 10,
        borderWidth: 0.3,
        padding: 10,
        color: 'red',
        fontWeight: 'bold',
        backgroundColor: '#DFDFDF',
        fontSize: 18,
        borderRadius: 5
    },

    labelInput: {
        fontSize: 12,
        fontWeight:'bold'
    },

    labelVersion: {
        fontSize: 10,
        fontWeight:'500'
    },

    labelEstado: {
        marginTop: 3
    },

    labelRecuperar: {
        color: '#C6C5C5',
        marginTop: 12
    },

    buttonView: {
        marginTop: 10,
        width: 320
    },
    btnLogin:{
        height:48,
        backgroundColor:colors.white,
        shadowColor: colors.negro,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.4,
        shadowRadius: 2.00,
        elevation: 4,
        borderRadius:4,
        display:'flex',
        justifyContent:'center',
        marginVertical:16
    },
    btnLoginText:{
        fontWeight:'bold',
        alignSelf:'center',
        color:colors.verde_primary,
    },
    crtBanner:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        position:'absolute',
        top:0,
        width:'100%',
        height:30,
        paddingHorizontal:'5%',
        alignItems:'center'
    }
});
