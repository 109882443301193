import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View, RefreshControl} from 'react-native';
import {Icon} from 'react-native-elements';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../../../constants/colors';
import routes from '../../../constants/routes';
import urlsGenerales from '../../../constants/urlsGenerales';
import ModalOperacionAdmin from "../../../components/ModalOperacionAdmin";
import ModalMensaje from "../../../components/ModalMensaje";
import {AuthenticationService} from "../../../shared/AuthenticationService";
import CustomAlert from '../../../shared/CustomAlert';
import { StorageUtil } from '../../../shared/StorageUtil';
import {SelectList} from 'react-native-dropdown-select-list';

export default function BorrarModificarCredito() {
   const estados = [
        {key: '1', value: 'Activos', label: 'activo'},
        {key: '0', value: 'Inactivos', label: 'inactivo'},
        {key: '2', value: 'En Mora', label: 'en mora'},
        {key: '3', value: 'Vencidos', label: 'vencido'},
        {key: '4', value: 'Pagados', label: 'pagado'}
    ];

    const route = useRoute();
    const navigation = useNavigation();

    const cobrador = route.params!.cobrador;

    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMensaje, setModalMensaje] = useState(false);
    const [codSelected, setCodSelected] = useState('');
    
    const [estadoCredito, setEstadoCredito] = useState(1);

    const [creditos, setCreditos] = useState([]);
    const [activos, setActivos] = useState([]);
    const [inactivos, setInactivos] = useState([]);
    const [aplazados, setAplazados] = useState([]);
    const [vencidos, setVencidos] = useState([]);
    const [pagados, setPagados] = useState([]);

    const [creditosLista, setCreditosLista] = useState([]);
    const [total, setTotal] = useState(0);
    
    const [refreshing, setRefreshing] = useState(false);

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const handleEliminar = async (usuario: string, clave: string) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codCredito', codSelected);
            formData.append('usu', usuario);
            formData.append('pass', clave);

            console.log("------>",formData);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ELIMINAR_CREDITO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                if(response.data.mensaje == "ok"){
                    CustomAlert.show({
                        title: 'Aviso',
                        message: 'Crédito eliminado con éxito',
                        buttons: [
                            {
                                text: 'OK',
                                onPress: () => {
                                    listarCreditos();
                                },
                            },
                        ]
                    });
                }else{
                    CustomAlert.show({
                        title: 'Aviso',
                        message: response.data.mensaje
                    });
                }
                
            }

        } catch (e) {
            console.log('Error en -> ', urlsGenerales.ELIMINAR_CREDITO);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {

        setModalVisible(false);

        setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            setModalVisible(false);
            CustomAlert.errorCredenciales();
            setLoading(false);
            return;
        }
        setLoading(false);

        handleEliminar(usuario, clave);
    }

    const renderItem = ({item}) => {
        const onPressHandle = () => {
            
            const fechaformat = new Date();
            const fechaString = fechaformat.getFullYear()+"-"+String(fechaformat.getMonth() + 1).padStart(2, '0')+"-"+String(fechaformat.getDate()).padStart(2, '0');
            const fecha1 = new Date(item.timestamp.substring(0,10));
            const fecha2 = new Date(fechaString);

            setCodSelected(item.codCredito);
            if(fecha1 >= fecha2){
                setModalVisible(true);
            }else{
                setModalMensaje(true);
            }
            
        }

        return (
            <TouchableOpacity
                style={styles.crtItem}
                onLongPress={onPressHandle}
                onPress={() => navigation.navigate(routes.VER_CREDITO, {codCredito: item.codCredito})}>
                
                <View style={{flex:1,alignSelf:'center'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>{item.nombre} {item.apellido}</Text>
                    <Text style={styles.code}><Text style={{fontWeight:'bold'}}>#{item.codCredito}</Text> - {item.fecha}</Text>
                </View>
                <Text style={{alignSelf:'center', fontWeight:'bold', fontSize:16}}>
                    S/ {Number(item.totalDeuda).toFixed(2)}
                </Text>

            </TouchableOpacity>
        );
    };

    // Para mejor rendimiento del FlatList
    const MemoizedItem = React.memo(renderItem);

    const renderEmpty = () => {
        return (
                <Text style={{fontSize: 14, marginVertical: 20, textAlign:'center', fontWeight:'400'}}>{searchText!=''?'No se encontrarón resultados':"No hay créditos"}</Text>
        );
    };

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                await listarCreditos();
            };
            fetchData();
        }, [])
    );

    const listarCreditos = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_CREDITOS_V2, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            setCreditos(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.LISTAR_CREDITOS_V2);
            console.log(error);
            setCreditos([]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        setActivos(creditos.filter((s: { estado: string; }) => s.estado === '1'));
        setInactivos(creditos.filter((s: { estado: string; }) => s.estado === '0'));
        setAplazados(creditos.filter((s: { estado: string; }) => s.estado === '2'));
        setVencidos(creditos.filter((s: { estado: string; }) => s.estado === '3'));
        setPagados(creditos.filter((s: { estado: string; }) => s.estado === '4'));

        switch (estadoCredito+"") {
            case "0":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '0'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '0').length)
                break;
            case "1":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '1'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '1').length);
                break;
            case "2":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '2'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '2').length);
                break;
            case "3":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '3'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '3').length)
                break;
            case "4":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '4'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '4').length)
                break;
            default:
                break;
        }
    }, [creditos]);

    const changeEstado = (value: String) => {
        switch (value) {
            case "0":
                setCreditosLista(inactivos);
                setTotal(inactivos.length)
                break;
            case "1":
                setCreditosLista(activos);
                setTotal(activos.length)
                break;
            case "2":
                setCreditosLista(aplazados);
                setTotal(aplazados.length)
                break;
            case "3":
                setCreditosLista(vencidos);
                setTotal(vencidos.length)
                break;
            case "4":
                setCreditosLista(pagados);
                setTotal(pagados.length)
                break;
            default:
                break;
        }
    }

    const onRefresh = async() => {
        await setRefreshing(true);
        await listarCreditos()
        await setRefreshing(false)
    }

    // return (
    //     <>
    //     <View style={styles.container}>

    //         <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({creditos.length}{creditos.length==1?' credito':' creditos'})</Text></Text>

    //         <Text style={{ fontSize: 12, marginHorizontal:'5%' }}>Esta funcionalidad le permite borrar o modificar un crédito seleccionado</Text>
    //         <View style={{marginTop:16}}>
    //                 <View style={[styles.row,styles.mrg]}>
    //                     <View style={styles.iconSearch}>
    //                         <Icon name='search' color={colors.negro}></Icon>
    //                     </View>
    //                     <TextInput
    //                         placeholder='Buscar'
    //                         style={styles.inputSearch}
    //                         onChangeText={handleSearch}
    //                         value={searchText}
    //                         selectionColor={colors.negro}
    //                     />
    //                     {
    //                     searchText !== '' &&
    //                         <TouchableOpacity
    //                             style={styles.btnSearch}
    //                             onPress={limpiarSearchText}>
    //                             <Icon name='clear' color={colors.negro}></Icon>
    //                         </TouchableOpacity>
    //                     }
    //                 </View>
                    
    //                 <View style={[styles.row,{marginHorizontal:'5%', marginTop:8}]}>
    //                     <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
    //                     <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Manten presionado para eliminar un crédito</Text>
    //                 </View>
    //             </View>
    //         {/* <Text style={stylesAdmin.labelTitle}>
    //             Opción de modificación de créditos de la ruta</Text>

    //         <Text style={{width: '90%', fontSize: 17, marginVertical: '3%'}}>
    //             Esta opción le permite borrar o modificar un crédito seleccionado</Text>

    //         <Text style={stylesAdmin.labelTable}>Seleccione crédito a modificar</Text>

    //         <View style={[stylesGenerales.containerSearch, {marginBottom: '3%'}]}>
    //             <View style={stylesGenerales.btnSearch}>
    //                 <Icon name='search' color={colors.logo_gris}></Icon>
    //             </View>
    //             <TextInput placeholder='Buscar'
    //                        style={stylesGenerales.textInputSearch}
    //                        onChangeText={handleSearch}
    //                        value={searchText}></TextInput>
    //             {searchText !== '' &&
    //                 <TouchableOpacity style={stylesGenerales.btnSearch}
    //                                   onPress={limpiarSearchText}>
    //                     <Icon name='clear' color={colors.logo_gris}></Icon>
    //                 </TouchableOpacity>}
    //         </View>

    //         <Text>
    //             Manten presionado un crédito para eliminar</Text> */}

    //         <FlatList
    //             data={creditos.filter((item) => item.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
    //                 item.apellido.toLowerCase().includes(searchText.toLowerCase()))}
    //             renderItem={({item}) => <MemoizedItem item={item}/>}
    //             keyExtractor={(item) => item.codCredito}
    //             ListEmptyComponent={renderEmpty}
    //             style={{width: '100%', marginTop: 5, marginBottom: '2%'}}
    //         />

    //         <ModalOperacionAdmin
    //             visible={modalVisible}
    //             title='Aviso'
    //             subtitle='Ingrese sus credenciales para confirmar la operación'
    //             onCancel={() => setModalVisible(false)}
    //             onConfirm={handleConfirmModal}
    //         />

    //         <ModalMensaje
    //             visible={modalMensaje}
    //             title={"No permitido"}
    //             subtitle={subtitle}
    //             onCancel={() => setModalMensaje(false)}
    //             onConfirm={() => setModalMensaje(false)}
    //         />

    //         <Spinner
    //             visible={loading}
    //             color={colors.verde_primary}
    //             textContent={textoSpinner}
    //             textStyle={stylesGenerales.textSpinner}
    //         />
    //     </View>
    //     </>
    // )
    return (
        <>
            <View style={styles.container}>
                <View style={[styles.row,styles.mrg,{justifyContent:'space-between',paddingVertical:24,paddingBottom:16, display:'flex'}]}>
                    <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({total} {total==1?`credito ${estados.find(objeto => objeto.key == String(estadoCredito)).label}`:`creditos ${estados.find(objeto => objeto.key == String(estadoCredito)).label}${estadoCredito==2?'':'s'}`})</Text></Text>
                </View>
                <View style={styles.selectEstado}>
                    <SelectList
                        data={estados}
                        search={false}
                        boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                        inputStyles={{alignSelf:'center',fontWeight:'bold'}}
                        dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                        setSelected={(value: number) => {
                            setEstadoCredito(value);
                            changeEstado(value);
                        }}
                        defaultOption={estados[0]}
                    />
                </View>
                <View style={[styles.row,styles.mrg]}>
                    <View style={styles.iconSearch}>
                        <Icon name='search' color={colors.negro}></Icon>
                    </View>
                    <TextInput
                        placeholder='Buscar'
                        style={styles.inputSearch}
                        onChangeText={handleSearch}
                        value={searchText}
                        selectionColor={colors.negro}
                    />
                    {
                    searchText !== '' &&
                        <TouchableOpacity
                            style={styles.btnSearch}
                            onPress={limpiarSearchText}>
                            <Icon name='clear' color={colors.negro}></Icon>
                        </TouchableOpacity>
                    }
                </View>
                <View style={[styles.row,styles.mrg,{marginTop:16}]}>
                    <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                    <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Manten presionado para eliminar un credito</Text>
                </View>

                <FlatList
                    refreshControl={
                        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                    }
                    data={creditosLista.filter((item) => (item.nombre+" "+item.apellido).toLowerCase().includes(searchText.toLowerCase()))}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.codCredito}
                    ListEmptyComponent={renderEmpty}
                    contentContainerStyle={{paddingTop:0,paddingBottom:56}}
                    style={{width: '100%', marginTop:24,}}
                    showsVerticalScrollIndicator={false}
                />
                
                <ModalOperacionAdmin
                    visible={modalVisible}
                    title='Aviso'
                    subtitle='Ingrese sus credenciales para confirmar la operación'
                    onCancel={() => setModalVisible(false)}
                    onConfirm={handleConfirmModal}
                />
                <ModalMensaje
                    visible={modalMensaje}
                    title={"No permitido"}
                    subtitle={"Este credito fue creado en una fecha anterior a la de hoy, prohibido su borrado"}
                    onCancel={() => setModalMensaje(false)}
                    onConfirm={() => setModalMensaje(false)}
                />
            </View>
            <Spinner
                visible={loading}
                color={colors.white}
            />
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    mrg:{
        marginHorizontal:'5%'
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        alignSelf:'center'
    },
    label: {
        textAlign: 'left',
        width: 400
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor: colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },
    img: {
        width: 80,
        height: 80,
        marginTop: 30
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontSize:14,
        marginBottom:4
    },
    code:{
        fontWeight:'300',
        fontSize:12,
        marginTop:4,
    },
    date:{
        fontWeight:'300',
        fontSize:12,
        color:colors.logo_naranja
    },
    selectEstado:{
        width:'100%',
        paddingHorizontal:16,
        marginBottom:16
    }
})
