import {Image, Modal, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View} from "react-native";
import React from "react";
import {Icon} from "react-native-elements";
import colors from "../constants/colors";
import { ProductoType } from "../types/ProductType";
import { TextInput } from "react-native-gesture-handler";
import stylesGenerales from "../constants/stylesGenrales";

type Props = {
    visible: boolean;
    producto: ProductoType;
    nuevo: boolean
    onCancel: () => void;
};

export default function ModalVerProductos(props: Props) {
    const [nombreProducto, setnombreProducto] = React.useState(props.producto.nombre);

    var timestamp = new Date(props.producto.timestamp);
    var dia = timestamp.getDate()+"";
    dia = dia.toString().length < 2? "0"+dia: dia;
    var mes = (timestamp.getMonth()+1)+"";
    mes = mes.toString().length < 2? "0"+mes: mes;
    var hora = timestamp.getHours()+"";
    hora = hora.toString().length < 2? "0"+hora: hora;
    var minuto = timestamp.getMinutes()+"";
    minuto = minuto.toString().length < 2? "0"+minuto: minuto;
    var segundos = timestamp.getSeconds()+"";
    segundos = segundos.toString().length < 2? "0"+segundos: segundos;
    var fechaCreado = dia+"-"+mes+"-"+timestamp.getFullYear()+" "+hora+":"+minuto+":"+segundos+" (24 hrs)";
    const [mostrarElimnar, setmostrarElimnar] = React.useState("true");
    const [mostrarGuardar, setmostrarGuardar] = React.useState("none");
    const [editable, setEditable] = React.useState(false);
    

    function mostrarEliminarBtn(){
        setmostrarElimnar("true");
        setmostrarGuardar("none");
        setEditable(false);
    }

    function mostrarGuardarBtn(){
        setmostrarGuardar("true");
        setmostrarElimnar("none");
        setEditable(true);
    }

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.visible}
        >
            <TouchableWithoutFeedback>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>

                        <Text style={styles.subTitlesModal}>
                            Codigo: {props.producto.idProducto}</Text>

                        <View style={styles.viewBody}>

                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={styles.textBody}>Nombre: {}</Text>
                                <TextInput 
                                    editable = {editable}
                                    style={[stylesGenerales.inputText,{width:'80%',marginBottom:'1%'}]}
                                    value={props.producto.nombre}>
                                </TextInput>                            
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={styles.textBody}>Detalle: {}</Text>
                                <TextInput 
                                    editable = {editable}
                                    style={[stylesGenerales.inputText,{width:'80%',marginBottom:'1%',height: '40'}]}
                                    value={props.producto.detalle}
                                    multiline={true}
                                    numberOfLines={3}>
                                </TextInput>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={styles.textBody}>Precio: {}</Text>
                                <TextInput 
                                    editable = {editable}
                                    style={[stylesGenerales.inputText,{width:'80%',marginBottom:'1%'}]}
                                    value={props.producto.precio}>
                                </TextInput>                            
                            </View>
                            
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={styles.textBody}>Imagen: {}</Text>
                                <TextInput 
                                    editable = {editable}
                                    style={[stylesGenerales.inputText,{width:'80%',marginBottom:'1%'}]}
                                    value={props.producto.imagen}>
                                </TextInput>                            
                            </View>
                            <Image
                                style={{ width: 250, height: 250, alignSelf:"center",display:mostrarElimnar}} 
                                source={{ uri: props.producto.imagen }} 
                            />
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={styles.textBody}>Creado: {}</Text>
                                <TextInput 
                                    editable = {false}
                                    style={[stylesGenerales.inputText,{width:'80%',marginBottom:'1%'}]}
                                    value={fechaCreado}>
                                </TextInput>                            
                            </View>
                            <View style={[styles.rowButtons,{display:mostrarElimnar}]}>

                                <TouchableOpacity onPress={mostrarGuardarBtn}
                                                  style={[styles.buttonOptions, {backgroundColor: colors.naranja}]}
                                >
                                    <Text style={styles.textButtonOptions}>
                                        Modificar</Text>
                                    <Icon name={'edit'} color={colors.white}/>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={()=>{}}
                                                  style={[styles.buttonOptions, {backgroundColor: colors.rojo}]}
                                >
                                    <Text style={styles.textButtonOptions}>
                                        Eliminar</Text>
                                    <Icon name={'cancel'} color={colors.white}/>
                                </TouchableOpacity>

                            </View>

                            <View style={[styles.rowButtons,{display:mostrarGuardar}]}>

                                <TouchableOpacity onPress={()=>{}}
                                                  style={[styles.buttonOptions, {backgroundColor: colors.verde_primary}]}
                                >
                                    <Text style={styles.textButtonOptions}>
                                        Guardar</Text>
                                    <Icon name={'save'} color={colors.white}/>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={mostrarEliminarBtn}
                                                  style={[styles.buttonOptions, {backgroundColor: colors.rojo}]}
                                >
                                    <Text style={styles.textButtonOptions}>
                                        Cancelar</Text>
                                    <Icon name={'cancel'} color={colors.white}/>
                                </TouchableOpacity>

                            </View>

                        </View>

                        <TouchableOpacity style={[styles.buttonOptions,
                            {backgroundColor: colors.logo_gris, marginTop: '5%'}]}
                                          onPress={props.onCancel}>
                            <Text style={styles.textButtonOptions}>Cerrar</Text>
                            <Icon name={'cancel'} color={colors.white}/>
                        </TouchableOpacity>

                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        paddingBottom: 15,
        paddingTop: 20,
        width: '80%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 5,
    },

    titleModal: {
        fontWeight: 'bold',
        fontSize: 20,
        width: '90%',
        marginBottom: '3%'
    },

    subTitlesModal: {
        fontWeight: "bold",
        fontSize: 16,
        width: '90%'
    },

    viewBody: {
        width: '90%',
        borderWidth: 0.5,
        borderColor: colors.gris_fondo_cell,
        borderRadius: 5,
        paddingHorizontal: '4%',
        paddingTop: '3%',
        paddingBottom: '4%',
        marginTop: '4%'
    },

    textBody: {
        fontWeight: "normal",
        fontSize: 16,
        marginBottom: '1%'
    },

    rowButtons: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: '100%',
        marginTop: '3%'
    },

    buttonOptions: {
        flexDirection: "row",
        padding: 8,
        borderRadius: 5,
        width: '49%',
        justifyContent: "center"
    },

    textButtonOptions: {
        fontSize: 15,
        fontWeight: "normal",
        color: colors.white,
        marginRight: '10%'
    }
})

