export default {
  white: "#FFFFFF",
  negro: "#000000",
  rojo: "#FF0000",
  naranja: "#eba534",
  logo_azul: "#003853",
  logo_naranja: "#dd6b2a",
  logo_celeste: "#75b5b4",
  logo_gris: "#9f9fa1",
  verde_primary: "#019a32",
  verde_shadow: "#CBF3AC",
  gris_borde_item_lista: "#2a2b2b",
  gris_fondo_cell: "#e0e3e4",
  azul_cobalto: "#3F448C",
};
