import React, {useState} from 'react';
import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';

import {useFocusEffect, useNavigation} from '@react-navigation/native';
import colors from '../../constants/colors';
import routes from '../../constants/routes';
import stylesAdmin from '../../constants/stylesAdmin';
import {StorageUtil} from "../../shared/StorageUtil";
import {CobradorType} from "../../types/CobradorType";

export default function Reportes() {

    const navigator = useNavigation();

    const [cobrador, setCobrador] = useState<CobradorType>({
        codUsuario: "",
        nombre: "",
        apellido: "",
        codUsuarioHijo: "",
        nombreHijo: "",
        apellidoHijo: "",
        documentoHijo: "",
        telefonoHijo: "",
        correoHijo: "",
        idHijo: "",
        contraHijo: ""
    });

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                const cobradorStorage = await StorageUtil.getCobradorStorage();
                setCobrador(cobradorStorage);
            };
            fetchData();
        }, [])
    );

    return (
        <ScrollView contentContainerStyle={styles.container} showsHorizontalScrollIndicator={false}>
            <View style={{paddingHorizontal:'5%'}}>
                <View>
                    <Text style={styles.lblTitle}>Reportes</Text>
                    <Text style={styles.lblDes}>Visualizar información por fechas</Text>
                </View>
                <View style={{borderRadius:8, overflow:'hidden'}}>
                    <TouchableOpacity style={styles.rowFn}
                                onPress={() => navigator.navigate(routes.RESUMEN_POR_FECHA, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Resumen por fecha</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='format-list-bulleted' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.RECAUDADO_POR_FECHA, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Recaudado por fecha</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='done' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.CREDITOS_POR_FECHA_REP, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Créditos por fecha</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='more' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.CREDITOS_VENCIDOS, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Créditos vencidos</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='warning' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.CREDITOS_SEGUN_ESTADO, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Créditos según estado</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='apps' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.CREDITOS_ACTIVOS, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Créditos activos (Todos los clientes)</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='person' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.CLIENTES_INACTIVOS_POR_CALIFICACION, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Clientes inactivos por calificación</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='dangerous' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.GASTOS_POR_FECHA, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Gastos por fecha</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='remove-circle' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.ENTREGAS_SALIDAS_POR_FECHA, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Entregas/Salidas por fecha</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='directions' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.INVERSIONES_POR_FECHA, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Inversiones por fecha</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='attach-money' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                </View>
                <View>
                    <Text style={styles.lblTitle}>Supervision</Text>
                    <Text style={styles.lblDes}>Supervisa los cobros diarios </Text>
                </View>
                <View style={{borderRadius:8, overflow:'hidden'}}>
                    <TouchableOpacity style={styles.rowFn}
                                onPress={() => navigator.navigate(routes.LISTA_RECAUDO_DEL_DIA, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Lista de recaudo del día</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='stars' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.rowFn}
                                    onPress={() => navigator.navigate(routes.NO_RECAUDADOS_POR_FECHA, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>No recaudados por fecha</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='stars' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                </View>
                <View>
                    <Text style={styles.lblTitle}>Cartera</Text>
                    <Text style={styles.lblDes}>Información de la cartera del cobrador</Text>
                </View>
                <View style={{borderRadius:8, overflow:'hidden'}}>
                    <TouchableOpacity style={styles.rowFn}
                                onPress={() => navigator.navigate(routes.CRECIMIENTO_CARTERA, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Crecimiento cartera</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='directions' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.rowFn, {marginBottom: '6%'}]}
                                    onPress={() => navigator.navigate(routes.CARTERA_ACTUAL, {cobrador: cobrador})}
                    >
                        <Text style={styles.textBtn}>Cartera actual</Text>
                        <View style={styles.containerIcon}>
                            <Icon name='favorite' color={colors.logo_gris}></Icon>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>

            
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingBottom:48,
    },
    lblTitle:{
        fontSize:18,
        fontWeight:'bold',
        marginTop:24,
        marginBottom:8
    },
    lblDes:{
        fontSize:12,
        marginBottom:16
    },
    rowFn:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:12,
        backgroundColor:colors.gris_fondo_cell
    },
    textBtn:{
        fontSize:12,
        fontWeight:'bold',
        alignSelf:'center'
    },
    containerIcon:{
        alignSelf:'center'
    },
})
