import axios from 'axios';
import React, {useEffect, useRef} from 'react';
import {
    Modal,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from 'react-native';
import {Calendar} from 'react-native-calendars';
import {Icon} from 'react-native-elements';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../constants/colors';
import stylesGenerales from '../constants/stylesGenrales';
import urlsGenerales from '../constants/urlsGenerales';
import {textoSpinner} from '../shared/TiposDocumento';
import {StorageUtil} from '../shared/StorageUtil';
import {DateUtils} from "../shared/DateUtils";
import CustomAlert from '../shared/CustomAlert';
import { Positions } from 'react-native-calendars/src/expandableCalendar';
import { color } from 'react-native-elements/dist/helpers';
import { useNavigation } from '@react-navigation/native';
import routes from '../constants/routes';

export default function RecaudoPorFecha() {

    const navigation = useNavigation();

    const [recaudos, setRecaudos] = React.useState([]);
    const [totalAbonosPorFecha, setTotalAbonosPorFecha] = React.useState(0);
    const [cantAbonosPorFecha, setCantAbonosPorFecha] = React.useState(0);

    const [fechaDesde, setFechaDesde] = React.useState(DateUtils.getDateStringYYYYMMDD());
    const [fechaHasta, setFechaHasta] = React.useState(DateUtils.getDateStringYYYYMMDD());

    // Lógica para los modals de fecha
    const [mostrarFecha1, setMostrarFecha1] = React.useState(false);
    const [mostrarFecha2, setMostrarFecha2] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    
    const fechaDesdeRef = useRef(null);
    const fechaHastaRef = useRef(null);

    const showSpinner = () => {
        setLoading(true);
    };

    const hideSpinner = () => {
        setLoading(false);
    };

    const verRecaudoPorFecha = async () => {

        if (fechaDesde.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese fecha desde'
            });
            return;
        }

        if (fechaHasta.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese fecha hasta'
            });
            return;
        }

        showSpinner();
        const formData = new FormData();
        const codUsuario = await StorageUtil.getCodUsuarioStorage();
        formData.append('codUsuario', codUsuario!);
        formData.append('fecha1', fechaDesde);
        formData.append('fecha2', fechaHasta);

        try {
            const token = await StorageUtil.getTokenStorage(); // Obtén el token del almacenamiento local
            const response = await axios.post(urlsGenerales.LISTAR_RECAUDO_POR_FECHA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            setRecaudos(response.data);

        } catch (error) {
            console.log('Se cae en error');
            console.error(error);

            setRecaudos([]);
            setCantAbonosPorFecha(0);
            setTotalAbonosPorFecha(0);
            hideSpinner();

        } finally {
            hideSpinner();
        }
    }

    useEffect(() => {
        setCantAbonosPorFecha(recaudos.length);
        setTotalAbonosPorFecha(recaudos.reduce((acumulador, objeto) => {
            return acumulador + parseFloat(objeto.monto);
        }, 0));
    });

    const listarRecaudos = () => {
        if (recaudos.length === 0) {
            return (
                <View style={stylesGenerales.row}>
                    <View style={stylesGenerales.cell}>
                        <Text style={stylesGenerales.textCellBody}>
                            -
                        </Text>
                    </View>
                    <View style={stylesGenerales.cell}>
                        <Text style={stylesGenerales.textCellBody}>
                            -
                        </Text>
                    </View>
                    <View style={stylesGenerales.cell}>
                        <Text style={stylesGenerales.textCellBody}>
                            -
                        </Text>
                    </View>
                </View>
            );
        } else {
            return recaudos.map((item) => (
                <View
                    style={{
                        flexDirection: 'row',
                        width: '90%'
                    }}>
                    <Text style={{
                        flex: 1,
                        backgroundColor: colors.gris_fondo_cell,
                        padding: 10,
                        borderWidth: 0.5,
                        borderColor: colors.logo_gris
                    }}>{item.nombre+" "+item.apellido}</Text>
                    <Text style={{
                        flex: 1,
                        backgroundColor: colors.gris_fondo_cell,
                        padding: 10,
                        borderWidth: 0.5,
                        borderColor: colors.logo_gris
                    }}>{item.fechaDetalleDeCaja}</Text>
                    <Text style={{
                        flex: 1,
                        backgroundColor: colors.gris_fondo_cell,
                        padding: 10,
                        borderWidth: 0.5,
                        borderColor: colors.logo_gris
                    }}>{item.monto}</Text>
                </View>
            ))
        }
    }

    return (
        <ScrollView contentContainerStyle={{alignItems: 'center', maxWidth: '100%'}}
                    showsHorizontalScrollIndicator={false}>

            <Text style={styles.titleListadoCreditos}>
                Recaudo por Fecha
            </Text>

            <Text style={stylesGenerales.labelInput}>
                Desde
            </Text>

            <TextInput style={stylesGenerales.inputSearch}
                        placeholder='yyyy-mm-dd'
                        value={fechaDesde}
                        onPressIn={() => setMostrarFecha1(true)}
                        ref={fechaDesdeRef}
                        onFocus={() => {
                            setMostrarFecha1(true);
                            // @ts-ignore
                            fechaDesdeRef.current.blur();
                        }}
            ></TextInput>

            <Modal
                animationType="fade"
                transparent={true}
                visible={mostrarFecha1}
            >
                <TouchableWithoutFeedback onPress={() => setMostrarFecha1(false)}>
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            <Calendar
                                current={Date()}
                                onDayPress={day => {
                                    setFechaDesde(day.dateString);
                                    setMostrarFecha1(false);
                                }}
                                monthFormat={'yyyy MM'}
                                hideArrows={false}
                                hideExtraDays={true}
                                disableMonthChange={true}
                                firstDay={1}
                            />
                            { Platform.OS === 'web' && 
                                <style>
                                    {
                                    `.css-9pa8cd {
                                            position: relative;
                                        }`
                                    }
                                </style>
                            }
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>

            <Text style={stylesGenerales.labelInput}>
                Hasta
            </Text>

            <TextInput style={stylesGenerales.inputSearch}
                        placeholder='yyyy-mm-dd'
                        value={fechaHasta}
                        onPressIn={() => setMostrarFecha2(true)}
                        ref={fechaHastaRef}
                        onFocus={() => {
                            setMostrarFecha2(true);
                            // @ts-ignore
                            fechaHastaRef.current.blur();
                        }}
            ></TextInput>

            <Modal
                animationType="fade"
                transparent={true}
                visible={mostrarFecha2}
            >
                <TouchableWithoutFeedback onPress={() => setMostrarFecha2(false)}>
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            <Calendar
                                current={Date()}
                                minDate={fechaDesde}
                                onDayPress={day => {
                                    setFechaHasta(day.dateString);
                                    setMostrarFecha2(false);
                                }}
                                monthFormat={'yyyy MM'}
                                hideArrows={false}
                                hideExtraDays={true}
                                disableMonthChange={true}
                                firstDay={1}
                            />
                            { Platform.OS === 'web' && 
                                <style>
                                    {
                                    `.css-9pa8cd {
                                            position: relative;
                                        }`
                                    }
                                </style>
                            }
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>

            <TouchableOpacity
                style={stylesGenerales.btn}
                onPress={verRecaudoPorFecha}>
                <Icon name='visibility' color={colors.white}></Icon>
                <Text style={[stylesGenerales.textBtn, {paddingLeft: 5}]}>Ver recaudo</Text>
            </TouchableOpacity>

            <View style={stylesGenerales.row}>
                <View style={[stylesGenerales.cell, {borderTopLeftRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopLeftRadius: 5}]}>
                        Abonos
                    </Text>
                </View>
                <View style={[stylesGenerales.cell, {borderTopRightRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopRightRadius: 5}]}>
                        Recaudo
                    </Text>
                </View>
            </View>

            <View style={[stylesGenerales.row, {marginBottom: 15}]}>
                <View style={stylesGenerales.cell}>
                    <Text style={stylesGenerales.textCellBody}>
                        {cantAbonosPorFecha}
                    </Text>
                </View>
                <View style={stylesGenerales.cell}>
                    <Text style={stylesGenerales.textCellBody}>
                        S/ {totalAbonosPorFecha}
                    </Text>
                </View>
            </View>

            <Text style={[stylesGenerales.labelInput, {marginBottom: 5}]}>Listado de Abonos</Text>

            <View style={stylesGenerales.row}>
                <View style={[stylesGenerales.cell, {borderTopLeftRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopLeftRadius: 5}]}>
                        Nombre
                    </Text>
                </View>
                <View style={[stylesGenerales.cell]}>
                    <Text style={[stylesGenerales.textCellHeader]}>
                        Fecha
                    </Text>
                </View>
                <View style={[stylesGenerales.cell, {borderTopRightRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopRightRadius: 5}]}>
                        Abono
                    </Text>
                </View>
            </View>

            {listarRecaudos()}

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center'
    },

    titleListadoCreditos: {
        fontSize: 30,
        fontWeight: 'bold',
        width: '90%',
        marginVertical: 20
    },

    /// MODAL ///
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        paddingBottom: 15,
        width: '70%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 5,
    }
})
