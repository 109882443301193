import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet, Text, TextInput, View, TouchableOpacity} from 'react-native';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import {useRoute} from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../../shared/TiposDocumento";
import ModalCalendar from "../../../components/ModalCalendar";
import {DateUtils} from "../../../shared/DateUtils";
import ButtonReporteBuscar from "../../../components/ButtonReporteBuscar";
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';
import moment from 'moment';
import { Icon } from 'react-native-elements';

type CrecimientoResponse = {
    anterior: string;
    recaudo: string;
    creditos: string;
    intereses: string;
    cartera: number;
    crecimiento: number;
}

export default function CrecimientoCartera() {

    const navigation = useNavigation();

    const route = useRoute();
    // @ts-ignore
    const cobrador = route.params?.cobrador;

    const [loading, setLoading] = useState(false);
    const [fecha, setFecha] = useState(DateUtils.getDateStringYYYYMMDD());
    const fechaDesdeRef = useRef(null);
    const [mostrarFecha, setMostrarFecha] = useState(false);

    const [anterior, setAnterior] = useState('0');
    const [recaudo, setRecaudo] = useState('0');
    const [creditos, setCreditos] = useState(0);
    const [intereses, setIntereses] = useState(0);
    const [cartera, setCartera] = useState(0);
    const [crecimiento, setCrecimiento] = useState(0);

    const [crecResponse, setCrecResponse] = useState<CrecimientoResponse[]>([]);
    const [first, setFirst] = useState(true)

    const handleFecha = (fechaDesdeModal: string) => {
        setMostrarFecha(false);
        setFecha(fechaDesdeModal);
    }

    const obtenerCrecimientoCartera = async () => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo!);
            formData.append('fecha', fecha);
            const token = await StorageUtil.getTokenStorage(); 
            const response = await axios.post(urlsGenerales.REP_CRECIMIENTO_CARTERA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            console.log('response -> ', response.data);

            if (response.data) {
                setCrecResponse(response.data);
            }

        } catch (error) {
            console.log('Se cae en error -> ', urlsGenerales.REP_CRECIMIENTO_CARTERA);
            console.log(error);

        } finally {
            setLoading(false);
            setFirst(false)
        }
    }

    useEffect(() => {
        if (crecResponse.length > 0) {
            const response = crecResponse[0];
            setAnterior(response.anterior);
            setRecaudo(response.recaudo);
            setCreditos(response.creditos);
            setIntereses(response.intereses);
            setCartera(response.cartera);
            setCrecimiento(response.crecimiento);
        } else {

        }
    }, [crecResponse]);

    return (
        <View style={styles.container}>

            <Text style={[styles.title]}>Crecimiento de cartera</Text>

            <Text style={{ fontSize: 12, marginHorizontal:'5%' }}>Información sobre el crecimiento de la cartera en una
                fecha con respecto a un cierre de caja anterior.</Text>

                <View style={styles.rowDate}>
                <View style={{flex:1}}>
                    <Text style={styles.labelInput}>Fecha</Text>
                    <TextInput
                        style={styles.inputText}
                        placeholder='yyyy-mm-dd'
                        value={fecha}
                        onPressIn={() => setMostrarFecha(true)}
                        ref={fechaDesdeRef}
                        onFocus={() => {
                            setMostrarFecha(true);
                            // @ts-ignore
                            fechaDesdeRef.current.blur();
                        }}
                    >
                    </TextInput>
                </View>
                <TouchableOpacity style={styles.btn} onPress={obtenerCrecimientoCartera}>
                    <Icon name='search' color={colors.white}></Icon>
                    <Text style={{
                        color: colors.white,
                        fontWeight: 'bold',
                    }}>Consultar</Text>
                </TouchableOpacity>
            </View>
            
            <ModalCalendar
                visible={mostrarFecha}
                onPress={handleFecha}
                currentDate={moment(fecha).format()}
                onCancel={() => setMostrarFecha(false)}
            />

            {/* <Text style={stylesGenerales.labelInput}>Fecha</Text>
            <TextInput
                placeholder='yyyy-mm-dd'
                style={stylesGenerales.inputText}
                value={fecha}
                onPressIn={() => setMostrarFecha(true)}
                ref={fechaDesdeRef}
                onFocus={() => {
                    setMostrarFecha(true);
                    // @ts-ignore
                    fechaDesdeRef.current.blur();
                }}
            />

            <ModalCalendar
                visible={mostrarFecha}
                onPress={handleFecha}
                onCancel={() => setMostrarFecha(false)}
            /> */}

            {/* <ButtonReporteBuscar
                icon={'visibility'}
                text={'Ver Balance'}
                onPress={obtenerCrecimientoCartera}
            /> */}
            {
                first ? null : 

            <View style={styles.boxShadow}>
                <Text style={styles.lblBox}>Cartera</Text>
                
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Total</Text>
                    <Text style={[styles.lblBoxAmount,{color:colors.verde_primary}]}>S/ {cartera}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Crecimiento (+/-)</Text>
                    <Text style={styles.lblBoxAmount}>S/ {crecimiento}</Text>
                </View>
            </View>
            // <View>
                
            //     <View style={[stylesGenerales.row, {marginTop: '3%'}]}>
            //         <Text style={[styles.cellHeader,{borderTopLeftRadius: 5}]}>ANTERIOR</Text>
            //         <Text style={[styles.cellHeader,{borderTopRightRadius: 5}]}>Recaudo</Text>
            //     </View>
            //     <View style={stylesGenerales.row}>
            //         <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>S/ {anterior}</Text>
            //         <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>S/ {recaudo}</Text>
            //     </View>

            //     <View style={[stylesGenerales.row, {marginTop: '5%'}]}>
            //         <Text style={[styles.cellHeader,{borderTopLeftRadius: 5}]}>Créditos</Text>
            //         <Text style={[styles.cellHeader,{borderTopRightRadius: 5}]}>Intereses</Text>
            //     </View>
            //     <View style={stylesGenerales.row}>
            //         <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>S/ {creditos}</Text>
            //         <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>S/ {intereses}</Text>
            //     </View>

            //     <View style={[stylesGenerales.row, {marginTop: '5%'}]}>
            //         <Text style={[styles.cellLastRow,{borderTopLeftRadius: 5}]}>CARTERA</Text>
            //         <Text style={[styles.cellLastRow,{borderTopRightRadius: 5}]}>Crecimiento(+/-)</Text>
            //     </View>
            //     <View style={stylesGenerales.row}>
            //         <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>S/ {cartera}</Text>
            //         <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>S/ {crecimiento}</Text>
            //     </View>
            // </View>
            }
            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
        marginHorizontal:'5%'
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    rowDate:{
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:'5%'
    },
    btn:{
        backgroundColor:colors.verde_primary,
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
        height:48,
        alignSelf:'flex-end',
        marginLeft:16,
        alignItems:'center',
        borderRadius:4
    },
    boxShadow:{
        display:'flex',
        borderRadius:8,
        justifyContent:'center', 
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
        padding:16,
        marginHorizontal:'5%',
        marginVertical:16,
        paddingBottom:8
    },
    lblBox:{
        fontSize:18,
        fontWeight:'bold',
        marginBottom:16,
    },
    row:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        marginBottom:8
    },
    lblBoxAmount:{
        fontSize:16,
        fontWeight:'bold',
        color:colors.logo_azul,
    },
    lblBoxText:{
        color:colors.gris_borde_item_lista
    },
})
