import {useFocusEffect, useNavigation} from '@react-navigation/native';
import axios from 'axios';
import React, {useState, useEffect,useCallback} from 'react';
import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View, RefreshControl} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../constants/colors';
import routes from '../constants/routes';
import urlsGenerales from '../constants/urlsGenerales';
import ModalOperacionAdmin from "../components/ModalOperacionAdmin";
import {AuthenticationService} from "../shared/AuthenticationService";
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {StorageUtil} from '../shared/StorageUtil';
import {CajaService} from "../shared/CajaService";
import CustomAlert from '../shared/CustomAlert';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function Clientes() {

    const navigation = useNavigation();

    const [clientes, setClientes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [codClienteElim, setCodClienteElim] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const handleConfirmModal = async (usuario: string, clave: string) => {

        setModalVisible(false);
        setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            setModalVisible(false);
            CustomAlert.errorCredenciales();
            setLoading(false);
            return;
        }
        setLoading(false);
        handleEliminarCliente(usuario,clave);
    }

    const handleEliminarCliente = async (usuario: string, clave: string) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codCliente', codClienteElim+"");
            formData.append('usu', usuario);
            formData.append('pass', clave);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ELIMINAR_CLIENTE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                if (response.data.mensaje == "Actualizado") {
                    CustomAlert.show({
                        title: 'Aviso',
                        message: 'Cliente eliminado con éxito',
                        buttons : [
                            {
                                text: 'OK',
                                onPress: () => {
                                    listarClientes();
                                },
                            },
                        ]
                    });
                }else{
                    CustomAlert.show({
                        title: 'Aviso',
                        message: response.data.mensaje,
                    });
                }
            }

        } catch (e) {
            console.log('Error en -> ', urlsGenerales.ELIMINAR_CLIENTE);
            console.log(e);
            setClientes([]);

        } finally {
            setLoading(false);
        }
    }

    const handlePressEliminar = (nomClie:String) => {
        CustomAlert.show({
            title: "Acción Eliminación",
            message: "¿Desea Eliminar el cliente '"+nomClie+"'?",
            buttons : [
                {
                    text: 'Sí',
                    onPress: () => {
                        setModalVisible(true);
                    },
                },
                {
                    text: 'No',
                    style: 'cancel',
                },
            ]
        });
        
    }

    const irVerCliente = (item) => {
        navigation.navigate(routes.VER_CLIENTE, {cliente: item});
    }

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const renderItem = ({item}) => {
        const handlePress = () => {
            irVerCliente(item);
        }

        return (
            <TouchableOpacity
                style={styles.crtItem}
                onLongPress={() => {
                    handlePressEliminar(item.nombre+" "+item.apellido);
                    setCodClienteElim(item.codCliente)
                }}
                onPress={handlePress}>
                
                <View style={{flex:1,alignSelf:'center'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>{item.nombre} {item.apellido}</Text>
                    {item.numDocumento && item.numDocumento!== '' ? <Text style={styles.nrodoc}>{item.numDocumento}</Text> : null}
                </View>
                <Icon name='chevron-right' size={24} style={{alignSelf:'center'}}></Icon>

            </TouchableOpacity>
        );
    };

    const renderEmpty = () => {
        return (
                <Text style={{fontSize: 14, marginVertical: 20, textAlign:'center', fontWeight:'400'}}>{searchText!=''?'No se encontraron resultados':"No hay clientes registrados"}</Text>
        );
    };

    const listarClientes = async (loading) => {
        try {
            setLoading(loading);
            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_CLIENTES, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }
            console.log(response.data)
            setClientes(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.LISTAR_CLIENTES);
            console.log(error);

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData(true);
    }, [])
    
    useFocusEffect(
        useCallback(() => {
            get()
        }, [])
    );

    const get = async()=> {
        const recargar = await AsyncStorage.getItem('recargar_client')
        console.log("Necesito Actualizar Cliente ? ",recargar)
        if(recargar){
            await AsyncStorage.removeItem('recargar_client')
            if(recargar == 'si'){
                fetchData(true);
            } 
        }
    } 

    const fetchData = async (loading) => {
        await listarClientes(loading);
    };

    const irNuevoCliente = async () => {
        const codUsuario = await StorageUtil.getCodUsuarioStorage();
        await setLoading(true)
        const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuario!,navigation);
        await setLoading(false)
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                await StorageUtil.setRutaStorage('1');
                navigation.navigate(routes.CREAR_CLIENTE);
            } else {
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const onRefresh = async() => {
        await setRefreshing(true);
        await fetchData(false)
        await setRefreshing(false)
    }

    return (
        <>
        <View style={styles.container}>
            <View style={[styles.row,styles.mrg,{justifyContent:'space-between',paddingVertical:24,paddingBottom:16, display:'flex'}]}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({clientes.length}{clientes.length==1?' cliente':' clientes'})</Text></Text>
                <TouchableOpacity style={{backgroundColor:colors.white, padding:8, borderRadius:4,alignSelf:'center'}} onPress={irNuevoCliente}>
                    <Icon name='person-add' color={colors.negro}></Icon>
                </TouchableOpacity>
            </View>
            <View style={[styles.row,styles.mrg]}>
                <View style={styles.iconSearch}>
                    <Icon name='search' color={colors.negro}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    style={styles.inputSearch}
                    onChangeText={handleSearch}
                    value={searchText}
                    selectionColor={colors.negro}
                />
                {
                searchText !== '' &&
                    <TouchableOpacity
                        style={styles.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.negro}></Icon>
                    </TouchableOpacity>
                }
            </View>
            <View style={[styles.row,styles.mrg,{marginTop:16}]}>
                <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Manten presionado para eliminar un cliente</Text>
            </View>

            <FlatList
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
                data={clientes.filter((item) => (item.nombre.trim()+" "+item.apellido.trim()).toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(item) => item.codCliente}
                ListEmptyComponent={renderEmpty}
                contentContainerStyle={{paddingTop:0,paddingBottom:48}}
                style={{width: '100%', marginTop:24,}}
                showsVerticalScrollIndicator={false}
            />
            
             <ModalOperacionAdmin
                visible={modalVisible}
                title='Aviso'
                subtitle='Ingrese sus credenciales para confirmar la operación'
                onCancel={() => setModalVisible(false)}
                onConfirm={handleConfirmModal}
            />
        </View>
        <Spinner
            visible={loading}
            color={colors.white}
        />
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    mrg:{
        marginHorizontal:'5%'
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        alignSelf:'center'
    },
    label: {
        textAlign: 'left',
        width: 400
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor: colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },
    img: {
        width: 80,
        height: 80,
        marginTop: 30
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontWeight:'300',
        fontSize:14,
    },
    nrodoc:{
        marginTop:4,
        fontSize:12,
        fontWeight:'200',
    }
});
