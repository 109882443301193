import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useState} from 'react';
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import ModalOperacionAdmin from '../../../components/ModalOperacionAdmin';
import colors from '../../../constants/colors';
import stylesGenerales from '../../../constants/stylesGenrales';
import {textoSpinner} from '../../../shared/TiposDocumento';
import axios from 'axios';
import urlsGenerales from '../../../constants/urlsGenerales';
import {AuthenticationService} from "../../../shared/AuthenticationService";
import {DateUtils} from "../../../shared/DateUtils";
import CustomAlert from '../../../shared/CustomAlert';
import { StorageUtil } from '../../../shared/StorageUtil';

export default function OpcionesCredito() {

    const route = useRoute();
    const navigation = useNavigation();
    const credito = route.params!.credito;

    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [operacion, setOperacion] = useState('');

    const [interes, setInteres] = useState(credito.interes);

    const guardarCambios = () => {
        setOperacion('Guardar');
        setModalVisible(true);
    }

    const eliminarCredito = () => {
        setOperacion('Eliminar');
        setModalVisible(true);
    }

    const handleAlertClose = (title: string, subtitle: string) => {
        CustomAlert.show({
            title: title,
            message: subtitle,
            buttons: [
                {
                    text: 'OK',
                    onPress: () => {
                        navigation.goBack();
                    },
                },
            ]
        });
    };

    const handleConfirmModal = async (usuario: string, clave: string) => {

        setModalVisible(false);

        setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            setModalVisible(false);
            CustomAlert.errorCredenciales();
            setLoading(false);
            return;
        }
        setLoading(false);

        if (operacion === 'Guardar') {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('usu', usuario);
                formData.append('pass', clave);
                formData.append('codCredito', credito.codCredito);
                formData.append('interes', interes);
                const token = await StorageUtil.getTokenStorage(); 
                const response = await axios.post(urlsGenerales.EDITAR_CREDITO, formData, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data) {
                    handleAlertClose('Aviso', 'Actualizado con éxito');
                }

            } catch (error) {
                console.log('Error en -> ', urlsGenerales.EDITAR_CREDITO);
                console.log(error);

            } finally {
                setLoading(false);
            }

        } else {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('usu', usuario);
                formData.append('pass', clave);
                formData.append('codCredito', credito.codCredito);
                const token = await StorageUtil.getTokenStorage();
                const response = await axios.post(urlsGenerales.ELIMINAR_CREDITO, formData, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data) {
                    handleAlertClose('Aviso', 'Eliminado con éxito');
                }

            } catch (error) {
                console.log('Error en -> ', urlsGenerales.ELIMINAR_CREDITO);
                console.log(error);

            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <View style={styles.container}>

            <Text style={{
                fontSize: 22, fontWeight: 'bold', width: '90%', marginTop: '3%'
            }}>
                Nombre: {credito.nombre} {credito.apellido}
            </Text>
            <Text style={{
                fontSize: 16, fontWeight: 'normal', width: '90%', marginTop: '1%', marginBottom: '3%'
            }}>
                Código crédito: [{credito.codCredito}]
            </Text>

            <Text style={{fontSize: 18, fontWeight: 'bold', width: '90%'}}>Fecha:</Text>
            <Text style={{
                fontSize: 18, fontWeight: 'normal', width: '90%', marginTop: '1%', marginBottom: '4%'
            }}>
                {DateUtils.formatDateYYYYMMDD(credito.fecha)}</Text>

            <Text style={stylesGenerales.labelInput}>Interés (%)</Text>
            <TextInput
                style={stylesGenerales.inputText}
                placeholder='0'
                keyboardType="numeric"
                maxLength={3}
                value={interes}
                onChangeText={setInteres}
            ></TextInput>


            <Text style={{fontWeight: 'bold', fontSize: 17, width: '90%', marginTop: '5%'}}>Opciones</Text>

            <TouchableOpacity
                style={[
                    stylesGenerales.btnLarge,
                    {
                        backgroundColor: colors.logo_gris,
                        marginTop: '3%',
                        marginBottom: '1%',
                    }
                ]}
                onPress={guardarCambios}
            >
                <Icon name='save' color={colors.white} style={{paddingLeft: '3%'}}></Icon>
                <Text style={[stylesGenerales.textBtn, {width: '80%', textAlign: 'center'}]}>
                    Guardar cambios</Text>
            </TouchableOpacity>

            <TouchableOpacity
                style={[
                    stylesGenerales.btnLarge,
                    {
                        backgroundColor: colors.rojo,
                        marginTop: '1%',
                        marginBottom: '5%',
                    }
                ]}
                onPress={eliminarCredito}
            >
                <Icon name='clear' color={colors.white} style={{paddingLeft: '3%'}}></Icon>
                <Text style={[stylesGenerales.textBtn, {width: '80%', textAlign: 'center'}]}>
                    Eliminar crédito</Text>
            </TouchableOpacity>

            <ModalOperacionAdmin
                visible={modalVisible}
                title='Aviso'
                subtitle='Ingrese sus credenciales para confirmar la operación'
                onCancel={() => setModalVisible(false)}
                onConfirm={handleConfirmModal}
            />

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center'
    },
})
