export default {
  // GENERALES
  LOGIN: "https://app.cfp.com.pe/app-cfp-backend/checklogin.php",
  LOGIN_CLIENTE: "https://app.cfp.com.pe/app-cfp-backend/checkloginCliente.php",
  EDITAR_USUARIO: "https://app.cfp.com.pe/app-cfp-backend/editarUsuario.php",
  CAMBIAR_CLAVE: "https://app.cfp.com.pe/app-cfp-backend/cambioContrasenia.php",

  ES_CAJA_CERRADA: "https://app.cfp.com.pe/app-cfp-backend/esCajaBloqueada.php",

  // CREDITO
  LISTAR_CREDITOS: "https://app.cfp.com.pe/app-cfp-backend/listaCreditos.php",
  LISTAR_CREDITOS_V_FINAL:
    "https://app.cfp.com.pe/app-cfp-backend/listaCreditosClientesFinal.php",
  DETALLE_CREDITO: "https://app.cfp.com.pe/app-cfp-backend/detalleCredito.php",
  DETALLE_CREDITO_INFO:
    "https://app.cfp.com.pe/app-cfp-backend/detalleCreditoInfo.php",
  DETALLE_CREDITO_V2:
    "https://app.cfp.com.pe/app-cfp-backend/detalleCreditov2.php",
  LISTAR_CREDITOS_ESTADO:
    "https://app.cfp.com.pe/app-cfp-backend/listaCreditosEstado.php",
  LISTAR_CREDITOS_V2:
    "https://app.cfp.com.pe/app-cfp-backend/listaCreditosV2.php",
  LISTAR_CREDITOS_V3:
    "https://app.cfp.com.pe/app-cfp-backend/listaCreditosV3.php",
  CREAR_CREDITO: "https://app.cfp.com.pe/app-cfp-backend/crearCredito.php",
  CRONOGRAMA_PAGOS:
    "https://app.cfp.com.pe/app-cfp-backend/cronogramaPagos.php",
  BOLETA_PAGOS: "https://app.cfp.com.pe/app-cfp-backend/boletaPagos.php",
  BOLETA_PAGOSb: "https://app.cfp.com.pe/app-cfp-backend/boletaPagosPng.php",
  S23: "https://app.cfp.com.pe/app-cfp-backend/ticketPago.php",
  S23b: "https://app.cfp.com.pe/app-cfp-backend/ticketPagoPng.php",

  S24: "https://app.cfp.com.pe/app-cfp-backend/ticketdeVenta.php",
  S24b: "https://app.cfp.com.pe/app-cfp-backend/ticketdeVentaPng.php",
  S25: "https://app.cfp.com.pe/app-cfp-backend/cronogramaPagos.php",
  S25b: "https://app.cfp.com.pe/app-cfp-backend/cronogramaPagosPng.php",

  // ABONOS
  LISTAR_ABONOS_CREDITO:
    "https://app.cfp.com.pe/app-cfp-backend/listarPagos.php",
  LISTAR_ABONOS_CREDITO_V2:
    "https://app.cfp.com.pe/app-cfp-backend/listarPagosV2.php",
  LISTAR_ABONOS_CREDITO_V3:
    "https://app.cfp.com.pe/app-cfp-backend/listarPagosV3.php",

  // BALANCE
  BALANCE: "https://app.cfp.com.pe/app-cfp-backend/balance.php",
  BALANCE_DATE: "https://app.cfp.com.pe/app-cfp-backend/balanceDate.php",
  INSERTAR_MOVIMIENTO:
    "https://app.cfp.com.pe/app-cfp-backend/movimientoCaja.php",
  ELIMINAR_MOVIMIENTO:
    "https://app.cfp.com.pe/app-cfp-backend/eliminarMovimiento.php",
  ELIMINAR_PAGO: "https://app.cfp.com.pe/app-cfp-backend/eliminarPago.php",
  ELIMINAR_PAGO_V_FINAL:
    "https://app.cfp.com.pe/app-cfp-backend/eliminarPagoVFinal.php",
  DETALLE_PAGOS:
    "https://app.cfp.com.pe/app-cfp-backend/reporteDetallePagos.php",

  // RUTA
  LISTAR_RUTAS: "https://app.cfp.com.pe/app-cfp-backend/listaRutas.php",
  ENRUTAR: "https://app.cfp.com.pe/app-cfp-backend/enrutar.php",
  MODIFICAR_RUTA: "https://app.cfp.com.pe/app-cfp-backend/modificarRuta.php",

  // HOME
  PANTALLA_INICIO: "https://app.cfp.com.pe/app-cfp-backend/pantallaInicio.php",
  PANTALLA_INICIO_V2:
    "https://app.cfp.com.pe/app-cfp-backend/pantallaInicioV2.php",
  PANTALLA_INICIO_LIST:
    "https://app.cfp.com.pe/app-cfp-backend/pantallaInicioList.php",
  PANTALLA_INICIO_INFO:
    "https://app.cfp.com.pe/app-cfp-backend/pantallaInicioInfo.php",
  COBRO_POR_DIA: "https://app.cfp.com.pe/app-cfp-backend/cobroDia.php",
  PAGOS_DEL_DIA: "https://app.cfp.com.pe/app-cfp-backend/listarPagosHoy.php",
  FECHAS_PAGO_CALENDARIO:
    "https://app.cfp.com.pe/app-cfp-backend/listaFechasCalendario.php",
  PAGAR_CUOTA: "https://app.cfp.com.pe/app-cfp-backend/pagarCuota.php",
  PAGAR_CUOTA_V2: "https://app.cfp.com.pe/app-cfp-backend/pagarCuotaV2.php",
  PAGAR_CUOTA_V3: "https://app.cfp.com.pe/app-cfp-backend/pagarCuotaV3.php",

  // CLIENTES
  LISTAR_CLIENTES: "https://app.cfp.com.pe/app-cfp-backend/listaClientes.php",
  CREAR_CLIENTE: "https://app.cfp.com.pe/app-cfp-backend/crearCliente.php",
  EDITAR_CLIENTE: "https://app.cfp.com.pe/app-cfp-backend/editarCliente.php",
  ELIMINAR_CLIENTE:
    "https://app.cfp.com.pe/app-cfp-backend/eliminarCliente.php",
  LISTAR_CREDITOS_CANCELADOS:
    "https://app.cfp.com.pe/app-cfp-backend/listaEstadoInacCredito.php",
  LISTAR_CREDITOS_ACTIVOS:
    "https://app.cfp.com.pe/app-cfp-backend/listaEstadoActCredito.php",

  // LISTADOS
  LISTAR_RECAUDO_POR_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/recaudoFecha.php",
  LISTAR_CREDITOS_POR_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/creditosFecha.php",

  // ######################## SERVICIOS ADMINISTRADOR #####################################
  // HOME
  LISTAR_USUARIOS_ASIGNADOS:
    "https://app.cfp.com.pe/app-cfp-backend/listaUsuariosAsignados.php",
  LISTAR_ASIGNADOS_ADMIN:
    "https://app.cfp.com.pe/app-cfp-backend/listaAsignadosAdmin.php",

  // REPORTE
  REPORTE_USUARIOS_CREADOS:
    "https://app.cfp.com.pe/app-cfp-backend/reporteCantUsuarios.php",

  // USUARIO
  CREAR_USUARIO: "https://app.cfp.com.pe/app-cfp-backend/crearUsuario.php",

  // REPORTES
  REP_RESUMEN_POR_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/resumenPorFecha.php",
  REP_CREDITOS_POR_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/creditosFecha.php",
  REP_CREDITOS_VENCIDOS:
    "https://app.cfp.com.pe/app-cfp-backend/creditosVencidos.php",
  REP_CREDITOS_SEGUN_ESTADO:
    "https://app.cfp.com.pe/app-cfp-backend/creditoEstado.php",
  REP_CLIENTES_INACTIVOS_POR_CALIFICACION:
    "https://app.cfp.com.pe/app-cfp-backend/inactivosPorCalificacion.php",
  REP_GASTOS_POR_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/gastosFecha.php",
  REP_ENTREGAS_SALIDAS_POR_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/entregasSalidasFecha.php",
  REP_INVERSIONES_POR_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/inversionFecha.php",
  REP_LISTA_RECAUDO_DEL_DIA:
    "https://app.cfp.com.pe/app-cfp-backend/recaudoDia.php",
  REP_NO_REACUDADOS_POR_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/noRecaudadoFecha.php",
  REP_CARTERA_ACTUAL:
    "https://app.cfp.com.pe/app-cfp-backend/carteraActual.php",
  REP_CRECIMIENTO_CARTERA:
    "https://app.cfp.com.pe/app-cfp-backend/crecimientoCartera.php",

  REP_DESCARGAR_CREDITO_EXCEL:
    "https://app.cfp.com.pe/app-cfp-backend/listCreditoExcel.php",
  REP_DESCARGAR_CARTERA_EXCEL:
    "https://app.cfp.com.pe/app-cfp-backend/listCreditoVencidoExcel.php",

  // CREDITOS
  BORRAR_ABONO: "https://app.cfp.com.pe/app-cfp-backend/eliminarPago.php",
  EDITAR_CREDITO: "https://app.cfp.com.pe/app-cfp-backend/editarCredito.php",
  EDITAR_CREDITO_V2:
    "https://app.cfp.com.pe/app-cfp-backend/editarCreditov2.php",
  ELIMINAR_CREDITO:
    "https://app.cfp.com.pe/app-cfp-backend/eliminarCredito.php",

  LISTAR_LIQUIDACIONES:
    "https://app.cfp.com.pe/app-cfp-backend/listarCajaLiquidada.php",

  BLOQUEAR_CAJA: "https://app.cfp.com.pe/app-cfp-backend/bloquearCaja.php",

  // DELEGADOS
  LISTAR_USUARIOS_ADMIN:
    "https://app.cfp.com.pe/app-cfp-backend/listaUsuariosAdmin.php",

  // Productos
  LISTAR_PRODUCTOS: "https://app.cfp.com.pe/app-cfp-backend/listaProductos.php",

  // -- DELEGAR CUENTAS
  LISTAR_USUARIOS_NO_ASIGNADOS:
    "https://app.cfp.com.pe/app-cfp-backend/listaUsuariosSinAsignar.php",
  ASIGNACION_USUARIO: "https://app.cfp.com.pe/app-cfp-backend/asignaciones.php",
  ELIMINAR_ASIGNACION:
    "https://app.cfp.com.pe/app-cfp-backend/eliminarAsignacion.php",

  // EDICION DATOS
  LISTAR_ABONOS_POR_USUARIO_FECHA:
    "https://app.cfp.com.pe/app-cfp-backend/abonosPorFecha.php",

  // LIQUIDACIONES
  VER_LIQUIDACIONES: "https://app.cfp.com.pe/app-cfp-backend/liquidaciones.php",
  DETALLE_LIQUIDACION: "https://app.cfp.com.pe/app-cfp-backend/detalleCaja.php",
  DESBLOQUEAR_CAJA:
    "https://app.cfp.com.pe/app-cfp-backend/desbloquearCaja.php",
};
