import React, { useRef, useState} from 'react';
import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesGenerales from '../../../constants/stylesGenrales';
import {useRoute} from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import ModalCalendar from "../../../components/ModalCalendar";
import {DateUtils} from "../../../shared/DateUtils";
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import {NumberUtils} from "../../../shared/NumberUtils";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';
import moment from 'moment';

export default function NoRecaudadoPorFecha() {

    const navigation = useNavigation();

    const route = useRoute();
    // @ts-ignore
    const cobrador = route.params?.cobrador;

    const [loading, setLoading] = useState(false);
    const [fecha, setFecha] = useState(DateUtils.getDateStringYYYYMMDD());

    const fechaDesdeRef = useRef(null);

    const [mostrarFecha, setMostrarFecha] = useState(false);

    const [cantLista, setCantLista] = React.useState(0);
    const [totalNoRec, setTotalNoRec] = React.useState('0');
    const [noRecaudosList, setNoRecaudosList] = useState([]);

    const [searchText, setSearchText] = React.useState('');
    const [first, setFirst] = useState(true);

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const handleSetFecha = (fechaDesdeModal: string) => {
        setMostrarFecha(false);
        setFecha(fechaDesdeModal);
    }

    const obtenerNoRecaudados = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo!);
            formData.append('fechaVencimiento', fecha);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.REP_NO_REACUDADOS_POR_FECHA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                setNoRecaudosList(response.data);
                setCantLista(response.data.length);
                const importeTotal = response.data.reduce((acumulador, elemento) => {
                    return acumulador + parseFloat(elemento.monto);
                }, 0);
                setTotalNoRec(NumberUtils.formatThousands(importeTotal));
            }

        } catch (e) {
            console.log('Se cae en error -> ', urlsGenerales.REP_NO_REACUDADOS_POR_FECHA);
            console.log(e);
            setNoRecaudosList([]);

        } finally {
            setLoading(false);
            setFirst(false)
        }
    }

    const renderEmpty = () => {
        return (
            <View style={stylesGenerales.emptyList}>
                <Text style={{fontSize: 15, marginVertical: 20}}>No existen no recaudados para mostrar</Text>
            </View>
        );
    };

    const renderItem = ({item}) => {
        return (
            // <TouchableOpacity style={{
            //     width: '100%',
            //     flexDirection: 'row',
            //     borderColor: '#2a2b2b',
            //     borderWidth: 0.5,
            //     backgroundColor: colors.gris_fondo_cell,
            //     justifyContent: 'space-between',
            //     paddingStart: '1%',
            //     paddingEnd: '2%',
            // }}>
            //     <View style={{
            //         padding: 10
            //     }}>
            //         <Text style={{fontWeight: 'bold', fontSize: 18}}>{item.nombre} {item.apellido}</Text>
            //         <Text>Fecha: {item.fecha}</Text>
            //         <Text>Plazo: {item.frecuencia}, {item.cantCuotas} cuotas</Text>
            //         <Text>Estado: {item.detEstado}</Text>
            //         <Text>Saldo: S/ {item.saldoCredito}</Text>
            //     </View>
            //     <View style={{
            //         marginVertical: '2%', backgroundColor: item.estado === "1" ?  colors.white : item.estado === "2" ?  colors.naranja : colors.rojo,
            //         borderRadius: 10, padding: 10, justifyContent: 'center'
            //     }}>
            //         <Text style={{color: item.estado === "1" || item.estado === "2" ?  colors.negro : colors.white}}>Val. Cuota</Text>
            //         <Text style={{fontWeight: 'bold', textAlign: 'center',color: item.estado === "1" || item.estado === "2" ?  colors.negro : colors.white}}>S/ {item.monto}</Text>
            //     </View>
            // </TouchableOpacity>
            <View style={{
                borderBottomWidth: 0.5,
                borderBottomColor: '#222222',
                marginBottom: 8,
                paddingVertical:8,
                paddingBottom:16,
                paddingHorizontal:'5%',
            }}
            >
                <View style={{flexDirection:'row',display:'flex'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={{alignSelf:'center',flex:1,fontSize:18,fontWeight:'bold',marginBottom:4, marginRight:16}}>{item.nombre} {item.apellido}</Text>
                    <View style={{alignSelf:'center'}}>
                        <Text style={{textAlign:'right'}}>Cuota</Text>
                        <Text style={{color: item.estado === "1" ?  colors.negro : item.estado === "2" ?  colors.naranja : colors.rojo, fontWeight:'bold', fontSize:18}}>S/ {item.monto}</Text>
                    </View>
                </View>

                <View style={styles.boxShadow}>
                    <Text style={{fontSize:16,fontWeight:'bold'}}>Información del credito</Text>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3,marginTop:4}}>
                        <Text style={{textAlign:'left'}}>Fecha</Text>
                        <Text style={{fontSize:14, textAlign:'left'}}>{item.fecha}</Text>
                    </View>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3}}>
                        <Text style={{textAlign:'left'}}>Plazo</Text>
                        <Text style={{fontSize:14, textAlign:'left'}}>{item.frecuencia}, {item.cantCuotas} cuotas</Text>
                    </View>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3}}>
                        <Text style={{textAlign:'left'}}>Estado</Text>
                        <Text style={{fontSize:14, textAlign:'left'}}>{item.detEstado}</Text>
                    </View>
                    <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                        <Text style={{textAlign:'left'}}>Saldo</Text>
                        <Text style={{fontSize:14, textAlign:'left'}}>S/ {Number(item.saldoCredito).toFixed(2)}</Text>
                    </View>
                </View>
            </View>
        );
    };

    return (
        <View style={styles.container}>

        <Text style={[styles.title]}>Listado de no recaudados por fecha</Text>

        <Text style={{ fontSize: 12, marginHorizontal:'5%' }}>Información sobre los no pagos por fecha.</Text>

            <View style={styles.rowDate}>
            <View style={{flex:1}}>
                <Text style={styles.labelInput}>Fecha</Text>
                <TextInput
                    style={styles.inputText}
                    placeholder='yyyy-mm-dd'
                    value={fecha}
                    onPressIn={() => setMostrarFecha(true)}
                    ref={fechaDesdeRef}
                    onFocus={() => {
                        setMostrarFecha(true);
                        // @ts-ignore
                        fechaDesdeRef.current.blur();
                    }}
                >
                </TextInput>
            </View>
            <TouchableOpacity style={styles.btn} onPress={obtenerNoRecaudados}>
                <Icon name='search' color={colors.white}></Icon>
                <Text style={{
                    color: colors.white,
                    fontWeight: 'bold',
                }}>Consultar</Text>
            </TouchableOpacity>
        </View>

        <ModalCalendar
            visible={mostrarFecha}
            onPress={handleSetFecha}
            currentDate={moment(fecha).format()}
            onCancel={() => setMostrarFecha(false)}
        />

            {/* <Text style={stylesAdmin.labelTitle}>Listado de no recaudados por fecha</Text>

            <Text style={stylesGenerales.labelInput}>Fecha</Text>
            <TextInput
                placeholder='yyyy-mm-dd'
                style={stylesGenerales.inputText}
                value={fecha}
                onPressIn={() => setMostrarFecha(true)}
                ref={fechaDesdeRef}
                onFocus={() => {
                    setMostrarFecha(true);
                    // @ts-ignore
                    fechaDesdeRef.current.blur();
                }}
            ></TextInput>

            <ModalCalendar
                visible={mostrarFecha}
                onPress={handleSetFecha}
                onCancel={() => setMostrarFecha(false)}
            />

            <ButtonReporteBuscar
                icon={'visibility'}
                text={'Ver créditos'}
                onPress={obtenerNoRecaudados}
            />

            <Text style={{width: '90%', fontSize: 20, fontWeight: 'bold', marginTop: '2%'}}>
                Datos de recaudos no cancelados</Text>
            <View style={[stylesGenerales.row, {marginTop: '3%'}]}>
                <Text style={[styles.cellHeader,{borderTopLeftRadius: 5}]}>Cant. Lista</Text>
                <Text style={[styles.cellHeader,{borderTopRightRadius: 5}]}>Total No Recaudado</Text>
            </View>
            <View style={[stylesGenerales.row, {marginBottom: '1%'}]}>
                <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>{cantLista}</Text>
                <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>S/ {totalNoRec}</Text>
            </View>

            <Text style={stylesAdmin.labelTitle}>Lista</Text>
            <View style={stylesGenerales.containerSearch}>
                <View style={stylesGenerales.btnSearch}>
                    <Icon name='search' color={colors.logo_gris}></Icon>
                </View>
                <TextInput placeholder='Buscar'
                           style={stylesGenerales.textInputSearch}
                           onChangeText={handleSearch}
                           value={searchText}></TextInput>
                {searchText !== '' &&
                    <TouchableOpacity style={stylesGenerales.btnSearch}
                                      onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.logo_gris}></Icon>
                    </TouchableOpacity>}
            </View> */}
            {
                first ? null :
                <>
                <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingHorizontal:'5%'}}>
                    <Text style={[styles.title,{marginHorizontal:0,}]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({cantLista}{cantLista==1?' pago no recaudado':' pagos no recaudados'})</Text></Text>
                    <View style={{alignSelf:'center'}}>
                        <Text style={{fontSize:12, textAlign:'right',paddingTop:8,color:colors.logo_azul,fontWeight:'400'}}>Total</Text>
                        <Text style={{fontSize:20,fontWeight:'bold',color:colors.logo_azul}}>S/ {totalNoRec}</Text>
                    </View>
                </View>
                
                    <View style={[styles.row,styles.mrg]}>
                        <View style={styles.iconSearch}>
                            <Icon name='search' color={colors.negro}></Icon>
                        </View>
                        <TextInput
                            placeholder='Buscar'
                            style={styles.inputSearch}
                            onChangeText={handleSearch}
                            value={searchText}
                            selectionColor={colors.negro}
                        />
                        {
                        searchText !== '' &&
                            <TouchableOpacity
                                style={styles.btnSearch}
                                onPress={limpiarSearchText}>
                                <Icon name='clear' color={colors.negro}></Icon>
                            </TouchableOpacity>
                        }
                    </View>
                
                <FlatList
                    data={noRecaudosList.filter((item) => item.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
                        item.apellido.toLowerCase().includes(searchText.toLowerCase()))}
                    renderItem={renderItem}
                    keyExtractor={(index) => index.codCredito}
                    ListEmptyComponent={renderEmpty}
                    style={{ marginTop: 15,paddingBottom:48}}
                    contentContainerStyle={{paddingBottom:48}}
                />
                </>
            }

            

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
        marginHorizontal:'5%'
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    rowDate:{
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:'5%'
    },
    btn:{
        backgroundColor:colors.verde_primary,
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
        height:48,
        alignSelf:'flex-end',
        marginLeft:16,
        alignItems:'center',
        borderRadius:4
    },
    row:{
            display:'flex',
            flexDirection:'row',
        },
        inputSearch:{
            height:48,
            borderRadius:4,
            backgroundColor: colors.white,
            width:'100%',
            color:colors.negro,
            paddingLeft:48,
            paddingRight:48,
            zIndex:1,
            fontWeight:'500'
        },
        iconSearch:{
            position:'absolute',
            zIndex:2,
            alignSelf:'center',
            paddingLeft:16,
        },
        mrg:{
            marginHorizontal:'5%',
            marginTop:16,
        },
        btnSearch:{
            position:'absolute',
            zIndex:2,
            alignSelf:'center',
            paddingRight:16,
            right:0,
        },boxShadow:{
            display:'flex',
            borderRadius:8,
            justifyContent:'center', 
            backgroundColor: colors.white,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.1,
            shadowRadius: 2,
            elevation: 2,
            padding:16,
            marginTop:8
        },
        row:{
            display:'flex',
            flexDirection:'row',
        },
})
