import {useRoute} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Modal, StyleSheet, Text, TouchableOpacity, View, Image, Dimensions, Pressable, Switch, RefreshControl} from 'react-native';
import {Icon} from 'react-native-elements';
import {FlatList} from 'react-native-gesture-handler';
import colors from '../constants/colors';
import urlsGenerales from '../constants/urlsGenerales';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {StorageUtil} from "../shared/StorageUtil";
import ModalOperacionAdmin from "../components/ModalOperacionAdmin";
import {AuthenticationService} from "../shared/AuthenticationService";
import ModalMensaje from "../components/ModalMensaje";
import {CajaService} from "../shared/CajaService";
import CustomAlert from '../shared/CustomAlert';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import routes from '../constants/routes';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const windowWidth = Dimensions.get("window").width;

export default function VerAbonos() {

    type Abono = {
        codpagos: string;
        codUsuario: string;
        codCredito: string;
        codCaja: string;
        fecha: string;
        monto: string;
        timestamp: string;
        tipo: string;
    }

    const route = useRoute()
    const {disabledFn} = route.params;
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const [isConnected, setIsConnected] = useState(true)
    const [pagos, setPagos] = useState({})
    const [abonos, setAbonos] = useState<Abono[]>([]);
    const [totlRecaudo, setTotalRecaudo] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [codAbono, setCodAbono] = useState('');
    const [codCuota, setCodCuota] = useState('');
    const [codCredito, setCodCredito] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalMensaje, setModalMensaje] = useState(false);
    const [saldo, setSaldo] = useState(0);
    const [newVersion,setNewVersion] = useState(true)
    const [refreshing, setRefreshing] = useState(false);
    const [modalTicketRecaudo, setModalTicketRecaudo] = useState(false);
    const [modalImprBluetooth, setModalImprBluetooth] = useState(false);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
          setIsConnected(state.isConnected);
        });
    
        return () => {
          unsubscribe();
        };
    }, []);

    const listarAbonos = async (newV) => {
        try {
            await setLoading(true);

            const {codCredito} = route.params;

            let responseFinal = { data : [] };

            const formData = new FormData();
            formData.append('codCredito', codCredito);
            const token = await StorageUtil.getTokenStorage();
            responseFinal = await axios.post(newVersion?urlsGenerales.LISTAR_ABONOS_CREDITO_V3:urlsGenerales.LISTAR_ABONOS_CREDITO_V2, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (responseFinal) {
                setPagos(responseFinal?.data || {});
                setAbonos(responseFinal?.data?.listaPagos || []);
            }
            console.log("RESPONSE API LIST ABONOS", responseFinal)

        } catch (error) {
            console.log('error', error);
            await setLoading(false);
            await setAbonos([]);
        } finally {
            await setLoading(false);
        }
    }

    useEffect(() => {
        fetchData(newVersion);
    }, [isConnected,newVersion])
    
    const fetchData = async (newV) => {
        await listarAbonos(newV);
        setSaldo(pagos?.saldoCredito || 0);
    };

    useEffect(() => {
        const totalMonto = abonos.reduce((accumulator, current) => accumulator + parseInt(current.monto), 0);
        setTotalRecaudo(totalMonto);
    }, [abonos]);

    const validarCajaAbierta = async () => {
        await setLoading(true)
        const codUsuario = await StorageUtil.getCodUsuarioStorage();
        const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuario!, navigation);
        await setLoading(false)
        return estadoCajaValida;
    }

    const handleEliminarAbono = async (usuario: string, clave: string) => {
        try {
            await setLoading(true);

            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('codDetalleCaja', codAbono);
            formData.append('usu', usuario);
            formData.append('pass', clave);
            formData.append('codCuota',codCuota);
            formData.append('codCredito', codCredito);

            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ELIMINAR_PAGO_V_FINAL, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                if(response.data.mensaje == "ok"){
                    CustomAlert.show({
                        title: 'Aviso',
                        message: 'Abono eliminado con éxito',
                        buttons: [
                            {
                                text: 'OK',
                                onPress: () => {
                                    listarAbonos();
                                },
                            },
                        ]
                    });
                    await AsyncStorage.setItem('recargar_home', "si");
                    await AsyncStorage.setItem('recargar_detalle_credito', "si");
                }else{
                    CustomAlert.show({
                        title: 'Aviso',
                        message: response.data.mensaje
                    });
                }
            }
        } catch (e) {
            console.log('Error en -> ', urlsGenerales.ELIMINAR_PAGO_V_FINAL);
            console.log(e);
            await setLoading(false);
            setAbonos([]);

        } finally {
            await setLoading(false);
        }
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {

        await setModalVisible(false);

        setTimeout(async() => {
            await setLoading(true);
            const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
            if (!validCredentials) {
                CustomAlert.errorCredenciales();
                setLoading(false);
                return;
            }

            handleEliminarAbono(usuario, clave);
        }, 300);
    }

    const eliminarAbono = async (codDetalleCaja: any, codCuo: any, codCred: any) => {
        await setLoading(true);
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                setCodAbono(codDetalleCaja);
                setCodCuota(codCuo);
                setCodCredito(codCred?codCred:pagos.codCredito)
                setModalVisible(true);
            } else {
                CustomAlert.cajaBloq();
                return;
            }
        }   
    }

    const renderItem = ({item}) => {

        const handlePressEliminar = async() => {
            if(await canDelete()){
                eliminarAbono(item.codPagos, item.codCuota, item.codCredito);
            }else{
                setModalMensaje(true);
            }
        }

        const canDelete = async() => {
            try {
                const fechaformat = new Date();
                const fechaString = fechaformat.getFullYear()+"-"+String(fechaformat.getMonth() + 1).padStart(2, '0')+"-"+String(fechaformat.getDate()).padStart(2, '0');
                const fecha1 = new Date(item.timestamp.substring(0,10));
                const fecha2 = new Date(fechaString);

                if(fecha1 >= fecha2){
                    return true
                }else{
                    return false
                }
            } catch (error) {
                return false
            }  
        }

        const getEstado = () => {
            let tipo = ' '
            if(item.tipo){
                switch (item.tipo) {
                        case '0':
                            tipo = ' Efectivo '
                            break;
                        case '1':
                            tipo = ' Yape '
                            break;
                        case '2':
                            tipo = ' Plin '
                            break;
                        case '3':
                            tipo = ' BCP '
                            break;
                        case '4':
                            tipo = ' Interbank '
                            break;
                    default:
                        tipo = ' Otros '
                        break;
                }
            }
            return tipo
        }

        return (
            <TouchableOpacity style={{
                flexDirection: 'row',
                borderBottomWidth: 0.5,
                borderBottomColor: '#222222',
                marginBottom: 8,
                paddingVertical:8,
                paddingBottom:16,
                paddingHorizontal:'5%'
            }}
            disabled={disabledFn}
            onLongPress={()=>{handlePressEliminar()}}
            >
                <View style={{flex:1,paddingRight:16}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={{fontSize:14,fontWeight:'bold',marginBottom:4}}>Pago{getEstado()}{item.codCuota}</Text>
                    <Text style={{fontSize:12,fontWeight:'400'}}>{item.fecha}</Text>
                </View>

                <View style={{alignSelf:'center'}}>
                    <Text style={{fontSize:16,fontWeight:'bold'}}>S/ {Number(item.monto).toFixed(2)}</Text>
                </View>
            </TouchableOpacity>
        );
    };

    const renderEmpty = () => {
        return (
            <View>
                <Text style={{fontSize: 16, marginVertical: 20, textAlign:'center'}}>Aun no se ha realizado abonos :c</Text>
            </View>
        );
    };


    const onRefresh = async() => {
        await setRefreshing(true);
        await fetchData(newVersion)
        await setRefreshing(false)
    }

    const randomString = ()=>{
        return Math.random().toString(36).substring(7);
    } 

    return (
        <>
        <View style={{flex:1,}}>
            <FlatList
                showsVerticalScrollIndicator={false}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
                data={abonos}
                renderItem={renderItem}
                keyExtractor={(item, index) => item.codpagos + index.toString()}
                ListEmptyComponent={renderEmpty}
                style={{width: '100%', flexGrow:1}}
                contentContainerStyle={{paddingBottom:40}}
                removeClippedSubviews={false}
                ListHeaderComponent={
                    <>
                    <View style={styles.crtBox}>
                        <Text style={styles.lblTitle}>Credito #{pagos.codCredito}</Text>
                        <View style={{marginTop:16}}>
                            <Text style={styles.lblmount}>Recaudado</Text>
                            <Text style={[styles.mount,{marginTop:8}]}>S/ {Number(totlRecaudo).toFixed(2)}</Text>
                        </View>
                        <View style={[styles.row,{marginTop:16}]}>
                            <Text style={[styles.lblmount,{alignSelf:'center'}]}>Saldo</Text>
                            <Text style={styles.mount2}>S/ {pagos.saldoCredito ? Number(pagos.saldoCredito).toFixed(2) : Number(saldo).toFixed(2)}</Text>
                        </View>
                    </View>
                    <View style={[styles.row,{marginBottom:16, borderTopWidth:0.5, borderBottomWidth:0.5, borderColor:'#22222230'}]}>
                        <TouchableOpacity style={[styles.botonFooter]}
                                        onPress={() => {
                                            setModalTicketRecaudo(true);
                                        }}>
                                        <View style={{display:'flex',height:40,width:40, borderRadius:20,justifyContent:'center', backgroundColor:colors.verde_primary,alignSelf:'center'}}>
                                            <Icon name='confirmation-number' color={colors.white} size={20} style={{alignSelf:'center'}}></Icon>
                                        </View>
                            <Text style={styles.labelBoton}>Ticket{'\n'}recaudo</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={[styles.botonFooter]}
                                        onPress={() => {
                                            setModalImprBluetooth(true);
                                        }}>
                            
                            <View style={{display:'flex',height:40,width:40, borderRadius:20,justifyContent:'center', backgroundColor:colors.logo_azul,alignSelf:'center'}}>
                                            <Icon name='confirmation-number' color={colors.white} size={20} style={{alignSelf:'center'}}></Icon>
                                        </View>
                            <Text style={styles.labelBoton}>Boleta{'\n'}pagos</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{marginHorizontal:'5%', marginBottom:16}}>
                        <View style={{display:'flex',flexDirection:'row'}}>
                            <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                            <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,textAlign:'left'}}> Manten presionado para eliminar un pago</Text>
                        </View>
                        <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>   
                            <Text style={styles.lblMov}>Movimientos</Text>
                            <View style={{display:'flex',flexDirection:'row'}}>
                                <Text style={{alignSelf:'center',marginRight:8}}>{newVersion?'Nueva versión':'Antigua versión'}</Text>
                                <Switch
                                    trackColor={{false: '#767577', true: '#767577'}}
                                    thumbColor={newVersion ? colors.verde_shadow : '#f4f3f4'}
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={async()=>{
                                        await setNewVersion(!newVersion)
                                    }}
                                    value={newVersion}
                                    style={{alignSelf:'center'}}
                                />
                            </View>
                        </View>
                    </View>
                    </>
                }
                >
            </FlatList>
            
        </View>
        <ModalOperacionAdmin
            visible={modalVisible}
            title='Aviso'
            subtitle='Ingrese sus credenciales para confirmar la operación :)'
            onCancel={() => setModalVisible(false)}
            onConfirm={handleConfirmModal}
        />

        <ModalMensaje
            visible={modalMensaje}
            title={"No permitido"}
            subtitle={"Este pago fue creado en una fecha anterior a la de hoy, prohibido su borrado"}
            onCancel={() => setModalMensaje(false)}
            onConfirm={() => setModalMensaje(false)}
        />

        
        <Modal
            style={{flex:1}}
            visible={modalTicketRecaudo}>
            <View style={[styles.crtModalImg,{marginTop:insets.top, marginBottom:insets.bottom}]}>
                <View style={styles.headerBox}>
                    <Text style={styles.headerText}>TICKET DE RECAUDO</Text>
                    <Pressable onPress={()=>setModalTicketRecaudo(false)} style={styles.btnClose}>
                        <Icon name='cancel' color={colors.black} size={28}></Icon>
                    </Pressable>
                </View>
                <Image 
                    style={styles.imgBoleta}
                    resizeMode="contain"
                    source={{uri:urlsGenerales.S23b + '?codCredito=' + pagos.codCredito  + '&random=' + randomString()}} 
                />
            </View>
        </Modal>

        <Modal visible={modalImprBluetooth} style={{flex:1}}>
            <View style={[styles.crtModalImg,{marginTop:insets.top, marginBottom:insets.bottom}]}>
                <View style={styles.headerBox}>
                    <Text style={styles.headerText}>BOLETA DE PAGOS</Text>
                    <Pressable onPress={()=>setModalImprBluetooth(false)} style={styles.btnClose}>
                        <Icon name='cancel' color={colors.black} size={28}></Icon>
                    </Pressable>
                </View>
                <Image 
                    style={styles.imgBoleta}
                    resizeMode="contain"
                    source={{uri:urlsGenerales.BOLETA_PAGOSb + '?codCredito=' + pagos.codCredito  + '&random=' + randomString()}} 
                />
            </View>
        </Modal>

        <Spinner
            visible={loading}
            color={colors.white}
        />
        </>
    )
}

const styles = StyleSheet.create({
    
        labelTitle: {
            width: '90%',
            fontWeight: 'bold',
            fontSize: 23,
            marginTop: '4%',
            marginBottom: '1%'
        },
    
        labelSubTitle: {
            width: '90%',
            fontSize: 16
        },
    
        labelDescrip: {
            fontWeight: 'bold',
            width: '90%',
            fontSize: 18,
            marginTop: '6%'
        },
    
        btn: {
            borderRadius: 10,
            padding: 10,
            flexDirection: 'row',
            marginRight: 10
        },
    
        textBox: {
            backgroundColor: '#ccc',
            width: '90%',
            padding: 10,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 20,
            color: colors.logo_azul,
            marginTop: '6%',
            marginBottom: '4%'
        },
    
        btnAgregarDisable: {
            flexDirection: 'row',
            backgroundColor: colors.gris_fondo_cell,
            padding: 8,
            borderRadius: 5,
            margin: 5
        },
    
        titleModal: {fontWeight: 'bold', fontSize: 18, marginTop: '5%', marginBottom: '2%'},
    
        labelPlazo: {
            fontWeight: 'bold',
            fontSize: 16
        },
    
        labelConfirmar: {
            fontSize: 16,
            width: '80%'
        },
    
    container: {
        flex: 1,
        // paddingHorizontal:'5%'
        // alignItems: 'center',
    },

    labelOpciones: {
        fontWeight: 'bold',
        fontSize: 20,
        marginVertical:16,
        width: '90%'
    },

    botonFooter: {
        width: '50%',
        paddingVertical:16
    },

    labelBoton: {
        fontWeight: '400',
        marginTop:2,
        fontSize: 12,
        color: colors.negro,
        textAlign: 'center'
    },
    crtModalImg:{
        flex: 1,
        display:'flex',
        flexDirection:'column',
        backgroundColor:colors.gray
    },
    imgBoleta:{
        width: windowWidth* 0.9, // Adjust the percentage as needed
        // height: windowHeight,
        aspectRatio:0.5,
        alignSelf: 'center',
        // flex:1,
    },
    headerBox:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        height:40,
        backgroundColor:colors.white,
        paddingHorizontal: '5%',
        borderBottomWidth: 0.5,
        borderBottomColor: colors.logo_gris
    },
    btnClose:{
        display:'flex',
        width: 50,
        justifyContent:'center'
    },
    headerText:{
        fontSize:20,
        fontWeight: 'bold',
        color: colors.verde_primary,
        alignSelf: 'center'
    },
    btnEliminar: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.10,
        shadowRadius: 1,
        elevation: 1,
        borderRadius: 4,
        backgroundColor: colors.white,
        padding: 16,
        paddingHorizontal:24,
        marginVertical:16,
        width:'auto',
        alignSelf:'center'
    },
    btnLabel: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 16,
        marginStart: 5
    },
    lblMov:{
        fontSize:20,
        fontWeight:'bold',
        textAlign:'left',
        marginVertical:16,
    },
    crtBox:{
        // backgroundColor: colors.white,
        // shadowColor: colors.black,
        // shadowOffset: {
        //     width: 0,
        //     height: 2,
        // },
        // shadowOpacity: 0.25,
        // shadowRadius: 2,
        // elevation: 2,
        // borderRadius: 4,
        paddingVertical:16,
        // marginVertical:16,
        marginHorizontal:'5%'
    },
    row:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
    },
    mount:{
        fontSize:32,
        fontWeight:'bold',
        color:colors.logo_azul
    },
    mount2:{
        fontSize:12,
        fontWeight:'bold',
        color:'#222222',
        alignSelf:'center'
    },
    lblmount:{
        color:'#222222',
        fontSize:12,
    },
    lblTitle:{
        fontSize:12,
        textAlign:'left',
        fontWeight:'bold'
    },
    boxInformationVersion:{
        padding:16,
        backgroundColor:colors.naranja,
        borderRadius:4,
    },
})
