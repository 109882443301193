import React, { createContext, useContext, useState } from 'react';

const IconContext = createContext();

export const useIconContext = () => {
  return useContext(IconContext);
};

export const IconProvider = ({ children }) => {
  const [isSync, setIsSync] = useState(false); // Valor inicial del booleano

  const updateIsSync = (value) => {
    setIsSync(value);
  };

  return (
    <IconContext.Provider value={{ isSync, updateIsSync }}>
      {children}
    </IconContext.Provider>
  );
};