import {FlatList, StyleSheet, Text, View, TouchableOpacity} from "react-native";
import React, {useEffect, useState} from "react";
import Spinner from "react-native-loading-spinner-overlay";
import colors from "../constants/colors";
import {StorageUtil} from "../shared/StorageUtil";
import axios from "axios";
import urlsGenerales from "../constants/urlsGenerales";
import { useNavigation, useRoute } from "@react-navigation/native";
import routes from "../constants/routes";
// import { PieChart } from "react-native-gifted-charts";
import moment from 'moment';
import 'moment/locale/es';

export default function DetallePagos() {

    const navigation = useNavigation();
    const route = useRoute();
    const {id, fecha} = route.params;

    const [loading, setLoading] = useState(false);

    const [listResumen, setListResumen] = useState([]);
    const [keyFilter, setKeyFilter] = useState("Efectivo")
    const [keyFilterColor, setKeyFilterColor] = useState(colors.logo_gris)
    const [bar, setBar] = useState([])

    const [totalRecaudado, setTotalRecaudado] = useState(0);

    const obtenerDetallePagos = async (id? ) => {
        try {
            setLoading(true);
            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario);
            id && formData.append('codCaja', id);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.DETALLE_PAGOS, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                const detallePagos = response.data;

                const barData = [
                    {value: detallePagos.efectivo, label: 'Efectivo', color: colors.logo_gris},
                    {value: detallePagos.yape, label: 'Yape', color: '#821090'},
                    {value: detallePagos.plin, label: 'Plin', color: '#0AA0A1'},
                    {value: detallePagos.bcp, label: 'BCP', color: '#022C70'},
                    {value: detallePagos.interbank, label: 'Interbank', color: '#299855'},
                    {value: detallePagos.otros, label: 'Otros', color: '#000000'},
                ];
                setBar(barData)
                console.log(response.data)
                setTotalRecaudado(detallePagos.totalRecaudado);
                setListResumen(detallePagos.listaPagos);
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.DETALLE_PAGOS);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await obtenerDetallePagos(id);
        };
        fetchData();
    }, [])
    

    const renderItem = ({item, index}) => {
        return (
            <View
                style={styles.crtItem}
                key={item.codDetalleCaja}
            >
                <View style={{flex:1, marginRight:8}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>{item.nombre} {item.apellido}</Text>
                    <Text style={styles.datePago}>{moment(item.fecha).format('LT')}</Text>
                </View>
                <Text style={styles.lblMonto}>S/ {Number(item.monto).toFixed(2)}</Text>
            </View>
        );
    }

    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 14, marginVertical: 20, textAlign:'center', fontWeight:'400'}}>No hay pagos registrados</Text>
        );
    };

    const renderLegendComponent = () => {
        return (
            <View
                style={styles.containerLegend}>
                <View style={styles.rowLegend}>
                    {
                        bar.map((item,index)=>{
                            return(
                                <TouchableOpacity key={"type"+index} style={styles.itemLegend} onPress={()=>{
                                    setKeyFilter(item.label)
                                    setKeyFilterColor(item.color)
                                }}>
                                    <Text style={{color: 'black', fontSize: 12, marginBottom:4, fontWeight:'bold'}}>S/ {Number(item.value).toFixed(2)}</Text>
                                    <View style={styles.itemLegendRow}>
                                        <View
                                        style={{
                                            height: 20,
                                            width: 20,
                                            marginRight: 6,
                                            borderRadius: 4,
                                            backgroundColor: item.color || 'white',
                                        }}
                                        />
                                        <Text style={{color: colors.negro, fontSize: 12, alignSelf:'center'}}>{item.label || ''} ({listResumen.filter((data) => data.tipoPago == item.label).length})</Text>
                                    </View>
                                </TouchableOpacity>
                            )
                        })
                    }
                </View>
            </View>
        );
    };

    useEffect(() => {
        navigation.setOptions({
            headerTitle:`Pagos del ${fecha.format('LL')}`
        });
    }, [navigation]); 

    return (
        <>
        <View style={{flex:1}}>
            <FlatList
                ListHeaderComponent={()=>{
                    return(
                        <>
                        <View style={styles.containerPie}>
                            {/* <PieChart
                                data={bar}
                                donut
                                sectionAutoFocus
                                radius={100}
                                innerRadius={50}
                                innerCircleColor={'#FFFFFF'}
                                focusOnPress
                                centerLabelComponent={() => {
                                    return (
                                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                        <Text
                                        style={{fontSize: 14, color: colors.negro, fontWeight: 'bold'}}>
                                        S/ {totalRecaudado.toFixed(2)}
                                        </Text>
                                        <Text style={{fontSize: 12, color: colors.negro}}>Soles</Text>
                                    </View>
                                    );
                                }}
                                /> */}
                                {
                                    renderLegendComponent()
                                }
                        </View>
                        <View style={styles.ctrTitleList}>
                            <Text style={styles.titleList}>Lista de pagos <Text style={{color:keyFilterColor}}>{keyFilter.toUpperCase()}</Text> <Text style={{fontSize:12, fontWeight:'500'}}>({listResumen.filter((item) => item.tipoPago == keyFilter).length}{listResumen.filter((item) => item.tipoPago == keyFilter).length==1?' pago':' pagos'})</Text></Text>
                        </View>
                        </>
                    
                    )
                }}
                data={listResumen.filter((item) => item.tipoPago == keyFilter).sort((a, b) => new Date(b.fecha) - new Date(a.fecha))}
                renderItem={renderItem}
                keyExtractor={(item) => item.codDetalleCaja}
                ListEmptyComponent={renderEmpty}
                contentContainerStyle={{paddingBottom:48}}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
        </>
    )
}

const styles = StyleSheet.create({
    containerPie: {
        padding: 24, alignItems: 'center',
    },
    containerLegend:{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:'5%'
    },
    rowLegend:{
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    itemLegend:{
        width: '30%', // Establece el ancho de cada elemento a aproximadamente el 30% del ancho del contenedor
        marginBottom:8,
        marginTop:8,
    },
    itemLegendRow:{
        display:'flex',
        flexDirection:'row',
    },
    ctrTitleList:{
        display:'flex',
        justifyContent:'flex-start',
        paddingVertical:16,
        paddingHorizontal:16,
    },
    titleList:{
        fontWeight: 'bold',
        fontSize: 16,
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontWeight:'400',
        fontSize:14,
    },
    lblMonto:{
        fontSize:16,
        fontWeight:'bold'
    },
    datePago:{
        fontSize:12,
        marginTop:2,
        fontWeight:'300',
    }
})
