export class RecaudoDTO {
  constructor(
    alias,
    apellido,
    cantCuotas,
    cantCuotasPagadas,
    codCliente,
    codCredito,
    codDocumento,
    codFrecuencia,
    codUsuario,
    correo,
    detEstado,
    diaFrecuencia,
    diaFrecuencia2,
    direccion,
    estado,
    fecVencimiento,
    fecha,
    idProducto,
    interes,
    montoAlDia,
    nombre,
    numDocumento,
    saldoCredito,
    telefono,
    timestamp,
    totalDeuda,
    valorCredito,
    valorCuota,
    valorCuotaTotal,
    quota_not_pay
  ) {
    this.alias = alias ? alias : "";
    this.apellido = apellido ? apellido : "";
    this.cantCuotas = cantCuotas ? cantCuotas : "0";
    this.cantCuotasPagadas = cantCuotasPagadas ? cantCuotasPagadas : "0";
    this.codCliente = codCliente ? codCliente : "0";
    this.codCredito = codCredito ? codCredito : "0";
    this.codDocumento = codDocumento ? codDocumento : "0";
    this.codFrecuencia = codFrecuencia ? codFrecuencia : "0";
    this.codUsuario = codUsuario ? codUsuario : "0";
    this.correo = correo ? correo : "";
    this.detEstado = detEstado ? detEstado : "";
    this.diaFrecuencia = diaFrecuencia ? diaFrecuencia : "0";
    this.diaFrecuencia2 = diaFrecuencia2 ? diaFrecuencia2 : "0";
    this.direccion = direccion ? direccion : "";
    this.estado = estado ? estado : "0";
    this.fecVencimiento = fecVencimiento ? fecVencimiento : "";
    this.fecha = fecha ? fecha : "";
    this.idProducto = idProducto ? idProducto : null;
    this.interes = interes ? interes : "0";
    this.montoAlDia = montoAlDia ? montoAlDia : "0";
    this.nombre = nombre ? nombre : "";
    this.numDocumento = numDocumento ? numDocumento : "";
    this.saldoCredito = saldoCredito ? saldoCredito : "";
    this.telefono = telefono ? telefono : "";
    this.timestamp = timestamp ? timestamp : "";
    this.totalDeuda = totalDeuda ? totalDeuda : "0";
    this.valorCredito = valorCredito ? valorCredito : "0";
    this.valorCuota = valorCuota ? valorCuota : "0";
    this.valorCuotaTotal = valorCuotaTotal ? valorCuotaTotal : "0";
    this.quota_not_pay = quota_not_pay ? quota_not_pay : null;
  }
}
