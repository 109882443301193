import React, {useCallback, useEffect, useState} from 'react';
import {Modal,  Platform,  StyleSheet, Text, TextInput, TouchableOpacity ,View, FlatList, RefreshControl} from 'react-native';

import {useFocusEffect, useNavigation} from '@react-navigation/native';
import axios from 'axios';
import {Icon} from 'react-native-elements';
import colors from '../../constants/colors';
import routes from '../../constants/routes';
import stylesGenerales from '../../constants/stylesGenrales';
import urlsGenerales from '../../constants/urlsGenerales';
import {DateUtils} from '../../shared/DateUtils';
import {StorageUtil} from '../../shared/StorageUtil';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import Checkbox from 'expo-checkbox';

export default function HomeAdmin() {

    const navigation = useNavigation();

    const [dateNow] = useState(new Date());
    const [modalVisibleVencimiento, setModalVisibleVencimiento] = useState(false);
    const [modalVisiblePago, setModalVisiblePago] = useState(false);
    const [privilegio, setPrivilegio] = useState("U");
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [fecVencimiento, setFecVencimiento] = useState(DateUtils.getDateStringYYYYMMDD());
    const [diaPago, setDiaPago] = useState('0');
    const [diaFinal, setDiaFinal] = useState('');
    const [diasRestantes, setDiasRestantes] = useState('');
    const [ocultarModalHoy, setOcultarModalHoy] = useState(false);
    const [listUsuarios, setListUsuarios] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [refreshing, setRefreshing] = useState(false);

    const cambiarCheckDemo = () => {
        setOcultarModalHoy(!ocultarModalHoy);
    };

    const cerrarModalDemo = async () => {
        if (ocultarModalHoy) {
            try {
                const fechaString =  DateUtils.getDateStringYYYYMMDD();
                await SecureStore.setItemAsync('ocultarModalHoy', true+"");
                await SecureStore.setItemAsync('fechaModalHoy', fechaString);
                console.log("Guardo estado: ",true," ,fecha: ",fechaString);
            } catch (error) {
              console.error('Error saving user data:', error);
            }
        }
        setModalVisibleVencimiento(false)
    }

    const getDateString = () => {
        return String(dateNow.getDate()).padStart(2, '0') + ' de ' +
            DateUtils.getMonthName(dateNow.getMonth() + 1) + ' de ' +
            dateNow.getFullYear();
    }

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const fetchData = async () => {
        try {

            const nombre = await AsyncStorage.getItem('nombre');
            const apellido = await AsyncStorage.getItem('apellido');
            const codTipoCuenta = await AsyncStorage.getItem('codTipoCuenta');
            const diaPago = await AsyncStorage.getItem('diaPago');
            const fecVenci = await AsyncStorage.getItem('fechaVencimiento');

            setNombre(nombre);
            setApellido(apellido);
            setDiaPago(diaPago);
            setFecVencimiento(fecVenci);

            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            const privilegio = await StorageUtil.getPrivilegioStorage();
            setPrivilegio(privilegio);

            if( (privilegio === "S" || privilegio === "M") && codTipoCuenta === "1" ){

                const fechaHoy = new Date();
                const nroDia = String(fechaHoy.getDate()).padStart(2, '0');
                const fechaDiaPago = new Date("2000-01-"+nroDia);
                const diaPagoFormateado = String(diaPago).padStart(2, '0');
                const fechaLimitePago = new Date("2000-01-"+diaPagoFormateado);
                const fechaInicioPago = new Date("2000-01-"+diaPagoFormateado);
                fechaInicioPago.setDate(fechaInicioPago.getDate() - 2);

                setDiasRestantes(( (fechaLimitePago-fechaDiaPago)/ (1000 * 60 * 60 * 24) )+"");

                
                if(fechaDiaPago >= fechaInicioPago && fechaDiaPago <= fechaLimitePago){
                    setModalVisiblePago(true);
                }else{
                    setModalVisiblePago(false);
                } 
            }

            let ocultoModalHoy = false;
            setOcultarModalHoy(false);

            if(Platform.OS !== 'web'){
                try {
                const savedOcultarModalHoy = await SecureStore.getItemAsync('ocultarModalHoy');
                const savedFechaModalHoy = await SecureStore.getItemAsync('fechaModalHoy');
                const fechaString = DateUtils.getDateStringYYYYMMDD();

                if (savedOcultarModalHoy && savedFechaModalHoy && (fechaString === savedFechaModalHoy)) {

                    const savedOcultarModalHoyBoolean = JSON.parse(savedOcultarModalHoy);

                    if(savedOcultarModalHoyBoolean){
                        setOcultarModalHoy(true);
                        ocultoModalHoy = true;
                    }
                }
                } catch (error) {
                console.error('Error retrieving user data:', error);
                }
            }

            if(codTipoCuenta === "2" && !ocultoModalHoy){
              
                const fechaString = DateUtils.getDateStringYYYYMMDD();
                const fechaVence = new Date(fecVenci);
                const fechaIni = new Date(fechaString);
                const fechaFin = new Date(fechaString);
                fechaFin.setDate(fechaFin.getDate() + 3);
                
                if(fechaVence >= fechaIni && fechaVence <= fechaFin){
                    if(fechaVence.getTime() === fechaIni.getTime()){
                        setDiaFinal("\n\n ¡ Hoy es el ultimo Día !\n")
                    }else{
                        setDiaFinal("");    
                    }
                    setModalVisibleVencimiento(true);
                }else{
                    setModalVisibleVencimiento(false);
                    setDiaFinal("");
                }
            }else{
                setModalVisibleVencimiento(false);
            }

            const formData = new FormData();
            formData.append('codUsuario', codUsuario!);
            formData.append('privilegio', privilegio!);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_ASIGNADOS_ADMIN, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            setListUsuarios(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.LISTAR_ASIGNADOS_ADMIN);
            console.log(error);
            setListUsuarios([]);

        } finally {
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    useFocusEffect(
        useCallback(() => {
            get()
        }, [])
    );

    const get = async()=> {
        const recargar = await AsyncStorage.getItem('recargar_home_admin')
        console.log("Necesito Actualizar Home admin ? ",recargar)
        if(recargar){
            await AsyncStorage.removeItem('recargar_home_admin')
            if(recargar == 'si'){
                fetchData()
            } 
        }
    } 
    
    const renderEmpty = () => {
        return (
                <Text style={styles.lblEmpty}>{searchText.length>0?'No se encontrarón resultados':'No tiene usuarios asignados'}</Text>
        );
    };

    const renderItem = ({item}) => {

        const irOpcionesRuta = async () => {
            await StorageUtil.setCobradorStorage(item);

            navigation.navigate(routes.OPCIONES_RUTA, {
                screen: routes.OPCIONES_RUTA,
                params: {cobrador: item}
            });
        }

        const irOpcionesCuenta = () => {
            navigation.navigate(routes.OPCIONES_CUENTA, {cobrador: item});
        }

        const irReporteCuenta = () => {
            navigation.navigate(routes.REPORTE_USUARIOS_CREADOS, {cobrador: item});
        }

        return (
            <View style={styles.crtItemList}>
                <TouchableOpacity
                    style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',paddingLeft:16}}
                    onPress={item.privilegioHijo == "U" ? irOpcionesRuta : irOpcionesCuenta}
                >   
                    <View>
                        <Text numberOfLines={1} ellipsizeMode="tail"><Text style={{fontWeight:'bold'}}>{item.privilegioHijo != "" ? item.privilegioHijo == "S" ? "SP - " : item.privilegioHijo == "A" ? "ADM - " : "COB - " : ""}</Text>{item.nombreHijo} {item.apellidoHijo}</Text>
                    </View>
                </TouchableOpacity>
                {
                    privilegio == "M" ?
                    <TouchableOpacity
                        style={{
                            paddingHorizontal:12,
                            justifyContent: 'center',
                        }}
                        onPress={irReporteCuenta}
                    >
                        <Icon name='person' color={colors.verde_primary}></Icon>
                    </TouchableOpacity>
                    :null
                }
                <TouchableOpacity
                    style={{
                        paddingHorizontal:12,
                        justifyContent: 'center',
                    }}
                    onPress={irOpcionesCuenta}
                >
                    <Icon name='settings' color={colors.azul_cobalto}></Icon>
                </TouchableOpacity>
            </View>
        );
    };

    const onRefresh = async() => {
        await setRefreshing(true);
        await fetchData()
        await setRefreshing(false)
    }

    return(
        <>
            <View style={styles.container}>
                <FlatList
                    refreshControl={
                        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                    }
                    ListHeaderComponent={
                        <>
                        <View  style={styles.ctrInfo}>
                            <View style={styles.boxWelcome}>
                                <Text style={styles.textWelcome}>
                                {
                                    privilegio == "M" ? 
                                        "Master"
                                    : 
                                    privilegio == "S" ? 
                                        "Super Administrador"
                                    : 
                                    privilegio == "A" ? 
                                        "Administrador"
                                    : 
                                        "Cobrador"
                                }
                                </Text>
                                <Text numberOfLines={1} ellipsizeMode="tail" style={styles.textName}>{nombre + " "+apellido}</Text>
                            </View>
                            <View style={styles.crtDate}>
                                <Text style={styles.lblDate}>{getDateString()}</Text>
                            </View>
                        </View>
                        <Text style={[styles.title]}>Lista de usuarios asignados</Text>
                        <View style={styles.crtSearch}>
                            <View style={styles.searchIcon}>
                                <Icon name='search' color={colors.negro}></Icon>
                            </View>
                            <TextInput 
                                placeholder='Buscar'
                                style={styles.searchInput}
                                onChangeText={handleSearch}
                                value={searchText} 
                                selectionColor={colors.negro}
                                placeholderTextColor={colors.gris_fondo_cell}
                            />
                            {searchText !== '' &&
                                <TouchableOpacity 
                                    style={styles.clearSearch}
                                    onPress={limpiarSearchText}>
                                    <Icon name='clear' color={colors.negro}></Icon>
                                </TouchableOpacity>
                            }
                        </View>
                        </>
                    }
                    data={listUsuarios.filter((item) => (item.nombreHijo+" "+item.apellidoHijo).toLowerCase().includes(searchText.toLowerCase()))}
                    renderItem={renderItem}
                    keyExtractor={(_, index) => index.toString()}
                    ListEmptyComponent={renderEmpty}
                    contentContainerStyle={{paddingBottom:48}}
                    showsVerticalScrollIndicator={false}
                >
                    
                </FlatList>
            </View>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisibleVencimiento}
            >
                <View style={styles.centeredView}>
                    <View style={[styles.modalView,{alignSelf:'center'}]}>
                        <View style={[styles.modalHeader,{flexDirection:'row'}]}>
                            <Text style={[styles.modalHeader,{flex:1}]}>{"¡ Se termina la Demo !"}</Text>
                            <TouchableOpacity
                                onPress={cerrarModalDemo}>
                                <Icon size={24} name='highlight-off' color='black'></Icon>
                            </TouchableOpacity>
                        </View>

                        <Icon size={60} name='face' color='green' style={{marginTop:"5%"}}></Icon>

                        <Text style={[stylesGenerales.labelInput,{paddingHorizontal:16}]}>
                            {'\nEstimado '+nombre + " "+ apellido +', se le informa que su cuenta esta proxima a ser bloqueada, la fecha de vencimiento de su cuenta Demo es el "'+DateUtils.formatTODDMMYYYY(fecVencimiento)+'".\n\nConsulte como puede realizar una extensión de su demo ó suscribase al servicio.\n'}
                            <Text style={[stylesGenerales.labelInput,{marginTop:"-2%",fontWeight:'bold'}]}>{diaFinal}</Text>
                        </Text>

                        
                        { Platform.OS !== "web" &&
                        <TouchableOpacity
                            style={{ flexDirection: 'row', alignSelf:'flex-start' ,marginHorizontal:16}}
                                    onPress={cambiarCheckDemo}
                                >
                            <View style={{ flexDirection: 'row',alignItems: 'center' }}>
                                <Checkbox
                                    value={ocultarModalHoy}
                                    onValueChange={cambiarCheckDemo}
                                    style={{ marginRight: 10 }}
                                />
                                <Text>Dejar de mostrar este mensaje por hoy.</Text>
                            </View>
                        </TouchableOpacity>
                        }

                    <TouchableOpacity style={[stylesGenerales.btnLarge, {
                        backgroundColor: colors.verde_primary,
                        marginTop: '5%',
                        borderRadius: 4,
                        marginHorizontal:16,
                        width:'auto',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }]} onPress={cerrarModalDemo}
                    >
                        <Text style={[stylesGenerales.textBtn, { textAlign: 'center', fontSize:16, padding:8,}]}>Cerrar</Text>

                    </TouchableOpacity>

                    </View>
                </View>
            </Modal>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisiblePago}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View style={[styles.modalHeader]}>
                            <Text style={styles.modalHeader}>{"¡ Fecha de Pago !"}</Text>
                            <TouchableOpacity
                                style={{marginLeft:16}}
                                onPress={() => {setModalVisiblePago(false)}}>
                                <Icon size={35} name='highlight-off' color='white'></Icon>
                            </TouchableOpacity>
                        </View>

                        <Icon size={60} name='attach-money' color='green' style={{marginTop:"5%"}}></Icon>

                        <Text style={[stylesGenerales.labelInput,{paddingHorizontal:16}]}>
                            {'\nEstimado '+nombre + " "+ apellido +', se le notifica que esta proxima su fecha de pago, siendo los días '+diaPago+' de cada mes quedan.\n\nQuedan '+diasRestantes+' días para el proximo pago incluyendo hoy.\n\nConserve su acceso al aplicativo manteniendose al dia con sus pagos.\n\nAgradecemos su permanencia \u{1F604}\n'}
                            <Text style={[stylesGenerales.labelInput,{marginTop:"-2%",fontWeight:'bold'}]}>{diaFinal}</Text>
                        </Text>

                        <TouchableOpacity style={[stylesGenerales.btnLarge, {
                        backgroundColor: colors.verde_primary,
                        marginTop: '5%',
                        borderRadius: 4,
                        marginHorizontal:16,
                        width:'auto',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }]} onPress={cerrarModalDemo}
                    >
                        <Text style={[stylesGenerales.textBtn, { textAlign: 'center', fontSize:16, padding:8,}]}>Cerrar</Text>

                    </TouchableOpacity>

                    </View>
                </View>
            </Modal>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    ctrInfo:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        backgroundColor: colors.verde_primary,
        alignSelf:'center',
        justifyContent:'center',
        paddingHorizontal:'5%',
        paddingVertical:16,
    },
    boxWelcome:{
        flex:1,
    },
    textWelcome:{
        textAlign: 'left',
        color: colors.white,
        fontSize: 16,
    },
    textName:{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 24,
        color: colors.white,
        textTransform:'capitalize'
    },
    
    crtDate:{
        borderRadius:20,
        paddingHorizontal:16,
        height:40,
        borderWidth:1,
        borderColor:colors.white,
        alignSelf:'center',
        display:'flex',
        justifyContent:'center',
        marginLeft:16
    },
    lblDate:{
        color:colors.white,
        alignSelf:'center',
        fontWeight:'bold',
        fontSize:12,
    },
    crtSearch:{
        height: 48,
        position: 'relative',
        marginHorizontal: '5%',
        marginBottom:24,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.20,
        shadowRadius: 2,
        elevation: 4,
    },
    searchIcon:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
        left:0,
        top:12
    },
    searchInput:{
        height:48,
        borderRadius:4,
        backgroundColor:colors.white,
        width:'100%',
        color:colors.black,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    clearSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
        top:12
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        marginLeft:'5%',
        marginVertical:16
    },
    lblEmpty:{
        textAlign:'center',
        marginVertical:24,
        fontSize:16
    },
    crtItemList:{
        marginHorizontal: '5%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.10,
        shadowRadius: 1,
        elevation: 1,
        borderRadius: 4,
        maxHeight: 56,
        minHeight: 56,
        marginBottom:8,
    },
    ////////////////
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalView: {
        width:'90%',
        maxWidth:500,
        backgroundColor: 'white',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },
    modalHeader: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18, 
        marginTop:16, 
        paddingHorizontal:16,
    },
    titleModal: {
        fontSize: 14,
        textAlign: 'left',
        marginTop:16,
        paddingHorizontal:16,
    },

})
