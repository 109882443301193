// @ts-ignore
import numeral from 'numeral';

export class NumberUtils {
    static formatThousands(monto: number): string {
        const formattedNumber = numeral(monto).format('0,0');
        return formattedNumber;
    }

    static formatStringThousands(monto: string): string {
        const number = Number(monto.replace(/[^0-9.-]+/g, ""));
        const formattedNumber = numeral(number).format('0,0');
        return formattedNumber;
    }
}
