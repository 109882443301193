import React, { useState } from 'react';
import { Modal, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import colors from '../constants/colors';

type Props = {
    visible: boolean;
    onCancel: () => void;
    onConfirm: (inputValue1: string, inputValue2: string) => void;
    title?: string;
    subtitle?: string;
};

export default function ModalOperacionAdmin(props: Props) {

    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');

    const handleConfirm = () => {
        props.onConfirm(usuario, clave);
        setUsuario('');
        setClave('');
    };

    const handleCancel = () => {
        setUsuario('');
        setClave('');
        props.onCancel();
    }
    
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.visible}
            onRequestClose={props.onCancel}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>

                    {props.title && <Text style={styles.modalTitle}>{props.title}</Text>}
                    {props.title && <View style={{borderBottomWidth: 1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>}
                    {props.subtitle && <Text style={styles.modalSubtitle}>{props.subtitle}</Text>}

                    <View style={styles.inputContainer}>
                        <TextInput
                            style={styles.input}
                            value={usuario}
                            onChangeText={(text) => setUsuario(text)}
                            placeholder="Usuario"
                        />
                        <TextInput
                            style={styles.input}
                            value={clave}
                            onChangeText={(text) => setClave(text)}
                            secureTextEntry={true}
                            placeholder="Contraseña"
                        />
                    </View>

                    <View style={styles.buttonsContainer}>

                        <TouchableOpacity
                            style={[styles.button, styles.buttonCancel]}
                            onPress={handleCancel}
                        >
                            <Text style={[styles.textButton,{color:colors.negro, fontWeight:'400'}]}>Cancelar</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={[styles.button, styles.buttonConfirm]}
                            onPress={handleConfirm}
                        >
                            <Text style={styles.textButton}>Confirmar</Text>
                        </TouchableOpacity>

                    </View>

                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({

    centeredView: {
        paddingHorizontal:'5%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        position:'relative',
        width:'100%',
        maxWidth:500,
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },

    modalTitle: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18, 
        marginTop:16, 
        paddingHorizontal:16,
    },

    modalSubtitle: {
        fontSize: 16,
        textAlign: 'left',
        paddingHorizontal:16,
        marginTop:16,
    },

    inputContainer: {
        width: '100%',
        paddingHorizontal:16,
        marginTop:16,
        marginBottom: 24,
    },

    input: {
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginBottom:16,
        paddingHorizontal:16,
    },

    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingHorizontal:16,
        width: '100%',
    },

    button: {
        borderRadius: 4,
        paddingVertical: 16,
        paddingHorizontal: 16,
        flexDirection: 'row'
    },

    buttonCancel: {
        backgroundColor: colors.white,
        marginRight: 8,
    },

    buttonConfirm: {
        backgroundColor: colors.verde_primary
    },

    textButton: {
        color: colors.white,
        fontSize: 16,
        fontWeight:'bold'
    }
})
