import AsyncStorage from '@react-native-async-storage/async-storage';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import axios from 'axios';
import React, {useState, useCallback} from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View, Image, Modal} from 'react-native';
import {Icon} from 'react-native-elements';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../../constants/colors';
import routes from '../../constants/routes';
import stylesAdmin from '../../constants/stylesAdmin';
import stylesGenerales from '../../constants/stylesGenrales';
import urlsGenerales from '../../constants/urlsGenerales';
import {StorageUtil} from '../../shared/StorageUtil';
import {textoSpinner} from '../../shared/TiposDocumento';
import ModalOperacionAdmin from "../../components/ModalOperacionAdmin";
import {AuthenticationService} from "../../shared/AuthenticationService";
import CustomAlert from '../../shared/CustomAlert';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

const error = {
    item1:{value:false,text:''},
    item2:{value:false,text:''},
    item3:{value:false,text:''}
}
const errorEdit_ = {
    name:{value:false,text:''},
    lastName:{value:false,text:''},
    email:{value:false,text:''},
    phone:{value:false,text:''}
}

export default function EditarUsuarioAdmin() {

    const navigation = useNavigation();

    const [infoUser, setInfoUser] = useState({}) 
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [contraActual, setContraActual] = useState('')
    const [contraNueva, setContraNueva] = useState('')
    const [contraNuevaRep, setContraNuevaRep] = useState('')
    const [errorPassword,setErrorPassword] = useState(error)
    const [errorEdit,setErrorEdit] = useState(errorEdit_)
    const [loading, setLoading] = useState(false)
    const [modalPassword, setModalPassword] = useState(false)
    const [modalEditUser, setModalEditUser] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)


    const [mostrarBotones, setMostrarBotones] = React.useState(false);
    const [mostrarEditar, setMostrarEditar] = React.useState(true);


    const guardar = async() => {
        if(nombre == '' || apellido =='' || telefono =='' || correo == ''){
            if(nombre==''){
                setError('name',true, 'Campo requerido', setErrorEdit)
            }
            if(apellido==''){
                setError('lastName',true, 'Campo requerido', setErrorEdit)
            }
            if(telefono==''){
                setError('phone',true, 'Campo requerido', setErrorEdit)
            }
            if(correo==''){
                setError('email',true, 'Campo requerido', setErrorEdit)
            }
            return
        }
        await setModalEditUser(false)
        setTimeout( async() => {
            await setModalVisible(true);
        }, 1000);
    }

    const doChangeInformation = async (userAdmin: string, passAdmin: string) => {

        try {
            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);

            formData.append('usu', userAdmin);
            formData.append('pass', passAdmin);

            formData.append('nombre', nombre);
            formData.append('apellido', apellido);
            formData.append('correo', correo);
            formData.append('telefono', telefono);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.EDITAR_USUARIO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await AsyncStorage.setItem('nombre', nombre);
                await AsyncStorage.setItem('apellido', apellido);
                await AsyncStorage.setItem('telefono', telefono);
                await AsyncStorage.setItem('correo', correo);
                const userStorage = await AsyncStorage.getItem('usu');

                setMostrarEditar(true);
                setMostrarBotones(false);
                setInfoUser({
                    nombre:nombre,
                    apellido:apellido,
                    telefono:telefono,
                    correo:correo,
                    user:userStorage,
                })

                CustomAlert.show({
                    title: 'Aviso',
                    message: 'Se actualizaron los datos'
                });
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.EDITAR_USUARIO);
            console.log(error);

        } finally {
            await setLoading(false);
        }
    }

    
    const handleConfirmModal = async (userAdmin: string, passAdmin: string) => {

        await setModalVisible(false);
        await setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(userAdmin, passAdmin);
        if (!validCredentials) {
            await setLoading(false);
            CustomAlert.errorCredenciales();
            return;
        }

        await doChangeInformation(userAdmin, passAdmin);
    }

    // Para realizar el cambio contraseña
    const changePassword = async () => {
        if(contraActual == '' || contraNueva =='' || contraNuevaRep ==''){
            if(contraActual==''){
                setError('item1',true, 'Campo requerido', setErrorPassword)
            }
            if(contraNueva==''){
                setError('item2',true, 'Campo requerido', setErrorPassword)
            }
            if(contraNuevaRep==''){
                setError('item3',true, 'Campo requerido', setErrorPassword)
            }
            return
        }
        
        const passStorage = await StorageUtil.getPassStorage();

        if (contraActual != passStorage) {
            setError('item1',true, 'La contraseña actual es incorrecta', setErrorPassword)
            return;
        }

        if (contraNueva != contraNuevaRep) {
            setError('item3',true, 'Las contraseñas no coinciden', setErrorPassword)
            return;
        }

        const userEnMemoria = await StorageUtil.getUserStorage();
        const claveEnMemoria = await StorageUtil.getPassStorage();

        try {
            await setModalPassword(false)
            await setLoading(true);

            const formData = new FormData();
            const token = await StorageUtil.getTokenStorage(); 
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('usu', userEnMemoria!);
            formData.append('pass', claveEnMemoria!);
            formData.append('nuevoPass', contraNueva!);

            const response = await axios.post(urlsGenerales.CAMBIAR_CLAVE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await AsyncStorage.setItem('pass', contraNueva);
                if(Platform.OS !== 'web'){
                    await SecureStore.setItemAsync('password', contraNueva);
                }
                CustomAlert.show({
                    title: 'Aviso',
                    message: 'Se cambio la contraseña correctamente'
                });
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.CAMBIAR_CLAVE);
            console.log(error);
        } finally {
            await setLoading(false);
            await setContraActual('')
            await setContraNueva('')
            await setContraNuevaRep('')
        }
    }

    const setError = (key,value,text, set) => {
        set((prevState) => ({
            ...prevState,
            [key]: {value,text},
        }));
    };

    useFocusEffect(
        useCallback(() => {
            const fetchData = async () => {
                setLoading(true);

                setMostrarBotones(false);
                setMostrarEditar(true);

                const nombreStorage = await AsyncStorage.getItem('nombre');
                const apellidoStorage = await AsyncStorage.getItem('apellido');
                const telefonoStorage = await AsyncStorage.getItem('telefono');
                const correoStorage = await AsyncStorage.getItem('correo');
                const userStorage = await AsyncStorage.getItem('usu');

                setInfoUser({
                    nombre:nombreStorage,
                    apellido:apellidoStorage,
                    telefono:telefonoStorage,
                    correo:correoStorage,
                    user:userStorage,
                })

                setLoading(false)
            };
            fetchData()
        }, [])
    );


    return (
        <ScrollView 
            contentContainerStyle={styles.container}
            showsHorizontalScrollIndicator={false}
        >
            <View style={styles.ctrImage}>
                <Image 
                    style={[styles.circleStatus]}
                    resizeMode="center"
                    source={require('../../assets/client.png')}
                />
                    <Text style={styles.name}>{infoUser.nombre} {infoUser.apellido}</Text>
            </View>
            <View style={[styles.mrH, styles.ctrInfo]}>
                <View style={[styles.row,styles.itemInfo]}>
                    <Text style={styles.label}>Usuario</Text>
                    <Text style={styles.phone}>{infoUser.user}</Text>
                </View>
                <View style={[styles.row,styles.itemInfo]}>
                    <Text style={styles.label}>Correo</Text>
                    <Text style={styles.phone}>{infoUser.correo}</Text>
                </View>
                <View style={[styles.row,styles.itemInfo,{borderBottomWidth:0}]}>
                    <Text style={styles.label}>Telefono</Text>
                    <Text style={styles.phone}>{infoUser.telefono}</Text>
                </View>
            </View>

            <TouchableOpacity style={styles.btnChange} onPress={()=>{
                setModalEditUser(true)
                setNombre(infoUser.nombre);
                setApellido(infoUser.apellido);
                setTelefono(infoUser.telefono);
                setCorreo(infoUser.correo);
            }}>
                <View style={styles.row}>
                    <Icon name='edit' color={colors.verde_primary}></Icon>
                    <Text style={[styles.labelChange,{color:colors.verde_primary}]}>Editar usuario</Text>
                </View>
            </TouchableOpacity>

            <TouchableOpacity style={styles.btnChange} onPress={()=>setModalPassword(true)}>
                <View style={styles.row}>
                    <Icon name='lock-open' color={colors.rojo}></Icon>
                    <Text style={[styles.labelChange,{color:colors.rojo}]}>Cambiar contraseña</Text>
                </View>
            </TouchableOpacity>

            <ModalOperacionAdmin
                visible={modalVisible}
                title='Aviso'
                subtitle='Ingrese sus credenciales para confirmar la operación'
                onCancel={() => {
                    setModalVisible(false) 
                }}
                onConfirm={handleConfirmModal}
            />

            <Modal
                animationType="fade"
                transparent={true}
                visible={modalPassword}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>

                        <Text style={styles.modalTitle}>Cambiar contraseña</Text>
                        <View style={{borderBottomWidth: 1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>

                        <View style={styles.inputContainer}>
                            <View>
                                <Text style={styles.labelInput}>Contraseña actual*</Text>
                                <TextInput
                                    style={[styles.input,errorPassword.item1.value?{borderColor:colors.rojo}:{}]}
                                    value={contraActual}
                                    onChangeText={(text)=>{
                                        setContraActual(text)
                                        setError('item1',false, '', setErrorPassword)
                                    }}
                                    secureTextEntry
                                />
                                {
                                    errorPassword.item1.value?<Text style={styles.lblError}>{errorPassword.item1.text}</Text>:null
                                }
                            </View>
                            <View>
                                <Text style={styles.labelInput}>Nueva contraseña</Text>
                                <TextInput
                                    style={[styles.input,errorPassword.item2.value?{borderColor:colors.rojo}:{}]}
                                    value={contraNueva}
                                    onChangeText={(text)=>{
                                        setContraNueva(text)
                                        setError('item2',false, '', setErrorPassword)
                                    }}
                                    secureTextEntry
                                />
                                {
                                    errorPassword.item2.value?<Text style={styles.lblError}>{errorPassword.item2.text}</Text>:null
                                }
                            </View>
                            <View>
                                <Text style={styles.labelInput}>Confirmar nueva contraseña</Text>
                                <TextInput
                                    style={[styles.input,errorPassword.item3.value?{borderColor:colors.rojo}:{}]}
                                    value={contraNuevaRep}
                                    onChangeText={(text)=>{
                                        setContraNuevaRep(text)
                                        setError('item3',false, '', setErrorPassword)
                                    }}
                                    secureTextEntry
                                />
                                {
                                    errorPassword.item3.value?<Text style={styles.lblError}>{errorPassword.item3.text}</Text>:null
                                }
                            </View>
                        </View>

                        <View style={styles.buttonsContainer}>

                            <TouchableOpacity
                                style={[styles.button, styles.buttonCancel]}
                                onPress={()=>{
                                    setModalPassword(false)
                                    setErrorPassword(error)
                                }}
                            >
                                <Text style={[styles.textButton,{color:colors.negro, fontWeight:'400'}]}>Cancelar</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[styles.button, styles.buttonConfirm]}
                                onPress={()=>changePassword()}
                            >
                                <Text style={styles.textButton}>Confirmar</Text>
                            </TouchableOpacity>

                        </View>

                    </View>
                </View>
            </Modal>

            <Modal
                animationType="fade"
                transparent={true}
                visible={modalEditUser}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>

                        <Text style={styles.modalTitle}>Editar Usuario</Text>
                        <View style={{borderBottomWidth: 1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>

                        <View style={styles.inputContainer}>
                            <View>
                                <Text style={styles.labelInput}>Nombres</Text>
                                <TextInput
                                    style={[styles.input,errorEdit.name.value?{borderColor:colors.rojo}:{}]}
                                    value={nombre}
                                    onChangeText={(text)=>{
                                        setNombre(text)
                                        setError('name',false, '',setErrorEdit)
                                    }}
                                />
                                {
                                    errorEdit.name.value?<Text style={styles.lblError}>{errorEdit.name.text}</Text>:null
                                }
                            </View>
                            <View>
                                <Text style={styles.labelInput}>Apellidos</Text>
                                <TextInput
                                    style={[styles.input,errorEdit.lastName.value?{borderColor:colors.rojo}:{}]}
                                    value={apellido}
                                    onChangeText={(text)=>{
                                        setApellido(text)
                                        setError('lastName',false, '',setErrorEdit)
                                    }}
                                />
                                {
                                    errorEdit.lastName.value?<Text style={styles.lblError}>{errorEdit.lastName.text}</Text>:null
                                }
                            </View>
                            <View>
                                <Text style={styles.labelInput}>Correo</Text>
                                <TextInput
                                    style={[styles.input,errorEdit.email.value?{borderColor:colors.rojo}:{}]}
                                    value={correo}
                                    onChangeText={(text)=>{
                                        setCorreo(text)
                                        setError('email',false, '',setErrorEdit)
                                    }}
                                />
                                {
                                    errorEdit.email.value?<Text style={styles.lblError}>{errorEdit.email.text}</Text>:null
                                }
                            </View>
                            <View>
                                <Text style={styles.labelInput}>Telefono</Text>
                                <TextInput
                                    style={[styles.input,errorEdit.phone.value?{borderColor:colors.rojo}:{}]}
                                    value={telefono}
                                    onChangeText={(text)=>{
                                        setTelefono(text)
                                        setError('phone',false, '',setErrorEdit)
                                    }}
                                />
                                {
                                    errorEdit.phone.value?<Text style={styles.lblError}>{errorEdit.phone.text}</Text>:null
                                }
                            </View>
                        </View>

                        <View style={styles.buttonsContainer}>

                            <TouchableOpacity
                                style={[styles.button, styles.buttonCancel]}
                                onPress={()=>{
                                    setModalEditUser(false)
                                    setErrorEdit(errorEdit_)
                                }}
                            >
                                <Text style={[styles.textButton,{color:colors.negro, fontWeight:'400'}]}>Cancelar</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[styles.button, styles.buttonConfirm]}
                                onPress={()=>guardar()}
                            >
                                <Text style={styles.textButton}>Actualizar</Text>
                            </TouchableOpacity>

                        </View>

                    </View>
                </View>
            </Modal>

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex:1,
    },
    ctrImage: {
        display:'flex', 
        backgroundColor:colors.verde_primary,
        paddingVertical:16,
    },
    circleStatus:{
        width:80,
        height:80,
        borderRadius:40,
        alignSelf:'center',
        marginBottom:8,
    },
    name:{
        color:colors.white,
        fontWeight:'bold',
        textAlign:'center'
    },
    phone:{
        color:colors.gris_borde_item_lista,
        fontSize:14,
    },
    label:{
        color:colors.negro,
        fontWeight:'bold',
        fontSize:14,
    },
    mrH:{
        marginHorizontal:'5%',
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    ctrInfo:{
        marginTop:16,
        borderRadius:8,
        backgroundColor:colors.white,
    },
    itemInfo:{
        paddingVertical:16,
        paddingHorizontal:0,
        marginHorizontal:16,
        borderBottomWidth:0.5,
        borderBottomColor:'#C2C2C2',
        justifyContent:'space-between'
    },
    btnChange:{
        marginHorizontal:'5%',
        marginTop:16,
        backgroundColor:colors.white,
        padding:16,
        borderRadius:8,
    },
    labelChange:{
        alignSelf:'center',
        fontSize:14,
        marginLeft:16,
        fontWeight:'bold'
    },
    centeredView: {
        paddingHorizontal:'5%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        position:'relative',
        width:'100%',
        maxWidth:500,
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },

    modalTitle: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18, 
        marginTop:16, 
        paddingHorizontal:16,
    },

    modalSubtitle: {
        fontSize: 16,
        textAlign: 'left',
        paddingHorizontal:16,
        marginTop:16,
    },

    inputContainer: {
        width: '100%',
        paddingHorizontal:16,
        marginTop:16,
        marginBottom: 24,
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginBottom:4,
        marginTop:16,
    },
    input: {
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        paddingHorizontal:16,
    },
    lblError:{
        fontSize:12,
        color:colors.rojo,
        marginTop:2
    },
    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingHorizontal:16,
        width: '100%',
    },

    button: {
        borderRadius: 4,
        paddingVertical: 16,
        paddingHorizontal: 16,
        flexDirection: 'row'
    },

    buttonCancel: {
        backgroundColor: colors.white,
        marginRight: 8,
    },

    buttonConfirm: {
        backgroundColor: colors.verde_primary
    },

    textButton: {
        color: colors.white,
        fontSize: 16,
        fontWeight:'bold'
    }
})
