import {useRoute} from '@react-navigation/native';
import axios from 'axios';
import React, {useRef, useState} from 'react';
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import {FlatList} from 'react-native-gesture-handler';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import ModalOperacionAdmin from '../../../components/ModalOperacionAdmin';
import colors from '../../../constants/colors';
import urlsGenerales from '../../../constants/urlsGenerales';
import {AuthenticationService} from "../../../shared/AuthenticationService";
import ModalCalendar from "../../../components/ModalCalendar";
import {DateUtils} from "../../../shared/DateUtils";
import ModalMensaje from "../../../components/ModalMensaje";
import CustomAlert from '../../../shared/CustomAlert';
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';
import moment from 'moment';

export default function BorrarAbonoPagos() {

    const navigation = useNavigation();

    const [loading, setLoading] = useState(false);
    
    const [subtitle, setSubtitle] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMensaje, setModalMensaje] = useState(false);
    const route = useRoute();

    // @ts-ignore
    const cobrador = route.params!.cobrador;

    const [abonos, setAbonos] = useState([]);
    const [codAbono, setCodAbono] = useState('');
    const [fecha, setFecha] = useState(DateUtils.getDateStringYYYYMMDD());
    const fechaRef = useRef(null);
    const [mostrarCalendario, setMostrarCalendario] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [first, setFirst] = useState(true)

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const consultar = async () => {
        if (fecha === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese la fecha a consultar'
            });
            return;
        }

        try {
            setLoading(true);
            const formData = new FormData();
            const token = await StorageUtil.getTokenStorage();
            formData.append('codUsuario', cobrador.codUsuarioHijo);
            formData.append('fecha1', fecha);
            formData.append('fecha2', fecha);

            const response = await axios.post(urlsGenerales.LISTAR_ABONOS_POR_USUARIO_FECHA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            setAbonos(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.LISTAR_ABONOS_POR_USUARIO_FECHA);
            console.log(error);
            setAbonos([]);

        } finally {
            setLoading(false);
            setFirst(false)
        }
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {

        setModalVisible(false);

        setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            setModalVisible(false);
            CustomAlert.errorCredenciales();
            setLoading(false);
            return;
        }
        setLoading(false);

        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo);
            formData.append('codDetalleCaja', codAbono);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.BORRAR_ABONO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.BORRAR_ABONO);
            console.log(error);

        } finally {
            setLoading(false);
            CustomAlert.show({
                title: 'Aviso',
                message: 'Abono eliminado con éxito',
                buttons : [
                    {
                        text: 'OK',
                        onPress: () => {
                            consultar();
                        },
                    },
                ]
            });
        }
    };

    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 14, marginVertical: 20, textAlign:'center', fontWeight:'400'}}>{searchText!=''?'No se encontraron resultados':"No hay abonos registrados"}</Text>
        );
    };

    const eliminarAbono = (codDetalleCaja: any) => {
        setCodAbono(codDetalleCaja);
        setModalVisible(true);
    }

    const renderItem = ({item}) => {
        const handlePress = () => {

            const fechaformat = new Date();
            const fechaString = fechaformat.getFullYear()+"-"+String(fechaformat.getMonth() + 1).padStart(2, '0')+"-"+String(fechaformat.getDate()).padStart(2, '0');
            const fecha1 = new Date(item.timestamp.substring(0,10));
            const fecha2 = new Date(fechaString);         
            
            if(fecha1 >= fecha2){
                eliminarAbono(item.codPagos);
            }else{
                setSubtitle("Este pago fue creado en una fecha anterior a la de hoy, prohibido su borrado.")
                setModalMensaje(true);
            }
            

        }

        return (
            <TouchableOpacity
                style={styles.crtItem}
                onLongPress={handlePress}>
                <View style={[styles.row,{justifyContent:'space-between'}]}>
                    <View style={{flex:1}}>
                        <Text style={{fontSize: 12,}}>
                            #{item.codCredito}
                        </Text>
                        <Text style={{fontSize: 14,fontWeight: 'bold'}}>
                            {item.nombre} {item.apellido}
                        </Text>

                    </View>
                    <View style={{alignSelf:'center'}}>
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>
                            S/ {item.monto}
                        </Text>
                    </View>
                </View>

            </TouchableOpacity>
        );
    }

    const handleFechaDesde = (fechaDesdeModal: string) => {
        setMostrarCalendario(false);
        setFecha(fechaDesdeModal);
    }

    return (
        <>
        <View style={styles.container}>

            <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({abonos.length}{abonos.length==1?' abono':' abonos'})</Text></Text>

            <Text style={{ fontSize: 12, marginHorizontal:'5%' }}>Listado de pago/abono por fecha.
            </Text>

            <View style={styles.rowDate}>
                <View style={{flex:1}}>
                    <Text style={styles.labelInput}>Fecha</Text>
                    <TextInput
                        style={styles.inputText}
                        placeholder='yyyy-mm-dd'
                        value={fecha}
                        onPressIn={() => setMostrarCalendario(true)}
                        ref={fechaRef}
                        onFocus={() => {
                            setMostrarCalendario(true);
                            // @ts-ignore
                            fechaRef.current.blur();
                        }}
                    >
                    </TextInput>
                </View>
                <TouchableOpacity style={styles.btn} onPress={consultar}>
                    <Icon name='search' color={colors.white}></Icon>
                    <Text style={{
                        color: colors.white,
                        fontWeight: 'bold',
                    }}>Consultar</Text>
                </TouchableOpacity>
            </View>
            
            <ModalCalendar
                visible={mostrarCalendario}
                onPress={handleFechaDesde}
                currentDate={moment(fecha).format()}
                onCancel={() => setMostrarCalendario(false)}
            />

            {
                first ? null : 
                <>
                <View style={{marginTop:16}}>
                    <View style={[styles.row,styles.mrg]}>
                        <View style={styles.iconSearch}>
                            <Icon name='search' color={colors.negro}></Icon>
                        </View>
                        <TextInput
                            placeholder='Buscar'
                            style={styles.inputSearch}
                            onChangeText={handleSearch}
                            value={searchText}
                            selectionColor={colors.negro}
                        />
                        {
                        searchText !== '' &&
                            <TouchableOpacity
                                style={styles.btnSearch}
                                onPress={limpiarSearchText}>
                                <Icon name='clear' color={colors.negro}></Icon>
                            </TouchableOpacity>
                        }
                    </View>
                    
                    <View style={[styles.row,{marginHorizontal:'5%', marginTop:8}]}>
                        <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                        <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Manten presionado para eliminar una entrada o salida</Text>
                    </View>
                </View>
                <FlatList
                    data={abonos.filter((item) => (item.nombre+" "+item.apellido).toLowerCase().includes(searchText.toLowerCase()) )}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.codPagos+ " "+item.codCredito}
                    ListEmptyComponent={renderEmpty}
                    contentContainerStyle={{paddingTop:0,paddingBottom:48}}
                    style={{width: '100%', marginTop:24,}}
                    showsVerticalScrollIndicator={false}
                />
                </>
            }
            

            <ModalOperacionAdmin
                visible={modalVisible}
                title='Aviso'
                subtitle='Ingrese sus credenciales para confirmar la operación'
                onCancel={() => setModalVisible(false)}
                onConfirm={handleConfirmModal}
            />

            <ModalMensaje
                visible={modalMensaje}
                title={"No permitido"}
                subtitle={subtitle}
                onCancel={() => setModalMensaje(false)}
                onConfirm={() => setModalMensaje(false)}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
        </>
    )
}

const styles = StyleSheet.create({
    container:{
        flex:1,
    },
    
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
        marginHorizontal:'5%'
    },

    
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    rowDate:{
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:'5%'
    },
    btn:{
        backgroundColor:colors.verde_primary,
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
        height:48,
        alignSelf:'flex-end',
        marginLeft:16,
        alignItems:'center',
        borderRadius:4
    },

    selectTipDoc: {
        margin: 10,
        width: '90%'
    },

    labelButton: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        marginEnd: 8
    },

    // MODAL
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    row:{
        display:'flex',
        flexDirection:'row',
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor: colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },
    mrg:{
        marginHorizontal:'5%'
    },
    crtItem:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    }
})
