import React, {useEffect, useState} from 'react';
import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import {useNavigation, useRoute} from '@react-navigation/native';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {default as COLORS, default as colors} from '../constants/colors';
import routes from '../constants/routes';
import ModalOperacionAdmin from "../components/ModalOperacionAdmin";
import {AuthenticationService} from "../shared/AuthenticationService";
import {CajaService} from "../shared/CajaService";
import {StorageUtil} from "../shared/StorageUtil";
import CustomAlert from '../shared/CustomAlert';
import urlsGenerales from '../constants/urlsGenerales';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function RecaudoDelDia() {

    const route = useRoute();
    const navigation = useNavigation();
    const {pagosHoy} = route.params;
    const [modalVisible, setModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [codAbono, setCodAbono] = useState('');
    const [codCuota, setCodCuota] = useState('');
    const [codCredito, setCodCredito] = useState('');
    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const [dateNow, setDate] = React.useState(new Date());
    const [cantRecaudoDia, setCantRecaudoDia] = React.useState(0);
    const [sumRecaudoDia, setSumRecaudoDia] = React.useState(0);

    const [loading, setLoading] = React.useState(false);

    const getDateString = () => {
        return dateNow.getFullYear() + '-' + String(dateNow.getMonth() + 1).padStart(2, '0')
            + '-' + String(dateNow.getDate()).padStart(2, '0')
    }

    const validarCajaAbierta = async () => {
        await setLoading(true)
        const codUsuario = await StorageUtil.getCodUsuarioStorage();
        const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuario!, navigation);
        await setLoading(false)
        return estadoCajaValida;
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {

        await setModalVisible(false);

        setTimeout(async() => {
            await setLoading(true);
            const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
            if (!validCredentials) {
                CustomAlert.errorCredenciales();
                setLoading(false);
                return;
            }

            handleEliminarAbono(usuario, clave);
        }, 300);
    }

    const handleEliminarAbono = async (usuario: string, clave: string) => {
        try {
            await setLoading(true);

            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('codDetalleCaja', codAbono);
            formData.append('usu', usuario);
            formData.append('pass', clave);
            formData.append('codCuota',codCuota);
            formData.append('codCredito', codCredito);

            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ELIMINAR_PAGO_V_FINAL, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                if(response.data.mensaje == "ok"){
                    CustomAlert.show({
                        title: 'Aviso',
                        message: 'Abono eliminado con éxito',
                        buttons: [
                            {
                                text: 'OK',
                                onPress: () => {
                                    navigation.goBack();
                                },
                            },
                        ]
                    });
                    await AsyncStorage.setItem('recargar_home', "si");
                    await AsyncStorage.setItem('recargar_detalle_credito', "si");
                }else{
                    CustomAlert.show({
                        title: 'Aviso',
                        message: response.data.mensaje
                    });
                }
            }
        } catch (e) {
            console.log('Error en -> ', urlsGenerales.ELIMINAR_PAGO_V_FINAL);
            console.log(e);
            await setLoading(false);
        } finally {
            await setLoading(false);
        }
    }

    const renderItem = ({item}) => {

        const handlePress = () => {
            navigation.navigate(routes.CREDITOS, {
                screen: routes.VER_CREDITO,
                params: {codCredito: item.codCredito},
                initial: false
            });
        }

        const eliminarAbono = async (codDetalleCaja: any, codCuo: any, codCred: any) => {
            const estadoCajaValida = await validarCajaAbierta()
            if(estadoCajaValida != 'redirigir'){
                if (estadoCajaValida) {
                    setCodAbono(codDetalleCaja)
                    setCodCuota(codCuo)
                    setCodCredito(codCred)
                    setModalVisible(true)
                } else {
                    CustomAlert.cajaBloq();
                    return;
                }
            }
        }

        return (
            <TouchableOpacity
                style={styles.crtItem}
                onPress={handlePress}
                onLongPress={()=>{eliminarAbono(item.codPagos,item.codCuota,item.codCredito)}}
            >
                
                <View style={{flex:1,}}>
                    <Text style={styles.name}>{item.nombre} {item.apellido}</Text>
                    {/* <Text style={styles.nrodoc}>{item.numDocumento}</Text> */}
                </View>
                <Text style={styles.lblMonto}>S/ {Number(item.monto).toFixed(2)}</Text>

            </TouchableOpacity>
        );
    };

    const renderEmpty = () => {
        return (
                <Text style={{fontSize: 14, marginVertical: 20, textAlign:'center', fontWeight:'400'}}>{searchText!=''?'No se encontraron resultados':'No hay pagos del día :c'}</Text>
        );
    };


    useEffect(() => {
        console.log(pagosHoy)
        setCantRecaudoDia(pagosHoy.length);
        setSumRecaudoDia(pagosHoy.reduce((acumulador, elemento) => {
            return acumulador + parseFloat(elemento.monto);
        }, 0));
    });

    return (
        <>
        <View style={styles.container}>
            <View style={styles.crtSum}>
                <View>
                    <Text style={styles.lblRecaudo}>Total Recaudado</Text>
                    <Text style={styles.sum}>S/ {Number(sumRecaudoDia).toFixed(2)}</Text>
                </View>
                <View style={styles.crtDate}>
                    <Text style={styles.lblDate}>{getDateString()}</Text>
                </View>
            </View>
            <View style={styles.crtList}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({cantRecaudoDia}{cantRecaudoDia==1?' pago':' pagos'})</Text></Text>
            </View>
            <View style={[styles.row]}>
                <View style={styles.iconSearch}>
                    <Icon name='search' color={colors.negro}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    placeholderTextColor={colors.gris_fondo_cell}
                    style={styles.inputSearch}
                    onChangeText={handleSearch}
                    value={searchText}
                    selectionColor={colors.negro}
                />
                {
                searchText !== '' &&
                    <TouchableOpacity
                        style={styles.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.negro}></Icon>
                    </TouchableOpacity>
                }
            </View>

            
            <View style={[styles.row,{marginTop:24}]}>
                <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Manten presionado para eliminar un abono</Text>
            </View>

            <FlatList
                showsVerticalScrollIndicator={false}
                data={pagosHoy.filter((item) => (item.nombre+" "+item.apellido).toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(item, index) => item.nombre + index.toString()}
                ListEmptyComponent={renderEmpty}
                contentContainerStyle={{paddingTop:0,paddingBottom:48}}
                style={{width: '100%', marginTop:24,}}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
        
            <ModalOperacionAdmin
                visible={modalVisible}
                title='Aviso'
                subtitle='Ingrese sus credenciales para confirmar la operación :)'
                onCancel={() => setModalVisible(false)}
                onConfirm={handleConfirmModal}
            />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    crtSum:{
        paddingHorizontal:'5%',
        paddingTop:16,
        paddingBottom:24,
        backgroundColor:colors.white,
        borderBottomLeftRadius:32,
        borderBottomRightRadius:32,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    lblRecaudo:{
        fontSize:14,
        color:colors.verde_primary,
        marginBottom:8
    },
    sum:{
        fontSize:24,
        fontWeight:'bold',
        color:colors.verde_primary,
    },
    crtDate:{
        borderRadius:20,
        paddingHorizontal:16,
        height:40,
        borderWidth:1,
        borderColor:colors.verde_primary,
        alignSelf:'center',
        display:'flex',
        justifyContent:'center'
    },
    lblDate:{
        color:colors.verde_primary,
        alignSelf:'center',
        fontWeight:'bold',
        fontSize:12,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
    },
    crtList:{
        paddingVertical:16,
        paddingHorizontal:'5%'
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontWeight:'300',
        fontSize:14,
    },
    lblMonto:{
        fontSize:16,
        fontWeight:'bold'
    },
    row: {
        display:'flex',
        flexDirection: 'row',
        marginHorizontal:'5%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.20,
        shadowRadius: 2,
        elevation: 4,
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor:colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },

    tableHead: {
        backgroundColor: COLORS.verde_primary,
        width: '95%',
    },

    cell: {
        flex: 1
    },

    cellHeader: {
        padding: 10,
        color: COLORS.white,
        fontWeight: 'bold',
        fontSize: 15,
        borderColor: COLORS.logo_gris,
        borderWidth: 0.5
    },

    cellRow: {
        padding: 10,
        fontSize: 15,
        borderColor: COLORS.logo_gris,
        borderWidth: 0.5
    },

    titulo: {
        marginVertical: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },

    labelTitulo: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlignVertical: 'center',
        marginHorizontal: 10
    },
});
