import React, {useEffect, useRef} from 'react';
import {
    Modal,
    ScrollView,
    Platform,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from 'react-native';
import {Icon} from 'react-native-elements';

import axios from 'axios';
import {Calendar} from 'react-native-calendars';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../constants/colors';
import stylesGenerales from '../constants/stylesGenrales';
import urlsGenerales from '../constants/urlsGenerales';
import {StorageUtil} from '../shared/StorageUtil';
import {textoSpinner} from '../shared/TiposDocumento';
import {DateUtils} from "../shared/DateUtils";
import CustomAlert from '../shared/CustomAlert';
import { useNavigation } from '@react-navigation/native';
import routes from '../constants/routes';

export default function CreditosPorFecha() {

    const navigation = useNavigation();

    const [creditos, setCreditos] = React.useState([]);
    const [totalCreditosPorFecha, setTotalCreditosPorFecha] = React.useState(0);
    const [cantCreditosPorFecha, setCantCreditosPorFecha] = React.useState(0);

    const [fechaDesde, setFechaDesde] = React.useState(DateUtils.getDateStringYYYYMMDD());
    const [fechaHasta, setFechaHasta] = React.useState(DateUtils.getDateStringYYYYMMDD());

    const [loading, setLoading] = React.useState(false);

    const fechaDesdeRef = useRef(null);
    const fechaHastaRef = useRef(null);

    const showSpinner = () => {
        setLoading(true);
    };

    const hideSpinner = () => {
        setLoading(false);
    };

    const [mostrarFilaVacia, setMostrarFilaVacia] = React.useState(true);

    const verCreditosPorFecha = async () => {

        if (fechaDesde.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese fecha desde'
            });
            return;
        }

        if (fechaHasta.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese fecha hasta'
            });
            return;
        }

        showSpinner();
        const formData = new FormData();
        const codUsuario = await StorageUtil.getCodUsuarioStorage();
        formData.append('codUsuario', codUsuario!);
        formData.append('fecha1', fechaDesde);
        formData.append('fecha2', fechaHasta);

        try {
            const token = await StorageUtil.getTokenStorage(); 
            const response = await axios.post(urlsGenerales.LISTAR_CREDITOS_POR_FECHA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }
            setCreditos(response.data);
            setMostrarFilaVacia(false);

        } catch (error) {
            console.log('Se cae en error');
            console.error(error);

            setCreditos([]);
            setCantCreditosPorFecha(0);
            setTotalCreditosPorFecha(0);
            setMostrarFilaVacia(true);
            hideSpinner();

        } finally {
            hideSpinner();
        }
    }

    useEffect(() => {
        setCantCreditosPorFecha(creditos.length);
        setTotalCreditosPorFecha(creditos.reduce((acumulador, objeto) => {
            return acumulador + parseFloat(objeto.valorCredito);
        }, 0));
    });

    const listarCreditos = () => {
        if (creditos.length === 0) {
            return (
                <View style={stylesGenerales.row}>
                    <View style={stylesGenerales.cell}>
                        <Text style={stylesGenerales.textCellBody}>
                            -
                        </Text>
                    </View>
                    <View style={stylesGenerales.cell}>
                        <Text style={stylesGenerales.textCellBody}>
                            -
                        </Text>
                    </View>
                </View>
            )
        } else {
            return creditos.map((item) => (
                <View
                    key={item.codCredito}
                    style={{
                        flexDirection: 'row',
                        width: '90%'
                    }}>
                    <Text style={{
                        flex: 1,
                        backgroundColor: colors.gris_fondo_cell,
                        padding: 10,
                        borderWidth: 0.5,
                        borderColor: colors.logo_gris
                    }}>{item.nombre} {item.apellido}</Text>
                    <Text style={{
                        flex: 1,
                        backgroundColor: colors.gris_fondo_cell,
                        padding: 10,
                        borderWidth: 0.5,
                        borderColor: colors.logo_gris
                    }}>{item.valorCredito}</Text>
                </View>
            ))
        }
    }

    // Lógica para los modals de fecha
    const [mostrarFecha1, setMostrarFecha1] = React.useState(false);
    const [mostrarFecha2, setMostrarFecha2] = React.useState(false);

    return (
        <ScrollView contentContainerStyle={styles.container}
                    showsHorizontalScrollIndicator={false}>

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />

            <Text style={styles.titleListadoCreditos}>
                Créditos por Fecha
            </Text>

            <Text style={stylesGenerales.labelInput}>
                Desde
            </Text>

            <TextInput style={stylesGenerales.inputSearch}
                        placeholder='aaaa-mm-dd'
                        value={fechaDesde}
                        onPressIn={() => setMostrarFecha1(true)}
                        ref={fechaDesdeRef}
                        onFocus={() => {
                            setMostrarFecha1(true);
                            // @ts-ignore
                            fechaDesdeRef.current.blur();
                        }}
            >
            </TextInput>

            <Modal
                animationType="fade"
                transparent={true}
                visible={mostrarFecha1}
            >
                <TouchableWithoutFeedback onPress={() => setMostrarFecha1(false)}>
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            <Calendar
                                current={Date()}
                                onDayPress={day => {
                                    setFechaDesde(day.dateString);
                                    setMostrarFecha1(false);
                                }}
                                monthFormat={'yyyy MM'}
                                onMonthChange={month => {
                                    console.log('month changed', month);
                                }}
                                hideArrows={false}
                                hideExtraDays={true}
                                disableMonthChange={true}
                                firstDay={1}
                            />
                            { Platform.OS === 'web' && 
                                <style>
                                    {
                                    `.css-9pa8cd {
                                            position: relative;
                                        }`
                                    }
                                </style>
                            }
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>

            <Text style={stylesGenerales.labelInput}>
                Hasta
            </Text>
            <TextInput style={stylesGenerales.inputSearch}
                        placeholder='aaaa-mm-dd'
                        value={fechaHasta}
                        onPressIn={() => setMostrarFecha2(true)}
                        ref={fechaHastaRef}
                        onFocus={() => {
                            setMostrarFecha2(true);
                            // @ts-ignore
                            fechaHastaRef.current.blur();
                        }}
            ></TextInput>

            <Modal
                animationType="fade"
                transparent={true}
                visible={mostrarFecha2}
            >
                <TouchableWithoutFeedback onPress={() => setMostrarFecha2(false)}>
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            <Calendar
                                current={Date()}
                                minDate={fechaDesde}
                                onDayPress={day => {
                                    setFechaHasta(day.dateString);
                                    setMostrarFecha2(false);
                                }}
                                monthFormat={'yyyy MM'}
                                onMonthChange={month => {
                                    console.log('month changed', month);
                                }}
                                hideArrows={false}
                                hideExtraDays={true}
                                disableMonthChange={true}
                                firstDay={1}
                            />
                            { Platform.OS === 'web' && 
                                <style>
                                    {
                                    `.css-9pa8cd {
                                            position: relative;
                                        }`
                                    }
                                </style>
                            }
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>

            <TouchableOpacity
                style={stylesGenerales.btn}
                onPress={verCreditosPorFecha}>
                <Icon name='visibility' color={colors.white}></Icon>
                <Text style={[stylesGenerales.textBtn, {paddingLeft: 5}]}>Ver créditos</Text>
            </TouchableOpacity>

            <View style={stylesGenerales.row}>
                <View style={[stylesGenerales.cell, {borderTopLeftRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopLeftRadius: 5}]}>
                        Cantidad
                    </Text>
                </View>
                <View style={[stylesGenerales.cell, {borderTopRightRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopRightRadius: 5}]}>
                        Total
                    </Text>
                </View>
            </View>

            <View style={[stylesGenerales.row, {marginBottom: 15}]}>
                <View style={stylesGenerales.cell}>
                    <Text style={stylesGenerales.textCellBody}>
                        {cantCreditosPorFecha}
                    </Text>
                </View>
                <View style={stylesGenerales.cell}>
                    <Text style={stylesGenerales.textCellBody}>
                        S/ {totalCreditosPorFecha}
                    </Text>
                </View>
            </View>

            <Text style={[stylesGenerales.labelInput, {marginBottom: 5}]}>Listado de Créditos</Text>

            <View style={stylesGenerales.row}>
                <View style={[stylesGenerales.cell, {borderTopLeftRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopLeftRadius: 5}]}>
                        Nombre
                    </Text>
                </View>
                <View style={[stylesGenerales.cell, {borderTopRightRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopRightRadius: 5}]}>
                        Crédito
                    </Text>
                </View>
            </View>

            {listarCreditos()}

            <View style={{marginVertical: '4%'}}></View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        alignItems: 'center'
    },

    titleListadoCreditos: {
        fontSize: 30,
        fontWeight: 'bold',
        width: '90%',
        marginVertical: 20
    },

    /// MODAL ///
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        paddingBottom: 15,
        width: '70%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 5,
    }
})
