import React, { useState, useEffect, useRef } from 'react';
import {useNavigation} from '@react-navigation/native';
import { Text, View, TouchableOpacity, Animated, Easing } from 'react-native';
import colors from '../constants/colors';

const App = () => {
  const playerYval = useRef(new Animated.Value(0)).current;
  const objXval = useRef(new Animated.Value(0)).current;
  const bgXval = useRef(new Animated.Value(0)).current;
  const intervalRef = useRef(null);
  const animationRefSecond = useRef(null);
  const animationRefBG = useRef(null);
  const animationRefStart = useRef(null);

  const [status, setStatus] = useState('Start');
  const [objWidth, setObjWidth] = useState(40);
  const [trees, setTrees] = useState('🌲           🌴          🌳');
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const navigation = useNavigation();

  const jump = () => {
    Animated.timing(playerYval, {
      toValue: -150,
      duration: 320,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();

    setTimeout(() => {
      Animated.timing(playerYval, {
        toValue: 0,
        duration: 300,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start();
    }, 200);
  };

  const start = () => {
    // setTimeout(() => {
        animationRefStart.current = Animated.loop(
            Animated.timing(objXval, {
                toValue: -450,
                duration: score > 100 ? 900 : 1300,
                easing: Easing.linear,
                useNativeDriver: false,
            })
        )

        animationRefBG.current = Animated.loop(
          Animated.timing(bgXval, {
            toValue: -500,
            duration: score > 100 ? 2400 : 3000,
            easing: Easing.linear,
            useNativeDriver: false,
          })
        )

        animationRefStart.current.start(() => {
            const objArray = [40, 50, 30, 20];
            const obj = objArray[Math.floor(Math.random() * objArray.length)];
            setObjWidth(obj);
            secondRun();
            secondBackgroundRun();
        });

        animationRefBG.current.start(() => {
          secondBackgroundRun();
        });

    // },1000)

    checkStatus();
    countScore();
  };

  const secondRun = () => {
    objXval.setValue(0);
    const objArray = [40, 50, 60, 30, 20];
    const obj = objArray[Math.floor(Math.random() * objArray.length)];
    setObjWidth(obj);
  };

  const secondBackgroundRun = () => {
    bgXval.setValue(0);
    const objArray = [
    '🌲           🌴☁          🌳',
    '🌲     🌲🌲    ☁    🌳',
    '🌴',
    '🌳      🌳🌴  ☁    🌴',
    '🌹🌹',
    '☁ ☁',
    ];
    const obj = objArray[Math.floor(Math.random() * objArray.length)];
    setTrees(obj);
  };

  const checkStatus = () => {
    objXval.addListener(({ value }) => {
      if (objWidth > 0) {
        if (playerYval._value > -40 && value >= -400 && value <= -330) {
          setStatus('crashed');
          animationRefSecond.current?.reset();
          animationRefBG.current?.reset();
          animationRefStart.current?.reset();
          clearInterval(intervalRef?.current);
          if (score > highScore) {
            setHighScore(score);
          }
        }
      }
    });
  };

  const countScore = () => {
    intervalRef.current = setInterval(() => {
      if (status === 'normal') {
        setScore((prevScore) => prevScore + 1);
      }
    }, 500);
  };

  useEffect(() => {
    if (status === 'normal') {
      start();
    }else if(status === 'crashed'){
        objXval.removeAllListeners()
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (animationRefSecond.current) {
        animationRefSecond.current.stop();
      }
      if (animationRefBG.current) {
        animationRefBG.current.stop();
      }
      if (animationRefStart.current) {
        animationRefStart.current.stop();
      }
      if(objXval){
        objXval.removeAllListeners()
      }
    };
  }, [status]);

  if (status === 'normal') {
    return (
      <View style={{ flex: 1, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }} onTouchStart={jump}>
        <Animated.View
          style={{
            height: 40,
            width: 40,
            backgroundColor: '#000',
            position: 'absolute',
            left: '10%',
            transform: [{ translateY: playerYval }],
            bottom: '40%',
          }}
        />
        <Animated.View
          style={{
            height: 38,
            width: objWidth - 2,
            borderWidth: objWidth > 0 ? 2 : 0,
            borderColor: 'black',
            backgroundColor: '#fff',
            position: 'absolute',
            left: '110%',
            transform: [{ translateX: objXval }],
            bottom: '40%',
          }}
        />
        <View style={{ height: 2, width: '95%', backgroundColor: 'black', alignSelf: 'center', bottom: '40%', position: 'absolute' }} />
        <Animated.Text
          style={{
            fontSize: 25,
            opacity: 0.4,
            zIndex: -10,
            left: '90%',
            position: 'absolute',
            bottom: '40%',
            transform: [{ translateX: bgXval }],
          }}
        >
          {trees}
        </Animated.Text>
        <Text style={{ position: 'absolute', top: 50, right: 30, fontSize: 18 }}>Puntaje: {score}</Text>
      </View>
    );
  } else if (status === 'crashed') {
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <Text style={{ textAlign: 'center',marginBottom:24 }}>Hiciste <Text style={{fontWeight:'bold'}}>{score}</Text> puntos</Text>
        <TouchableOpacity
          onPress={() => {
            setTimeout(() => {
              objXval.setValue(0);
              playerYval.setValue(0);
              setScore(0);
              setStatus('normal');
            }, 1000);
          }}
          style={{ alignSelf: 'center', padding: 16, backgroundColor: colors.logo_naranja, borderRadius:4, borderRadius:4, width:'95%' }}
        >
          <Text style={{ color: 'white', textAlign:'center', fontWeight:'bold'}}>Intentar de nuevo</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigation.replace('Login')
          }}
          style={{ alignSelf: 'center', padding: 16, backgroundColor: colors.verde_primary, marginTop: 16, borderRadius:4, width:'95%' }}
        >
          <Text style={{ color: 'white', textAlign:'center', fontWeight:'bold' }}>Continuar</Text>
        </TouchableOpacity>
      </View>
    );
  } else {
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <TouchableOpacity
          onPress={() => {
            setTimeout(() => {
              objXval.setValue(0);
              playerYval.setValue(0);
              setScore(0);
              setStatus('normal');
            }, 1000);
          }}
          style={{ alignSelf: 'center', padding: 16, backgroundColor: colors.verde_primary,  borderRadius:4, width:'95%' }}
        >
          <Text style={{ color: 'white', textAlign:'center', fontWeight:'bold' }}>Comenzar</Text>
        </TouchableOpacity>
      </View>
    );
  }
};

export default App;