import { Alert, Platform } from 'react-native';

interface CustomAlertOptions {
  title?: string;
  message: string;
  buttons?: { text: string; onPress?: () => void; style?: 'default' | 'cancel' | 'destructive' | undefined; }[];
}

class CustomAlertClass {
  show = (options: CustomAlertOptions) => {
    const { title = 'Aviso', message, buttons = []} = options;

    if (Platform.OS === 'web') {
      if (buttons.length > 1) {
        const buttonCallbacks = buttons.map((btn) => btn.onPress);
        const result = window.confirm(message);
        if (result) {
          if (buttonCallbacks[0]) buttonCallbacks[0]();
        } else {
          if (buttonCallbacks[1]) buttonCallbacks[1]();
        }
      } else {
        window.alert(message);
        if(buttons.length > 0){
            const buttonCallbacks = buttons.map((btn) => btn.onPress);
            buttonCallbacks[0](); 
        }
        

      }
    } else {
      const alertButtons = buttons.map((btn) => {
        const { text, onPress } = btn;
        return {
          text,
          onPress: () => {
            if (onPress) onPress();
          },
        };
      });

      if(alertButtons.length === 0){
        Alert.alert(title, message)  
      }else{
        Alert.alert(title, message, alertButtons);
      }
    }
  };
  cajaBloq = () => {
    if (Platform.OS === 'web') {
      window.alert('La caja se encuentra bloqueada.');
    }else{
      Alert.alert('Mensaje', 'La caja se encuentra bloqueada.');
    }
  };
  errorCredenciales = () => {
    if (Platform.OS === 'web') {
      window.alert('Credenciales no válidas');
    }else{
      Alert.alert('Error', 'Credenciales no válidas');
    }
  };
}

const CustomAlert = new CustomAlertClass();
export default CustomAlert;
