import React, {useState} from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View, Modal} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../../constants/colors';
import {useNavigation, useRoute} from '@react-navigation/native';
import axios from 'axios';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import ModalOperacionAdmin from '../../components/ModalOperacionAdmin';
import {SelectList} from 'react-native-dropdown-select-list';
import urlsGenerales from '../../constants/urlsGenerales';
import routes from '../../constants/routes';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ModalCalendar from "../../components/ModalCalendar";
import {AuthenticationService} from "../../shared/AuthenticationService";
import CustomAlert from '../../shared/CustomAlert';
import { StorageUtil } from '../../shared/StorageUtil';
import { color } from 'react-native-elements/dist/helpers';

const error = {
    item2:{value:false,text:''},
    item3:{value:false,text:''}
}
const tiposCuenta = [
    {key: '0', value: 'Permanente'},
    {key: '1', value: 'Suscripción'},
    {key: '2', value: 'Demo'}
];

export default function OpcionesCuenta() {

    const route = useRoute();
    const navigation = useNavigation();

    const {cobrador} = route.params;

    const [clave,setClave] = useState(cobrador.contraHijo);
    const [verMostrar, setVerMostrar] = useState(true);
    const [verOcultar, setVerOcultar] = useState(false);
    const [nombre, setNombre] = useState(cobrador.nombreHijo);
    const [apellido, setApellido] = useState(cobrador.apellidoHijo);
    const [fono, setFono] = useState(cobrador.telefonoHijo);
    const [correo, setCorreo] = useState(cobrador.correoHijo);
    const [privilegio] = useState(cobrador.privilegioHijo);
    const [cantAdmins, setCantAdmins] = useState(cobrador.cantAdminsHijo);
    const [cantUsus, setCantUsus] = useState(cobrador.cantUsusHijo);
    const [codTipoCuenta, setCodTipoCuenta] = useState(cobrador.codTipoCuentaHijo);
    const [diaPago, setDiaPago] = useState(cobrador.diaPagoHijo);
    const [fecVencimiento, setFecVencimiento] = useState(cobrador.fechaVencimientoHijo);
    const [mostrarFecha, setMostrarFecha] = useState(false);
    const [contraNueva, setContraNueva] = useState('');
    const [contraNuevaRep, setContraNuevaRep] = useState('');
    const [errorPassword,setErrorPassword] = useState(error)
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalPassword, setModalPassword] = useState(false)
    const [operacion, setOperacion] = useState('');

    const onTipoCuenta = (tipoCuenta) => {
        setCodTipoCuenta(tipoCuenta);
        switch (tipoCuenta) {
            case '0':
                setDiaPago(0);
                setFecVencimiento('2999-01-01');
                break;
            case '1':
                setDiaPago(cobrador.diaPagoHijo);
                setFecVencimiento('2999-01-01');
                break;
            case '2':
                setDiaPago(0);
                setFecVencimiento(cobrador.fechaVencimientoHijo);
                break;
            default:
                setDiaPago(0);
                setFecVencimiento('2999-01-01');
                break;
        }
    };

    const guardar = () => {
        setOperacion('INFO');
        setModalVisible(true);
    }

    const changePassword = async () => {

        if(contraNueva =='' || contraNuevaRep ==''){
            if(contraNueva==''){
                setError('item2',true, 'Campo requerido', setErrorPassword)
            }
            if(contraNuevaRep==''){
                setError('item3',true, 'Campo requerido', setErrorPassword)
            }
            return
        }
        
        if (contraNueva != contraNuevaRep) {
            setError('item3',true, 'Las contraseñas no coinciden', setErrorPassword)
            return;
        }

        await setModalPassword(false)

        await setTimeout(() => {
            setOperacion('CHANGE');
            setModalVisible(true);
        }, 300);
    }

    const setError = (key,value,text, set) => {
        set((prevState) => ({
            ...prevState,
            [key]: {value,text},
        }));
    };

    const siMostrar = async() => {
        setVerMostrar(false);
        setVerOcultar(true);
    }

    const mostrar = () => {
        setOperacion('CONTRA');
        setModalVisible(true);
    }

    const ocultar = () => {
        setVerOcultar(false);
        setVerMostrar(true);
    }

    const obtenerClave = (): string => {
        if (verOcultar) {
            return clave;
        } else {
            return '*'.repeat(clave.length);
        }
    }

    const handleConfirmModal = async (userAdmin: string, passAdmin: string) => {

        await setModalVisible(false);

        await setLoading(true);

        const validCredentials = await AuthenticationService.validarCredenciales(userAdmin, passAdmin);
        if (!validCredentials) {
            await setLoading(false);
            CustomAlert.errorCredenciales();
            return;
        }

        switch (operacion) {
            case 'INFO':
                await doChangeInformation();
                break;
            case 'CHANGE':
                await doChangePassword(userAdmin,passAdmin);
                break;
            case 'CONTRA':
                await setLoading(false)
                await siMostrar();
                break;
            default:
                break;
        }
    };

    // Para realizar el cambio de información
    const doChangeInformation = async () => {
        try {
            const formData = new FormData();

            formData.append('usu', cobrador.idHijo);
            formData.append('pass', clave);

            formData.append('codUsuario', cobrador.codUsuarioHijo);
            formData.append('nombre', nombre);
            formData.append('apellido', apellido);
            formData.append('correo', correo);
            formData.append('telefono', fono);

            const privilegioStorage = await AsyncStorage.getItem('privilegio');
            if (privilegioStorage === 'M') {
                formData.append('cantAdmins', cantAdmins);
                formData.append('cantUsus', cantUsus);
                formData.append('tipoCuenta', codTipoCuenta + "");
                formData.append('diaPago', diaPago + "");
                formData.append('fechaVencimiento', fecVencimiento + "");
            }
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.EDITAR_USUARIO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

                
            await setLoading(false);

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                return;
            }

            if (response.data) {
                await AsyncStorage.setItem('recargar_home_admin', "si");
                await setTimeout(() => {
                    CustomAlert.show({
                        title: 'Aviso',
                        message: 'Se actualizaron los datos'
                    });
                }, 300);
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.EDITAR_USUARIO);
            console.log(error);

        } finally {
            setLoading(false);
        }
    }

    // Para realizar el cambio contraseña
    const doChangePassword = async (v_usu,v_pass) => {
        try {
            const formData = new FormData();
            const token = await StorageUtil.getTokenStorage(); 
            formData.append('codUsuario', cobrador.codUsuarioHijo);
            formData.append('usu', v_usu);
            formData.append('pass', v_pass);
            formData.append('nuevoPass', contraNueva);

            const response = await axios.post(urlsGenerales.CAMBIAR_CLAVE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            await setLoading(false)

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            await setTimeout(async() => {
                if (response.data.mensaje) {
                    await AsyncStorage.setItem('recargar_home_admin', "si");
                    await setClave(contraNueva)
                    CustomAlert.show({
                        title: 'Aviso',
                        message: response.data.mensaje
                    });
                    setTimeout(() => {
                        setContraNueva('')
                        setContraNuevaRep('')
                    }, 300);
                }else{
                    CustomAlert.show({
                        title: 'Error',
                        message: "No se pudo actualizar la contraseña."
                    });
                }
            }, 300);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.CAMBIAR_CLAVE);
            console.log(error);

        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <ScrollView 
                contentContainerStyle={styles.container}
                style={{paddingBottom:48}}
                showsHorizontalScrollIndicator={false}
            >

            <Text style={[styles.mrH, styles.title]}>Cuenta</Text>
            <View style={[styles.mrH, styles.ctrInfo]}>
                <View style={[styles.row,styles.itemInfo]}>
                    <Text style={styles.label}>Estado</Text>
                    <Text style={styles.phone}>Activo</Text>
                </View>
                <View style={[styles.row,styles.itemInfo]}>
                    <Text style={styles.label}>Usuario</Text>
                    <Text style={styles.phone}>{cobrador.idHijo}</Text>
                </View>
                <View style={[styles.row,styles.itemInfo,{borderBottomWidth:0}]}>
                    <Text style={styles.label}>Contraseña</Text>
                    <Text style={styles.phone}>{obtenerClave()}</Text>
                </View>
            </View>

            <TouchableOpacity style={styles.btnChange} onPress={()=>{
                if(verMostrar){
                    mostrar()
                }else{
                    ocultar()
                }
            }}>
                <View style={styles.row}>
                    <Icon name='visibility' color={colors.logo_gris}></Icon>
                    <Text style={[styles.labelChange,{color:colors.logo_gris}]}>{verMostrar?'Mostrar contraseña':'Ocultar contraseña'}</Text>
                </View>
            </TouchableOpacity>

            <Text style={[styles.mrH, styles.title,{marginTop:24}]}>Información de contacto</Text>
            <View style={[styles.mrH, styles.ctrInfo]}>
                <View style={[styles.row,styles.itemInfo]}>
                    <Text style={styles.label}>Nombre</Text>
                    <TextInput
                        style={styles.inputText}
                        value={nombre}
                        onChangeText={setNombre}
                        selectionColor={colors.negro}
                    ></TextInput>
                </View>
                <View style={[styles.row,styles.itemInfo]}>
                    <Text style={styles.label}>Apellido</Text>
                    <TextInput
                        style={styles.inputText}
                        value={apellido}
                        onChangeText={setApellido}
                        selectionColor={colors.negro}
                    ></TextInput>
                </View>
                <View style={[styles.row,styles.itemInfo]}>
                    <Text style={styles.label}>Telefono</Text>
                    <TextInput
                        style={styles.inputText}
                        value={fono}
                        onChangeText={setFono}
                        keyboardType='number-pad'
                        selectionColor={colors.negro}
                    ></TextInput>
                </View>
                <View style={[styles.row,styles.itemInfo,{borderBottomWidth:0}]}>
                    <Text style={styles.label}>Correo</Text>
                    <TextInput
                        style={styles.inputText}
                        value={correo}
                        onChangeText={setCorreo}
                        keyboardType='email-address'
                        selectionColor={colors.negro}
                    ></TextInput>
                </View>
            </View>

            {
                privilegio == "S" ? 
                <View>
                    <Text style={[styles.mrH, styles.title,{marginTop:24}]}>Información de cuenta</Text>
                    <View style={[styles.mrH, styles.ctrInfo]}>
                        <View style={[styles.row,styles.itemInfo]}>
                            <Text style={styles.label}>Administradores</Text>
                            <TextInput
                                style={styles.inputText}
                                value={cantAdmins}
                                onChangeText={setCantAdmins}
                                keyboardType='number-pad'
                                selectionColor={colors.negro}
                            ></TextInput>
                        </View>
                        <View style={[styles.row,styles.itemInfo]}>
                            <Text style={styles.label}>Cobradores</Text>
                            <TextInput
                                style={styles.inputText}
                                value={cantUsus}
                                onChangeText={setCantUsus}
                                keyboardType='number-pad'
                                selectionColor={colors.negro}
                            ></TextInput>
                        </View>
                        <View style={[styles.row,styles.itemInfo, codTipoCuenta!=1 && codTipoCuenta!=2 ?{borderBottomWidth:0} : {}]}>
                            <Text style={styles.label}>Tipo de cuenta</Text>
                            
                        <SelectList
                            data={tiposCuenta}
                            setSelected={onTipoCuenta}
                            boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                            search={false}
                            arrowicon={<Text></Text>}
                            defaultOption={tiposCuenta[codTipoCuenta]}
                            dropdownStyles={{borderWidth:0, backgroundColor:colors.gris_fondo_cell,borderRadius:4}}
                        />
                        </View>
                        {
                            codTipoCuenta == 1 ?
                            <View style={[styles.row,styles.itemInfo, {borderBottomWidth:0}]}>
                                <Text style={styles.label}>Dia de Pago</Text>
                                <TextInput
                                    style={styles.inputText}
                                    value={diaPago}
                                    onChangeText={setDiaPago}
                                    keyboardType='number-pad'
                                    selectionColor={colors.negro}
                                ></TextInput>
                            </View>
                            :
                            null
                        }

                        {
                            codTipoCuenta == 2 ?
                            <View style={[styles.row,styles.itemInfo ,{borderBottomWidth:0}]}>
                                <Text style={styles.label}>Fecha de vencimiento</Text>
                                <TouchableOpacity style={styles.inputText} onPress={()=>setMostrarFecha(true)}>
                                    <Text style={styles.phone_white}>{fecVencimiento}</Text>
                                </TouchableOpacity>
                            </View>
                            :
                            null
                        }
                    </View>
                </View>
                :
                null
            }

            <TouchableOpacity style={styles.btnChange} onPress={()=>{guardar()}}>
                <View style={styles.row}>
                    <Icon name='edit' color={colors.verde_primary}></Icon>
                    <Text style={[styles.labelChange,{color:colors.verde_primary}]}>Actualizar usuario</Text>
                </View>
            </TouchableOpacity>

            <TouchableOpacity style={styles.btnChange} onPress={()=>{setModalPassword(true)}}>
                <View style={styles.row}>
                    <Icon name='lock-open' color={colors.rojo}></Icon>
                    <Text style={[styles.labelChange,{color:colors.rojo}]}>Cambiar contraseña</Text>
                </View>
            </TouchableOpacity>
            <ModalCalendar
                visible={mostrarFecha}
                onPress={(fechaDesdeModal)=>{
                    setMostrarFecha(false);
                    setFecVencimiento(fechaDesdeModal);
                }}
                onCancel={() => setMostrarFecha(false)}
            />
            <ModalOperacionAdmin
                visible={modalVisible}
                title='Aviso'
                subtitle='Ingrese sus credenciales para confirmar la operación'
                onCancel={() => setModalVisible(false)}
                onConfirm={handleConfirmModal}
            />
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalPassword}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>

                        <Text style={styles.modalTitle}>Cambiar contraseña</Text>
                        <View style={{borderBottomWidth: 1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>

                        <View style={styles.inputContainer}>
                            <View>
                                <Text style={styles.labelInput}>Nueva contraseña</Text>
                                <TextInput
                                    style={[styles.input,errorPassword.item2.value?{borderColor:colors.rojo}:{}]}
                                    value={contraNueva}
                                    onChangeText={(text)=>{
                                        setContraNueva(text)
                                        setError('item2',false, '', setErrorPassword)
                                    }}
                                    secureTextEntry
                                />
                                {
                                    errorPassword.item2.value?<Text style={styles.lblError}>{errorPassword.item2.text}</Text>:null
                                }
                            </View>
                            <View>
                                <Text style={styles.labelInput}>Confirmar nueva contraseña</Text>
                                <TextInput
                                    style={[styles.input,errorPassword.item3.value?{borderColor:colors.rojo}:{}]}
                                    value={contraNuevaRep}
                                    onChangeText={(text)=>{
                                        setContraNuevaRep(text)
                                        setError('item3',false, '', setErrorPassword)
                                    }}
                                    secureTextEntry
                                />
                                {
                                    errorPassword.item3.value?<Text style={styles.lblError}>{errorPassword.item3.text}</Text>:null
                                }
                            </View>
                        </View>

                        <View style={styles.buttonsContainer}>

                            <TouchableOpacity
                                style={[styles.button, styles.buttonCancel]}
                                onPress={()=>{
                                    setModalPassword(false)
                                    setErrorPassword(error)
                                }}
                            >
                                <Text style={[styles.textButton,{color:colors.negro, fontWeight:'400'}]}>Cancelar</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[styles.button, styles.buttonConfirm]}
                                onPress={()=>changePassword()}
                            >
                                <Text style={styles.textButton}>Confirmar</Text>
                            </TouchableOpacity>

                        </View>

                    </View>
                </View>
            </Modal>
            

            <Spinner
                visible={loading}
                color={colors.white}
            />

        </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingBottom:48,
    },

    labelDescripcion: {
        width: '90%',
        fontSize: 16
    },

    textNombre: {
        width: '90%',
        paddingHorizontal: '3%',
        paddingVertical: '1%',
        textAlign: 'center',
        backgroundColor: '#0089b7',
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 19,
        marginBottom: '3%',
        marginTop: '4%',
        borderRadius: 8
    },

    labelKey: {
        fontWeight: 'bold',
        fontSize: 16,
        width: '90%'
    },

    labelValue: {
        fontSize: 16,
        marginBottom: '3%'
    },

    labelSubTitle: {
        fontSize: 17,
        fontWeight: 'bold',
        width: '90%'
    },

    selectTipDoc: {
        margin: 10,
        width: '90%'
    },
    //
    title:{
        fontWeight: 'bold',
        fontSize: 17,
        marginLeft:'5%',
        marginTop:16
    },
    mrH:{
        marginHorizontal:'5%',
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    ctrInfo:{
        marginTop:16,
        borderRadius:8,
        backgroundColor:colors.white,
    },
    itemInfo:{
        paddingVertical:16,
        paddingHorizontal:0,
        marginHorizontal:16,
        borderBottomWidth:0.5,
        borderBottomColor:'#C2C2C2',
        justifyContent:'space-between'
    },
    phone:{
        color:colors.gris_borde_item_lista,
        fontSize:14,
        alignSelf:'center'
    },
    phone_white:{
        color:colors.negro,
        fontSize:14,
        alignSelf:'center'
    },
    label:{
        color:colors.negro,
        fontWeight:'600',
        fontSize:14,
        alignSelf:'center'
    },
    btnChange:{
        marginHorizontal:'5%',
        marginTop:16,
        backgroundColor:colors.white,
        padding:16,
        borderRadius:8,
    },
    labelChange:{
        alignSelf:'center',
        fontSize:14,
        marginLeft:16,
        fontWeight:'bold'
    },
    inputText:{
        backgroundColor:colors.gris_fondo_cell,
        padding:4,
        paddingHorizontal:16,
        color:colors.negro,
        borderRadius:4,
    },
    inputContainer: {
        width: '100%',
        paddingHorizontal:16,
        marginTop:16,
        marginBottom: 24,
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginBottom:4,
        marginTop:16,
    },
    input: {
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        paddingHorizontal:16,
    },
    lblError:{
        fontSize:12,
        color:colors.rojo,
        marginTop:2
    },
    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingHorizontal:16,
        width: '100%',
    },

    button: {
        borderRadius: 4,
        paddingVertical: 16,
        paddingHorizontal: 16,
        flexDirection: 'row'
    },

    buttonCancel: {
        backgroundColor: colors.white,
        marginRight: 8,
    },

    buttonConfirm: {
        backgroundColor: colors.verde_primary
    },

    textButton: {
        color: colors.white,
        fontSize: 16,
        fontWeight:'bold'
    },
    centeredView: {
        paddingHorizontal:'5%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalView: {
        position:'relative',
        width:'100%',
        maxWidth:500,
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },
    modalTitle: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18, 
        marginTop:16, 
        paddingHorizontal:16,
    },
})
