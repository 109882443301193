import {useFocusEffect, useNavigation} from '@react-navigation/native';
import React, {useState} from 'react';
import {Image, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../../constants/colors';
import routes from '../../constants/routes';
import stylesAdmin from '../../constants/stylesAdmin';
import stylesGenerales from '../../constants/stylesGenrales';
import axios from 'axios';
import urlsGenerales from '../../constants/urlsGenerales';
import ModalVerProductos from '../../components/ModalVerProductos';
import {FlatList} from 'react-native-gesture-handler';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {textoSpinner} from '../../shared/TiposDocumento';
import {StorageUtil} from '../../shared/StorageUtil';
import { ProductoType } from '../../types/ProductType';

export default function Productos() {

    const navigation = useNavigation();

    const [listDelegados, setListDelegados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [visibleProductos, setVisibleProductos] = useState(false);
    const [productoItem, setProductoItem] = useState<ProductoType>({
        idProducto: '',
        nombre: '',
        detalle: '',
        codSuperAdm: '',
        precio: '',
        imagen: '',
        timestamp: ''
    });

    const handleVerProductos = () => {
        setVisibleProductos(true);
    }

    const handleSearch = (text: string) => {
        setSearchText(text);
    }

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const renderEmpty = () => {
        return (
            <View style={stylesGenerales.emptyList}>
                <Text style={{fontSize: 15, marginVertical: 20}}>No hay productos registrados</Text>
            </View>
        );
    };

    const renderItem = ({item}) => {

        const verProducto = () => {
            setProductoItem(item);
            handleVerProductos();
        }

        
        return (
            <View style={[stylesAdmin.containerBtnList, {marginBottom: '1%', marginLeft: '5%'}]}>
                {/* <TouchableOpacity style={stylesAdmin.btnLargeList} */}
                <TouchableOpacity style={stylesAdmin.btnFullList}
                                  onPress={verProducto}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image
                            style={{ width: 65, height: 75, marginRight: 10 }} 
                            source={{ uri: item.imagen }} 
                        />
                        <View style={{
                                width: '100%'}}>
                            <Text style={{
                                fontSize: 12, fontWeight: 'bold'
                            }}>
                                Id Producto: {item.idProducto}
                            </Text>
                            <Text style={{
                                fontSize: 20, fontWeight: 'bold'
                            }}>
                                {item.nombre}
                            </Text>
                            <Text style={{
                                fontSize: 15
                            }}>
                                {item.detalle + "\nS/" +item.precio }
                            </Text>
                        </View>
                    </View>
                    
                </TouchableOpacity>
            </View>
        );
    };

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                try {
                    const formData = new FormData();
                    const codUsuario = await StorageUtil.getCodUsuarioStorage();
                    const privilegio = await StorageUtil.getPrivilegioStorage();

                    formData.append('codUsuario', codUsuario!);
                    formData.append('privilegio', privilegio!);
                    setLoading(true);
                    const token = await StorageUtil.getTokenStorage();
                    const response = await axios.post(urlsGenerales.LISTAR_PRODUCTOS, formData, {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    setListDelegados(response.data);
                } catch (error) {
                    console.log('Error en -> ', urlsGenerales.LISTAR_PRODUCTOS);
                    console.log(error);
                    setListDelegados([]);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }, [])
    );

    return (
        <View style={styles.container}>
            
            <Text style={{marginBottom: '2%'}}></Text>

            <Text style={stylesAdmin.labelListado}>Listado de Productos</Text>

            <TouchableOpacity style={[styles.botonFooter,
                    {backgroundColor: colors.verde_primary,marginBottom:"2%"}]}
                                  onPress={()=>{}}>
                    <Icon name='add' color={colors.white}></Icon>
                    <Text style={styles.labelBoton}>Agregar</Text>
                </TouchableOpacity>

            <View style={[stylesGenerales.containerSearch, {marginBottom: '1%'}]}>
                <View style={stylesGenerales.btnSearch}>
                    <Icon name='search' color={colors.logo_gris}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    style={stylesGenerales.textInputSearch}
                    onChangeText={handleSearch}
                    value={searchText}></TextInput>
                {searchText !== '' &&
                    <TouchableOpacity
                        style={stylesGenerales.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.logo_gris}></Icon>
                    </TouchableOpacity>}
            </View>

            <FlatList
                data={listDelegados.filter((item) => item.nombre.toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                ListEmptyComponent={renderEmpty}
                style={{marginTop: 15, width: '100%', paddingBottom: '2%'}}
            />

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
            <ModalVerProductos
                visible={visibleProductos}
                producto={productoItem}
                nuevo={false}
                onCancel={() => setVisibleProductos(false)}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        alignItems: 'center'
    },

    botonFooter: {
        width: '48%',
        flexDirection: 'row',
        padding: 10,
        borderRadius: 5
    },

    labelBoton: {
        fontWeight: 'bold',
        fontSize: 16,
        color: colors.white,
        width: '80%',
        textAlign: 'center'
    },


    img: {
        width: 150,
        height: 150,
        marginTop: 30,
        marginBottom: '7%'
    }
})
