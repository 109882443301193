import React, {useEffect, useState} from 'react';
import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View, Image} from 'react-native';

import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import axios from 'axios';
import ExpoCheckbox from 'expo-checkbox/build/ExpoCheckbox';
import {Icon} from 'react-native-elements';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../../constants/colors';
import routes from '../../constants/routes';
import stylesAdmin from '../../constants/stylesAdmin';
import stylesGenerales from '../../constants/stylesGenrales';
import urlsGenerales from '../../constants/urlsGenerales';
import {StorageUtil} from '../../shared/StorageUtil';
import {textoSpinner} from '../../shared/TiposDocumento';

export default function DelegarCuentas() {

    const navigator = useNavigation();
    const route = useRoute();

    const delegado = route.params?.delegado;

    const [searchText, setSearchText] = React.useState('');
    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const [loading, setLoading] = React.useState(false);
    const [listUsuarios, setListUsuarios] = useState([]);
    const [listUsuarioAsignados, setListUsuarioAsignados] = useState([]);
    const [listUsuariosSinAsignar, setListUsuariosSinAsignar] = useState([]);

    async function asignarUsuario(codUsuarioHijo: any) {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuarioAdmin', delegado.codUsuario!);
            formData.append('codUsuarioSinAsignar', codUsuarioHijo);
            const token = await StorageUtil.getTokenStorage(); // Obtén el token del almacenamiento local
            const response = await axios.post(urlsGenerales.ASIGNACION_USUARIO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            console.log(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.ASIGNACION_USUARIO);
            console.log(error);
            setLoading(false);

        } finally {

            setLoading(false);
        }
    }

    async function eliminarAsignacion(codUsuarioHijo: any) {
        console.log('Eliminar asignacion usuario', codUsuarioHijo);

        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', codUsuarioHijo);
            const token = await StorageUtil.getTokenStorage(); 
            const response = await axios.post(urlsGenerales.ELIMINAR_ASIGNACION, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            console.log(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.ELIMINAR_ASIGNACION);
            console.log(error);
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }

    const onToggleItem = (index: any, codUsuarioHijo: any) => {
        setListUsuarios((prevState) => {
            const newData = [...prevState];
            newData[index].checked = !newData[index].checked;

            if (newData[index].checked) {
                asignarUsuario(codUsuarioHijo);
            } else {
                eliminarAsignacion(codUsuarioHijo);
            }

            console.log(newData)
            return newData;
        });
    };

    const renderItem = ({item, index}) => {
        return (
            <TouchableOpacity
                style={[styles.boxItem]}
                onPress={() => onToggleItem(index, item.codUsuarioHijo)}>

                <ExpoCheckbox
                    value={item.checked}
                    color={colors.verde_primary}
                    onValueChange={() => onToggleItem(index, item.codUsuarioHijo)}/>

                <Text style={{width: '100%', fontSize: 16, marginLeft: 16}}>
                    {item.nombre} {item.apellido}</Text>

            </TouchableOpacity>
        );
    };

    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 12, marginVertical: 20, textAlign:'center'}}>No hay usuarios registrados</Text>
        );
    };

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {

                try {
                    setLoading(true);

                    const formData = new FormData();
                    formData.append('codUsuario', delegado.codUsuario!);
                    formData.append('soloCobrador', "1"); /* Solo cobradores */
                    const token = await StorageUtil.getTokenStorage();
                    const responseAsignados = await axios.post(urlsGenerales.LISTAR_USUARIOS_ASIGNADOS, formData, {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    console.log('responseAsignados.data -> ', responseAsignados.data);

                    const dataWithCheckedProp = responseAsignados.data.map(item => {
                        return {
                            ...item,
                            checked: true,
                            nombre: item.nombreHijo,
                            apellido: item.apellidoHijo
                        }
                    });

                    setListUsuarioAsignados(dataWithCheckedProp);
                    console.log('dataWithCheckedProp -> ', dataWithCheckedProp);

                } catch (error) {
                    console.log('Error en -> ', urlsGenerales.LISTAR_USUARIOS_ASIGNADOS);
                    console.log(error);
                    setListUsuarioAsignados([]);
                    setLoading(false);

                } finally {
                    setLoading(false);
                }

                try {
                    setLoading(true);
                    const formData = new FormData();
                    const codUsuario = await StorageUtil.getCodUsuarioStorage();
                    formData.append('codUsuario', codUsuario!);
                    const token = await StorageUtil.getTokenStorage();
                    const responseNoAsignados = await axios.post(urlsGenerales.LISTAR_USUARIOS_NO_ASIGNADOS, formData, {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    console.log('responseNoAsignados.data -> ', responseNoAsignados.data);

                    const dataWithCheckedProp = responseNoAsignados.data.map(item => {
                        return {
                            ...item,
                            codUsuarioHijo: item.codUsuario,
                            checked: false
                        }
                    });

                    setListUsuariosSinAsignar(dataWithCheckedProp);

                } catch (error) {
                    console.log('Error en -> ', urlsGenerales.LISTAR_USUARIOS_NO_ASIGNADOS);
                    console.log(error);
                    setListUsuariosSinAsignar([]);
                    setLoading(false);

                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }, [])
    );

    useEffect(() => {
        const listaUsuariosFinal = [...listUsuarioAsignados, ...listUsuariosSinAsignar];
        setListUsuarios(listaUsuariosFinal);
    }, [listUsuarioAsignados, listUsuariosSinAsignar]);

    return (
        <View style={styles.container}>

            <View style={[styles.row,{margin:'5%', marginBottom:0}]}>
                <View style={{alignSelf:'center'}}>
                    <Image 
                        style={[styles.circleStatus]}
                        resizeMode="center"
                        source={require('../../assets/client.png')}
                    />
                </View>
                <View style={{alignSelf:'center', marginLeft:8}}>
                    <View>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.labelTitle}>{delegado.nombre} {delegado.apellido}</Text>
                    </View>
                </View>
            </View>

            <View style={[styles.row,styles.mrg,{justifyContent:'space-between',paddingVertical:24,paddingBottom:16, display:'flex', paddingTop:8}]}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({listUsuarios.filter((item)=>item.checked).length}{listUsuarios.filter((item)=>item.checked).length==1?' usuario asignado':' usuarios asignados'})</Text></Text>
            </View>

                <View style={styles.crtSearch}>
                    <View style={styles.searchIcon}>
                        <Icon name='search' color={colors.negro}></Icon>
                    </View>
                    <TextInput 
                        placeholder='Buscar'
                        style={styles.searchInput}
                        onChangeText={handleSearch}
                        value={searchText} 
                        selectionColor={colors.negro}
                        placeholderTextColor={colors.gris_fondo_cell}
                    />
                    {searchText !== '' &&
                        <TouchableOpacity 
                            style={styles.clearSearch}
                            onPress={limpiarSearchText}>
                            <Icon name='clear' color={colors.negro}></Icon>
                        </TouchableOpacity>
                    }
                </View>
            
            <View style={[styles.row,styles.mrg]}>
                <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}>Lista de cobradores disponibles para asignar</Text>
            </View>

            <FlatList
                data={listUsuarios.filter((item) => item.nombre.toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                ListEmptyComponent={renderEmpty}
                style={{marginTop: 15, width: '100%',}}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    textNombre: {
        width: '90%',
        paddingHorizontal: '3%',
        paddingVertical: '1%',
        textAlign: 'center',
        backgroundColor: '#AD129A',
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 19,
        marginBottom: '2%',
        marginTop: '4%'
    },
    mrg:{
        marginHorizontal:'5%'
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        alignSelf:'center'
    },
    label: {
        textAlign: 'left',
        width: 400
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    img: {
        width: 150,
        height: 150,
        marginTop: 30,
        marginBottom: '7%'
    },
    crtSearch:{
        height: 48,
        position: 'relative',
        marginHorizontal: '5%',
        marginBottom:24,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.20,
        shadowRadius: 2,
        elevation: 4,
    },
    searchIcon:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
        left:0,
        top:12
    },
    searchInput:{
        height:48,
        borderRadius:4,
        backgroundColor:colors.white,
        width:'100%',
        color:colors.black,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    clearSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
        top:12
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontWeight:'300',
        fontSize:14,
    },
    circleStatus:{
        width:40,
        height:40,
        borderRadius:20,
        alignSelf:'center',
        marginBottom:8,
    },
    labelTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 8,
        textAlign:'center',
        color:colors.negro
    },
    boxItem:{
        display:'flex',
        flexDirection:'row',
        borderBottomWidth: 0.5,
        borderBottomColor: '#22222230',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%'
    }
})
