import axios from 'axios';
import urlsGenerales from "../constants/urlsGenerales";

export class AuthenticationService {

    static validarCredenciales(usuarioInput: string, passwordInput: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const formData = new FormData();
            formData.append('usu', usuarioInput.trim());
            formData.append('pass', passwordInput.trim());

            try {
                const response = await axios.post(urlsGenerales.LOGIN, formData, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data) {
                    const usuarioResponse = response.data.usu;
                    const passwordResponse = response.data.pass;

                    if (usuarioInput.toUpperCase() === usuarioResponse.toUpperCase() &&
                        passwordInput === passwordResponse) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            } catch (error) {
                console.log('Error en -> ', urlsGenerales.LOGIN);
                console.error(error);
                resolve(false);
            }
        });
    }
}

