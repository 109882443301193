import React, {useEffect, useState} from 'react';
import {FlatList, Linking, ListRenderItem, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import {useFocusEffect, useRoute} from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../../shared/TiposDocumento";
import {CreditoType} from "../../../types/CreditoType";
import ModalVerCliente from "../../../components/reportes/ModalVerCliente";
import ModalVerAbono from "../../../components/reportes/ModalVerAbonos";
import {AbonoType} from "../../../types/AbonoType";
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';

export default function CreditosActivos() {

    const navigation = useNavigation();

    const route = useRoute();
    const cobrador = route.params?.cobrador;
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = React.useState('');

    const [cantidad, setCantidad] = React.useState(0);
    const [capital, setCapital] = React.useState(0);
    const [saldos, setSaldos] = React.useState(0);

    const [visibleDetalles, setVisibleDetalles] = useState(false);
    const [visibleAbonos, setVisibleAbonos] = useState(false);
    const [creditosResponse, setCreditosResponse] = useState<CreditoType[]>([]);

    const [abonos, setAbonos] = useState<AbonoType[]>([]);

    const [creditoItem, setCreditoItem] = useState<CreditoType>({
        codCredito: '',
        codUsuario: '',
        codCliente: '',
        fecha: '',
        valorCredito: '',
        interes: '',
        cantCuotas: '',
        valorCuota: '',
        codFrecuencia: '',
        diaFrecuencia: '',
        diaFrecuencia2: '',
        totalDeuda: '',
        estado: '',
        timestamp: '',
        nombre: '',
        apellido: '',
        codDocumento: '',
        numDocumento: '',
        telefono: '',
        correo: '',
        direccion: '',
        alias: '',
        saldoCredito: ''
    });

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const descargarListadoGeneral = () => {
        Linking.openURL(urlsGenerales.REP_DESCARGAR_CREDITO_EXCEL + '?codUsuario=' + cobrador.codUsuarioHijo!)
            .then(() => {
                console.log('Se descargó el excel correctamente');
            })
            .catch((err: any) => console.error('Error al descargar excel: ', err));
    }

    const obtenerAbonos = async (codCredito: string) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('codCredito', codCredito);
            
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_ABONOS_CREDITO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                setAbonos(response.data);
            }

        } catch (error) {
            console.log(error);
            console.log('Se cae en error -> ', urlsGenerales.LISTAR_ABONOS_CREDITO);
            setAbonos([]);

        } finally {
            setLoading(false);
        }
    }

    const handleVerDetalles = () => {
        setVisibleDetalles(true);
    }

    const handleVerAbonos = async (codCredito: string) => {
        await obtenerAbonos(codCredito);
        setVisibleAbonos(true);
    }

    const obtenerCreditos = async () => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo!);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_CREDITOS_ACTIVOS, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                setCreditosResponse(response.data);
            }

        } catch (error) {
            console.log('Se cae en error -> ', urlsGenerales.LISTAR_CREDITOS_ACTIVOS);
            console.log(error);
            setCreditosResponse([]);

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await obtenerCreditos();
        };
        fetchData();
    }, [])
    

    const renderEmpty = () => {
        return (
                <Text style={{fontSize: 12, marginVertical: 15, textAlign:'center'}}>No hay créditos activos</Text>
        );
    };

    const renderItem: ListRenderItem<CreditoType> = ({item}) => {

        const verDetalles = () => {
            // setCreditoItem(item);
            // handleVerDetalles();
            
            navigation.navigate(routes.RECAUDAR, {codCredito: item.codCredito,disabledFn:true});
        }

        const verAbonos = async () => {
            navigation.navigate(routes.VER_ABONOS, {codCredito: item.codCredito,disabledFn:true});
        }

        return (
            // <View style={{
            //     width: '100%',
            //     paddingHorizontal: '4%',
            //     paddingVertical: '2%',
            //     borderWidth: 0.5,
            //     borderColor: colors.logo_gris,
            //     backgroundColor: colors.white
            // }}>
            //     <Text style={{fontSize: 15, marginTop: '1%', textAlign: 'right'}}>{item.fecha}</Text>
            //     <Text style={{fontWeight: 'bold', fontSize: 22, color: item.estado == "3" ? colors.rojo : item.estado == "2" ? colors.naranja : colors.verde_primary, marginBottom: '1%'}}>
            //         {item.nombre} {item.apellido}
            //     </Text>
            //     <Text
            //         style={{fontWeight: 'normal', fontSize: 17, marginVertical: '1%'}}>Teléfono: {item.telefono}</Text>
            //     <Text style={{
            //         fontWeight: 'normal',
            //         fontSize: 17,
            //         marginVertical: '1%'
            //     }}>Dirección: {item.direccion}</Text>
            //     <Text style={{fontSize: 15, marginVertical: '1%'}}>Capital Inicial: S/ {item.valorCredito}</Text>
            //     <Text style={{fontSize: 15, marginVertical: '1%', color: item.estado == "3" ? colors.rojo : item.estado == "2" ? colors.naranja : colors.verde_primary}}>Saldo Actual: S/ {item.saldoCredito}</Text>

            //     <TouchableOpacity style={{
            //         backgroundColor: colors.logo_gris,
            //         borderRadius: 5,
            //         flexDirection: 'row',
            //         padding: '3%',
            //         marginTop: '4%',
            //         marginBottom: '2%'
            //     }}
            //                       onPress={verDetalles}>
            //         <View style={{width: '10%'}}>
            //             <Icon name='info' color={colors.white}></Icon>
            //         </View>
            //         <Text style={{
            //             color: colors.white,
            //             fontWeight: 'bold',
            //             fontSize: 18,
            //             width: '90%',
            //             textAlign: 'center'
            //         }}>Ver detalles</Text>
            //     </TouchableOpacity>

            //     <TouchableOpacity style={{
            //         backgroundColor: '#13CE26',
            //         borderRadius: 5,
            //         flexDirection: 'row',
            //         padding: '3%',
            //         marginBottom: '4%'
            //     }} onPress={verAbonos}>
            //         <View style={{width: '10%'}}>
            //             <Icon name='list' color={colors.white}></Icon>
            //         </View>
            //         <Text style={{
            //             color: colors.white,
            //             fontWeight: 'bold',
            //             fontSize: 18,
            //             width: '90%',
            //             textAlign: 'center'
            //         }}>Ver abonos</Text>
            //     </TouchableOpacity>
            // </View>
            
            <View
                style={styles.crtItem}
            >
                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                <View style={{flex:1,alignSelf:'center'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>{item.nombre} {item.apellido}</Text>
                    <Text style={styles.code}><Text style={{fontWeight:'bold'}}>#{item.codCredito}</Text> - {item.fecha}</Text>
                </View>
                <Text style={{alignSelf:'center', fontWeight:'bold', fontSize:16}}>
                    S/ {Number(item.totalDeuda).toFixed(2)}
                </Text>
                </View>
                <View style={{flexDirection:'row',justifyContent:'space-between',marginTop:8}}>
                <TouchableOpacity style={{
                    backgroundColor: colors.logo_gris,
                    padding:16,
                    borderRadius:4,
                    flex:1,
                    marginRight:8
                }}
                                  onPress={verDetalles}>
                    <Text style={{
                        color: colors.white,
                        fontWeight: 'bold',
                        fontSize: 14,
                        textAlign: 'center'
                    }}>Ver detalles</Text>
                </TouchableOpacity>

                <TouchableOpacity style={{
                    backgroundColor: colors.verde_primary,
                    padding:16,
                    borderRadius:4,
                    flex:1,
                    marginLeft:8
                }} onPress={verAbonos}>
                    <Text style={{
                        color: colors.white,
                        fontWeight: 'bold',
                        fontSize: 14,
                        textAlign: 'center',
                    }}>Ver abonos</Text>
                </TouchableOpacity>
                </View>

            </View>
        )
    }

    useEffect(() => {
        setCantidad(creditosResponse.length);
        setCapital(creditosResponse.reduce((acumulador, elemento) => {
            return acumulador + parseFloat(elemento.valorCredito);
        }, 0));
        setSaldos(creditosResponse.reduce((acumulador, elemento) => {
            return acumulador + parseFloat(elemento.saldoCredito!);
        }, 0))
    }, [creditosResponse]);

    return (
        <View style={styles.container}>

            <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingHorizontal:'5%', marginBottom:8}}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({cantidad}{cantidad==1?' credito activo':' creditos activos'})</Text></Text>
            </View>
            <View style={{flexDirection:'row',justifyContent:'space-between', marginHorizontal:'5%', marginBottom:3}}>
                <Text style={{fontSize:12, textAlign:'right',paddingTop:8,fontWeight:'400'}}>Capital</Text>
                <Text style={{fontSize:20,fontWeight:'bold'}}>S/ {capital}</Text>
            </View>
            <View style={{flexDirection:'row',justifyContent:'space-between', marginHorizontal:'5%'}}>
                <Text style={{fontSize:12, textAlign:'right',paddingTop:8,color:colors.rojo,fontWeight:'400'}}>Saldo</Text>
                <Text style={{fontSize:20,fontWeight:'bold',color:colors.rojo}}>S/ {saldos}</Text>
            </View>

            <TouchableOpacity    style={styles.btn} onPress={descargarListadoGeneral}>
                <Icon name='file-download' color={colors.white}></Icon>
                <Text style={{
                    color: colors.white,
                    fontWeight: 'bold',
                }}>Descargar creditos activos</Text>
            </TouchableOpacity>

            <View style={[styles.row,styles.mrg]}>
                <View style={styles.iconSearch}>
                    <Icon name='search' color={colors.negro}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    style={styles.inputSearch}
                    onChangeText={handleSearch}
                    value={searchText}
                    selectionColor={colors.negro}
                />
                {
                searchText !== '' &&
                    <TouchableOpacity
                        style={styles.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.negro}></Icon>
                    </TouchableOpacity>
                }
            </View>

            {/* <View style={stylesGenerales.containerSearch}>
                <View style={stylesGenerales.btnSearch}>
                    <Icon name='search' color={colors.logo_gris}></Icon>
                </View>
                <TextInput placeholder='Buscar'
                           style={stylesGenerales.textInputSearch}
                           onChangeText={handleSearch}
                           value={searchText}></TextInput>
                {searchText !== '' &&
                    <TouchableOpacity style={stylesGenerales.btnSearch}
                                      onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.logo_gris}></Icon>
                    </TouchableOpacity>}
            </View> */}

            <FlatList
                data={creditosResponse.filter((item) => (item.nombre + " "+ item.apellido).toLowerCase().includes(searchText.toLowerCase())
                )}
                renderItem={renderItem}
                keyExtractor={(item) => item.codCredito}
                ListEmptyComponent={renderEmpty}
                style={{marginTop: 15}}
                contentContainerStyle={{paddingBottom:48}}
            />

            <ModalVerCliente
                visible={visibleDetalles}
                credito={creditoItem}
                onCancel={() => setVisibleDetalles(false)}
            />

            <ModalVerAbono
                visible={visibleAbonos}
                credito={creditoItem}
                abonos={abonos}
                onCancel={() => setVisibleAbonos(false)}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
    },
    btn:{
        backgroundColor:colors.verde_primary,
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
        height:48,
        marginHorizontal:'5%',
        alignItems:'center',
        borderRadius:4,
        alignContent:'center',
        justifyContent:'center',
        marginVertical:16
    },row:{
        display:'flex',
        flexDirection:'row',
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor: colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    mrg:{
        marginHorizontal:'5%',
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },
    crtItem: {
        display:'flex',
        flexDirection:'column',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
    },
    name:{
        fontSize:14,
        marginBottom:4
    },
    code:{
        fontWeight:'300',
        fontSize:12,
        marginTop:4,
    },
})
