import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, StyleSheet, Text, TextInput, View, TouchableOpacity} from 'react-native';
import colors from '../../../constants/colors';
import {useRoute} from "@react-navigation/native";
import ModalCalendar from "../../../components/ModalCalendar";
import Spinner from "react-native-loading-spinner-overlay";
import {DateUtils} from "../../../shared/DateUtils";
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import {NumberUtils} from "../../../shared/NumberUtils";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';
import { Icon } from 'react-native-elements';
import moment from 'moment';

export default function GastosPorFecha() {

    const navigation = useNavigation();

    const route = useRoute();
    // @ts-ignore
    const cobrador = route.params?.cobrador;
    const [loading, setLoading] = useState(false);

    const [fechaDesde, setFechaDesde] = useState(DateUtils.getDateStringYYYYMMDD());
    const [fechaHasta, setFechaHasta] = useState(DateUtils.getDateStringYYYYMMDD());

    const fechaDesdeRef = useRef(null);
    const fechaHastaRef = useRef(null);

    const [mostrarFechaDesde, setMostrarFechaDesde] = useState(false);
    const [mostrarFechaHasta, setMostrarFechaHasta] = useState(false);

    const [cantidad, setCantidad] = React.useState(0);
    const [total, setTotal] = React.useState('0');
    const [gastosList, setGastosList] = useState([]);
    const [first,setFirst] = useState(true)

    const handleFechaDesde = (fechaDesdeModal: string) => {
        setMostrarFechaDesde(false);
        setFechaDesde(fechaDesdeModal);
    }

    const handleFechaHasta = (fechaHastaModal: string) => {
        setMostrarFechaHasta(false);
        setFechaHasta(fechaHastaModal);
    }

    useEffect(() => {
        setCantidad(gastosList.length);
        const importeTotal = gastosList.reduce((acumulador, elemento) => {
            return acumulador + parseFloat(elemento.monto);
        }, 0);
        setTotal(NumberUtils.formatThousands(importeTotal));
    }, [gastosList]);

    const obtenerGastosPorFecha = async () => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo!);
            formData.append('fecha1', fechaDesde);
            formData.append('fecha2', fechaHasta);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.REP_GASTOS_POR_FECHA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                setGastosList(response.data);
            }

        } catch (e) {
            console.log('Se cae en error -> ', urlsGenerales.REP_GASTOS_POR_FECHA);
            console.error(e);
            setGastosList([]);

        } finally {
            setLoading(false);
            setFirst(false)
        }
    }

    const listarGastos = () => {
        if (gastosList.length === 0) {
            return (
                
                <Text style={{fontSize: 12, marginVertical: 20, textAlign:'center'}}>No hay gastos en el rango de fechas</Text>
            )
        } else {
            return gastosList.map((item) => (
                <View style={{
                    flexDirection: 'row',
                    borderBottomWidth: 0.5,
                    borderBottomColor: '#222222',
                    marginBottom: 8,
                    paddingVertical:8,
                    paddingBottom:16,
                    paddingHorizontal:'5%'
                }}
                >
                    <View style={{flex:1,paddingRight:16}}>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={{fontSize:14,fontWeight:'bold',marginBottom:4}}>{item.detalle}</Text>
                        <Text style={{fontSize:12,fontWeight:'400'}}>{item.fecha}</Text>
                    </View>
    
                    <View style={{alignSelf:'center'}}>
                        <Text style={{fontSize:16,fontWeight:'bold'}}>S/ {Number(item.monto).toFixed(2)}</Text>
                    </View>
                </View>
            ))
        }
    }

    return (
        <ScrollView
            contentContainerStyle={styles.container}
            showsHorizontalScrollIndicator={false}>

<Text style={[styles.title,{marginHorizontal:'5%'}]}>Gastos por fecha</Text>
            <Text style={styles.lblDes}>Detalle y listado de gastos del negocio</Text>

        <View style={styles.rowDate}>
        <View style={{flex:1, marginRight:9}}>
            <Text style={styles.labelInput}>Desde</Text>
            <TextInput
                style={styles.inputText}
                placeholder='yyyy-mm-dd'
                value={fechaDesde}
                onPressIn={() => setMostrarFechaDesde(true)}
                ref={fechaDesdeRef}
                onFocus={() => {
                    setMostrarFechaDesde(true);
                    // @ts-ignore
                    fechaDesdeRef.current.blur();
                }}
            >
            </TextInput>
        </View>
        <View style={{flex:1, marginLeft:8}}>
            <Text style={[styles.labelInput]}>Hasta</Text>
            <TextInput
                style={styles.inputText}
                placeholder='yyyy-mm-dd'
                value={fechaHasta}
                onPressIn={() => setMostrarFechaHasta(true)}
                ref={fechaHastaRef}
                onFocus={() => {
                    setMostrarFechaHasta(true);
                    // @ts-ignore
                    fechaHastaRef.current.blur();
                }}
            >
            </TextInput>
        </View>
        </View>

        <ModalCalendar
        visible={mostrarFechaDesde}
        onPress={handleFechaDesde}
        currentDate={moment(fechaDesde).format()}
        onCancel={() => setMostrarFechaDesde(false)}
        />

        <ModalCalendar
        visible={mostrarFechaHasta}
        onPress={handleFechaHasta}
        currentDate={moment(fechaHasta).format()}
        onCancel={() => setMostrarFechaHasta(false)}
        />

        <TouchableOpacity    style={styles.btn} onPress={obtenerGastosPorFecha}>
            <Icon name='search' color={colors.white}></Icon>
            <Text style={{
                color: colors.white,
                fontWeight: 'bold',
            }}>Consultar</Text>
        </TouchableOpacity>

            {
                first ? null :
                <>
                <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingHorizontal:'5%', marginBottom:16}}>
                    <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({cantidad}{cantidad==1?' gasto':' gastos'})</Text></Text>
                    <View style={{alignSelf:'center'}}>
                        <Text style={{fontSize:12, textAlign:'right',paddingTop:8,color:colors.logo_azul,fontWeight:'400'}}>Total</Text>
                        <Text style={{fontSize:20,fontWeight:'bold',color:colors.logo_azul}}>S/ {total}</Text>
                    </View>
                </View>
                
                {listarGastos()}
                </>
            }
            {/* <View style={[stylesGenerales.row, {marginTop: '3%'}]}>
                <Text style={[styles.cellHeader,{borderTopLeftRadius: 5}]}>Cantidad</Text>
                <Text style={[styles.cellHeader,{borderTopRightRadius: 5}]}>Total</Text>
            </View>
            <View style={stylesGenerales.row}>
                <Text style={[stylesAdmin.cellBody, {width: '50%'}]}>{cantidad}</Text>
                <Text style={[stylesAdmin.cellBody,  {width: '50%'}]}>{total}</Text>
            </View>

            <Text style={[stylesAdmin.labelListado, {marginTop: '6%'}]}>
                Listado de Gastos</Text>

            <View style={[stylesGenerales.row]}>
                <Text style={[stylesAdmin.cellHeader, {borderTopLeftRadius: 5}, {width: '30%'}]}>Fecha</Text>
                <Text style={[stylesAdmin.cellHeader, {width: '40%'}]}>Detalle</Text>
                <Text style={[stylesAdmin.cellHeader,{borderTopRightRadius: 5}, {width: '30%'}]}>Valor</Text>
            </View>

            {listarGastos()} */}

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingBottom: '5%'
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8
    },
    lblDes:{
        fontSize:12,
        marginBottom:16,
        marginHorizontal:'5%'
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    rowDate:{
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:'5%'
    },
    btn:{
        backgroundColor:colors.verde_primary,
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
        height:48,
        marginHorizontal:'5%',
        alignItems:'center',
        borderRadius:4,
        marginTop:16,
        alignContent:'center',
        justifyContent:'center'
    },
})
