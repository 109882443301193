import {useNavigation,  useFocusEffect} from '@react-navigation/native';
import React, {useEffect, useState, useCallback, useRef} from 'react';
import {
    FlatList,
    Platform,
    Modal,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View,
    Linking,
    RefreshControl,
    Image,
    Animated
} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../constants/colors';
import axios from 'axios';
import {Calendar} from 'react-native-calendars';
import routes from '../constants/routes';
import stylesGenerales from '../constants/stylesGenrales';
import urlsGenerales from '../constants/urlsGenerales';
import {DateUtils} from '../shared/DateUtils';
import {StorageUtil} from '../shared/StorageUtil';
import {CajaService} from "../shared/CajaService";
import {NumberUtils} from "../shared/NumberUtils";
import CustomAlert from '../shared/CustomAlert';
import Checkbox from 'expo-checkbox';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import NetInfo from '@react-native-community/netinfo';
import { useIconContext } from '../navigations/IconContext';
import SwipeableComponent from '../components/Swipeable';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {SelectList} from 'react-native-dropdown-select-list';


const mediosPago = [
    {key: '1', value: 'Efectivo'}, {key: '2', value: 'Transferencia'},
]

const detallesMedioPago = [
    {key: '1', value: 'Yape'},
    {key: '2', value: 'Plin'},
    {key: '3', value: 'BCP'},
    {key: '4', value: 'Interbank'},
    {key: '5', value: 'Otros'},
];

export default function Home() {

    const {updateIsSync} = useIconContext();
    const navigation = useNavigation();
    const [dateNow] = useState(new Date())
    const [isConnected, setIsConnected] = useState(true);
    const [btnCreate, setBtnCreate] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [camposInicio, setCamposInicio] = useState([]);
    const [camposInicioRecaudo, setCamposInicioRecaudo] = useState([]);
    const [recaudado, setRecaudado] = useState(0);
    const [creditos, setCreditos] = useState(0);
    const [gastos, setGastos] = useState(0);
    const [caja, setCaja] = useState(0);
    const [debidoMin, setDebidoMin] = useState(0);
    const [debitoTot, setDebidoTot] = useState(0);
    const [cntPagosHoy, setCntPagosHoy] = useState(0);    
    const [clientesRuta, setClientesRuta] = useState([]);
    const [listaPagos, setListaPagos] =useState([]); 
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [fecVencimiento, setFecVencimiento] = useState(DateUtils.getDateStringYYYYMMDD());
    const [ocultarModalHoy, setOcultarModalHoy] = useState(false);    
    const [modalVisibleVencimiento, setModalVisibleVencimiento] = useState(false);
    const [diaFinal, setDiaFinal] = useState('');
    const [refreshing, setRefreshing] = useState(false);
    const [mostrarCalendario, setMostrarCalendario] = useState(false);
    const [markedDates, setMarkedDates] = useState({});
    const [lblList, setLblList] = useState(0);
    const [lblRecaudo, setLblRecaudo] = useState(0);
    const [modalAbono, setModalAbono] = useState(false)
    const [itemAbono, setItemAbono] = useState({})
    const [abonoValue, setAbonoValue] = useState(0)
    const [loading, setLoading] = useState(false)
    const [medioPago, setMedioPago] = useState('')
    const [detalleMedio, setDetalleMedio] = useState('')
    
    // listener is connected
    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
            setIsConnected(state.isConnected);
        });

        return () => {
        unsubscribe();
        };
    }, []);

    useFocusEffect(
        useCallback(() => {
            get()
        }, [])
    );

    const get = async()=> {
        const recargar = await AsyncStorage.getItem('recargar_home')
        console.log("Necesito Actualizar Home ? ",recargar)
        if(recargar){
            await AsyncStorage.removeItem('recargar_home')
            if(recargar == 'si'){
                asyncData()
                fetchList()
                fetchInfoCollect()
            } 
        }
    }   
    

    useEffect(() => {
        asyncData()
        fetchList()
        fetchInfoCollect()
    }, [isConnected])
    

    const fetchList = async () => {
        console.log('CALL FETCH LIST')
        try {

            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            
            if(await AsyncStorage.getItem("dataListRoute")){
                const data = JSON.parse(await AsyncStorage.getItem("dataListRoute"))
                setCamposInicio(data);
                setLblList(1)
            }

            let responseInicio = {data:[]};

            const formDataInicio = new FormData();
            formDataInicio.append('codUsuario', codUsuario!);

            const token = await StorageUtil.getTokenStorage();

            responseInicio = await axios.post(urlsGenerales.PANTALLA_INICIO_LIST, formDataInicio, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("FN FETCH LIST - ", responseInicio)

            if (responseInicio.data == 'redirigir') {
                console.log("FN FETCH LIST - Redirigir Login")
                navigation.replace(routes.LOGIN);
                return;
            }
            
            if (responseInicio.data) {
                await AsyncStorage.setItem("dataListRoute",JSON.stringify(responseInicio.data))
                setCamposInicio(responseInicio.data);
                setLblList(2)
            }
            
        } catch (error) {
            if(await AsyncStorage.getItem("dataListRoute")){
                const data = JSON.parse(await AsyncStorage.getItem("dataListRoute"))
                setCamposInicio(data);
                setLblList(1)
            }
            console.log('Error en -> ', urlsGenerales.PANTALLA_INICIO_LIST)
            console.log(error);
        }
    }

    useEffect(() => {
        if (camposInicio.length > 0) {
            const primerElemento = camposInicio[0];
            setRecaudado(primerElemento.recaudo);
            setCreditos(primerElemento.credito);
            setGastos(primerElemento.gastos);
            setCaja(primerElemento.caja);
            // setDebidoMin(primerElemento.pago_dia);
            // setDebidoTot(primerElemento.pago_semana);
            setCntPagosHoy(primerElemento.cantPagos);
            setClientesRuta(primerElemento.listaCobro);
            setListaPagos(primerElemento.listaPagos);
        }
    },[camposInicio]);

    const fetchInfoCollect = async () => {
        console.log('CALL FETCH COLLECT')
        try {

            if(await AsyncStorage.getItem("dataCollect")){
                const data = JSON.parse(await AsyncStorage.getItem("dataCollect"))
                setCamposInicioRecaudo(data);
                setLblRecaudo(1)
            }
            
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            let responseInicio = {data:[]};

            const formDataInicio = new FormData();
            formDataInicio.append('codUsuario', codUsuario!);

            const token = await StorageUtil.getTokenStorage();

            responseInicio = await axios.post(urlsGenerales.PANTALLA_INICIO_INFO, formDataInicio, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("FN FETCH COLLECT - ", responseInicio)

            if (responseInicio.data == 'redirigir') {
                console.log("FN FETCH COLLECT - Redirigir Login")
                navigation.replace(routes.LOGIN);
                return;
            }
            
            if (responseInicio.data) {
                await AsyncStorage.setItem('dataCollect', JSON.stringify(responseInicio.data));
                setCamposInicioRecaudo(responseInicio.data);
                setLblRecaudo(2)
            }
            
        } catch (error) {
            if(await AsyncStorage.getItem("dataCollect")){
                const data = JSON.parse(await AsyncStorage.getItem("dataCollect"))
                setCamposInicioRecaudo(data);
                setLblRecaudo(1)
            }
            console.log('Error en -> ', urlsGenerales.PANTALLA_INICIO_INFO)
            console.log(error);
        }
    }

    useEffect(() => {
        if (camposInicioRecaudo.length > 0) {
            const primerElemento = camposInicioRecaudo[0];
            setDebidoMin(primerElemento.pago_dia);
            setDebidoTot(primerElemento.pago_semana);
        }
    },[camposInicioRecaudo]);

    const asyncData = async () => {
        const nombre = await AsyncStorage.getItem('nombre');
        const apellido = await AsyncStorage.getItem('apellido');
        const codTipoCuenta = await AsyncStorage.getItem('codTipoCuenta');
        const fecVenci = await AsyncStorage.getItem('fechaVencimiento');

        setNombre(nombre);
        setApellido(apellido);
        setFecVencimiento(fecVenci);


        let ocultoModalHoy = false;
        setOcultarModalHoy(ocultoModalHoy);

        if(Platform.OS !== 'web'){
            try {
            const savedOcultarModalHoy = await SecureStore.getItemAsync('ocultarModalHoy');
            const savedFechaModalHoy = await SecureStore.getItemAsync('fechaModalHoy');
            const fechaString = DateUtils.getDateStringYYYYMMDD();

            if (savedOcultarModalHoy && savedFechaModalHoy && (fechaString === savedFechaModalHoy)) {

                const savedOcultarModalHoyBoolean = JSON.parse(savedOcultarModalHoy);

                if(savedOcultarModalHoyBoolean){
                    ocultoModalHoy = true;
                    setOcultarModalHoy(ocultoModalHoy);
                }
            }
            } catch (error) {
            console.error('Error retrieving user data:', error);
            }
        }

        if(codTipoCuenta === "2" && !ocultoModalHoy){
            
            const fechaString = DateUtils.getDateStringYYYYMMDD();
            const fechaVence = new Date(fecVenci);
            const fechaIni = new Date(fechaString);
            const fechaFin = new Date(fechaString);
            fechaFin.setDate(fechaFin.getDate() + 3);
            
            if(fechaVence >= fechaIni && fechaVence <= fechaFin){
                if(fechaVence.getTime() === fechaIni.getTime()){
                    setDiaFinal("\n\n ¡ Hoy es el ultimo Día !\n")
                }else{
                    setDiaFinal("");    
                }
                setModalVisibleVencimiento(true);
            }else{
                setModalVisibleVencimiento(false);
                setDiaFinal("");
            }
        }else{
            setModalVisibleVencimiento(false);
        }
    };

    const getDateString = () => {
        return dateNow.getFullYear() + '-' + String(dateNow.getMonth() + 1).padStart(2, '0')
            + '-' + String(dateNow.getDate()).padStart(2, '0')
    }
    
    const cambiarCheckDemo = () => {
        setOcultarModalHoy(!ocultarModalHoy);
    };

    const cerrarModalDemo = async () => {
        if (ocultarModalHoy) {
            try {
                const fechaString =  DateUtils.getDateStringYYYYMMDD();
                await SecureStore.setItemAsync('ocultarModalHoy', true+"");
                await SecureStore.setItemAsync('fechaModalHoy', fechaString);
            } catch (error) {
              console.error('Error saving user data:', error);
            }
        }
        setModalVisibleVencimiento(false)
    }

    const formatearFechas = (fechas: any[]) => {
        const objetoCuotas = fechas.reduce((obj, cuota) => {
            const {fechaVencimiento, montoPagado, estadoCalendario} = cuota;

            let color = '';
            switch (estadoCalendario) {
                case 'Amarillo':
                    color = 'yellow';
                    break;
                case 'Rojo':
                    color = 'red';
                    break;
                case 'Verde':
                    color = 'green';
                    break;
                case 'Gris':
                    color = 'blue';
                    break;
                default:
                    break;
            }

            if (fechaVencimiento && montoPagado && Number(montoPagado) > 0) {
                const key = fechaVencimiento;
                const dots = [{key: `S/ ${montoPagado}`}];
                obj[key] = {dots, color};
            }

            if (fechaVencimiento && !montoPagado) {
                const key = fechaVencimiento;
                obj[key] = {color};
            }

            return obj;
        }, {});
        console.log("calendar",objetoCuotas)
        setMarkedDates(objetoCuotas);
    }

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const intentarPagar = async() => {

        let msgRegistro = "error";
        setModalAbono(false);
        await setTimeout(() => {}, 300);
        try {
            await setLoading(true);
            const token = await StorageUtil.getTokenStorage();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            
            const formData = new FormData();
            formData.append('codUsuario', codUsuario);
            formData.append('codCredito', itemAbono.codCredito);
            formData.append('monto', abonoValue.toString());
            if (medioPago === '1') {
                formData.append('tipoPago', '0');
            } else {
                formData.append('tipoPago', detalleMedio);
            }

            const response = await axios.post(urlsGenerales.PAGAR_CUOTA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                setLoading(false);
                return;
            }

                if (response.data) {
                    if(response.data.resultado === "ok"){
                        CustomAlert.show({
                            title: 'Mensaje',
                            message: 'Pago Exitoso',
                            buttons: [
                                {
                                text: 'OK',
                                    onPress: async() => {
                                        await setLoading(true)
                                        await fetchList()
                                        fetchInfoCollect()
                                        setLoading(false)
                                    }
                                }
                            ],
                        });
                        msgRegistro = "ok";
                    }else{
                        msgRegistro = "duplicado";
                    }    
                }else{
                    msgRegistro = "error";
                }

        } catch (error) {
            msgRegistro = "error";
            console.error(error);
        } finally {
            setLoading(false);
            setMedioPago('')
            setAbonoValue(0)
            setItemAbono({})
        }
    }


    const renderItem = ({item}) => {

        const handlePress = () => {
            navigation.navigate(routes.RECAUDAR, {codCredito: item.codCredito});
        }

        const verCalendario = async () => {
            await setMostrarCalendario(false)
            try {
                const formData = new FormData();
                formData.append('codCredito', item.codCredito);
                const token = await StorageUtil.getTokenStorage();
                const response = await axios.post(urlsGenerales.FECHAS_PAGO_CALENDARIO, formData, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log("FETCH LIST CALENDAR - ", response.data)

                await formatearFechas(response.data);

            } catch (error) {
                console.log('Error en -> ', urlsGenerales.FECHAS_PAGO_CALENDARIO)
                console.log(error);
            }
            await setMostrarCalendario(true);
        }

        const handlerEnviar = () => {
            const mensaje = `Hola, ${item.nombre} ${item.apellido} te saluda el equipo CFP, recuerda que aún no has realizado el pago del día de hoy.\nRealiza tus pagos mediante transferencias bancarias o por los canales digitales de yape y/o plin.\n\nConserve su crédito pagando oportunamente :)`
            const v_tele = '51'+item.telefono;
            const phoneUrl = `https://wa.me/${v_tele}?text=${encodeURIComponent(mensaje)}`;
            Linking.openURL(phoneUrl);
        }

        const openAbono = async () => {
            try {
                
                await setLoading(true)
                const codUsuario = await StorageUtil.getCodUsuarioStorage();
                const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuario!, navigation);
                await setLoading(false)
                
                if(estadoCajaValida != 'redirigir'){
                    if (estadoCajaValida) {
                        setModalAbono(true)
                        setItemAbono(item)
                        setAbonoValue(Number(item.valorCuotaTotal))
                    } else {
                        CustomAlert.cajaBloq();
                        return;
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }


        

        return (
            <SwipeableComponent key={item.id} onClick={()=>{
                openAbono()
            }}>
                <View
                    style={[styles.crtItemList,{borderLeftColor:item.estado === '3' ? colors.rojo : item.estado === '2' ? colors.naranja : colors.logo_azul}]}>
                    <TouchableOpacity
                        style={[styles.itemText]}
                        onPress={handlePress}>
                        <Text 
                            numberOfLines={1} // Mostrar solo una línea
                            ellipsizeMode="tail" // Truncar con tres puntos suspensivos al final
                            style={[styles.lblItemName]}
                        >{item.nombre} {item.apellido}</Text>
                        <Text 
                        style={[styles.lblItemCuota,{color:item.estado === '3' ? colors.rojo : colors.negro }]}
                        >S/ {item.valorCuotaTotal}</Text>
                    </TouchableOpacity>

                    {
                        item.telefono && item.telefono.length===9 ? 
                        <TouchableOpacity
                            style={[styles.btnCalendar]}
                            onPress={handlerEnviar}
                        >
                            <Image style={{width:24,height:24, resizeMode:'cover',alignSelf:'center'}} source={require('../assets/drawer-icons/wsp-.png')} />
                        </TouchableOpacity>
                        :
                        <View style={styles.btnCalendar}></View>
                    }
                    

                    <TouchableOpacity
                        style={styles.btnCalendar}
                        onPress={verCalendario}
                    >
                        <Image style={{width:24,height:24, resizeMode:'cover',alignSelf:'center'}} source={require('../assets/drawer-icons/calendar.png')} />
                    </TouchableOpacity>
                </View>
            </SwipeableComponent>
        )
    };

    const renderEmpty = () => {
        return (
            <View>
                <Text style={styles.lblEmpty}>No hay ruta de clientes</Text>
            </View>
        );
    };

    const onRefresh = async() => {
        await setRefreshing(true);
        fetchList()
        fetchInfoCollect()
        await setRefreshing(false)
    }

    const irCrearCliente = async () => {

        if(isConnected){
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuario!,navigation);
            console.log("crear cliente", estadoCajaValida)
            
            if(estadoCajaValida != 'redirigir'){
                if (estadoCajaValida) {
                    navigation.navigate(routes.CLIENTES, {
                        screen: routes.CREAR_CLIENTE
                    });
                } else {
                    CustomAlert.cajaBloq();
                    return;
                }
            }
        }else{
            CustomAlert.show({message:"Conectate a internet para esta función."});
        }
        
    }

    return (
        <>
        <View style={{flex:1}}>

            
            <FlatList
                showsVerticalScrollIndicator={false}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
                data={clientesRuta.filter((item) => (item.nombre + " "+ item.apellido).toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(item) => item.codCredito}
                ListEmptyComponent={renderEmpty}
                style={{width: '100%', flexGrow:1}}
                contentContainerStyle={{paddingBottom:40}}
                removeClippedSubviews={false}
                ListHeaderComponent={
                    <>
                        <View  style={styles.ctrInfo}>
                            <View style={styles.boxWelcome}>
                                <Text style={styles.textWelcome}>Bienvenido</Text>
                                <Text numberOfLines={1} ellipsizeMode="tail" style={styles.textName}>{nombre + " "+apellido}</Text>
                            </View>
                            <View style={styles.crtDate}>
                                <Text style={styles.lblDate}>{getDateString()}</Text>
                            </View>
                        </View>
                        

                        <View style={styles.crtBoxShadow}>
                            <View style={[styles.row,styles.crtBox, { justifyContent:'center', marginBottom:16}]}>
                                <View style={[styles.crtBoxItem,{backgroundColor:'transparent', padding:0,flex:1,height:'auto'}]}>
                                    <Text style={[styles.lblBox,{fontSize:14,color:colors.negro}]}>Total Recaudado</Text>
                                    <Text style={[styles.lblBoxNumber,{color:colors.negro}]}>S/ {NumberUtils.formatThousands(recaudado)}</Text>
                                </View>
                                <TouchableOpacity style={{backgroundColor:colors.white, padding:8, borderRadius:4,alignSelf:'center'}} onPress={irCrearCliente}>
                                    <Icon name='person-add' color={colors.negro}></Icon>
                                </TouchableOpacity>
                            </View>
                            <View style={[styles.row, styles.crtBox]}>
                                <View  style={[styles.crtBoxItem,{flex:1, backgroundColor:colors.logo_naranja}]}>
                                    {/* <View style={{flex:1}}>
                                        <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start',marginLeft:-4}}></Icon>
                                    </View> */}
                                    <Text style={[styles.lblBoxNumber,{fontSize:14}]}>S/ {NumberUtils.formatThousands(creditos)}</Text>
                                    <Text style={styles.lblBox}>Créditos</Text>
                                </View>
                                <View style={[styles.crtBoxItem,{flex:1,  marginHorizontal:16,backgroundColor:colors.rojo}]}>
                                    {/* <View style={{flex:1}}>
                                        <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start',marginLeft:-4}}></Icon>
                                    </View> */}
                                    <Text style={[styles.lblBoxNumber,{fontSize:14}]}>S/ {NumberUtils.formatThousands(gastos)}</Text>
                                    <Text style={styles.lblBox}>Gastos</Text>
                                </View>
                                <View style={[styles.crtBoxItem,{flex:1,  backgroundColor:colors.logo_celeste}]}>    
                                    {/* <View style={{flex:1}}>
                                        <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start',marginLeft:-4}}></Icon>
                                    </View> */}
                                    <Text style={[styles.lblBoxNumber,{fontSize:14}]}>S/ {NumberUtils.formatThousands(caja)}</Text>
                                    <Text style={styles.lblBox}>Caja</Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.crtRecaudado}>
                            {/* <View style={styles.row}>
                                <Text style={styles.lblRecaudo}>Recaudado</Text>
                                {
                                    lblRecaudo!=0 ? 
                                    <Icon name={`${lblRecaudo==1?'timer':'check'}`} color={`${lblRecaudo==1?colors.logo_gris:colors.verde_primary}`} size={16} style={{alignSelf:'center', marginLeft:5, marginTop:5}}></Icon>
                                    :
                                    null
                                }
                            </View> */}
                            <View style={[styles.row,{justifyContent:'center'}]}>
                                <View style={[styles.boxItemRecaudado,{backgroundColor:colors.gris_fondo_cell}]}>
                                    <Text style={styles.lblItemRdd}>Debido mínimo</Text>
                                    <Text style={styles.lblItemRddNumber}>S/ {NumberUtils.formatThousands(debidoMin)}</Text>
                                </View>
                                <View style={{width:16}}></View>
                                <View style={[styles.boxItemRecaudado,{backgroundColor:colors.gris_fondo_cell}]}>
                                    <Text style={styles.lblItemRdd}>Debido total</Text>
                                    <Text style={styles.lblItemRddNumber}>S/ {NumberUtils.formatThousands(debitoTot)}</Text>
                                </View>
                            </View>
                            <View></View>
                        </View>

                        <View style={styles.crtRuta}>
                            <View style={[styles.row,{alignSelf:'center'}]}>
                                <Text style={styles.lblRuta}>Ruta Principal 
                                    <Text style={styles.lblPay}>
                                    {" ("+clientesRuta.length +" por cobrar)" }
                                    </Text>
                                </Text>
                                {
                                    lblList!=0 ? 
                                    <Icon name={`${lblList==1?'timer':'check'}`} color={`${lblList==1?colors.logo_gris:colors.verde_primary}`} size={16} style={{alignSelf:'center', marginLeft:4, marginTop:6}}></Icon>
                                    :
                                    null
                                }
                            </View>
                            <TouchableOpacity
                                style={[styles.row, styles.btnListPay, {alignSelf:'center'}]}
                                onPress={() => navigation.navigate(routes.RECAUDO_DEL_DIA, {pagosHoy: listaPagos})}>
                                    <Text style={{alignSelf:'center', fontSize:12}}>Pagos: <Text style={{fontWeight:'bold'}}>{cntPagosHoy}</Text></Text>
                                    <Icon name='flag' color={colors.verde_primary} size={28}></Icon>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.crtSearch}>
                            <View style={styles.searchIcon}>
                                <Icon name='search' color={colors.negro}></Icon>
                            </View>
                            <TextInput 
                                placeholder='Buscar'
                                style={styles.searchInput}
                                onChangeText={handleSearch}
                                value={searchText} 
                                selectionColor={colors.negro}
                                placeholderTextColor={colors.gris_fondo_cell}
                            />
                            {searchText !== '' &&
                                <TouchableOpacity 
                                    style={styles.clearSearch}
                                    onPress={limpiarSearchText}>
                                    <Icon name='clear' color={colors.negro}></Icon>
                                </TouchableOpacity>
                            }
                        </View>
                    </>
                } 
            />
        </View>    

        <Modal
            animationType="fade"
            transparent={true}
            visible={mostrarCalendario}
        >
            <TouchableWithoutFeedback onPress={() => setMostrarCalendario(false)}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalView, {alignSelf:'center'}]}>
                        <Calendar
                            style={{ width: 300, alignSelf:'center' }}
                            current={Date()}
                            markedDates={markedDates}
                            onDayPress={day => {
                                console.log('selected day', day);
                            }}
                            monthFormat={'yyyy MMMM'}
                            onMonthChange={month => {
                                console.log('month changed', month);
                            }}
                            hideArrows={false}
                            hideExtraDays={true}
                            disableMonthChange={false}
                            firstDay={1}
                            dayComponent={({date, state, marking}) => {
                                return (
                                    <View style={{alignItems: 'center', flexDirection: 'column'}}>
                                        <View 
                                            style={{
                                                display:'flex',
                                                justifyContent:'center',
                                                width:30,
                                                height:30,
                                                backgroundColor: marking ? marking.color : colors.white,
                                                borderRadius: 15
                                            }}
                                        >
                                            <Text style={{
                                                alignSelf:'center',
                                                textAlign: 'center',
                                                color: state === 'disabled' ? 'gray' : marking ? 'white' : 'black',
                                                fontSize: 18,
                                            }}>
                                                {date.day}
                                            </Text>
                                        </View>

                                        {marking && marking.dots && marking.dots!.map((dot, index) => (
                                            <View key={index}>
                                                <Text
                                                    style={{
                                                        textAlign: 'center',
                                                        color: colors.black,
                                                        fontSize: 8,
                                                        fontWeight: 'bold'
                                                    }}>
                                                    {dot.key}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                );
                            }}
                        />
                        { Platform.OS === 'web' && 
                            <style>
                                {
                                `.css-9pa8cd {
                                        position: relative;
                                    }`
                                }
                            </style>
                        }
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>

        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisibleVencimiento}
        >
            <View style={styles.centeredView}>
                <View style={[styles.modalView,{alignSelf:'center'}]}>
                    <View style={[styles.modalHeader,{flexDirection:'row'}]}>
                        <Text style={[styles.modalHeader,{flex:1,}]}>{"¡ Se termina la Demo !"}</Text>
                        <TouchableOpacity
                            onPress={cerrarModalDemo}>
                            <Icon size={24} name='highlight-off' color='black'></Icon>
                        </TouchableOpacity>
                    </View>

                    <Icon size={60} name='face' color='green' style={{marginTop:"5%"}}></Icon>

                    <Text style={[stylesGenerales.labelInput,{paddingHorizontal:16}]}>
                        {'\nEstimado '+nombre + " "+ apellido +', se le informa que su cuenta esta proxima a ser bloqueada, la fecha de vencimiento de su cuenta Demo es el "'+DateUtils.formatTODDMMYYYY(fecVencimiento)+'".\n\nConsulte como puede realizar una extensión de su demo ó suscribase al servicio.\n'}
                        <Text style={[stylesGenerales.labelInput,{marginTop:"-2%",fontWeight:'bold'}]}>{diaFinal}</Text>
                    </Text>

                    { Platform.OS !== "web" &&
                        <TouchableOpacity
                            style={{ flexDirection: 'row', alignSelf:'flex-start' ,marginHorizontal:16}}
                                    onPress={cambiarCheckDemo}
                                >
                            <View style={{ flexDirection: 'row',alignItems: 'center' }}>
                                <Checkbox
                                    value={ocultarModalHoy}
                                    onValueChange={cambiarCheckDemo}
                                    style={{ marginRight: 10 }}
                                />
                                <Text>Dejar de mostrar este mensaje por hoy.</Text>
                            </View>
                        </TouchableOpacity>
                    }

                    <TouchableOpacity style={[stylesGenerales.btnLarge, {
                        backgroundColor: colors.verde_primary,
                        marginTop: '5%',
                        borderRadius: 4,
                        marginHorizontal:16,
                        width:'auto',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }]} onPress={cerrarModalDemo}
                    >
                        <Text style={[stylesGenerales.textBtn, { textAlign: 'center', fontSize:16, padding:8,}]}>Cerrar</Text>

                    </TouchableOpacity>

                </View>
            </View>
        </Modal> 

        <Modal
            animationType="fade"
            transparent={true}
            visible={modalAbono}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <Text style={styles.modalHeader}>Abonar</Text>
                    <View style={{borderBottomWidth: 1,height:1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>
                    <View style={ {paddingHorizontal:16}}>
                        <Text style={[styles.titleModal,{paddingHorizontal:0}]}>Otro monto</Text>
                        <View style={[styles.row,]}>
                            <Text style={styles.textPay}>S/ </Text>
                            <TextInput
                                    style={styles.inputCuota}
                                    value={abonoValue.toString()}
                                    id="txtmonto"
                                    placeholder={'0'}
                                    onChangeText={(valorCuota)=>{
                                        try {
                                            if( Number(valorCuota) > Number(itemAbono.valorCuotaTotal)){
                                                setAbonoValue(Number(itemAbono.valorCuotaTotal))
                                            }else{
                                                setAbonoValue(Number(valorCuota))
                                            }
                                        } catch (error) {
                                            setAbonoValue(0)
                                        }
                                    }}
                                    keyboardType={'numeric'}
                                ></TextInput>
                            <Text style={{alignSelf:'center', fontSize:12}}> {abonoValue==1?' sol':' soles'}</Text>
                        </View>
                    </View>
                    
                    <Text style={styles.titleModal}>Seleccione medio de pago</Text>
                    <View style={styles.selectTipDoc}>
                        <SelectList
                            placeholder={'Buscar'}
                            data={mediosPago}
                            setSelected={setMedioPago}
                            search={false}
                        />
                    </View>

                    {
                        medioPago === '2' &&
                        <>
                            <Text style={styles.titleModal}>Seleccione tipo de transferencia</Text>
                            <View style={styles.selectTipDoc}>
                                <SelectList
                                    defaultOption={{ value: "Yape", key: "1", }}
                                    placeholder={'Buscar'}
                                    data={detallesMedioPago}
                                    setSelected={setDetalleMedio}
                                    search={false}
                                    
                                />
                            </View>
                        </>
                    }
                    <View style={{flexDirection: 'row', marginTop: 24, justifyContent:'space-between', marginHorizontal:16}}>
                        <TouchableOpacity
                            style={styles.btnEliminar}
                            onPress={() => {
                                setModalAbono(false);
                                setMedioPago('')
                                setAbonoValue(0)
                                setItemAbono({})
                            }}>
                            <Text style={styles.btnLabel}>Cancelar</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.btnAgregar}
                            disabled={medioPago=='' || abonoValue.toString()=='0' ?true:medioPago=='1'?false:detalleMedio==''?true:false}
                            onPress={async()=>{
                                intentarPagar()
                                // console.log(itemAbono)
                            }}>
                            <Text style={styles.btnLabel}>Confirmar</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
        <Spinner
            visible={loading}
            color={colors.white}
        />
        </>         
    );
};

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row'
    },
    btnAgregar: {
        flexDirection: 'row',
        backgroundColor: colors.verde_primary,
        padding: 8,
        paddingVertical:16,
        width:'48%',
        borderBottomRightRadius:4,
    },
    btnEliminar: {
        flexDirection: 'row',
        backgroundColor: colors.logo_gris,
        padding: 8,
        paddingVertical:16,
        width:'48%',
        borderBottomLeftRadius:4
    },
    btnLabel: {
        color: colors.white,
        fontSize: 16,
        fontWeight:'bold',
        textAlign: 'center',
        width:'100%'
    },
    textPay:{
        fontSize:16,
        fontWeight:'bold',
        alignSelf:'center'
    },
    inputCuota: {
        paddingHorizontal:16,
        height:40,
        maxWidth:80,
        minWidth:80,
        alignSelf:'center',
        fontSize:16,
        fontWeight:'bold',
        backgroundColor:colors.gris_fondo_cell,
        borderRadius:4,
    },
    selectTipDoc: {
        width:'100%',
        paddingHorizontal:16,
        alignSelf:'center'
    },
    /// MODAL ///
    
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalView: {
        width:'90%',
        backgroundColor: 'white',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },
    modalHeader: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18, 
        marginTop:16, 
        paddingHorizontal:16,
    },
    
    titleModal: {
        fontSize: 14,
        textAlign: 'left',
        marginTop:16,
        paddingHorizontal:16,
    },
    ctrInfo:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        backgroundColor: colors.verde_primary,
        alignSelf:'center',
        justifyContent:'center',
        paddingHorizontal:'5%',
        paddingVertical:16,
    },
    boxWelcome:{
        flex:1,
    },
    textWelcome:{
        textAlign: 'left',
        color: colors.white,
        fontSize: 16,
    },
    textName:{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 24,
        color: colors.white,
        textTransform:'capitalize'
    },
    textSync: {
        textAlign: 'center',
        color: colors.white,
        fontSize: 12,
        width: 100
    },
    textBold:{
        fontWeight: 'bold',
        fontSize: 16,
        textAlign:'center',
        color: colors.white,
    },
    crtBoxShadow: {
       padding: 16,
       paddingHorizontal:'5%',
       borderBottomEndRadius:24,
       borderBottomStartRadius:24,
       paddingBottom:0
    //    paddingBottom: 24,
    //    marginHorizontal: '5%',
    //    marginTop:16,
    //    marginVertical: 8,
    //    backgroundColor: colors.verde_shadow,
    },
    textDate: {
        textAlign: 'left',
        fontWeight: 'bold',
        color:colors.white,
        fontSize: 16,
        alignSelf:'center',
        textAlignVertical:'center'
    },
    crtBox:{
        display: 'flex',
        justifyContent: 'space-between',
    },
    crtBoxItem:{
        backgroundColor:colors.gris_fondo_cell,
        padding:8,
        paddingTop:16,
        borderRadius:8,
        // height:60,
        flex:1,
    },
    lblBox:{
        textAlign: 'left',
        fontSize: 12,
        color: colors.white,
        marginTop: 4,
    },
    lblBoxNumber:{
        textAlign: 'left',
        fontSize: 24,
        fontWeight: 'bold',
        color:colors.white
    },
    crtRecaudado:{
        padding: 16,
        paddingHorizontal: 0,
        marginHorizontal: '5%',
        marginTop:8
    },
    lblRecaudo: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    boxItemRecaudado:{
        flex: 1,
        padding: 8,
        borderRadius: 4,
    },
    lblItemRdd:{
        fontSize: 12,
        color: colors.negro,
        fontWeight: '400',
        marginBottom: 4,
    },
    lblItemRddNumber:{
        fontSize: 12,
        color: colors.negro,
        fontWeight: 'bold'
    },
    crtRuta:{
        paddingVertical: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 8,
        paddingTop: 0,
        marginHorizontal: '5%'
    },
    lblRuta: {
        fontWeight: 'bold',
        fontSize: 20
    },
    lblPay:{
        fontSize: 12,
        fontWeight: '500'
    },
    btnListPay:{
        padding: 4,
        alignSelf:'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    crtSearch:{
        height: 48,
        position: 'relative',
        marginHorizontal: '5%',
        marginBottom:24,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.20,
        shadowRadius: 2,
        elevation: 4,
    },
    searchIcon:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
        left:0,
        top:12
    },
    searchInput:{
        height:48,
        borderRadius:4,
        backgroundColor:colors.white,
        width:'100%',
        color:colors.black,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    clearSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
        top:12
    },
    btnCreateClient:{
        marginHorizontal: '5%',
        backgroundColor: colors.verde_primary,
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 4,
        marginTop: 8,
        padding: 8
    },
    btnLblClient:{
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 12,
        alignSelf: 'center',
        textAlign: 'center',
        width: '80%'
    },
    lblEmpty:{
        fontSize: 14,
        marginTop: 32,
        textAlign: 'center',
        fontWeight: '400',
    },
    crtItemList:{
        marginHorizontal: '5%',
        paddingHorizontal: 8,
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.white,
        // marginBottom: 12,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.10,
        shadowRadius: 1,
        elevation: 1,
        borderRadius: 4,
        maxHeight: 64,
        minHeight: 64,
        borderLeftWidth:8,
    },
    itemText:{
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        alignSelf: 'center'
    },
    lblItemName:{
        fontSize: 12,
        fontWeight: '300',
        textTransform: 'capitalize',
        marginBottom: 4,
        color:colors.negro
    },
    lblItemCuota:{
        fontSize: 16,
        fontWeight: 'bold',
    },
    btnCalendar:{
        width: '20%', 
        height: 64,
        display: 'flex',
        justifyContent:'center',
    },
    crtDate:{
        borderRadius:20,
        paddingHorizontal:16,
        height:40,
        borderWidth:1,
        borderColor:colors.white,
        alignSelf:'center',
        display:'flex',
        justifyContent:'center'
    },
    lblDate:{
        color:colors.white,
        alignSelf:'center',
        fontWeight:'bold',
        fontSize:12,
    },
    btnAbonar: {
        minHeight: 64,
        display:'flex',
        justifyContent:'center', backgroundColor:colors.verde_primary,
        marginLeft:'5%',
        borderRadius:8,
    },
    actionText: {
        color: '#fff',
        fontWeight: 'bold',
        padding:16,
        alignSelf:'center'
    },
});
