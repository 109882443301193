import { createStackNavigator } from "@react-navigation/stack";
import ROUTES from "../constants/routes";
import { Platform } from "react-native";

// screens home
import LoginScreen from "../screens/LoginScreen";
import GameScreen from "../screens/GameScreen";

import MenuNavigator from "./MenuNavigator";
import MenuNavigatorCliente from "./MenuNavigatorCliente";

const Stack = createStackNavigator();

function LoginNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={
        Platform.OS == "android" || Platform.OS == "ios" ? "game" : "Login"
      }
    >
      <Stack.Screen
        name={"game"}
        component={GameScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={ROUTES.LOGIN}
        component={LoginScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Menu"
        component={MenuNavigator}
        options={{ headerShown: false }}
      ></Stack.Screen>
      <Stack.Screen
        name="MenuCliente"
        component={MenuNavigatorCliente}
        options={{ headerShown: false }}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}

export default LoginNavigator;
