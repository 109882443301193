import {useFocusEffect, useNavigation} from '@react-navigation/native';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {FlatList, Image, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {SelectList} from 'react-native-dropdown-select-list';
import {Icon} from 'react-native-elements';
import {default as COLORS, default as colors} from '../../constants/colors';
import routes from '../../constants/routes';
import stylesGenerales from '../../constants/stylesGenrales';
import urlsGenerales from '../../constants/urlsGenerales';
import {StorageUtil} from '../../shared/StorageUtil';
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../shared/TiposDocumento";
import {AuthenticationService} from "../../shared/AuthenticationService";
import ModalOperacionAdmin from "../../components/ModalOperacionAdmin";
import ModalMensaje from "../../components/ModalMensaje";
import {CajaService} from "../../shared/CajaService";
import CustomAlert from '../../shared/CustomAlert';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function HomeScreenCliente() {
    const estados = [
        {key: '1', value: 'Activos'},
        {key: '0', value: 'Inactivos'},
        {key: '2', value: 'En Mora'},
        {key: '3', value: 'Vencidos'},
        {key: '4', value: 'Pagados'}
    ];

    const navigation = useNavigation();

    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = React.useState('');
    
    const [estadoCredito, setEstadoCredito] = React.useState(1);

    const [creditos, setCreditos] = React.useState([]);
    const [activos, setActivos] = React.useState([]);
    const [inactivos, setInactivos] = React.useState([]);
    const [aplazados, setAplazados] = React.useState([]);
    const [vencidos, setVencidos] = React.useState([]);
    const [pagados, setPagados] = React.useState([]);

    const [creditosLista, setCreditosLista] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [nombre,setNombre] = React.useState('');
    const [apellido,setApellido] = React.useState('');
    const [telefono,setTelefono] = React.useState('');
    const [direccion,setDireccion] = React.useState('');
    const [correo,setCorreo] = React.useState('');
    const [numDocumento,setNumDocumento] = React.useState('');


    const renderItem = ({item}) => {

        return (
            <TouchableOpacity style={styles.crtInfo} onPress={()=>{
                navigation.navigate(routes.VER_ABONOS, {codCredito: item.codCredito})
            }}  >
                
                <View style={[styles.row,{marginBottom:16,justifyContent:'space-between'}]}>

                    <Text style={{            
                        fontSize:12,
                        textAlign:'left',
                        fontWeight:'bold',
                    }}>Credito #{item.codCredito}</Text>
                    <Text style={{fontSize:12, fontWeight:'bold'}}>{item.fecha}</Text>
                </View>
                <View style={[styles.row,{marginBottom:8,justifyContent:'space-between'}]}>
                    <View>
                        <Text style={styles.lblPay}>Pago</Text>
                        <Text style={styles.lblMount}>S/ {Number(item.detalle_caja_total).toFixed(2)}</Text>
                    </View>
                    <View>
                        <Text style={[styles.lblPay, {textAlign:'right'}]}>Total</Text>
                        <Text style={styles.lblMount}>S/ {Number(item.totalDeuda).toFixed(2)}</Text>
                    </View>
                </View>
                <View style={styles.crtProgress}>
                    <View style={[styles.progressBar, { 
                        width: `${(((item.totalDeuda-(item.totalDeuda-item.detalle_caja_total))/item.totalDeuda)*100)}%`,
                    }]} />
                </View>
                <View style={[styles.row,{marginTop:8,justifyContent:'space-between'}]}>
                    <View></View>
                    <View>
                        <Text style={[styles.lblPay, {textAlign:'right'}]}><Text style={{fontWeight:'bold'}}>Saldo:</Text> S/ {Number(item.totalDeuda-item.detalle_caja_total).toFixed(2)}</Text>
                        <Text style={[styles.lblPay, {textAlign:'right'}]}><Text style={{fontWeight:'bold'}}>Monto original:</Text> S/ {Number(item.valorCredito).toFixed(2)}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    const renderEmpty = () => {
        return (
                <Text style={{fontSize: 12, marginVertical: 20, textAlign:'center'}}>
                    No hay créditos
                    {
                        estadoCredito == 0 ?
                            (
                                " inactivos"
                            ) : (
                                estadoCredito == 1 ?
                                    (
                                        " activos"
                                    ) : (
                                        estadoCredito == 2 ?
                                            (
                                                " en mora"
                                            ) : (
                                                estadoCredito == 3 ?
                                                    (
                                                        " vencidos"
                                                    ) : (
                                                        estadoCredito == 4 ?
                                                            (
                                                                " pagados"
                                                            ) : (
                                                                " activos"
                                                            )
                                                    )
                                            )
                                    )
                            )
                    }
                </Text>
        );
    };


    const listarCreditos = async () => {
        try {
            setLoading(true);

            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            const codCliente = await AsyncStorage.getItem('codCliente');
            
            formData.append('codCliente', codCliente!);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_CREDITOS_V3, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }
            console.log(response.data)
            setCreditos(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.LISTAR_CREDITOS_V2);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {

            const nombre = await AsyncStorage.getItem('nombre');
            const apellido = await AsyncStorage.getItem('apellido');
            const telefono = await AsyncStorage.getItem('telefono');
            const direccion = await AsyncStorage.getItem('direccion');
            const correo = await AsyncStorage.getItem('correo');
            const numDocumento = await AsyncStorage.getItem('numDocumento')

            setNombre(nombre);
            setApellido(apellido);
            setTelefono(telefono);
            setDireccion(direccion);
            setCorreo(correo);
            setNumDocumento(numDocumento);
            await listarCreditos();
        };
        fetchData();
    }, [])
    

    useEffect(() => {

        setActivos(creditos.filter((s: { estado: string; }) => s.estado === '1'));
        setInactivos(creditos.filter((s: { estado: string; }) => s.estado === '0'));
        setAplazados(creditos.filter((s: { estado: string; }) => s.estado === '2'));
        setVencidos(creditos.filter((s: { estado: string; }) => s.estado === '3'));
        setPagados(creditos.filter((s: { estado: string; }) => s.estado === '4'));

        switch (estadoCredito+"") {
            case "0":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '0'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '0').length)
                break;
            case "1":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '1'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '1').length);
                break;
            case "2":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '2'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '2').length);
                break;
            case "3":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '3'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '3').length)
                break;
            case "4":
                setCreditosLista(creditos.filter((s: { estado: string; }) => s.estado === '4'));
                setTotal(creditos.filter((s: { estado: string; }) => s.estado === '4').length)
                break;
            default:
                break;
        }
    }, [creditos]);

    const changeEstado = (value: String) => {
        switch (value) {
            case "0":
                setCreditosLista(inactivos);
                setTotal(inactivos.length)
                break;
            case "1":
                setCreditosLista(activos);
                setTotal(activos.length)
                break;
            case "2":
                setCreditosLista(aplazados);
                setTotal(aplazados.length)
                break;
            case "3":
                setCreditosLista(vencidos);
                setTotal(vencidos.length)
                break;
            case "4":
                setCreditosLista(pagados);
                setTotal(pagados.length)
                break;
            default:
                break;
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.box}>
                <Text style={[styles.font,styles.lblTitle]}>{nombre} {apellido}</Text>
                <Text style={[styles.font,styles.lblAmountTitle]}>
                    ({total} creditos)
                </Text>
                <Text style={[styles.font,styles.lblTotalTitle]}>Total</Text>
            </View>
            <View style={styles.list}>
                <View style={styles.selectEstado}>
                    <SelectList
                        data={estados}
                        search={false}
                        boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                        inputStyles={{alignSelf:'center',fontWeight:'bold'}}
                        dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                        setSelected={(value: number) => {
                            setEstadoCredito(value);
                            changeEstado(value);
                        }}
                        defaultOption={estados[0]}
                    />
                </View>
                <FlatList
                data={creditosLista.filter((item) => (item.nombre+" "+item.apellido).toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(item) => item.codCredito}
                ListEmptyComponent={renderEmpty}
                style={{ marginTop: 16}}
                contentContainerStyle={{paddingBottom:48}}
                />
            </View>

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex:1,
        display:'flex',
        flexDirection:'column'
    },
    box:{
        backgroundColor:colors.verde_primary,
        paddingHorizontal:'5%',
        paddingVertical:24
    },
    font:{
        color:colors.white,
        fontWeight:'bold',
    },
    lblTitle:{
        fontSize:24,
    },
    lblAmountTitle:{
        textAlign:'center',
        marginTop:8,
        fontSize:18,
        fontWeight:'600'
    },
    lblTotalTitle:{
        textAlign:'center',
        marginTop:16,
        fontWeight:'400'
    },
    list:{
        flex:1,
        marginTop:-12,
        borderTopLeftRadius:24,
        borderTopRightRadius:24,
        backgroundColor:'#f2f2f2'
    },
    selectEstado:{
        paddingHorizontal:16,
        marginBottom:16,
        marginTop:16,
    },
    boxshadow:{
        display:'flex',
        borderRadius:8,
        justifyContent:'center', 
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
        padding:16,
        marginHorizontal:'5%',
        paddingBottom:8
    },
    crtInfo:{
        padding: 16,
        marginHorizontal: '5%',
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 8,
        marginBottom:16,
     },
     boxCredit:{
         marginVertical:16,
         marginTop:0,
         justifyContent:'space-between'
     },
     txtInfoCredit:{
         marginLeft:'5%',
         fontSize: 20,
         fontWeight: 'bold',
         alignSelf:'center'
     },
     btnAbono:{
         marginRight:'5%',
         paddingLeft:16,
         paddingVertical:8,
         flexDirection:'row',
         alignItems:'center'
     },
     txtAbono:{
         color:colors.negro,
         fontWeight:'bold',
         fontSize:14,
         textAlign:'right',
         alignSelf:'center',
         marginLeft:4
     },
     crtProgress: {
         width: '100%',
         height: 10,
         backgroundColor: colors.gris_fondo_cell,
         borderRadius: 4,
         overflow: 'hidden',
     },
     progressBar: {
         height: '100%',
         backgroundColor:colors.logo_azul
     },
     lblPay:{
         fontSize:12,
         color:colors.negro,
         marginBottom:6
     },
     lblMount:{
         fontSize:18,
         fontWeight:'bold',
         color:colors.logo_azul,
     },
     row:{
         display:'flex',
         flexDirection:'row',
         position:'relative',
     },
});
