import React, {useEffect, useState} from 'react';
import {FlatList, StyleSheet, Text, View} from 'react-native';

import {useFocusEffect, useRoute} from '@react-navigation/native';
import {default as colors} from '../constants/colors';
import stylesGenerales from '../constants/stylesGenrales';
import {StorageUtil} from "../shared/StorageUtil";
import urlsGenerales from "../constants/urlsGenerales";
import axios from "axios";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../shared/TiposDocumento";
import {NumberUtils} from "../shared/NumberUtils";

export default function VerCreditosCliente() {

    const route = useRoute();
    const {cliente} = route.params;

    const [creditos, setCreditos] = useState([]);
    const [creditosCancelados, setCreditosCancelados] = React.useState([]);
    const [creditosActivos, setCreditosActivos] = React.useState([]);

    const [loading, setLoading] = useState(false);

    const renderEmpty = () => {
        return (
            <View style={stylesGenerales.row}>
                <Text style={stylesGenerales.cellBodyEmpty}>-</Text>
                <Text style={stylesGenerales.cellBodyEmpty}>-</Text>
                <Text style={stylesGenerales.cellBodyEmpty}>-</Text>
            </View>
        );
    };

    const renderItem = ({item}) => {
        return (
            <View style={stylesGenerales.row}>
                <Text style={[{color: item.estado == 0 ? "gray":"black"},stylesGenerales.cellBodyEmpty]}>{item.fecha}</Text>
                <Text style={[{color: item.estado == 0 ? "gray":"black"},stylesGenerales.cellBodyEmpty]}>S/ {NumberUtils.formatThousands(item.valorCredito)}</Text>
                <Text style={[{color: item.estado == 0 ? "gray":"black"},stylesGenerales.cellBodyEmpty]}>S/ {NumberUtils.formatThousands(item.totalDeuda)} {item.estado == 0 ? " (Eliminado)":""}</Text>
            </View>
        );
    };

    const renderItem2 = ({item}) => {
        return (
            <View style={stylesGenerales.row}>
                <Text style={stylesGenerales.cellBodyEmpty}>{item.fecha}</Text>
                <Text style={stylesGenerales.cellBodyEmpty}>S/ {NumberUtils.formatThousands(item.totalDeuda)}</Text>
                <Text style={stylesGenerales.cellBodyEmpty}>S/ {NumberUtils.formatThousands(item.saldoCredito)}</Text>
            </View>
        );
    };

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const formData = new FormData();
                    const codUsuario = await StorageUtil.getCodUsuarioStorage();
                    formData.append('codUsuario', codUsuario!);
                    formData.append('codCliente', cliente.codCliente!);
                    const token = await StorageUtil.getTokenStorage();
                    const response = await axios.post(urlsGenerales.LISTAR_CREDITOS, formData, {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    setCreditos(response.data);

                } catch (error) {
                    console.log('Error en ->', urlsGenerales.LISTAR_CREDITOS);
                    console.log(error);

                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }, [])
    );

    useEffect(() => {
        setCreditosActivos(creditos.filter(s => s.estado === '1' || s.estado === '2' || s.estado === '3'));
        setCreditosCancelados(creditos.filter(s => s.estado === '0' || s.estado === '4'));
    }, [creditos]);

    return (
        <View style={styles.container}>

            <Text style={styles.labelCliente}>Cliente: {cliente.nombre} {cliente.apellido}</Text>

            <Text style={styles.titleTable}>Créditos Cancelados</Text>

            <View style={stylesGenerales.row}>
                <View style={[stylesGenerales.cell, {borderTopLeftRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopLeftRadius: 5}]}>Fecha</Text>
                </View>
                <View style={[stylesGenerales.cell]}>
                    <Text style={[stylesGenerales.textCellHeader]}>Val. Credito</Text>
                </View>
                <View style={[stylesGenerales.cell, {borderTopRightRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopRightRadius: 5}]}>Total.</Text>
                </View>
            </View>
            <FlatList
                data={creditosCancelados}
                renderItem={renderItem}
                keyExtractor={(item) => item.codCredito}
                extraData={creditosCancelados.length}
                ListEmptyComponent={renderEmpty}
                style={{width: '100%', marginLeft: '10%', maxHeight: '20%'}}
            />

            <Text style={styles.titleTable}>Créditos Activos</Text>
            <View style={stylesGenerales.row}>
                <View style={[stylesGenerales.cell, {borderTopLeftRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopLeftRadius: 5}]}>Fecha</Text>
                </View>
                <View style={[stylesGenerales.cell]}>
                    <Text style={[stylesGenerales.textCellHeader]}>Total.</Text>
                </View>
                <View style={[stylesGenerales.cell, {borderTopRightRadius: 5}]}>
                    <Text style={[stylesGenerales.textCellHeader, {borderTopRightRadius: 5}]}>Saldo</Text>
                </View>
            </View>
            <FlatList
                data={creditosActivos}
                renderItem={renderItem2}
                keyExtractor={(item) => item.codCredito}
                extraData={creditosActivos.length}
                ListEmptyComponent={renderEmpty}
                style={{width: '100%', marginLeft: '10%'}}
            />

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </View>)
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: '4%'
        // justifyContent: 'center'
    },

    labelNombre: {
        width: '90',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 25
    },

    labelCliente: {
        width: '90%',
        textAlign: 'left',
        marginTop: 15
    },

    // ESTILOS DE LA TABLA
    header: {
        flexDirection: 'row',
        backgroundColor: colors.logo_azul,
        borderTopStartRadius: 10,
        borderWidth: 0.5,
        width: 400
    },

    row: {
        flexDirection: 'row',
        width: 400
    },

    headerText: {
        padding: 12,
        color: colors.white,
        fontWeight: 'bold',
    },

    cellText: {
        padding: 12,
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    cell: {
        flex: 1,
    },

    titleTable: {
        fontWeight: 'bold',
        fontSize: 15,
        marginBottom: 10,
        marginTop: 25
    },

    cellBody: {
        flex: 1,
        backgroundColor: colors.gris_fondo_cell,
        borderColor: colors.logo_gris,
        borderWidth: 0.5,
        padding: 5
    }
})
