import React, {useEffect, useState} from 'react';
import {FlatList, Linking, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import {useFocusEffect, useRoute} from "@react-navigation/native";
import urlsGenerales from "../../../constants/urlsGenerales";
import axios from "axios";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../../shared/TiposDocumento";
import stylesTablas from "../../../constants/stylesTablas";
import {NumberUtils} from "../../../shared/NumberUtils";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';

export default function CreditosVencidos() {

    const navigation = useNavigation();

    const route = useRoute();
    const cobrador = route.params?.cobrador;
    const [loading, setLoading] = useState(false);

    const [cantidad, setCantidad] = React.useState(0);
    const [totalVencido, setTotalVencido] = useState(0);
    const [creditosVencidos, setCreditosVencidos] = useState([]);

    const [searchText, setSearchText] = React.useState('');

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const obtenerCreditosVencidos = async () => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo!);
            const token = await StorageUtil.getTokenStorage(); 
            const response = await axios.post(urlsGenerales.REP_CREDITOS_VENCIDOS, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                setCreditosVencidos(response.data);
            }

        } catch (e) {
            console.log('Error en -> ', urlsGenerales.REP_CREDITOS_VENCIDOS);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                await obtenerCreditosVencidos();
            };
            fetchData();
        }, [])
    );

    useEffect(() => {
        setCantidad(creditosVencidos.length);
        setTotalVencido(creditosVencidos.reduce((acumulador, elemento) => {
            return acumulador + parseFloat(elemento.saldo);
        }, 0));
    }, [creditosVencidos]);

    const descargarListadoVencidos = () => {
        Linking.openURL(urlsGenerales.REP_DESCARGAR_CARTERA_EXCEL + '?codUsuario=' + cobrador.codUsuarioHijo!)
            .then(() => {
                console.log('Se descargó el excel correctamente');
            })
            .catch((err: any) => console.error('Error al descargar excel: ', err));
    }

    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 12, marginVertical: 15, textAlign:'center'}}>No hay créditos vencidos</Text>
        );
    };

    const renderItem = ({item}) => {

        const enviarSMS = () => {
            const mensaje = 'Hola, ¿cómo estás?'; // Pendiente definir mensaje a enviarse
            const phoneUrl = `whatsapp://send?phone=${item.telefono}&text=${encodeURIComponent(mensaje)}`;
            Linking.canOpenURL(phoneUrl)
                .then((supported) => {
                    if (supported) {
                        return Linking.openURL(phoneUrl);
                    } else {
                        console.log("No es posible abrir WhatsApp");
                    }
                })
                .catch((error) => console.log(error));
        }

        const llamar = () => {
            const phoneUrl = `tel:${item.telefono}`;
            Linking.openURL(phoneUrl);
        };

        return (
            <View style={{
                borderBottomWidth: 0.5,
                borderBottomColor: '#222222',
                marginBottom: 8,
                paddingVertical:8,
                paddingBottom:16,
                paddingHorizontal:'5%',
            }}
            >
                <View style={{flexDirection:'row',display:'flex'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={{alignSelf:'center',flex:1,fontSize:18,fontWeight:'bold',marginBottom:4, color:colors.rojo, marginRight:16}}>{item.nombre} {item.apellido}</Text>
                    <View style={[styles.rowButtons]}>
                            <TouchableOpacity 
                                onPress={enviarSMS}
                                style={[styles.buttonOptions, {borderColor: colors.verde_primary, borderWidth:1,marginRight:16}]}
                            >
                                <Text style={[styles.textButtonOptions,{color:colors.verde_primary}]}>
                                    WhatsApp</Text>
                            </TouchableOpacity>
                        
                            <TouchableOpacity 
                            onPress={llamar}
                            style={[styles.buttonOptions, {backgroundColor: colors.logo_azul}]}
                            >
                                <Text style={styles.textButtonOptions}>Llamar</Text>
                            </TouchableOpacity>
                        
                    </View>
                </View>

                <View style={styles.boxShadow}>
                    <Text style={{fontSize:16,fontWeight:'bold'}}>Información del credito</Text>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3,marginTop:4}}>
                        <Text style={{textAlign:'left'}}>Fecha</Text>
                        <Text style={{fontSize:14, textAlign:'left'}}>{item.fecha}</Text>
                    </View>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3}}>
                        <Text style={{textAlign:'left'}}>Capital</Text>
                        <Text style={{fontSize:14,fontWeight:'bold', textAlign:'left'}}>S/ {Number(item.capital).toFixed(2)}</Text>
                    </View>
                    <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                        <Text style={{textAlign:'left',color:colors.rojo}}>Saldo</Text>
                        <Text style={{fontSize:14,fontWeight:'bold', textAlign:'left',color:colors.rojo}}>S/ {Number(item.saldo).toFixed(2)}</Text>
                    </View>
                </View>
                <View style={styles.boxShadow}>
                    <Text style={{fontSize:16,fontWeight:'bold'}}>Información del cliente</Text>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3,marginTop:4}}>
                        <Text style={{textAlign:'left'}}>Teléfono</Text>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={{fontSize:14, textAlign:'left'}}>{item.telefono}</Text>
                    </View>
                    <View style={{flexDirection:'row',justifyContent:'space-between', marginBottom:3}}>
                        <Text style={{textAlign:'left', marginRight:16}}>Dirección</Text>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={{fontSize:14, textAlign:'left'}}>{item.direccion}</Text>
                    </View>
                    <View style={{flexDirection:'row',justifyContent:'space-between', flex:1}}>
                        <Text style={{textAlign:'left', marginRight:16}}>Fiador</Text>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={{fontSize:14, textAlign:'right', width:200}}>{item.alias}</Text>
                    </View>
                </View>
            </View>
        )
    }

    return (
        <View style={styles.container}>

            <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingHorizontal:'5%', marginBottom:16}}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({cantidad}{cantidad==1?' credito vencido':' creditos vencidos'})</Text></Text>
                <View style={{alignSelf:'center'}}>
                    <Text style={{fontSize:12, textAlign:'right',paddingTop:8,color:colors.logo_azul,fontWeight:'400'}}>Total vencido</Text>
                    <Text style={{fontSize:20,fontWeight:'bold',color:colors.logo_azul}}>S/ {totalVencido}</Text>
                </View>
            </View>

            <TouchableOpacity    style={styles.btn} onPress={descargarListadoVencidos}>
                <Icon name='file-download' color={colors.white}></Icon>
                <Text style={{
                    color: colors.white,
                    fontWeight: 'bold',
                }}>Descargar creditos vencidos</Text>
            </TouchableOpacity>

            <View style={[styles.row,styles.mrg]}>
                <View style={styles.iconSearch}>
                    <Icon name='search' color={colors.negro}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    style={styles.inputSearch}
                    onChangeText={handleSearch}
                    value={searchText}
                    selectionColor={colors.negro}
                />
                {
                searchText !== '' &&
                    <TouchableOpacity
                        style={styles.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.negro}></Icon>
                    </TouchableOpacity>
                }
            </View>

            <FlatList
                data={creditosVencidos}
                renderItem={renderItem}
                keyExtractor={(item) => item.codCredito}
                ListEmptyComponent={renderEmpty}
                style={{ marginTop: 15, marginBottom:48}}
                contentContainerStyle={{
                    paddingBottom:48
                }}
            />
            

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex:1,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
    },
    btn:{
        backgroundColor:colors.verde_primary,
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
        height:48,
        marginHorizontal:'5%',
        alignItems:'center',
        borderRadius:4,
        alignContent:'center',
        justifyContent:'center'
    },
    
    boxShadow:{
        display:'flex',
        borderRadius:8,
        justifyContent:'center', 
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
        padding:16,
        marginTop:8
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor: colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    mrg:{
        marginHorizontal:'5%',
        marginTop:16,
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },
    rowButtons: {
        display:'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        alignSelf:'center',
    },
    buttonOptions: {
        flexDirection: "row",
        borderRadius: 4,
        height:40,
        justifyContent: "center",
        paddingHorizontal:8,
    },
    textButtonOptions: {
        fontSize: 10,
        fontWeight: 'bold',
        color: colors.white,
        alignSelf:'center',
        textAlign:'center',
    },
})
