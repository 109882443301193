import {useNavigation, useRoute, useFocusEffect} from '@react-navigation/native';
import React, {useEffect, useRef, useState, useCallback} from 'react';
import {Linking, Modal, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View, Image, Switch, RefreshControl} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../constants/colors';
import routes from '../constants/routes';

import axios from 'axios';
import urlsGenerales from '../constants/urlsGenerales';
import {DateUtils} from '../shared/DateUtils';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {SelectList} from 'react-native-dropdown-select-list';
import {StringUtils} from "../shared/StringUtils";
import {StorageUtil} from "../shared/StorageUtil";
import {CajaService} from "../shared/CajaService";
import CustomAlert from '../shared/CustomAlert';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';
import {RecaudoDTO} from '../common/dtos/recaudo'


const ItemCuota = ({item={}, select})=>{
    const [countPay,setCountPay] = useState(Number(0).toString())
    const [isCheked,setIscheked] = useState(item.cheked)

    useEffect(() => {
        setIscheked(item.cheked)
        try {
            const result = Number(item.monto) - Number(item.pago)
            setCountPay(result.toString())
        } catch (error) {
            setCountPay(Number(0).toString())
        }
    }, [item])
    
    const handle =(value)=>{
        if(value>(Number(item.monto) - Number(item.pago))){
                setCountPay((Number(item.monto) - Number(item.pago)).toString())
        }else{
                setCountPay(value)
        }
        select(false, value)
    }

    useEffect(() => {
      setIscheked(false)
    }, [countPay])
    

    return(
        <View style={[styles.crtCuota,{borderLeftWidth:8,borderLeftColor:item.estado==3?colors.rojo:item.estado==2?colors.naranja:colors.logo_azul}]}>
            <TouchableOpacity
                activeOpacity={0.7}
                style={styles.row} 
                onPress={()=>{
                    if(countPay || countPay>0){
                        setIscheked(!isCheked)
                        select(!isCheked, countPay)
                    }
                }}
            >
                <View style={styles.boxRadius}>
                    <View style={[styles.radiusColor,{backgroundColor:isCheked?colors.negro:'transparent'}]}></View>
                </View>
                <View style={{flex:1,alignSelf:'center'}}>
                    <Text style={styles.lblQuota}>Cuota {item.codCuota}</Text>
                    <Text style={styles.lblDate}>{item.fechaVencimiento}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.textPay}>S/ </Text>
                    <TextInput
                        editable={true}
                        placeholder='0'
                        style={[styles.payInput]}
                        value={countPay} 
                        keyboardType='numeric'
                        onChangeText={handle}
                    >

                    </TextInput>
                    <Text style={{alignSelf:'center', fontSize:12}}> {countPay==1?' sol':' soles'}</Text>
                    <View style={{position:'absolute',bottom:-12,right:-8}}>
                        <Text style={[styles.lblDate,{fontSize:10}]}>Maximo <Text style={{fontWeight:'bold'}}>S/ {(Number(item.monto) - Number(item.pago)).toFixed(2)}</Text></Text>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}

const CuotasComponent = ({list, callbackAbonar}) =>{
    const [listPay,setLisPay] = useState([...list])
    const [newListPay,setNewLisPay] = useState([...list])
    
    const handleAbonarV2 = () => {
        const fixed = [...newListPay]
        const checkedItems = fixed.filter(item => item.cheked);
        if(checkedItems.length>0){
            callbackAbonar(checkedItems)
        }
    }

    useEffect(() => {
      setLisPay([...list])
      setNewLisPay([...list])
    }, [list])
    
    
    return (
        <View style={{marginBottom:40, marginTop:24}}>
            <Text style={{fontSize:20, fontWeight:'bold', marginBottom:18, marginLeft:'5%'}}>Pagos Pendientes<Text style={{fontSize:12,fontWeight:'500'}}>  ({listPay && listPay.length ? listPay.length: 0} cuotas)</Text></Text>
            <View>
            {
             listPay ? 
             listPay.length>0?
             listPay.map((item,index)=>
                    <ItemCuota 
                        item={item} 
                        key={index}
                        select={(value,text) => {
                            const newItems = [...newListPay];
                            newItems[index]['cheked'] = value;
                            try {
                                newItems[index].mountPay = Number(text);
                            } catch (error) {
                                delete newItems[index].mountPay
                            }
                            setNewLisPay(newItems);
                        }}
                    />
                
            ):
                <Text style={{textAlign:'center',marginHorizontal:'5%', marginTop:6}}>Cliente al día en sus pagos :)</Text>
            :
                <Text style={{textAlign:'center',marginHorizontal:'5%', marginTop:6}}>Cargando información de cuotas...</Text>
            }
            </View>
            {
                listPay && listPay.length>0 ? 
                <TouchableOpacity style={[styles.botonFooter,
                    {backgroundColor: colors.verde_primary,marginTop:24}]}
                                    onPress={handleAbonarV2}>
                    <Icon name='check' color={colors.white}></Icon>
                    <Text style={styles.labelBoton}>Abonar</Text>
                </TouchableOpacity>
                :
                null
            }
        </View>
    )    
}

const Recaudar = () => {

    const route = useRoute()
    const {disabledFn} = route.params
    const navigation = useNavigation();
    const [isConnected, setIsConnected] = useState(true)
    const [recaudo, setRecaudo] = useState(new RecaudoDTO())
    const [showAll,setShowALL] = useState(false)
    const [newVersion,setNewVersion] = useState(true)
    const [listPagos,setListPagos] = useState([])
    const [listPagosv2,setListPagosv2] = useState('[]')
    // INPUTS
    const [valorCuota, setValorCuota] = useState(0)
    // MODALS
    const [modalMedioPago, setModalMedioPago] = useState(false)
    const [modalRecrearCredito, setModalRecrearCredito] = useState(false)
    // LOADING
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false);
    // SELECTS
    const [medioPago, setMedioPago] = useState('')
    const [detalleMedio, setDetalleMedio] = useState('')
    // NEW DEV
    const inputValCuotaRef = useRef(null)

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
          setIsConnected(state.isConnected);
        });
    
        return () => {
          unsubscribe();
        };
    }, []);

    useFocusEffect(
        useCallback(() => {
            get()
        }, [])
    );

    useEffect(() => {
        fetchDetail(false)
    }, [isConnected])

    const get = async() => {
        const recargar = await AsyncStorage.getItem('recargar_detalle_credito')
        console.log("Necesito Actualizar Detalle Credito ? ", recargar)
        if(recargar){
            await AsyncStorage.removeItem('recargar_detalle_credito')
            if(recargar == 'si'){
                fetchDetail(false)
            } 
        }
    }

    const searchByCode = (array, code) =>{
        return array.find(obj => obj.codCredito === code);
    }

    const itemReplaceOrPush = async(array, code, newObj) => {
        let newArray = array
        // Buscar el objeto por su ID
        let objExist = searchByCode(array, code);
        if (objExist) {
            const index = array.findIndex(obj => obj.codCredito === code)
            newArray[index] = newObj;
        } else {
            newArray.push(newObj);
        }
        await AsyncStorage.setItem('listDetail',JSON.stringify(newArray))
    }

    const fetchDetail = async(refresh) => {
        
        await setLoading(true)

        const {codCredito} = route.params

        const codUserStorage = await AsyncStorage.getItem('codUsuario');
        const listDetailStorage = await AsyncStorage.getItem('listDetail');

        try {
            if(codUserStorage && listDetailStorage){
                const listDetail = JSON.parse(listDetailStorage);
                const existCredit = searchByCode(listDetail,codCredito?codCredito:route.params.recaudo.codCredito)
                if(existCredit){
                    setRecaudo(existCredit);
                    setListPagos([...existCredit.quota_not_pay])
                    if(!refresh){
                        console.log("entra aquiiiiii")
                        setLoading(false)
                    }
                    setListPagosv2('[]')
                }
            }
        } catch (error) {
            await AsyncStorage.removeItem('listDetail')
        }
        
        
        try {
            //LOGICA PARA MOSTRAR EL DETALLE
            let responseFinal = new RecaudoDTO();
            if(codCredito || route.params.recaudo){
                const formData = new FormData();
                formData.append('codCredito', codCredito?codCredito:route.params.recaudo.codCredito);
                const token = await StorageUtil.getTokenStorage(); 
                const response = await axios.post(urlsGenerales.DETALLE_CREDITO_V2, formData, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.data == 'redirigir') {
                    navigation.replace(routes.LOGIN);
                    return;
                }
                responseFinal = response.data;
                if(listDetailStorage){
                    const list = JSON.parse(listDetailStorage);
                    await itemReplaceOrPush(list,codCredito?codCredito:route.params.recaudo.codCredito,responseFinal)
                }else{
                    let arrayList = []
                    arrayList.push(responseFinal)
                    const credit = JSON.stringify(arrayList)
                    AsyncStorage.setItem('listDetail',credit)
                }
                await setLoading(false)
            }else if(route.params.recaudo){
                responseFinal = route.params.recaudo
                await setLoading(false)
            }
            setRecaudo(responseFinal);
            await setListPagos([...responseFinal.quota_not_pay])
            // if(responseFinal.estado == "3" || responseFinal.estado == "4"){
            //     responseFinal.valorCuota = responseFinal.valorCuotaTotal;
            // }
            setListPagosv2('[]')
            setValorCuota(Number(0).toString());
            console.log("API DETAIL", responseFinal)
        } catch (error) {
            console.log("error del detalle", error)
            setListPagosv2('[]')
            await setLoading(false)
        }
    }

    const validarCajaAbierta = async () => {
        try {
            await setLoading(true);
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuario!, navigation);
            await setLoading(false);
            return estadoCajaValida;
        } catch (error) {
           return false 
        }
    }

    const intentarPagar = async(formData : FormData, isNewVersion?) => {
        let msgRegistro = "error";
        try {
            setLoading(true);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(isNewVersion?urlsGenerales.PAGAR_CUOTA_V3:urlsGenerales.PAGAR_CUOTA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                setLoading(false);
                return;
            }

            console.log(response.data)
            if(newVersion){
                if (response.data) {
                    if(response.data.resultado === "ok"){
                        msgRegistro = 'ok'
                        await AsyncStorage.setItem('recargar_home', "si");
                    }else{
                        msgRegistro = "error";
                    }
                }else{
                    msgRegistro = "error";
                }
            }else{
                if (response.data) {
                    if(response.data.resultado === "ok"){
                        CustomAlert.show({
                            title: 'Mensaje',
                            message: 'Pago Exitoso',
                            buttons: [
                                {
                                text: 'OK',
                                onPress: () => {
                                    if (Number(valorCuota.toString()) >= Number(recaudo.saldoCredito) ){
                                        setModalRecrearCredito(true);
                                    }else{
                                        navigation.goBack();
                                    }
                                },
                                }
                            ],
                        });
                        msgRegistro = "ok";
                        await AsyncStorage.setItem('recargar_home', "si");
                    }else{
                        msgRegistro = "duplicado";
                    }    
                }else{
                    msgRegistro = "error";
                }
            }

        } catch (error) {
            msgRegistro = "error";
            console.error(error);
        } finally {
            setLoading(false);
        }

        return msgRegistro;
    }

    const abonar = async () => {
        if(isConnected){
            const estadoCajaValida = await validarCajaAbierta()
            if(estadoCajaValida == 'redirigir'){
                return
            }
            if (estadoCajaValida) {
                await setLoading(true)
                const formData = new FormData();
                formData.append('codUsuario', recaudo.codUsuario);
                formData.append('codCredito', recaudo.codCredito);
                formData.append('monto', valorCuota.toString());
    
                if (medioPago === '') {
                    CustomAlert.show({
                        title: 'Aviso',
                        message: 'Debe seleccionar un medio de pago.'
                    });
                    return;
                }
    
                if (medioPago === '1') {
                    formData.append('tipoPago', '0');
                } else {
                    formData.append('tipoPago', detalleMedio);
                }
                
                const msgRegistro = await intentarPagar(formData);
    
                if (msgRegistro === "duplicado"){
                    CustomAlert.show({
                        title: 'Aviso',
                        message: 'Parece que hace poco registraste un pago para este credito con el mismo medio de pago.\n\n ¿ Desea registrar otro de todas formas ?',
                        buttons: [
                            {
                                text: 'SI, HAZLO',
                                onPress: () => {
                                    formData.append('force', "1");
                                    intentarPagar(formData);
                                },
                            },
                            {
                                text: 'NO, REGRESAR',
                                onPress: () => {
                                    navigation.goBack();
                                },
                            },
                            {
                                text: 'CANCELAR'
                            },
                        ],
                    });
                  
    
                }else if(msgRegistro === "error"){
                    CustomAlert.show({
                        title: 'Error',
                        message: 'No se pudo realizar el pago, intentelo nuevamente.'
                    });
                    
                }
                
            } else {
                await setLoading(false);
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const abonarV2 = async () => {
        if(isConnected){
            await setTimeout(() => {}, 100);
            const estadoCajaValida = await validarCajaAbierta()
            if(estadoCajaValida == 'redirigir'){
                return
            }
            if (estadoCajaValida) {
                await setLoading(true)
                const formData = new FormData();
                const checkedItems = JSON.parse(listPagosv2).filter(item => item.cheked);
                formData.append('codUsuario', recaudo.codUsuario);
                formData.append('codCredito', recaudo.codCredito);
                formData.append('listQuota', JSON.stringify(checkedItems));
                if (medioPago === '1') {
                    formData.append('tipoPago', '0');
                } else {
                    formData.append('tipoPago', detalleMedio);
                }
                console.log("listaa enviar",{
                    list:checkedItems,
                    cod:recaudo.codUsuario,
                    cred:recaudo.codCredito,
                    tipo:medioPago=='1'?'0':detalleMedio
                })
                const sumaMountPay = checkedItems.reduce((total, objeto) => total + objeto.mountPay, 0);
                const msgRegistroResp = await intentarPagar(formData, true);
                await setTimeout(() => {}, 100);
                if(msgRegistroResp == 'ok'){
                    CustomAlert.show({
                        title: 'Mensaje',
                        message: 'Pago Exitoso',
                        buttons: [
                            {
                                text: 'OK',
                                onPress: () => {
                                    if(Number(sumaMountPay) >= Number(recaudo.saldoCredito)){
                                        setModalRecrearCredito(true);
                                    }else{
                                        navigation.goBack();
                                    }
                                },
                            }
                        ],
                    });
                    setMedioPago('')
                    setDetalleMedio('')
                }
                await setLoading(false)
            }else{
                await setLoading(false);
                CustomAlert.cajaBloq();
                return;
            }
        }
    }
    
    const handleChangeValorCuota = (valorCuota: string) => {
        try {
            if( valorCuota > Number(recaudo.saldoCredito)){
                setValorCuota(Number(recaudo.saldoCredito).toString())
            }else{
                setValorCuota(valorCuota)
            }
        } catch (error) {
            setValorCuota(Number(0).toString())
        }
    }

    const mediosPago = [
        {key: '1', value: 'Efectivo'}, {key: '2', value: 'Transferencia'},
    ]

    const detallesMedioPago = [
        {key: '1', value: 'Yape'},
        {key: '2', value: 'Plin'},
        {key: '3', value: 'BCP'},
        {key: '4', value: 'Interbank'},
        {key: '5', value: 'Otros'},
    ];

    const obtenerPlazo = (): string => {
        return StringUtils.getDescPlazoBD(recaudo.cantCuotas, recaudo.codFrecuencia,
            recaudo.diaFrecuencia, recaudo.diaFrecuencia2);
    }

    const handlerEnviar = () => {
        const mensaje = 'Hola, te saluda el equipo de CFP y este es el estado de tu Credito:\n\n*Fecha Credito: '+recaudo.fecha+'*\n*Fecha Venc.: '+recaudo.fecVencimiento+'*\n*Capital inicial: '+recaudo.valorCredito+'*\n*Cuotas Pagadas: '+recaudo.cantCuotasPagadas+'*\n*Plazo: '+obtenerPlazo()+'*\n*Val. Cuota: '+recaudo.valorCuota+'*\n*Saldo: S/ '+recaudo.saldoCredito+'*\n*Estado: '+recaudo.detEstado+'*\n\n*Conserve su crédito pagando oportunamente :)*';
        let v_tele = recaudo.telefono;

        if(recaudo.telefono.length === 9){
            v_tele = '51'+recaudo.telefono;
        }
        const phoneUrl = `https://wa.me/${v_tele}?text=${encodeURIComponent(mensaje)}`;
        Linking.openURL(phoneUrl);
    }

    const handleLlamar = () => {
        const phoneUrl = `tel:${recaudo.telefono}`;
        Linking.openURL(phoneUrl);
    }

    const verAbonos = () => {
        navigation.navigate(routes.VER_ABONOS, {codCredito: recaudo.codCredito, disabledFn:disabledFn})
    }

    const recrearCredito = () => {
        var cliente = recaudo;
        setModalRecrearCredito(false);
        navigation.navigate(routes.ASIGNAR_CREDITO, {cliente});
    }

    const handleAbonar = () => {
        
        if (valorCuota == '' || valorCuota == '0') {
            CustomAlert.show({
                title: 'Mensaje',
                message: 'Debe ingresar un monto válido a pagar'
            });
            
            // @ts-ignore
            inputValCuotaRef.current.focus();
            return;
        }

        if (Number(valorCuota) > Number(recaudo.saldoCredito)) {
            
            CustomAlert.show({
                title: 'Mensaje',
                message: 'Debe ingresar un monto igual o menor al saldo Actual'
            });
            // @ts-ignore
            inputValCuotaRef.current.focus();
            return;
        }

        setModalMedioPago(true);
    }

    const onRefresh = async() => {
        await setRefreshing(true);
        await fetchDetail(true)
        await setRefreshing(false)
    }
    
    return (
        <>
        <ScrollView
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
        >   
            <View style={styles.crtInfoUser}>
                <Text style={styles.cod}>Credito #{recaudo.codCredito}</Text>
                    <View style={{display:'flex', alignSelf:'center',}}>
                        <Image 
                            style={[styles.circleStatus]}
                            resizeMode="center"
                            source={require('../assets/client.png')}
                        />
                    </View>
                <View style={styles.row}>
                    <View style={{flex:1}}>
                        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.labelTitle}>{recaudo.nombre} {recaudo.apellido}</Text>
                    </View>
                </View>
                {
                    recaudo.telefono && recaudo.telefono.length === 9?
                        <View style={[styles.rowButtons]}>
                                <TouchableOpacity 
                                    onPress={handlerEnviar}
                                    style={[styles.buttonOptions, {borderColor: colors.verde_primary, borderWidth:1,marginRight:16}]}
                                >
                                    <Text style={[styles.textButtonOptions,{color:colors.verde_primary}]}>
                                        WhatsApp</Text>
                                </TouchableOpacity>
                            
                                <TouchableOpacity 
                                onPress={handleLlamar}
                                style={[styles.buttonOptions, {backgroundColor: colors.logo_azul}]}
                                >
                                    <Text style={styles.textButtonOptions}>Llamar</Text>
                                </TouchableOpacity>
                            
                        </View>
                    :
                        null
                }
            </View>
            <View style={[styles.row,styles.boxCredit]}>
                <View style={[styles.row,{marginLeft:'5%'}]}>
                    {
                        disabledFn ? null :
                        <>
                    <Switch
                        trackColor={{false: '#767577', true: '#767577'}}
                        thumbColor={newVersion ? colors.verde_shadow : '#f4f3f4'}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={()=>{setNewVersion(previousState => !previousState)}}
                        value={newVersion}
                        style={{alignSelf:'center'}}
                    />
                    <Text style={{alignSelf:'center',marginLeft:8}}>{newVersion?'Pago por cuotas':'Otro monto'}</Text>
                    </>
                    }
                </View>
                <TouchableOpacity 
                    style={styles.btnAbono}
                    onPress={verAbonos}
                >
                    <Icon name='visibility' color={colors.negro}></Icon>
                    <Text style={styles.txtAbono}>Ver abonos</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.crtInfo}>
                <View style={[styles.row,{marginBottom:8,justifyContent:'space-between'}]}>
                    <View>
                        <Text style={styles.lblPay}>Pago</Text>
                        <Text style={styles.lblMount}>S/ {Number(recaudo.totalDeuda-recaudo.saldoCredito).toFixed(2)}</Text>
                    </View>
                    <View>
                        <Text style={[styles.lblPay, {textAlign:'right'}]}>Total</Text>
                        <Text style={styles.lblMount}>S/ {Number(recaudo.totalDeuda).toFixed(2)}</Text>
                    </View>
                </View>
                <View style={styles.crtProgress}>
                    <View style={[styles.progressBar, { 
                        width: `${(((recaudo.totalDeuda-recaudo.saldoCredito)/recaudo.totalDeuda)*100)}%`,
                    }]} />
                </View>
                <View style={[styles.row,{marginTop:8,justifyContent:'space-between'}]}>
                    <View>
                        <Text style={[styles.lblPay]}><Text style={{fontWeight:'bold'}}>Cuota:</Text> {recaudo.cantCuotasPagadas} de {recaudo.cantCuotas}</Text>
                    </View>
                    <View>
                        <Text style={[styles.lblPay, {textAlign:'right'}]}><Text style={{fontWeight:'bold'}}>Saldo:</Text> S/ {Number(recaudo.saldoCredito).toFixed(2)}</Text>
                        <Text style={[styles.lblPay, {textAlign:'right'}]}><Text style={{fontWeight:'bold'}}>Monto original:</Text> S/ {Number(recaudo.valorCredito).toFixed(2)}</Text>
                    </View>
                </View>
            </View>
            {
                !showAll ? 
                <>
                    <View style={styles.boxInfo}>
                        <Text style={styles.txtBoxInfo}>Estado</Text>
                        <Text style={[styles.txtBoxInfo,{fontWeight:'bold', color: recaudo.estado == "2"  ? colors.naranja : (recaudo.estado == "3"  ?  colors.rojo : colors.logo_azul)}]}>{recaudo.detEstado}</Text>
                    </View>
                    <TouchableOpacity onPress={()=>{setShowALL(true)}} style={{marginTop:24}}>
                        <Text style={{textAlign:'center',color:colors.logo_naranja, fontWeight:'bold'}}>Ver mas</Text>
                    </TouchableOpacity>
                </>
                :
                <>
                    <View style={styles.boxInfo}>
                        <Text style={styles.txtBoxInfo}>Estado</Text>
                        <Text style={[styles.txtBoxInfo,{fontWeight:'bold', color: recaudo.estado == "2"  ? colors.naranja : (recaudo.estado == "3"  ?  colors.rojo : colors.logo_azul)}]}>{recaudo.detEstado}</Text>
                    </View>
                    <View style={styles.boxInfo}>
                        <Text style={styles.txtBoxInfo}>Fecha de inicio</Text>
                        <Text style={[styles.txtBoxInfo,{fontWeight:'bold'}]}>{DateUtils.formatDateYYYYMMDD(recaudo.fecha)}</Text>
                    </View>
                    <View style={styles.boxInfo}>
                        <Text style={styles.txtBoxInfo}>Fecha de vencimiento</Text>
                        <Text style={[styles.txtBoxInfo,{fontWeight:'bold'}]}>{DateUtils.formatDateYYYYMMDD(recaudo.fecVencimiento)}</Text>
                    </View>
                    <View style={styles.boxInfo}>
                        <Text style={styles.txtBoxInfo}>Tipo de cobro</Text>
                        <Text style={[styles.txtBoxInfo,{fontWeight:'bold'}]}>{StringUtils.typePay(recaudo.codFrecuencia, recaudo.diaFrecuencia, recaudo.diaFrecuencia2)}</Text>
                    </View>
                    <View style={styles.boxInfo}>
                        <Text style={styles.txtBoxInfo}>Tasa de interés</Text>
                        <Text style={[styles.txtBoxInfo,{fontWeight:'bold'}]}>{Number(recaudo.interes).toFixed(2)} %</Text>
                    </View>
                    <View style={styles.boxInfo}>
                        <Text style={styles.txtBoxInfo}>Cuota</Text>
                        <Text style={[styles.txtBoxInfo,{fontWeight:'bold'}]}>S/ {Number(recaudo.valorCuota).toFixed(2)}</Text>
                    </View>
                    <View style={styles.boxInfo}>
                        <Text style={styles.txtBoxInfo}>Monto de deuda (hoy)</Text>
                        <Text style={[styles.txtBoxInfo,{fontWeight:'bold'}]}>S/ {Number(recaudo.montoAlDia).toFixed(2)}</Text>
                    </View>
                    <TouchableOpacity onPress={()=>{setShowALL(false)}} style={{marginTop:24}}>
                        <Text style={{textAlign:'center',color:colors.logo_naranja, fontWeight:'bold'}}>Ver menos</Text>
                    </TouchableOpacity>
                </>
            }

            {
                disabledFn? null :
                !newVersion?
            <>
                <View style={[{marginHorizontal:'5%', marginVertical:24,}]}>
                    <View>
                        <Text style={{marginBottom:8,fontSize:16, fontWeight:'bold'}}>Otro monto</Text>
                        <View style={styles.row}>
                            <Text style={styles.textPay}>S/ </Text>
                            <TextInput
                                    style={styles.inputCuota}
                                    value={valorCuota.toString()}
                                    id="txtmonto"
                                    placeholder={'0'}
                                    onChangeText={handleChangeValorCuota}
                                    keyboardType={'numeric'}
                                    ref={inputValCuotaRef}
                                ></TextInput>
                            <Text style={{alignSelf:'center', fontSize:12}}> {valorCuota==1?' sol':' soles'}</Text>
                        </View>
                    </View>
                </View>

                <TouchableOpacity style={[styles.botonFooter,
                    {backgroundColor: colors.verde_primary,marginBottom:64}]}
                                    onPress={handleAbonar}>
                    <Icon name='check' color={colors.white}></Icon>
                    <Text style={styles.labelBoton}>Abonar</Text>
                </TouchableOpacity>
            </>
            :
                <CuotasComponent list={[...listPagos]} callbackAbonar={(lista)=>{
                    setListPagosv2(JSON.stringify(lista))
                    setModalMedioPago(true);
                }}/>
            }
        </ScrollView>
        
        <Modal
            animationType="fade"
            transparent={true}
            visible={modalRecrearCredito}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <Text style={styles.modalHeader}>Pago exitoso</Text>
                    <View style={{borderBottomWidth: 1, borderBottomColor: colors.gris_fondo_cell}}/>
                    <Text style={[styles.titleModal,{marginBottom:0,textAlign:'center'}]}>Se realizó el pago con éxito{'\n\n'}¿Quieres volver a generar un credito a <Text style={{fontWeight:'bold'}}>{recaudo.nombre}</Text>? </Text>
                    <View style={{flexDirection: 'row', marginTop: 24, justifyContent:'space-between', marginHorizontal:'5%'}}>
                        <TouchableOpacity
                            style={styles.btnEliminar}
                            onPress={() => {
                                setModalRecrearCredito(false);
                                navigation.goBack();
                            }}>
                            <Text style={styles.btnLabel}>Cancelar</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.btnAgregar}
                            onPress={recrearCredito}>
                            <Text style={styles.btnLabel}>Si</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            </View>
        </Modal>
            
        <Modal
            animationType="fade"
            transparent={true}
            visible={modalMedioPago}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <Text style={styles.modalHeader}>Metodo de Pago</Text>
                    <View style={{borderBottomWidth: 1, borderBottomColor: colors.gris_fondo_cell}}/>
                    <Text style={styles.titleModal}>Seleccione medio de pago</Text>
                    <View style={styles.selectTipDoc}>
                        <SelectList
                            placeholder={'Buscar'}
                            data={mediosPago}
                            setSelected={setMedioPago}
                            search={false}
                        />
                    </View>

                    {
                        medioPago === '2' &&
                        <>
                            <Text style={styles.titleModal}>Seleccione tipo de transferencia</Text>
                            <View style={styles.selectTipDoc}>
                                <SelectList
                                    defaultOption={{ value: "Yape", key: "1", }}
                                    placeholder={'Buscar'}
                                    data={detallesMedioPago}
                                    setSelected={setDetalleMedio}
                                    search={false}
                                    
                                />
                            </View>
                        </>
                    }

                    <View style={{flexDirection: 'row', marginTop: 24, justifyContent:'space-between', marginHorizontal:'5%'}}>
                        <TouchableOpacity
                            style={styles.btnEliminar}
                            onPress={() => {
                                setModalMedioPago(false);
                                setMedioPago('')
                            }}>
                            <Text style={styles.btnLabel}>Cancelar</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.btnAgregar}
                            disabled={medioPago==''?true:medioPago=='1'?false:detalleMedio==''?true:false}
                            onPress={async()=>{
                                setModalMedioPago(false);
                                if(newVersion){
                                    abonarV2()
                                }else{
                                    abonar()
                                }
                            }}>
                            <Text style={styles.btnLabel}>Confirmar</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            </View>
        </Modal>
        <Spinner
            visible={loading}
            color={colors.white}
        />
        </>
    )
}

export default Recaudar


const styles = StyleSheet.create({
    labelOpciones: {
        fontWeight: 'bold',
        fontSize: 16,
        marginBottom: '2%'
    },
    labelTextBox: {
        width: '90%',
        fontSize: 16,
        marginBottom: '1%',
        marginTop: '3%'
    },

    textBox: {
        backgroundColor: colors.gris_fondo_cell,
        borderRadius: 5,
        borderColor: colors.logo_gris,
        borderWidth: 0.5,
        width: '90%',
        padding: 10,
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center'
    },

    cabeceraTabla: {
        fontSize: 16,
        fontWeight: 'bold',
        color: colors.white,
        backgroundColor: colors.verde_primary,
        width: '50%',
        padding: 8,
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    celdaTabla: {
        fontSize: 16,
        fontWeight: 'bold',
        backgroundColor: colors.gris_fondo_cell,
        width: '50%',
        padding: 8,
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    labelFooter: {
        width: '50%',
        fontSize: 16
    },

    inputCuota: {
        paddingHorizontal:16,
        height:40,
        alignSelf:'center',
        fontSize:16,
        fontWeight:'bold',
        backgroundColor:colors.white,
        borderRadius:4,
    },

    botonFooter: {
        marginHorizontal:'5%',
        flexDirection: 'row',
        padding: 10,
        borderRadius: 4,
        backgroundColor: colors.verde_primary,
        marginBottom: 40,
    },

    labelBoton: {
        fontWeight: 'bold',
        fontSize: 16,
        color: colors.white,
        width: '85%',
        textAlign: 'center'
    },
    ////////
    centeredView: {
        paddingHorizontal:'5%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        position:'relative',
        width:'100%',
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },

    modalHeader: {
        width: '100%',
        padding: 16,
        fontWeight: 'bold',
        fontSize: 20,
        color: colors.negro,
        textAlign: 'left',
    },
    titleModal: {fontSize: 16, marginVertical:16, paddingHorizontal:16,},
    selectTipDoc: {
        width:'100%',
        paddingHorizontal:16,
        alignSelf:'center'
    },
    btnAgregar: {
        flexDirection: 'row',
        backgroundColor: colors.verde_primary,
        padding: 8,
        paddingVertical:16,
        width:'48%',
        borderBottomRightRadius:4,
    },
    btnEliminar: {
        flexDirection: 'row',
        backgroundColor: colors.logo_gris,
        padding: 8,
        paddingVertical:16,
        width:'48%',
        borderBottomLeftRadius:4
    },
    btnLabel: {
        color: colors.white,
        fontSize: 16,
        fontWeight:'bold',
        textAlign: 'center',
        width:'100%'
    },
    crtInfoUser:{
        // backgroundColor:colors.white,
        padding:'5%',
        // marginHorizontal:'5%',
        // marginTop:32,
        // shadowColor: colors.black,
        // shadowOffset: {
        //     width: 0,
        //     height: 1,
        // },
        // shadowOpacity: 0.25,
        // shadowRadius: 1,
        // elevation: 2,
        // borderRadius: 8,
    },
    row:{
        display:'flex',
        flexDirection:'row',
        position:'relative',
    },
    circleStatus:{
        width:40,
        height:40,
        borderRadius:20,
        alignSelf:'center',
        marginBottom:8,
    },
    status:{
        fontSize:12,
        fontWeight:'bold',
        textAlign:'center',
        marginTop:4,
        textTransform:'uppercase'
    },
    labelTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 8,
        textAlign:'center'
    },
    labelSubTitle: {
        fontSize: 16,
        marginLeft:8,
        color:colors.negro,
        alignSelf:'center',
    },
    rowButtons: {
        display:'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        width: '100%',
        alignSelf:'center',
        marginTop:16,
    },
    buttonOptions: {
        flexDirection: "row",
        borderRadius: 4,
        flex:1,
        height:40,
        justifyContent: "center"
    },
    textButtonOptions: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.white,
        alignSelf:'center',
        textAlign:'center',
    },
    crtInfo:{
       padding: 16,
       marginHorizontal: '5%',
       backgroundColor: colors.white,
       shadowColor: colors.black,
       shadowOffset: {
           width: 0,
           height: 2,
       },
       shadowOpacity: 0.25,
       shadowRadius: 3.84,
       elevation: 4,
       borderRadius: 8,
       marginBottom:4,
    },
    boxCredit:{
        marginVertical:16,
        marginTop:0,
        justifyContent:'space-between'
    },
    txtInfoCredit:{
        marginLeft:'5%',
        fontSize: 20,
        fontWeight: 'bold',
        alignSelf:'center'
    },
    btnAbono:{
        marginRight:'5%',
        paddingLeft:16,
        paddingVertical:8,
        flexDirection:'row',
        alignItems:'center'
    },
    txtAbono:{
        color:colors.negro,
        fontWeight:'bold',
        fontSize:14,
        textAlign:'right',
        alignSelf:'center',
        marginLeft:4
    },
    crtProgress: {
        width: '100%',
        height: 10,
        backgroundColor: colors.gris_fondo_cell,
        borderRadius: 4,
        overflow: 'hidden',
    },
    progressBar: {
        height: '100%',
        backgroundColor:colors.logo_azul
    },
    lblPay:{
        fontSize:12,
        color:colors.negro,
        marginBottom:6
    },
    lblMount:{
        fontSize:18,
        fontWeight:'bold',
        color:colors.logo_azul,
    },
    boxInfo:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        paddingVertical:16,
        borderBottomWidth: 1,
        borderBottomColor:colors.logo_gris,
        marginHorizontal:'5%'
    },
    txtBoxInfo:{
        fontSize:13,
    },
    boxInformationVersion:{
        padding:16,
        backgroundColor:colors.naranja,
        borderRadius:4,
    },
    crtCuota:{
        marginVertical:6,
        marginHorizontal:'5%',
        paddingVertical:16,
        marginBottom:8,
        paddingHorizontal:16,
        borderRadius:4,
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.10,
        shadowRadius: 1,
        elevation: 1,
    },
    boxRadius:{
        width:20,
        height:20,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        borderRadius:10,
        borderWidth:1,
        alignSelf:'center',
        marginRight:16
    },
    radiusColor:{
        width:12,
        height:12,
        borderRadius:6,
        alignSelf:'center'
    },
    lblQuota:{
        fontSize:14,
        fontWeight:'bold',
        marginBottom:2,
    },
    lblDate:{
        fontSize:12,
    },
    lblStatus:{
        fontSize:14,
        fontWeight:'bold',
        marginBottom:4,
        marginTop:-8
    },
    payInput:{
        paddingHorizontal:16,
        height:40,
        alignSelf:'center',
        fontSize:16,
        fontWeight:'bold',
        backgroundColor:colors.gris_fondo_cell,
        borderRadius:4,
        maxWidth:80,
    },
    textPay:{
        fontSize:16,
        fontWeight:'bold',
        alignSelf:'center'
    },
    cod:{
        position:'absolute',
        top:'10%',
        left:'5%',
        fontSize:12,
        fontWeight:'bold'
    }
})