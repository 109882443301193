import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import axios from 'axios';
import React, { useState } from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { SelectList } from 'react-native-dropdown-select-list';
import { Icon } from 'react-native-elements';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../../../constants/colors';
import routes from '../../../constants/routes';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import urlsGenerales from '../../../constants/urlsGenerales';
import { textoSpinner } from '../../../shared/TiposDocumento';
import CustomAlert from '../../../shared/CustomAlert';
import { StorageUtil } from '../../../shared/StorageUtil';

export default function AgregarClienteCredito() {

    const navigation = useNavigation();
    const route = useRoute();

    const cobrador = route.params!.cobrador;

    const [loading, setLoading] = useState(false);

    const tiposDocumento = [
        { key: '0', value: 'DNI' },
        { key: '1', value: 'CE' },
        { key: '2', value: 'Pasaporte' }
    ];

    const [selected, setSelected] = React.useState('');
    const [nombre, setNombre] = React.useState('');
    const [apellido, setApellido] = React.useState('');
    const [nroDoc, setNroDoc] = React.useState('');
    const [fono, setFono] = React.useState('');
    const [direccion, setDireccion] = React.useState('');
    const [fiador, setFiador] = React.useState('');
    const [referencia, setReferencia] = React.useState('');
    const [longitud, setLongitud] = React.useState(8);

    const registrarCliente = async () => {

        if (nombre.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese el nombre'
            });
            return;
        }

        const formData = new FormData();
        formData.append('codUsuario', cobrador!.codUsuarioHijo);
        formData.append('nombre', nombre);
        formData.append('apellido', apellido);
        formData.append('codDocumento', selected);
        formData.append('numDocumento', nroDoc);
        formData.append('telefono', fono);
        formData.append('correo', fiador);
        formData.append('direccion', direccion);
        formData.append('alias', referencia);

        try {
            setLoading(true);
            const token = await StorageUtil.getTokenStorage(); 
            const response = await axios.post(urlsGenerales.CREAR_CLIENTE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }
            const registroCliente = response.data;
            console.log('registroCliente -> ', registroCliente);
            navigation.navigate(routes.ASIGNAR_CREDITO_ADMIN, { cliente: registroCliente });

        } catch (error) {
            console.log('Se cae en error -> ', urlsGenerales.CREAR_CLIENTE);
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const onTipoDocumentoSeleccionado = (tipoDocumento) => {
        setNroDoc('');
        setSelected(tipoDocumento);
        switch (tipoDocumento) {
            case '0':
                setLongitud(8);
                break;
            case '1':
                setLongitud(12);
                break;
            case '2':
                setLongitud(12);
                break;
            default:
                setLongitud(8);
                break;
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = () => {
                setNombre('');
                setApellido('');
                setNroDoc('');
                setFono('');
                setDireccion('');
                setFiador('');
                setReferencia('');
            };
            fetchData();
        }, [])
    );

    return (
        <ScrollView
            contentContainerStyle={styles.container}
            showsHorizontalScrollIndicator={false}>
            
            <View style={styles.content}>
                <Text style={styles.labelTitle}>Crear cliente</Text>
                <Text style={styles.subTitle}>Complete la información del cliente</Text>
                <View>
                <Text style={styles.labelInput}>Nombre*</Text>
                <TextInput style={styles.inputText}
                        value={nombre}
                        selectionColor={colors.negro}
                        onChangeText={setNombre}></TextInput>

                <Text style={styles.labelInput}>Apellido*</Text>
                <TextInput style={styles.inputText}
                        value={apellido}
                        onChangeText={setApellido}></TextInput>

                <Text style={styles.labelInput}>Tipo de Documento*</Text>
                <View style={styles.selectTipDoc}>
                    <SelectList
                        data={tiposDocumento}
                        setSelected={onTipoDocumentoSeleccionado}
                        search={false}
                        defaultOption={tiposDocumento[0]}
                        boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                        inputStyles={{alignSelf:'center',}}
                        dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                        arrowicon={<Icon name="expand-more" size={24} style={{marginTop:0}} />}
                    />
                </View>

                <Text style={styles.labelInput}>N° Documento*</Text>
                <TextInput style={styles.inputText}
                        value={nroDoc}
                        onChangeText={setNroDoc}
                        maxLength={longitud}></TextInput>
                </View>
                <Text style={styles.labelInput}>Teléfono</Text>
                <TextInput style={styles.inputText}
                    keyboardType="numeric"
                    value={fono}
                    onChangeText={setFono}
                    maxLength={9}></TextInput>

                <Text style={styles.labelInput}>Dirección</Text>
                <TextInput style={styles.inputText}
                    value={direccion}
                    onChangeText={setDireccion}></TextInput>

                <Text style={styles.labelInput}>Correo electrónico</Text>
                <TextInput style={styles.inputText}
                    value={fiador}
                    onChangeText={setFiador}></TextInput>

                <Text style={styles.labelInput}>Referencia cliente</Text>
                <TextInput style={styles.inputText}
                    value={referencia}
                    onChangeText={setReferencia}></TextInput>
            </View>
            


            <TouchableOpacity
                    style={[styles.btn]}
                    onPress={registrarCliente}
                >
                    <Text style={[styles.labelButton,{alignSelf:'center'}]}>Guardar cliente</Text>
                </TouchableOpacity>

            <Spinner
                visible={loading}
                color={colors.white}
            />

        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingBottom:48,
    },
    content:{
        paddingHorizontal:'5%'
    },
    labelTitle:{
        fontSize:18,
        fontWeight:'bold',
        marginTop:16,
        marginBottom:8
    },
    subTitle: {
        fontSize:12,
        textAlign:'left',
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    selectTipDoc: {
        marginTop: 4,
    },
    btn: {
        backgroundColor: colors.verde_primary,
        borderRadius: 4,
        height:48,
        justifyContent:'center',
        marginTop:32,
        marginHorizontal:'5%'
    },
    labelButton: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        marginEnd: 8,
    },

})