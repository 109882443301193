import {DrawerContentScrollView, DrawerItem, DrawerItemList, createDrawerNavigator} from '@react-navigation/drawer';
import {createStackNavigator} from '@react-navigation/stack';
import {StyleSheet,Platform, Text,View, TouchableOpacity} from 'react-native';
import COLORS from '../constants/colors';
import colors from '../constants/colors';
import ROUTES from '../constants/routes';
import routes from '../constants/routes';

import HomeScreenCliente from '../screens/cliente/HomeScreenCliente';

import React, {useEffect} from 'react';
import {Image} from 'react-native-elements';
import Icon from 'react-native-vector-icons/MaterialIcons';

import {useNavigation} from "@react-navigation/native";

import NetInfo from '@react-native-community/netinfo';
import { useIconContext} from './IconContext';
import VerCreditoCliente from '../screens/cliente/VerCreditoCliente';
import VerAbonosCliente from '../screens/cliente/VerAbonosCliente';
import stylesMenu from '../constants/stylesMenu';
import AsyncStorage from '@react-native-async-storage/async-storage';


const Drawer = createDrawerNavigator();
const StackHome = createStackNavigator();

function HomeStack() {
    return (
        <StackHome.Navigator initialRouteName={routes.HOME}>
            <StackHome.Screen name={routes.HOME}
                              options={{headerShown: false}} component={HomeScreenCliente}/>
            <StackHome.Screen name={routes.VER_CREDITO}
                                  options={{
                                    headerShown: true,
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                                        </View>
                                    ),
                                    }}  component={VerCreditoCliente}/>
            <StackHome.Screen name={routes.VER_ABONOS}
                                  options={{
                                    headerShown: true,
                                    headerTitleAlign: 'center',
                                    headerBackImage: () => (
                                        <View
                                            style={stylesMenu.backButton}
                                        >
                                            <Icon name="reply" size={25} color={colors.verde_primary}/>
                                        </View>
                                    ),
                                    }}  component={VerAbonosCliente}/>
        </StackHome.Navigator>
    );
}




function MenuNavigatorCliente() {
    const [isConnected, setIsConnected] = React.useState(true);
    const { isSync } = useIconContext();
    const [ valSync, setValSync ] = React.useState("0deg");

    React.useEffect(() => {
        // const unsubscribe = NetInfo.addEventListener(state => {
        //   setIsConnected(state.isConnected);
        // });
    
        // return () => {
        //   unsubscribe();
        // };
    }, []);

    
    const [isLoading, setIsLoading] = React.useState(true);

    const navigation = useNavigation();

    useEffect(() => {
        const getLoading = async () => {
            setIsLoading(false);
        };
        getLoading();
    }, []);

    
    if (isLoading) {
        return null;
    }
    
    const logout = async () => {
        navigation.replace('Login');
        await AsyncStorage.clear()
    };

    const CustomDrawerContent = (props)=> {
        return (
          <DrawerContentScrollView {...props}>
            
                <DrawerItemList {...props} />
                <DrawerItem
                    style={{
                        backgroundColor: colors.rojo,
                    }}
                    labelStyle={{
                        color: colors.white,
                        fontWeight: 'bold',
                        fontSize: 16
                    }}
                    icon={()=><Icon name="logout" size={25} color={colors.white}/>}
                    label="Cerrar sesión"
                    onPress={()=>{logout()}}
                />
          </DrawerContentScrollView>
        );
    }

    return (
        <Drawer.Navigator screenOptions={{ drawerType: "back", }} drawerContent={props => <CustomDrawerContent {...props} />}>
            
                    
            <Drawer.Screen name={ROUTES.HOME} component={HomeStack} options={() => ({
                headerTintColor: COLORS.white,
                headerStyle: {
                    backgroundColor: COLORS.verde_primary,
                },
                headerTitleAlign: 'center',
                drawerIcon: () => (
                    <Image style={{width: 25, height: 25, marginLeft: 1}}
                            resizeMode="center"
                            source={require('../assets/drawer-icons/CASA.png')}></Image>
                ),
                headerTitle: () => (
                    <View style={{flexDirection:'row'}}>
                        { Platform.OS !== 'web' ?
                        (<Icon style={{ marginRight: 10 , marginTop : 3}} size={15} name={isConnected ? 'wifi' : 'wifi-off'} color={isConnected ? colors.white : colors.rojo}></Icon>):(<></>)
                        }
                        <Text style={{color:"white",fontSize:20,fontWeight:"600"}}>{ROUTES.HOME}</Text>
                        { isSync && Platform.OS !== 'web' ?
                        (<Icon style={{ marginLeft: 8 , marginTop : 5, transform: [{ rotate: valSync }],width:15,height:15 }} size={15} name='sync' color={colors.white}></Icon>):(<></>)
                        }
                    </View>
                ),
                drawerActiveTintColor: colors.verde_primary,
                drawerActiveBackgroundColor: colors.verde_shadow
            })}></Drawer.Screen>
         
            
                    
                
            
        </Drawer.Navigator>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    }
});

export default MenuNavigatorCliente;
