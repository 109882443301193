import React from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';

export default function CreditosNoEnrutados() {

    const [searchText, setSearchText] = React.useState('');
    const handleSearch = (text) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    return (
        <View style={styles.container}>

            <Text style={[stylesAdmin.labelTitle, { marginBottom: '6%' }]}>Listado de créditos no enrutados</Text>

            <View style={stylesGenerales.row}>
                <Text style={styles.cellHeader}>Cantidad</Text>
                <Text style={styles.cellHeader}>Total Saldos</Text>
            </View>
            <View style={stylesGenerales.row}>
                <Text style={[stylesAdmin.cellBody, { width: '50%' }]}>0</Text>
                <Text style={[stylesAdmin.cellBody, { width: '50%' }]}>S/ 0</Text>
            </View>

            <View style={[stylesGenerales.containerSearch, { marginTop: '8%' }]}>
                <View style={stylesGenerales.btnSearch}>
                    <Icon name='search' color={colors.logo_gris}></Icon>
                </View>
                <TextInput placeholder='Buscar'
                    style={stylesGenerales.textInputSearch}
                    onChangeText={handleSearch}
                    value={searchText}></TextInput>
                {searchText !== '' &&
                    <TouchableOpacity style={stylesGenerales.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.logo_gris}></Icon>
                    </TouchableOpacity>}
            </View>

        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        // flex: 1,
        alignItems: 'center'
    },

    cellHeader: {
        width: '50%',
        paddingVertical: '2%',
        paddingHorizontal: '3%',
        fontWeight: 'bold',
        fontSize: 17,
        color: colors.white,
        backgroundColor: colors.verde_primary,
        borderColor: colors.logo_gris,
        borderWidth: 0.5
    }
})