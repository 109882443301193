import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View, RefreshControl} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../constants/colors';
import COLORS from '../constants/colors';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {useNavigation} from '@react-navigation/native';
import axios from 'axios';
import urlsGenerales from '../constants/urlsGenerales';
import {StorageUtil} from '../shared/StorageUtil';
import ModalAgregarMov from "../components/ModalAgregarMov";
import ModalQuitarMov from "../components/ModalQuitarMov";
import ModalOperacionAdmin from "../components/ModalOperacionAdmin";
import {AuthenticationService} from "../shared/AuthenticationService";
import routes from "../constants/routes";
import {CajaService} from "../shared/CajaService";
import CustomAlert from '../shared/CustomAlert';
import CalendarStrip from 'react-native-calendar-strip';
import moment from 'moment';
import 'moment/locale/es';

type BalanceType = {
    credito: string;
    cajaBase: string;
    recaudado: string;
    totalCaja: string;
    listIngresos: any[];
    listSalidas: any[];
    codigo: string;
}

export default function Balance() {

    const navigation = useNavigation();

    const [balance, setBalance] = useState<BalanceType>({
        cajaBase: "",
        credito: "",
        listIngresos: [],
        listSalidas: [],
        recaudado: "",
        totalCaja: "",
        codigo: ""
    });

    const [importeCajaBase, setImporteCajaBase] = useState("");
    const [importeCobrado, setImporteCobrado] = useState("");
    const [importeCreditos, setImporteCreditos] = useState("");
    const [importeTotalCaja, setImporteTotalCaja] = useState("");

    const [listIngresos, setListIngresos] = useState([]);
    const [listGastos, setListGastos] = useState([]);
    const [refreshing, setRefreshing] = useState(false);

    // NUEVO DESARROLLO
    const [loading, setLoading] = useState(false);
    const [visibleAgregarEntrada, setVisibleAgregarEntrada] = useState(false);
    const [visibleQuitarEntrada, setVisibleQuitarEntrada] = useState(false);
    const [visibleAgregarGasto, setVisibleAgregarGasto] = useState(false);
    const [visibleAgregarEntrega, setVisibleAgregarEntrega] = useState(false);
    const [visibleQuitarGasto, setVisibleQuitarGasto] = useState(false);
    const [codigoEntradaQuitar, setCodigoEntradaQuitar] = useState('');
    const [codigoGastoQuitar, setCodigoGastoQuitar] = useState('');
    const [opcionBorrar, setOperacionBorrar] = useState('');
    const [modalConfirmarVisible, setModalConfirmarVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment())

    useEffect(() => {
        setImporteCajaBase(balance.cajaBase);
        setImporteCobrado(balance.recaudado);
        setImporteCreditos(balance.credito);
        setImporteTotalCaja(balance.totalCaja);
        setListIngresos(balance.listIngresos.sort((a, b) => a.codDetalleCaja.localeCompare(b.codDetalleCaja)));
        setListGastos(balance.listSalidas.filter(item => item.detalle !== 'Ingreso de Pago de cuota'));
    }, [balance]);

    const consultarBalance = async (date?) => {
        try {
            setLoading(true);

            const formData = new FormData();
            const token = await StorageUtil.getTokenStorage();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('date', date?date.format('YYYY-MM-DD'):selectedDate.format('YYYY-MM-DD'));

            const response = await axios.post(urlsGenerales.BALANCE_DATE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }
            console.log(response.data)
            setBalance(response.data);

            date && await setSelectedDate(date)

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.BALANCE);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await consultarBalance();
        };
        fetchData();
    }, [])

    const listarGastos = () => {
        if (listGastos.length === 0) {
            return (
                <View style={{marginTop:8}}>
                    <Text style={{textAlign:'center'}}>
                        No hay salidas
                    </Text>
                </View>
            )
        } else {
           
            
            return listGastos.map((item,index) => (
                <View style={[styles.row,{paddingHorizontal:0, borderBottomWidth:0.5}]} key={index}>
                    <TouchableOpacity
                        disabled={!selectedDate.isSame(moment(),'day')}
                        style={styles.rowItem}
                        onLongPress={() => {
                            setVisibleQuitarGasto(true)
                            setCodigoGastoQuitar(item.codDetalleCaja)
                        }}
                    >                        
                        <Text style={[styles.itemDetail,{color: item.movimiento === 'Entrega'? colors.rojo:'black'}]}>{item.detalle}</Text>
                        <Text style={[styles.itemMonto,{color: item.movimiento === 'Entrega'? colors.rojo:'black'}]}>S/ {Number(item.monto).toFixed(2)}</Text>
                    </TouchableOpacity>
                    
                </View>
            ))
        }
    }

    const listarEntradas = () => {
        if (listIngresos.length === 0) {
            return (
                <View style={{marginTop:8}}>
                    <Text style={{textAlign:'center'}}>No hay entradas</Text>
                </View>
            )
        } else {
            return listIngresos.map((item, index) => (
                <View style={[styles.row,{paddingHorizontal:0,borderBottomWidth:0.5}]} key={index}>
                    <TouchableOpacity
                        disabled={!selectedDate.isSame(moment(),'day')}
                        style={styles.rowItem}
                        onLongPress={() => {
                            setVisibleQuitarEntrada(true); 
                            setCodigoEntradaQuitar(item.codDetalleCaja)
                        }}
                    >                        
                        <Text style={styles.itemDetail}>{item.detalle}</Text>
                        <Text style={styles.itemMonto}>S/ {Number(item.monto).toFixed(2)}</Text>
                    </TouchableOpacity>
                    
                </View>
            ))
        }
    }


    // Nuevos modals
    const handleAgregarEntrada = async (detalle: string, valor: string) => {
        try {
            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('monto', valor);
            formData.append('tipo', '1');
            formData.append('detalle', detalle);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.INSERTAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleAgregarGasto = async (detalle: string, valor: string) => {
        try {
            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('monto', valor);
            formData.append('tipo', '0');
            formData.append('detalle', detalle);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.INSERTAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                console.log('Mov registrado correctamente');
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleAgregarEntrega = async (detalle: string, valor: string) => {
        try {
            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('monto', valor);
            formData.append('tipo', '5');
            formData.append('detalle', detalle);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.INSERTAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                console.log('Mov registrado correctamente');
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleQuitarEntrada = async (usuario: string, clave: string) => {
        setVisibleQuitarEntrada(false);

        try {
            setLoading(true);

            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('codDetalleCaja',codigoEntradaQuitar);
            formData.append('usu', usuario);
            formData.append('pass', clave);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ELIMINAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleQuitarGasto = async (usuario: string, clave: string) => {
        setVisibleQuitarGasto(false);

        try {
            setLoading(true);

            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);    
            formData.append('codDetalleCaja', codigoGastoQuitar);
            formData.append('usu', usuario);
            formData.append('pass', clave);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ELIMINAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {
        setModalConfirmarVisible(false);

        setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            CustomAlert.errorCredenciales();
            
            setLoading(false);
            return;
        }
        setLoading(false);

        switch (opcionBorrar) {
            case 'ENTRADA':
                handleQuitarEntrada(usuario, clave);
                break;
            case 'GASTO':
                handleQuitarGasto(usuario, clave);
                break;
            default:
                break;
        }
    }

    const validarCajaAbierta = async () => {
        await setLoading(true);
        const codUsuario = await StorageUtil.getCodUsuarioStorage();
        const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuario!, navigation);
        await setLoading(false);
        return estadoCajaValida;
    }

    const borrarEntrada = async () => {
        setVisibleQuitarEntrada(false);
        const estadoCajaValida = await validarCajaAbierta()
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                setOperacionBorrar('ENTRADA');
                setModalConfirmarVisible(true);
            }else{
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const borrarGasto = async () => {
        setVisibleQuitarGasto(false);
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                setOperacionBorrar('GASTO');
                setModalConfirmarVisible(true);
            } else {
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const agregarEntrada = async (detalle: string, valor: string) => {
        await setVisibleAgregarEntrada(false);
        await setTimeout(() => {}, 300);
        await setLoading(true)
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                await handleAgregarEntrada(detalle, valor);
            } else {
                await setLoading(false)
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const agregarGasto = async (detalle: string, valor: string) => {
        await setVisibleAgregarGasto(false);
        await setTimeout(() => {}, 300);
        await setLoading(true)
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                await handleAgregarGasto(detalle, valor);
            } else {
                await setLoading(false)
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const agregarEntrega = async (detalle: string, valor: string) => {
        await setVisibleAgregarEntrega(false);
        await setTimeout(() => {}, 300);
        await setLoading(true)
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                await handleAgregarEntrega(detalle, valor);
            } else {
                await setLoading(false)
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const onRefresh = async() => {
        await setRefreshing(true);
        await consultarBalance()
        await setRefreshing(false)
    }

    return (
        <>
            <ScrollView 
                stickyHeaderIndices={[0]}
                contentContainerStyle={{paddingBottom:48}}
                showsVerticalScrollIndicator={false}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
            >   
                <CalendarStrip
                    scrollable={false}
                    style={{height:100,paddingBottom: 8, marginBottom: 8}}
                    calendarHeaderStyle={{color: colors.negro , textTransform:'capitalize', marginTop:8}}
                    calendarColor={colors.white}
                    dateNumberStyle={{color: colors.negro}}
                    dateNameStyle={{color: colors.negro}}
                    iconContainer={{flex: 0.1}}
                    highlightDateNameStyle={{color: colors.white}}
                    highlightDateNumberStyle={{color: colors.white}}
                    highlightDateContainerStyle={{backgroundColor: colors.verde_primary}}
                    selectedDate={selectedDate}
                    onDateSelected={ async (date:any)=>{
                        await consultarBalance(date)
                    }}
                    maxDate={moment()}
                    useIsoWeekday={true}
                />
                <View>
                    <View style={[styles.row,{justifyContent:'space-between'}]}>
                        <Text style={styles.info}>#{balance.codigo}</Text>
                        <Text style={styles.info}>{selectedDate.format('LL')}</Text>
                    </View>
                    <View style={[styles.row]}>
                        <View style={[styles.totalCaja]}>
                            <Text style={styles.txtCaja}>Total Caja</Text>
                            <Text style={styles.sum}>S/ {Number(importeTotalCaja).toFixed(2)}</Text>
                        </View>
                        <TouchableOpacity style={styles.viewPays} onPress={()=> {
                            navigation.navigate(routes.DETALLE_PAGOS,{
                                id: balance.codigo,
                                fecha:selectedDate
                            })
                        }}>
                            <Icon name='visibility' color={colors.negro} size={16}></Icon>
                            <Text style={styles.textViewPays}>Ver pagos</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.row}>
                        
                        <View style={[styles.cajaBox,{backgroundColor:COLORS.logo_naranja}]}>
                            {/* <View style={{flex:1}}>
                                <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                            </View> */}
                            <Text style={[styles.lblMountBox,{fontSize:12}]}>S/ {Number(importeCajaBase).toFixed(2)}</Text>
                            <Text style={[styles.lblBox,{fontSize:12}]}>
                                Caja base
                            </Text>
                        </View>
                        <View style={[styles.cajaBox,{marginHorizontal:16,backgroundColor:COLORS.logo_celeste}]}>
                            {/* <View style={{flex:1}}>
                                <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                            </View> */}
                            <Text style={[styles.lblMountBox,{fontSize:12}]}>S/ {Number(importeCobrado).toFixed(2)}</Text>
                            <Text style={[styles.lblBox,{fontSize:12}]}>
                                Recaudado
                            </Text>
                        </View>
                        <View style={[styles.cajaBox,{backgroundColor:COLORS.logo_azul}]}>
                            {/* <View style={{flex:1}}>
                                <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                            </View> */}
                            <Text style={[styles.lblMountBox,{fontSize:12 }]}>S/ {Number(importeCreditos).toFixed(2)}</Text>
                            <Text style={[styles.lblBox,{fontSize:12}]}>
                                Créditos
                            </Text>
                        </View>
                    </View>
                    {
                        selectedDate.isSame(moment(),'day') ?
                        <View style={[styles.row,{marginTop:16}]}>
                            <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                            <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Manten presionado para eliminar una entrada o salida</Text>
                        </View>
                        :
                        null
                    }
                    
                    <View style={[styles.row,{flexDirection:'column'}]}>
                        <View style={[styles.ctrList]}>
                            <View style={[styles.row,{paddingHorizontal:0}]}>
                                <Text style={styles.crtListText}>Entradas</Text>
                                {
                                    selectedDate.isSame(moment(),'day') ?
                                        <TouchableOpacity onPress={() => setVisibleAgregarEntrada(true)}>
                                            <Icon name='add-circle' size={32} color={COLORS.verde_primary}></Icon>
                                        </TouchableOpacity>
                                    :
                                        null
                                }
                                
                            </View>
                            <View style={styles.list}>
                                {listarEntradas()}
                            </View>
                        </View>
                        <View style={[styles.ctrList]}>
                            <View style={[styles.row,{paddingHorizontal:0}]}>
                                <Text style={styles.crtListText}>Salidas</Text>
                                {
                                    selectedDate.isSame(moment(),'day') ?
                                    <>
                                    <TouchableOpacity onPress={()=>setVisibleAgregarGasto(true)} style={{marginRight:16}}>
                                        <Icon name='add-circle' size={32} color={COLORS.verde_primary}></Icon>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => setVisibleAgregarEntrega(true)}>
                                        <Icon name='add-circle' size={32} color={COLORS.rojo}></Icon>
                                    </TouchableOpacity>
                                    </>
                                    :
                                    null
                                }
                                
                            </View>
                            <View style={styles.list}>
                                {listarGastos()}
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView>
            <ModalAgregarMov
                visible={visibleAgregarEntrada}
                title='Ingreso'
                subtitle='Ingresa el detalle y valor del ingreso'
                onCancel={() => setVisibleAgregarEntrada(false)}
                onConfirm={agregarEntrada}
            />
            <ModalQuitarMov
                visible={visibleQuitarEntrada}
                title='Eliminar'
                subtitle='¿Está seguro que desea eliminar este ingreso?'
                onCancel={() => setVisibleQuitarEntrada(false)}
                onConfirm={borrarEntrada}
            />
            <ModalAgregarMov
                visible={visibleAgregarGasto}
                title='Gastos'
                subtitle='Ingresa el detalle y valor del gasto'
                onCancel={() => setVisibleAgregarGasto(false)}
                onConfirm={agregarGasto}
            />
            <ModalQuitarMov
                visible={visibleQuitarGasto}
                title='Eliminar'
                subtitle='¿Está seguro que deseas eliminar este gasto?'
                onCancel={() => setVisibleQuitarGasto(false)}
                onConfirm={borrarGasto}
            />
            <ModalAgregarMov
                visible={visibleAgregarEntrega}
                title='Entrega'
                subtitle={'Ingresa el detalle y valor entregado\n\nEl campo detalle complete con datos de entrega como donde, quien, etc.'}
                onCancel={() => setVisibleAgregarEntrega(false)}
                onConfirm={agregarEntrega}
            />
            
            <ModalOperacionAdmin
                visible={modalConfirmarVisible}
                title='Aviso'
                subtitle='Ingrese sus credenciales para confirmar la operación'
                onCancel={() => setModalConfirmarVisible(false)}
                onConfirm={handleConfirmModal}
            />
            <Spinner
                visible={loading}
                color={colors.white}
            />
        </>
    );
};

const styles = StyleSheet.create({
    row:{
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
    },
    totalCaja:{
        paddingVertical:8,
        marginVertical:8,
        flex:1,
    },
    txtCaja:{
        fontSize:14,
        marginBottom:8
    },
    sum:{
        fontSize:32,
        fontWeight:'bold',
    },
    cajaBox:{
        backgroundColor:colors.white,
        borderRadius:8,
        marginVertical:8,
        padding:8,
        paddingTop:16,
        // height:80,
        flex:1,
        display:'flex',
        flexDirection:'column'
    },
    lblBox:{
        fontSize: 14,
        color: colors.white,
    },
    lblMountBox:{
        fontSize: 20,
        fontWeight: 'bold',
        color:colors.white,
        marginBottom: 4,
    },
    viewPays:{
        flexDirection:'row',
        alignSelf:'center',
        paddingVertical:8,
        justifyContent:'center',
        alignItems:'center'
    },
    textViewPays:{
        fontSize:12,
        textAlign:'center',
        fontWeight:'bold',
        color:colors.negro,
        marginLeft:4
    },
    ctrList:{
        marginTop:24
    },
    crtListText:{
        fontWeight: 'bold',
        fontSize: 16,
        flex:1,
        alignSelf:'center'
    },
    list:{
        marginTop:4,
    },
    lblindex:{
        fontWeight:'bold',
        alignSelf:'center'
    },
    rowItem:{
        paddingVertical:16, 
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        flex:1,
        display:'flex',
        flexDirection:'row'
    },
    itemDetail:{
        fontSize:14,
        flex:1,
        marginRight:8,
    },
    itemMonto:{
        fontSize:14,
        fontWeight:'bold',
        alignSelf:'center'
    },
    info:{
        fontSize:12,
        fontWeight:'bold'
    }
});
