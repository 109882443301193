import React, {useState, useRef} from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';

import {useFocusEffect, useNavigation} from '@react-navigation/native';
import axios from 'axios';
import {SelectList} from 'react-native-dropdown-select-list';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../../constants/colors';
import stylesGenerales from '../../constants/stylesGenrales';
import urlsGenerales from '../../constants/urlsGenerales';
import {textoSpinner} from '../../shared/TiposDocumento';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ModalCalendar from "../../components/ModalCalendar";
import {DateUtils} from "../../shared/DateUtils";
import {StorageUtil} from '../../shared/StorageUtil';
import CustomAlert from '../../shared/CustomAlert';
import routes from '../../constants/routes';
import {Icon} from 'react-native-elements';

export default function CrearUsuario() {

    const navigation = useNavigation();

    const [loading, setLoading] = useState(false);

    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [selected, setSelected] = useState('');
    const [nroDoc, setNroDoc] = useState('');
    const [fono, setFono] = useState('');
    const [correo, setCorreo] = useState('');
    const [usu, setUsu] = useState('');
    const [pass, setPass] = useState('');
    const [privilegio, setPrivilegio] = useState('');
    const [privilegioLogin, setPrivilegioLogin] = useState('');
    const [cantUsus, setCantUsus] = useState('1');
    const [cantAdms, setCantAdms] = useState('1');
    const [codTipoCuenta, setCodTipoCuenta] = useState(0);
    const [diaPago, setDiaPago] = useState('1');
    const [fecVencimiento, setFecVencimiento] = useState(DateUtils.getDateStringYYYYMMDD());
    const [mostrarFecha, setMostrarFecha] = useState(false);
    const fechaDesdeRef = useRef(null);
    const [longitud, setLongitud] = useState(8);

    const tiposDocumento = [
        {key: '0', value: 'DNI'},
        {key: '1', value: 'CE'},
        {key: '2', value: 'Pasaporte'}
    ];

    const tiposCuenta = [
        {key: '0', value: 'Permanente'},
        {key: '1', value: 'Suscripción'},
        {key: '2', value: 'Demo'}
    ];

    const [tiposPrivilegio, setTiposPrivilegio] = useState<{ key: string, value: string }[]>([]);
    
    const handleSetFecha = (fechaDesdeModal: string) => {
        setMostrarFecha(false);
        setFecVencimiento(fechaDesdeModal);
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                setLoading(true);

                setNombre('');
                setApellido('');
                setNroDoc('');
                setFono('');
                setCorreo('');
                setUsu('');
                setPass('');

                const privilegioStorage = await AsyncStorage.getItem('privilegio');
                setPrivilegioLogin(privilegioStorage!);
                if (privilegioStorage === 'M') {
                    setTiposPrivilegio([{key: 'S', value: 'Super Administrador'}]);
                } else if (privilegioStorage === 'S') {
                    setTiposPrivilegio([{key: 'A', value: 'Administrador'}, {key: 'U', value: 'Cobrador'}]);
                }

                setLoading(false);
            };
            fetchData();
        }, [])
    );

    const onTipoDocumentoSeleccionado = (tipoDocumento) => {
        setNroDoc('');
        setSelected(tipoDocumento);
        switch (tipoDocumento) {
            case '0':
                setLongitud(8);
                break;
            case '1':
                setLongitud(12);
                break;
            case '2':
                setLongitud(12);
                break;
            default:
                setLongitud(8);
                break;
        }
    };

    const onTipoCuenta = (tipoCuenta) => {
        setCodTipoCuenta(tipoCuenta);
        switch (tipoCuenta) {
            case '0':
                setDiaPago(0);
                setFecVencimiento('2999-01-01');
                break;
            case '1':
                setDiaPago(1);
                setFecVencimiento('2999-01-01');
                break;
            case '2':
                setDiaPago(0);
                setFecVencimiento(DateUtils.getDateStringYYYYMMDD());
                break;
            default:
                setDiaPago(0);
                setFecVencimiento('2999-01-01');
                break;
        }
    };

    const handleAlert = (title: string, subtitle: string) => {
        CustomAlert.show({
            title: title,
            message: subtitle
        });
    };

    const crearNuevoUsuario = async () => {
        try {

            const formData = new FormData();
            formData.append('nombre', nombre);
            formData.append('apellido', apellido);

            formData.append('tipodocumento', selected);
            formData.append('numDocumento', nroDoc);

            formData.append('telefono', fono);
            formData.append('correo', correo);
            formData.append('usu', usu);
            formData.append('pass', pass);

            const codCreador = await StorageUtil.getCodUsuarioStorage();
            formData.append('codCreador', codCreador + "");
            

            if (privilegioLogin === 'M') {
                formData.append('cantAdmins', cantAdms + "");
                formData.append('cantUsus', cantUsus + "");
                formData.append('tipoCuenta', codTipoCuenta + "");
                formData.append('diaPago', diaPago + "");
                formData.append('fechaVencimiento', fecVencimiento + "");
                formData.append('privilegio', 'S');
            } else if (privilegioLogin === 'S') {
                formData.append('privilegio', privilegio);
            } else {
                formData.append('privilegio', 'U');
            }

            setLoading(true);
            const token = await StorageUtil.getTokenStorage(); 

            const response = await axios.post(urlsGenerales.CREAR_USUARIO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                console.log("------------->",response.data);
                if(response.data ===  "Correo Enviado"){
                    handleAlert('Aviso', 'Usuario creado con éxito.');    
                    navigation.goBack();
                }else{
                    const pcantAdmins = await AsyncStorage.getItem('cantAdmins');
                    const pcantUsus = await AsyncStorage.getItem('cantUsus');
                    handleAlert('Error', 'El usuario no puede ser creado usted maximo puede crear ('+pcantAdmins+' Administradores y '+pcantUsus+' Cobradores).');
                }
                
            }

        } catch (error) {
            console.log('Se cae en error -> ', urlsGenerales.CREAR_USUARIO);
            console.error(error);

        } finally {
            setLoading(false);
        }
    }


    return (
        <>
        <ScrollView 
            contentContainerStyle={styles.container}
            showsVerticalScrollIndicator={false}
        >
            <View style={styles.content}>
                <Text style={styles.subTitle}>Complete la información del usuario</Text>
                <Text style={styles.labelInput}>Nombre*</Text>
                <TextInput style={styles.inputText}
                        value={nombre}
                        onChangeText={setNombre}></TextInput>

                <Text style={styles.labelInput}>Apellido*</Text>
                <TextInput style={styles.inputText}
                        value={apellido}
                        onChangeText={setApellido}></TextInput>

                <Text style={styles.labelInput}>Tipo de Documento</Text>
                <View style={styles.selectTipDoc}>
                    <SelectList
                        data={tiposDocumento}
                        setSelected={onTipoDocumentoSeleccionado}
                        search={false}
                        defaultOption={tiposDocumento[0]}
                        boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                        inputStyles={{alignSelf:'center',flex:1}}
                        dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                        arrowicon={<Icon name="expand-more" size={24} style={{marginTop:0}} />}
                    />
                </View>

                <Text style={styles.labelInput}># Documento*</Text>
                <TextInput
                    style={styles.inputText}
                    value={nroDoc}
                    onChangeText={setNroDoc}
                    maxLength={longitud}
                ></TextInput>

                <Text style={styles.labelInput}>Teléfono*</Text>
                <TextInput style={styles.inputText}
                        keyboardType="number-pad"
                        value={fono}
                        onChangeText={setFono}
                        maxLength={9}></TextInput>

                <Text style={styles.labelInput}>Correo electrónico*</Text>
                <TextInput style={styles.inputText}
                        value={correo}
                        onChangeText={setCorreo}></TextInput>

                <Text style={styles.labelInput}>Usuario*</Text>
                <TextInput style={styles.inputText}
                        value={usu}
                        onChangeText={setUsu}></TextInput>

                <Text style={styles.labelInput}>Contraseña*</Text>
                <TextInput style={styles.inputText}
                        value={pass}
                        onChangeText={setPass}
                        secureTextEntry={true}
                ></TextInput>

                {
                    privilegioLogin === 'S' &&
                    <Text style={styles.labelInput}>Tipo de Usuario</Text>
                }
                {
                    privilegioLogin === 'S' &&
                    <View style={styles.selectTipDoc}>
                        <SelectList
                            data={tiposPrivilegio}
                            setSelected={setPrivilegio}
                            search={false}
                            boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                            inputStyles={{alignSelf:'center',flex:1}}
                            dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                            arrowicon={<Icon name="expand-more" size={24} style={{marginTop:0}} />}
                        />
                    </View>
                }

                {
                    privilegioLogin === 'M' &&
                    <Text style={styles.labelInput}>Cant. Administradores*</Text>
                }
                {
                    privilegioLogin === 'M' &&
                    <TextInput style={styles.inputText}
                        keyboardType="number-pad"
                        value={cantAdms}
                        onChangeText={setCantAdms}
                        maxLength={2}
                    >
                    </TextInput>
                }

                {
                    privilegioLogin === 'M' &&
                    <Text style={styles.labelInput}>Cant. Cobradores*</Text>
                }
                {
                    privilegioLogin === 'M' &&

                    <TextInput style={styles.inputText}
                        keyboardType="number-pad"
                        value={cantUsus}
                        onChangeText={setCantUsus}
                        maxLength={2}
                    >
                    </TextInput>
                }

                {
                    privilegioLogin === 'M' &&
                    <Text style={styles.labelInput}>Tipo de Cuenta</Text>
                }
                {
                    privilegioLogin === 'M' &&

                    <View style={styles.selectTipDoc}>
                        <SelectList
                            data={tiposCuenta}
                            setSelected={onTipoCuenta}
                            search={false}
                            defaultOption={tiposCuenta[0]}
                            boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                            inputStyles={{alignSelf:'center',flex:1}}
                            dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                            arrowicon={<Icon name="expand-more" size={24} style={{marginTop:0}} />}
                        />
                    </View>
                }

                {
                    privilegioLogin === 'M' && codTipoCuenta+"" === "1" &&
                    <Text style={styles.labelInput}>Dia de Pago*</Text>
                }
                {
                    privilegioLogin === 'M' && codTipoCuenta+"" === "1" &&
                    <TextInput style={styles.inputText}
                        keyboardType="number-pad"
                        value={diaPago}
                        onChangeText={setDiaPago}
                        maxLength={2}
                    >
                    </TextInput>
                }

                {
                    privilegioLogin === 'M' && codTipoCuenta+"" === "2" &&
                    <Text style={styles.labelInput}>Fecha de Vencimiento</Text>
                }
                {
                    privilegioLogin === 'M' && codTipoCuenta+"" === "2" &&

                    <TextInput
                        placeholder='yyyy-mm-dd'
                        style={styles.inputText}
                        value={fecVencimiento}
                        onPressIn={() => setMostrarFecha(true)}
                        ref={fechaDesdeRef}
                        onFocus={() => {
                            setMostrarFecha(true);
                            // @ts-ignore
                            fechaDesdeRef.current.blur();
                        }}
                    ></TextInput>
                }
                {
                    privilegioLogin === 'M' && codTipoCuenta+"" === "2" &&
                    <ModalCalendar
                        visible={mostrarFecha}
                        onPress={handleSetFecha}
                        onCancel={() => setMostrarFecha(false)}
                    />
                }
                

                <TouchableOpacity
                    style={[styles.btn]}
                    onPress={crearNuevoUsuario}
                >
                    <Text style={styles.labelButton}>Crear usuario</Text>
                </TouchableOpacity>

                <Spinner
                    visible={loading}
                    color={colors.verde_primary}
                    textContent={textoSpinner}
                    textStyle={stylesGenerales.textSpinner}
                />
            </View>
        </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingBottom:48
    },
    content:{
        paddingHorizontal:'5%'
    },
    button: {
        backgroundColor: colors.logo_azul,
        borderRadius: 5,
        padding: 10,
        flexDirection: 'row',
        marginTop: 15
    },

    labelButton: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        marginEnd: 8,
        alignSelf:'center'
    },
    btn: {
        backgroundColor: colors.verde_primary,
        borderRadius: 4,
        height:48,
        justifyContent:'center',
        marginTop:32,
    },

    

    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },

    selectTipDoc: {
        marginTop: 4,
    },
    title: {
        textDecorationLine: 'underline',
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 20
    },

    subTitle: {
        marginVertical:32,
        marginBottom:8,
        fontSize:14,
        textAlign:'left'
    }
})
