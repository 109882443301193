import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Linking, Text, TextInput, TouchableOpacity, View, useWindowDimensions, FlatList} from 'react-native';
import {SelectList} from 'react-native-dropdown-select-list';
import {Icon} from 'react-native-elements';
import {useNavigation} from '@react-navigation/native';
import axios from 'axios';
import colors from '../constants/colors';
import rts from '../constants/routes';
import urlsGenerales from '../constants/urlsGenerales';
import {StorageUtil} from '../shared/StorageUtil';
import {CajaService} from "../shared/CajaService";
import Spinner from "react-native-loading-spinner-overlay";
import ModalOperacionAdmin from "../components/ModalOperacionAdmin";
import {AuthenticationService} from "../shared/AuthenticationService";
import CustomAlert from '../shared/CustomAlert';
import {TabView, TabBar} from 'react-native-tab-view';
import * as Location from 'expo-location';

const tiposDocumento = [
    {key: '0', value: 'DNI'},
    {key: '1', value: 'CE'},
    {key: '2', value: 'Pasaporte'}
];

const Detalle = ({cliente}) => {
    
    const [selected, setSelected] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [nroDoc, setNroDoc] = useState('');
    const [fono, setFono] = useState('');
    const [direccion, setDireccion] = useState('');
    const [direccionNegocio, setDireccionNegocio] = useState('');
    const [referencia, setReferencia] = useState('');
    const [latLong, setLatLong] = useState('');
    const [longitud, setLongitud] = useState(8);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigation = useNavigation();

    useEffect(() => {
        setNombre(cliente.nombre);
        setApellido(cliente.apellido);
        setSelected(cliente.codDocumento);
        setNroDoc(cliente.numDocumento);
        setFono(cliente.telefono);
        setDireccion(cliente.direccion);
        setDireccionNegocio(cliente.direccionNegocio);
        setLatLong(cliente.latLong);
        setReferencia(cliente.alias);
    }, [cliente]);

    const onTipoDocumentoSeleccionado = (tipoDocumento) => {
        setNroDoc('');
        setSelected(tipoDocumento);
        switch (tipoDocumento) {
            case '0':
                setLongitud(8);
                break;
            case '1':
                setLongitud(12);
                break;
            case '2':
                setLongitud(12);
                break;
            default:
                setLongitud(8);
                break;
        }
    };

    const abrirGoogleMaps = () => {
        const url = "https://www.google.com/maps/search/?api=1&query="+latLong;
        Linking.openURL(url)
            .then(() => {
                console.log('Se abrio la ubicación del cliente en google maps',url);
            })
            .catch((err: any) => console.error('Error al abrir la ubicación del cliente en google maps: ', err));
    }

    const abrirWaze = () => {
        const url = "https://www.waze.com/ul?ll="+latLong+"&navigate=yes&zoom=17";
        Linking.openURL(url)
            .then(() => {
                console.log('Se abrio la ubicación del cliente en Waze',url);
            })
            .catch((err: any) => console.error('Error al abrir la ubicación del cliente en Waze: ', err));
    }

    const activarGPS = async () => {
        (async () => {
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                CustomAlert.show({
                    title: 'Error de permisos',
                    message: '\nActive los permisos de Ubicación en la configuración del dispositivo.'
                });
                return;
            }
            
            try{
                let location = await Location.getCurrentPositionAsync({});
                setLatLong(location.coords.latitude+","+location.coords.longitude);
            }catch(e){
                setLatLong('')
                CustomAlert.show({
                    title: 'Error de GPS',
                    message: '\nNo permitio el acceso al GPS o el dispositivo no cuenta con esta función.'
                });
            }
        })();
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {

        await setShowModalConfirm(false);
        await setTimeout(() => {}, 200);
        await setLoading(true)
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            await setLoading(false)
            CustomAlert.errorCredenciales();
            return;
        }
        guardarModif(usuario, clave);
    }

    const guardarModif = async (usu, pass) => {

        if (nombre.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese nombre'
            });
            return;
        }

        const formData = new FormData();
        formData.append('codCliente', cliente.codCliente!);
        formData.append('usu', usu);
        formData.append('pass', pass);
        formData.append('nombre', nombre.trim());
        formData.append('apellido', apellido.trim());
        formData.append('codDocumento', selected);
        formData.append('numDocumento', nroDoc);
        formData.append('telefono', fono.trim());
        formData.append('correo', '');
        formData.append('direccion', direccion.trim());
        formData.append('direccionNegocio', direccionNegocio.trim());
        formData.append('latLong', latLong.trim());
        formData.append('alias', referencia.trim());

        try {
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.EDITAR_CLIENTE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.navigate(rts.LOGIN);
                            return;
            }

            if (response.data.mensaje && response.data.mensaje == "Actualizado") {
                CustomAlert.show({
                    title: 'Mensaje',
                    message: 'Se actualizaron los datos del cliente.'
                });
            }

        } catch (error) {
            console.log('Se cae en error -> ', urlsGenerales.EDITAR_CLIENTE);
            console.error(error);
        } finally {
            await setLoading(false)
        }
    }

    return(
        <>
            <ScrollView 
                contentContainerStyle={styles.container}
                showsVerticalScrollIndicator={false}
            >
                <View style={styles.content}>
                    <Text style={styles.labelInput}>Nombre*</Text>
                    <TextInput 
                        style={styles.inputText}
                        value={nombre}
                        selectionColor={colors.negro}
                        onChangeText={setNombre}
                    />
                    <Text style={styles.labelInput}>Apellido*</Text>
                    <TextInput 
                        style={styles.inputText}
                        value={apellido}
                        onChangeText={setApellido}
                    />
                    <Text style={styles.labelInput}>Tipo de Documento*</Text>
                    <View style={styles.selectTipDoc}>
                        <SelectList
                            data={tiposDocumento}
                            setSelected={onTipoDocumentoSeleccionado}
                            search={false}
                            defaultOption={tiposDocumento[0]}
                            boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                            inputStyles={{alignSelf:'center',}}
                            dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                            arrowicon={<Icon name="expand-more" size={24} style={{marginTop:0}} />}
                        />
                    </View>
                    <Text style={styles.labelInput}>N° Documento*</Text>
                    <TextInput 
                        style={styles.inputText}
                        value={nroDoc}
                        onChangeText={setNroDoc}
                        maxLength={longitud}
                    />
                    <Text style={styles.labelInput}>Teléfono*</Text>
                    <TextInput 
                        style={styles.inputText}
                        keyboardType="numeric"
                        value={fono}
                        onChangeText={setFono}
                        maxLength={9}
                    />
                    <Text style={styles.labelInput}>Dirección</Text>
                    <TextInput 
                        style={styles.inputText}
                        value={direccion}
                        onChangeText={setDireccion}
                    />
                    <Text style={styles.labelInput}>Dirección de Negocio</Text>
                    <TextInput 
                        style={styles.inputText}
                        value={direccionNegocio}
                        onChangeText={setDireccionNegocio}
                    />
                    
                    <Text style={[styles.labelInput,{left:0}]}>Latitud y Longitud</Text>
                    <View style={styles.crtGps}>
                        <TouchableOpacity style={styles.crtIcon} onPress={activarGPS}>
                            <Icon name="my-location" color={colors.white} ></Icon>
                        </TouchableOpacity>
                        <TextInput 
                            style={[styles.inputText,{paddingLeft:56}]} 
                            value={latLong} 
                            editable={true}
                        />
                        {
                            latLong !== '' ? 
                            <TouchableOpacity
                                style={[styles.crtIcon,{right:0, backgroundColor:'transparent'}]}
                                onPress={()=>setLatLong('')}
                            >
                                <Icon name='clear' color={colors.negro}></Icon>
                            </TouchableOpacity>
                            :
                            null
                        }
                    </View>
                    {
                        latLong !== '' ?
                        <View>
                            <TouchableOpacity 
                                style={[styles.btn,styles.share]}
                                onPress={()=>abrirGoogleMaps()}
                            >
                                <Icon name="navigation" color={colors.negro} style={{alignSelf:'center'}}></Icon>
                                <Text style={styles.textBtn}>Compartir con Maps</Text>
                            </TouchableOpacity>
                            <TouchableOpacity 
                                style={[styles.btn,styles.share]}
                                onPress={()=>abrirWaze()}
                            >
                                <Icon name="commute" color={colors.negro} style={{alignSelf:'center'}}></Icon>
                                <Text style={styles.textBtn}>Compartir con Waze</Text>
                            </TouchableOpacity>
                        </View>
                        :
                        null
                    }
                    <Text style={styles.labelInput}>Referencia cliente</Text>
                    <TextInput 
                        style={styles.inputText}
                        value={referencia}
                        onChangeText={setReferencia}
                    />

                    <TouchableOpacity
                        style={[styles.btn]}
                        onPress={()=>setShowModalConfirm(true)}
                    >
                        <Text style={[styles.labelButton,{alignSelf:'center'}]}>Actualizar cliente</Text>
                    </TouchableOpacity>
                    
                </View>
            </ScrollView>
            <ModalOperacionAdmin
                visible={showModalConfirm}
                title='Aviso'
                subtitle={`Ingrese sus credenciales para confirmar la actualización del cliente ${cliente.nombre}`}
                onCancel={() => setShowModalConfirm(false)}
                onConfirm={handleConfirmModal}
            />
            <Spinner
                visible={loading}
                color={colors.white}
            />
        </>
    )

}

const Creditos = ({cliente}) => {

    const [creditos, setCreditos] = useState([]);
    const [creditosCancelados, setCreditosCancelados] = useState([]);
    const [creditosActivos, setCreditosActivos] = useState([]);

    useEffect(() => {
        fetchData()
    }, [cliente])
    
    const fetchData = async () => {
        try {
            const formData = new FormData();
            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            formData.append('codUsuario', codUsuario!);
            formData.append('codCliente', cliente.codCliente);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_CREDITOS_V_FINAL, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            setCreditos(response.data)
            console.log("response",response.data)

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.LISTAR_CREDITOS_V_FINAL);
            console.log(error);
        } finally {
        }
    }

    useEffect(() => {
        setCreditosActivos(creditos.filter(s => s.estado === '1' || s.estado === '2' || s.estado === '3'));
        setCreditosCancelados(creditos.filter(s => s.estado === '4'));
    }, [creditos]);

    const getColor = (type) =>{
        let color = colors.negro

        if(type == 'Malo'){
            color = colors.rojo
        }
        if(type == 'Regular'){
            color = colors.naranja
        }
        if(type == 'Bueno'){
            color = colors.azul_cobalto
        }
        if(type == 'Excelente'){
            color = colors.verde_primary
        }
        return color
    }

    const renderItem = ({item}) => {
        return (
            <View style={styles.rowItem}>
                <Text style={[styles.califi,{color:getColor(item.calificacion)}]}>{item.calificacion}</Text>
                <View>
                    <Text style={styles.lblCredito}>#{item.codCredito}</Text>
                    <Text style={styles.fecha}>{item.fecha}</Text>
                </View>
                <View>
                    {
                    item.estado == '0' || item.estado == '4' ? 
                    <>
                        <View>
                            <Text style={styles.lblTitleMonto}>Monto Original</Text>
                            <Text  style={[styles.lblMonto,{fontSize:12, color:'#222222', fontWeight:'500'}]}>S/ {Number(item.valorCredito).toFixed(2)}</Text>
                        </View>
                        <View style={{marginTop:8}}>
                            <Text style={[styles.lblMonto]}>S/ {Number(item.totalDeuda).toFixed(2)}</Text>
                            <Text  style={styles.lblTitleMonto}>Total Pagado</Text>
                        </View>
                    </>
                    :
                    <>
                        <View>
                            <Text style={styles.lblTitleMonto}>Total Deuda</Text>
                            <Text  style={[styles.lblMonto,{fontSize:12, color:'#222222', fontWeight:'500'}]}>S/ {Number(item.totalDeuda).toFixed(2)}</Text>
                        </View>
                        <View style={{marginTop:8}}>
                            <Text style={[styles.lblMonto]}>S/ {Number(item.totalDeuda-item.detalle_caja_total).toFixed(2)}</Text>
                            <Text  style={styles.lblTitleMonto}>Saldo</Text>
                        </View>
                    </>
                    }
                    
                </View>
            </View>
        );
    };
    
    const renderEmpty = () => {
        return (
            <View>
                <Text style={{textAlign:'center', marginBottom:8}}>Cliente no tiene creditos :c</Text>
            </View>
        );
    };

    return(
            <View style={styles.crtCredit}>
                <View style={[styles.boxCredit]}>
                    <Text style={styles.titleCredit}>Activos <Text style={{fontSize:12, fontWeight:'500'}}>({creditosActivos.length}{creditosActivos.length==1?' credito':' creditos'})</Text></Text>
                    <FlatList
                        alwaysBounceVertical={false}
                        showsVerticalScrollIndicator={false}
                        data={creditosActivos}
                        renderItem={renderItem}
                        keyExtractor={(item) => item.codCredito}
                        ListEmptyComponent={renderEmpty}
                        contentContainerStyle={{paddingHorizontal:'5%', paddingVertical:16, paddingBottom:0}}
                        style={{marginVertical:16,}}
                    />
                </View>
                <View style={[{flex:1,}]}>
                    <Text style={styles.titleCredit}>Cancelados <Text style={{fontSize:12, fontWeight:'500'}}>({creditosCancelados.length}{creditosCancelados.length==1?' credito':' creditos'})</Text></Text>
                    <FlatList
                        alwaysBounceVertical={false}
                        showsVerticalScrollIndicator={false}
                        data={creditosCancelados}
                        renderItem={renderItem}
                        keyExtractor={(item) => item.codCredito}
                        ListEmptyComponent={renderEmpty}
                        contentContainerStyle={{paddingHorizontal:'5%', paddingVertical:16, paddingBottom:48}}
                        style={{marginVertical:16,flex:1}}
                    />
                </View>
            </View>
    )
}

const RenderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{backgroundColor: colors.verde_primary}}
      activeColor={colors.verde_primary} // Color de la pestaña activa
      inactiveColor={colors.gris_fondo_cell} // Color de la pestaña inactiva
      style={{backgroundColor: colors.white}}
      labelStyle={{textTransform: 'capitalize', fontWeight: 'bold'}}
    />
);

const VerCliente = ({navigation,route}) => {
    const [loading,setLoading] = useState(false)
    const {cliente} = route.params;
    const layout = useWindowDimensions();

    const [index, setIndex] = useState(0);
    const [routes] = useState([
      {key: 'detalle', title: 'Informacion'},
      {key: 'credito', title: 'Creditos'},
    ]);

    const concederCredito = async () => {
        await setLoading(true)
        const codUsuario = await StorageUtil.getCodUsuarioStorage();
        const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuario!, navigation);
        await setLoading(false)
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                await StorageUtil.setRutaStorage('0');
                navigation.navigate(rts.ASIGNAR_CREDITO, {cliente:route.params.cliente});

            } else {
                await setTimeout(() => {}, 200);
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    useEffect(() => {
        navigation.setOptions({
            headerTitle:String(cliente.nombre+ ' '+ cliente.apellido),
            headerTitleContainerStyle:{
                maxWidth:'70%',
            },
            headerRight:()=>(
                <TouchableOpacity style={{marginRight:16, backgroundColor:colors.logo_naranja, padding:4, borderRadius:4}} onPress={()=>concederCredito()}>
                    <Icon name='attach-money' color={colors.white} size={24}></Icon>
                </TouchableOpacity>
            )
        });
    }, [navigation]);

    const renderScene = ({route}) => {
        switch (route.key) {
          case 'detalle':
            return <Detalle  cliente={cliente}/>;
          case 'credito':
            return <Creditos  cliente={cliente}/>;
          default:
            return null;
        }
    };
    return(
        <>
            <TabView
                navigationState={{index, routes}}
                renderScene={renderScene}
                onIndexChange={setIndex}
                initialLayout={{width: layout.width}}
                renderTabBar={props => (
                    <RenderTabBar
                        {...props}
                        cliente={cliente}
                    />
                )}
            />
            <Spinner
                visible={loading}
                color={colors.white}
            />
        </>
    )
}

export default VerCliente

const styles = StyleSheet.create({
    container: {
        paddingBottom:48,
    },
    content:{
        paddingHorizontal:'5%'
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    selectTipDoc: {
        marginTop: 4,
    },
    btn: {
        backgroundColor: colors.verde_primary,
        borderRadius: 4,
        height:48,
        justifyContent:'center',
        marginTop:48,
    },
    textBtn:{
        fontSize:14,
        fontWeight:'bold',
        color:colors.negro,
        alignSelf:'center',
        marginLeft:4,
    },
    share:{
        backgroundColor: 'transparent',
        flexDirection:'row',
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        marginTop:4
    },
    crtGps:{
        position:'relative'
    },
    crtIcon:{
        position:'absolute',
        height:48,
        width:48,
        padding:4,
        borderRadius:4,
        backgroundColor:colors.negro,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:5,
        bottom:0,
    },
    labelButton: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        marginEnd: 8,
    },
    crtCredit:{
        display:'flex',
        flex:1,
        flexDirection:'column',
        justifyContent:'space-between',
        paddingVertical:16
    },
    boxCredit:{
        maxHeight:'50%'
    },
    titleCredit:{
        fontWeight: 'bold',
        fontSize: 20,
        marginLeft:'5%'
    },
    rowItem:{
        justifyContent:'space-between',
        display:'flex',
        flexDirection:'row',
        backgroundColor:colors.white,
        marginBottom:8,
        borderRadius:4,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.10,
        shadowRadius: 1,
        elevation: 1,
        padding:16,
    },
    lblTitleMonto:{
        fontSize:12,
        color:'#222222',
        textAlign:'right',
        fontWeight:'400'
    },
    lblMonto:{
        fontSize:18,
        color:colors.logo_azul,
        textAlign:'right',
        fontWeight:'bold',
    },
    lblCredito:{
        fontSize:16,
        fontWeight:'bold'
    },
    fecha:{
        fontSize:12,
        marginTop:4,
        color:'#222222'
    },
    califi:{
        textTransform:'capitalize',
        position:'absolute',
        bottom:16,
        left:16,
        fontSize:12,
        fontWeight:'bold'
    }
})
