import React from 'react';
import {ScrollView, StyleSheet, Linking, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {SelectList} from 'react-native-dropdown-select-list';
import {Icon} from 'react-native-elements';

import {useFocusEffect, useNavigation} from '@react-navigation/native';
import axios from 'axios';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../constants/colors';
import routes from '../constants/routes';
import stylesGenerales from '../constants/stylesGenrales';
import urlsGenerales from '../constants/urlsGenerales';
import {StorageUtil} from '../shared/StorageUtil';
import {textoSpinner} from '../shared/TiposDocumento';
import CustomAlert from '../shared/CustomAlert';
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as Location from 'expo-location';

export default function ClienteNuevo() {

    const navigation = useNavigation();

    const [selected, setSelected] = React.useState('');
    const [nombre, setNombre] = React.useState('');
    const [apellido, setApellido] = React.useState('');
    const [nroDoc, setNroDoc] = React.useState('');
    const [fono, setFono] = React.useState('');
    const [direccion, setDireccion] = React.useState('');
    const [direccionNegocio, setDireccionNegocio] = React.useState('');
    const [correo, setCorreo] = React.useState('');
    const [referencia, setReferencia] = React.useState('');
    const [latLong, setLatLong] = React.useState('');
    const [longitud, setLongitud] = React.useState(8);
    const [btnCrearDisable, setBtnCrearDisable] = React.useState(false);
    const [btnGPSActivo, setBtnGPSActivo] = React.useState(true);

    const tiposDocumento = [
        {key: '0', value: 'DNI'},
        {key: '1', value: 'CE'},
        {key: '2', value: 'Pasaporte'}
    ];

    
    const [location, setLocation] = React.useState(null);

    
    const abrirGoogleMaps = () => {
        const url = "https://www.google.com/maps/search/?api=1&query="+latLong;
        Linking.openURL(url)
            .then(() => {
                console.log('Se abrio la ubicación del cliente en google maps',url);
            })
            .catch((err: any) => console.error('Error al abrir la ubicación del cliente en google maps: ', err));
    }

    const abrirWaze = () => {
        const url = "https://www.waze.com/ul?ll="+latLong+"&navigate=yes&zoom=17";
        Linking.openURL(url)
            .then(() => {
                console.log('Se abrio la ubicación del cliente en Waze',url);
            })
            .catch((err: any) => console.error('Error al abrir la ubicación del cliente en Waze: ', err));
    }

    const activarGPS = async () => {
        (async () => {
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                CustomAlert.show({
                    title: 'Error de permisos',
                    message: '\nActive los permisos de Ubicación en la configuración del dispositivo.'
                });
                return;
            }
            try{
                let location = await Location.getCurrentPositionAsync({});
                setLocation(location);
                setLatLong(location.coords.latitude+","+location.coords.longitude);
            }catch(e){
                CustomAlert.show({
                    title: 'Error de GPS',
                    message: '\nNo permitio el acceso al GPS o el dispositivo no cuenta con esta función.'
                });
            }
        })();
    }

    const recargarGPS = async () => {
        setLatLong('');
        setLocation(null);
        activarGPS();
    }

    const desactivarGPS = async () => {
        setBtnGPSActivo(true);
        setLatLong('');
        setLocation(null);
    }


    useFocusEffect(
        React.useCallback(() => {

            const fetchData = () => {
                setNombre('');
                setApellido('');
                setNroDoc('');
                setFono('');
                setDireccion('');
                setDireccionNegocio('');
                setLatLong('');
                setCorreo('');
                setReferencia('');

                setBtnCrearDisable(false);
            };
            fetchData();
        }, [])
    );

    const [loading, setLoading] = React.useState(false);

    const guardarCliente = async () => {
        if (nombre.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese el nombre del cliente'
            });
            return;
        }
        if (apellido.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese nombre'
            });
            return;
        }
        if (apellido.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese apellido'
            });
            return;
        }
        if (nroDoc.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese número de documento'
            });
            return;
        }
        if (fono.trim() === '') {
            CustomAlert.show({
                title: 'Error',
                message: 'Ingrese número de celular'
            });
            return;
        }


        const formData = new FormData();
        const codUsuario = await StorageUtil.getCodUsuarioStorage();
        formData.append('codUsuario', codUsuario!);
        formData.append('nombre', nombre);
        formData.append('apellido', apellido);
        formData.append('codDocumento', selected);
        formData.append('numDocumento', nroDoc);
        formData.append('telefono', fono);
        formData.append('correo', correo);
        formData.append('direccion', direccion);
        formData.append('direccionNegocio', direccionNegocio);
        formData.append('latLong', latLong);
        formData.append('alias', referencia);

        try {
            setLoading(true);
            const token = await StorageUtil.getTokenStorage(); 

            const response = await axios.post(urlsGenerales.CREAR_CLIENTE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                const registroCliente = response.data;
                navigation.navigate(routes.ASIGNAR_CREDITO, {cliente: registroCliente});
                await AsyncStorage.setItem('recargar_client', "si");
            }

        } catch (error) {
            console.log('Se cae en error -> ', urlsGenerales.CREAR_CLIENTE);
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const onTipoDocumentoSeleccionado = (tipoDocumento) => {
        setNroDoc('');
        setSelected(tipoDocumento);
        switch (tipoDocumento) {
            case '0':
                setLongitud(8);
                break;
            case '1':
                setLongitud(12);
                break;
            case '2':
                setLongitud(12);
                break;
            default:
                setLongitud(8);
                break;
        }
    };

    return (
        <>
        <ScrollView 
            contentContainerStyle={styles.container}
            showsVerticalScrollIndicator={false}
        >
            <View style={styles.content}>
                <Text style={styles.subTitle}>Complete la información del cliente</Text>
                <Text style={styles.labelInput}>Nombre*</Text>
                <TextInput style={styles.inputText}
                        value={nombre}
                        selectionColor={colors.negro}
                        onChangeText={setNombre}></TextInput>

                <Text style={styles.labelInput}>Apellido*</Text>
                <TextInput style={styles.inputText}
                        value={apellido}
                        onChangeText={setApellido}></TextInput>

                <Text style={styles.labelInput}>Tipo de Documento*</Text>
                <View style={styles.selectTipDoc}>
                    <SelectList
                        data={tiposDocumento}
                        setSelected={onTipoDocumentoSeleccionado}
                        search={false}
                        defaultOption={tiposDocumento[0]}
                        boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                        inputStyles={{alignSelf:'center',}}
                        dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                        arrowicon={<Icon name="expand-more" size={24} style={{marginTop:0}} />}
                    />
                </View>

                <Text style={styles.labelInput}>N° Documento*</Text>
                <TextInput style={styles.inputText}
                        value={nroDoc}
                        onChangeText={setNroDoc}
                        maxLength={longitud}></TextInput>

                <Text style={styles.labelInput}>Teléfono*</Text>
                <TextInput style={styles.inputText}
                        keyboardType="numeric"
                        value={fono}
                        onChangeText={setFono}
                        maxLength={9}></TextInput>

                <Text style={styles.labelInput}>Dirección</Text>
                <TextInput style={styles.inputText}
                        value={direccion}
                        onChangeText={setDireccion}></TextInput>

                <Text style={styles.labelInput}>Dirección de Negocio</Text>
                <TextInput style={styles.inputText}
                        value={direccionNegocio}
                        onChangeText={setDireccionNegocio}></TextInput>

                <Text style={[styles.labelInput,{left:0}]}>Latitud y Longitud</Text>
                <View style={styles.crtGps}>
                    <TouchableOpacity style={styles.crtIcon} onPress={()=>activarGPS()}>
                        <Icon name="my-location" color={colors.white} ></Icon>
                    </TouchableOpacity>
                    <TextInput 
                        style={[styles.inputText,{paddingLeft:56}]} 
                        value={latLong} 
                        editable={true}
                    />
                    {
                        latLong !== '' ? 
                        <TouchableOpacity
                            style={[styles.crtIcon,{right:0, backgroundColor:'transparent'}]}
                            onPress={()=>setLatLong('')}
                        >
                            <Icon name='clear' color={colors.negro}></Icon>
                        </TouchableOpacity>
                        :
                        null
                    }
                </View>
                {
                    latLong !== '' ?
                    <View>
                        <TouchableOpacity 
                            style={[styles.btn,styles.share]}
                            onPress={()=>abrirGoogleMaps()}
                        >
                            <Icon name="navigation" color={colors.negro} style={{alignSelf:'center'}}></Icon>
                            <Text style={styles.textBtn}>Compartir con Maps</Text>
                        </TouchableOpacity>
                        <TouchableOpacity 
                            style={[styles.btn,styles.share]}
                            onPress={()=>abrirWaze()}
                        >
                            <Icon name="commute" color={colors.negro} style={{alignSelf:'center'}}></Icon>
                            <Text style={styles.textBtn}>Compartir con Waze</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    null
                }
                <Text style={[styles.labelInput,{marginTop:12,}]}>Referencia cliente</Text>
                <TextInput style={styles.inputText}
                        value={referencia}
                        onChangeText={setReferencia}></TextInput>

                <TouchableOpacity
                    disabled={btnCrearDisable}
                    style={[styles.btn]}
                    onPress={guardarCliente}
                >
                    <Text style={[styles.labelButton,{alignSelf:'center'}]}>Guardar cliente</Text>
                </TouchableOpacity>
            </View>
        </ScrollView>
        <Spinner
            visible={loading}
            color={colors.verde_primary}
            textContent={textoSpinner}
            textStyle={stylesGenerales.textSpinner}
        />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingBottom:48
    },
    content:{
        paddingHorizontal:'5%'
    },
    button: {
        backgroundColor: colors.logo_azul,
        borderRadius: 5,
        padding: 10,
        flexDirection: 'row',
        marginTop: 15
    },

    labelButton: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        marginEnd: 8,
    },

    selectTipDoc: {
        marginTop: 4,
    },

    subTitle: {
        marginVertical:32,
        marginBottom:8,
        fontSize:14,
        textAlign:'left'
    },

    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    btn: {
        backgroundColor: colors.verde_primary,
        borderRadius: 4,
        height:48,
        justifyContent:'center',
        marginTop:32,
    },
    textBtn:{
        fontSize:14,
        fontWeight:'bold',
        color:colors.negro,
        alignSelf:'center',
        marginLeft:4,
    },
    share:{
        backgroundColor: 'transparent',
        flexDirection:'row',
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        marginTop:4
    },
    crtGps:{
        position:'relative'
    },
    crtIcon:{
        position:'absolute',
        height:48,
        width:48,
        padding:4,
        borderRadius:4,
        backgroundColor:colors.negro,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:5,
        bottom:0,
    }
})
