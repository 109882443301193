import React, {useState} from 'react';
import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';

import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import colors from '../../constants/colors';
import routes from '../../constants/routes';
import {StorageUtil} from "../../shared/StorageUtil";
import {CobradorType} from "../../types/CobradorType";
import Spinner from "react-native-loading-spinner-overlay";
export default function EdicionDatos() {

    const navigator = useNavigation();
    const route = useRoute();

    const [cobrador, setCobrador] = useState<CobradorType>({
        codUsuario: "",
        nombre: "",
        apellido: "",
        codUsuarioHijo: "",
        nombreHijo: "",
        apellidoHijo: "",
        documentoHijo: "",
        telefonoHijo: "",
        correoHijo: "",
        idHijo: "",
        contraHijo: ""
    });

    const [loading, setLoading] = useState(false);

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                setLoading(true);
                const cobradorStorage = await StorageUtil.getCobradorStorage();
                setCobrador(cobradorStorage);
                setLoading(false);
            };
            fetchData();
        }, [])
    );

    return (
        <ScrollView contentContainerStyle={styles.container}
                    showsHorizontalScrollIndicator={false}>
            
            <View style={{paddingHorizontal:'5%'}}>

            <View>
                <Text style={styles.lblTitle}>Funciones</Text>
                <Text style={styles.lblDes}>La funcionalidad de modificación de datos de la ruta está disponible solamente usando la Superclave.</Text>
            </View>
            <View style={{borderRadius:8, overflow:'hidden'}}>
                <TouchableOpacity style={styles.rowFn}
                                onPress={() => navigator.navigate(routes.AGREGAR_CREDITO, {cobrador: cobrador})}
                >
                    <Text style={styles.textBtn}>Agregar Crédito</Text>
                    <View style={styles.containerIcon}>
                        <Icon name='chevron-right' color={colors.negro}></Icon>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.rowFn}
                                onPress={() => navigator.navigate(routes.AGREGAR_CLIENTE_CREDITO, {cobrador: cobrador})}
                >
                    <Text style={styles.textBtn}>Agregar Cliente + Crédito</Text>
                    <View style={styles.containerIcon}>
                        <Icon name='chevron-right' color={colors.negro}></Icon>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.rowFn}
                                onPress={() => navigator.navigate(routes.BORRAR_MODIFICAR_CREDITOS, {cobrador: cobrador})}
                >
                    <Text style={styles.textBtn}>Borrar / Modificar Créditos</Text>
                    <View style={styles.containerIcon}>
                        <Icon name='chevron-right' color={colors.negro}></Icon>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.rowFn}
                                onPress={() => navigator.navigate(routes.BORRAR_ABONOS_PAGOS, {cobrador: cobrador})}
                >
                    <Text style={styles.textBtn}>Borrar Abonos / Pagos</Text>
                    <View style={styles.containerIcon}>
                        <Icon name='chevron-right' color={colors.negro}></Icon>
                    </View>
                </TouchableOpacity>
            </View>
            </View>

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex:1,
    },
    labelEdicion: {
        width: '90%',
        fontSize: 16,
        textAlign: 'justify',
        marginBottom: '3%'
    },
    rowFn:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        padding:12,
        backgroundColor:colors.gris_fondo_cell
    },
    textBtn:{
        fontSize:12,
        fontWeight:'bold',
        alignSelf:'center'
    },
    containerIcon:{
        alignSelf:'center'
    },
    textOpcion:{
        backgroundColor: colors.verde_primary,
        paddingHorizontal:10,
        paddingVertical:16,
        fontWeight: 'bold',
        fontSize: 16,
        color: colors.white
    },
    lblTitle:{
        fontSize:18,
        fontWeight:'bold',
        marginTop:24,
        marginBottom:8
    },
    lblDes:{
        fontSize:12,
        marginBottom:16
    }
})
