import {useFocusEffect, useRoute} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';

import axios from 'axios';
import {ScrollView, Linking,Modal, StyleSheet, Text, TouchableOpacity, View, Pressable, Dimensions, Image, RefreshControl} from 'react-native';
import {Icon} from 'react-native-elements';
import {FlatList} from 'react-native-gesture-handler';
import colors from '../../constants/colors';
import stylesGenerales from '../../constants/stylesGenrales';
import urlsGenerales from '../../constants/urlsGenerales';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {textoSpinner} from '../../shared/TiposDocumento';
import {StorageUtil} from "../../shared/StorageUtil";
import ModalOperacionAdmin from "../../components/ModalOperacionAdmin";
import {AuthenticationService} from "../../shared/AuthenticationService";
import ModalMensaje from "../../components/ModalMensaje";
import {CajaService} from "../../shared/CajaService";
import CustomAlert from '../../shared/CustomAlert';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import NetInfo from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import routes from '../../constants/routes';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const windowWidth = Dimensions.get("window").width;

export default function VerAbonosCliente() {
    const insets = useSafeAreaInsets();
    const navigation = useNavigation();

    const [isConnected, setIsConnected] = React.useState(true);

    React.useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
          setIsConnected(state.isConnected);
        });
    
        return () => {
          unsubscribe();
        };
    }, []);

    const route = useRoute();

    const [pagos, setPagos] = React.useState({});

    type Abono = {
        codpagos: string;
        codUsuario: string;
        codCredito: string;
        codCaja: string;
        fecha: string;
        monto: string;
        timestamp: string;
    }

    const [abonos, setAbonos] = React.useState<Abono[]>([]);
    const [totlRecaudo, setTotalRecaudo] = React.useState(0);

    const [modalVisible, setModalVisible] = useState(false);
    const [codAbono, setCodAbono] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalMensaje, setModalMensaje] = useState(false);
    const [saldo, setSaldo] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const listarAbonos = async () => {
        try {
            setLoading(true);

            let responseFinal = {data:[]};
            const {codCredito} = route.params;
            let cargado = false;

            //const isConnected = false;

            if(isConnected){
                cargado = true;

                const formData = new FormData();
                formData.append('codCredito', codCredito);
                const token = await StorageUtil.getTokenStorage();
                responseFinal = await axios.post(urlsGenerales.LISTAR_ABONOS_CREDITO_V2, formData, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }else{
                const codUsuario = await AsyncStorage.getItem('codUsuario');
                const listLoginResponseJson = await SecureStore.getItemAsync('listLoginResponse');
                const listLoginResponse = JSON.parse(listLoginResponseJson);
                listLoginResponse.forEach((loginResponse) => {
                    if(codUsuario === loginResponse['codUsuario']){
                        const listaCobro = loginResponse['pantallaInicio'][0]['listaCobro'];
                        listaCobro.forEach((cobro) => {
                            if(codCredito+"" === cobro['codCredito']){
                                responseFinal.data = cobro['abonos'];
                                cargado = true;
                                return false;
                            }
                        });
                        return false;
                    }
                });
            }

            if (cargado && responseFinal) {
                setPagos(responseFinal.data);
                setAbonos(responseFinal.data.listaPagos);
            }

        } catch (error) {
            console.log(error);
            console.log('Se cae en error -> ', urlsGenerales.LISTAR_ABONOS_CREDITO_V2);

            setAbonos([]);
        } finally {
            setLoading(false);
        }
    }

    const fetchData = async () => {
        await listarAbonos();
        setSaldo(pagos.saldoCredito);
    };

    useFocusEffect(
        React.useCallback(() => {
            fetchData();
        }, [isConnected])
    );

    useEffect(() => {
        const totalMonto = abonos.reduce((accumulator, current) => accumulator + parseInt(current.monto), 0);
        setTotalRecaudo(totalMonto);
    }, [abonos]);


    const renderItem = ({item}) => {

        const getEstado = () => {
            let tipo = ' '
            if(item.tipo){
                switch (item.tipo) {
                        case '0':
                            tipo = ' Efectivo '
                            break;
                        case '1':
                            tipo = ' Yape '
                            break;
                        case '2':
                            tipo = ' Plin '
                            break;
                        case '3':
                            tipo = ' BCP '
                            break;
                        case '4':
                            tipo = ' Interbank '
                            break;
                    default:
                        tipo = ' Otros '
                        break;
                }
            }
            return tipo
        }

        return (
            <View style={{
                flexDirection: 'row',
                borderBottomWidth: 0.5,
                borderBottomColor: '#222222',
                marginBottom: 8,
                paddingVertical:8,
                paddingBottom:16,
                paddingHorizontal:'5%'
            }}
            >
                <View style={{flex:1,paddingRight:16}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={{fontSize:14,fontWeight:'bold',marginBottom:4}}>Pago{getEstado()}{item.codCuota}</Text>
                    <Text style={{fontSize:12,fontWeight:'400'}}>{item.fecha}</Text>
                </View>

                <View style={{alignSelf:'center'}}>
                    <Text style={{fontSize:16,fontWeight:'bold'}}>S/ {Number(item.monto).toFixed(2)}</Text>
                </View>
            </View>
        );
    };

    const renderEmpty = () => {
        return (
            <View style={stylesGenerales.emptyList}>
                <Text style={{fontSize: 15, marginVertical: 20}}>No hay abonos</Text>
            </View>
        );
    };


    const [modalTicketRecaudo, setModalTicketRecaudo] = React.useState(false);
    const [modalImprBluetooth, setModalImprBluetooth] = React.useState(false);

    const onRefresh = async() => {
        await setRefreshing(true);
        await fetchData()
        await setRefreshing(false)
    }

    const randomString = ()=>{
        return Math.random().toString(36).substring(7);
    } 

    return (
        <>
        <View style={{flex:1,}}>
            <FlatList
                showsVerticalScrollIndicator={false}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
                data={abonos}
                renderItem={renderItem}
                keyExtractor={(item, index) => item.codpagos + index.toString()}
                ListEmptyComponent={renderEmpty}
                style={{width: '100%', flexGrow:1}}
                contentContainerStyle={{paddingBottom:40}}
                removeClippedSubviews={false}
                ListHeaderComponent={
                    <>
                    <View style={styles.crtBox}>
                        <Text style={styles.lblTitle}>Credito #{pagos.codCredito}</Text>
                        <View style={{marginTop:16}}>
                            <Text style={styles.lblmount}>Recaudado</Text>
                            <Text style={[styles.mount,{marginTop:8}]}>S/ {Number(totlRecaudo).toFixed(2)}</Text>
                        </View>
                        <View style={[styles.row,{marginTop:16}]}>
                            <Text style={[styles.lblmount,{alignSelf:'center'}]}>Saldo</Text>
                            <Text style={styles.mount2}>S/ {pagos.saldoCredito ? Number(pagos.saldoCredito).toFixed(2) : Number(saldo).toFixed(2)}</Text>
                        </View>
                    </View>
                    <View style={[styles.row,{marginBottom:16, borderTopWidth:0.5, borderBottomWidth:0.5, borderColor:'#22222230'}]}>
                        <TouchableOpacity style={[styles.botonFooter]}
                                        onPress={() => {
                                            setModalTicketRecaudo(true);
                                        }}>
                                        <View style={{display:'flex',height:40,width:40, borderRadius:20,justifyContent:'center', backgroundColor:colors.verde_primary,alignSelf:'center'}}>
                                            <Icon name='confirmation-number' color={colors.white} size={20} style={{alignSelf:'center'}}></Icon>
                                        </View>
                            <Text style={styles.labelBoton}>Ticket{'\n'}recaudo</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={[styles.botonFooter]}
                                        onPress={() => {
                                            setModalImprBluetooth(true);
                                        }}>
                            
                            <View style={{display:'flex',height:40,width:40, borderRadius:20,justifyContent:'center', backgroundColor:colors.logo_azul,alignSelf:'center'}}>
                                            <Icon name='confirmation-number' color={colors.white} size={20} style={{alignSelf:'center'}}></Icon>
                                        </View>
                            <Text style={styles.labelBoton}>Boleta{'\n'}pagos</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{marginHorizontal:'5%', marginBottom:16}}>
                        <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>   
                            <Text style={styles.lblMov}>Movimientos</Text>
                        </View>
                    </View>
                    </>
                }
                >
            </FlatList>
            
        </View>

        <Modal
            style={{flex:1}}
            visible={modalTicketRecaudo}>
            <View style={[styles.crtModalImg,{marginTop:insets.top, marginBottom:insets.bottom}]}>
                <View style={styles.headerBox}>
                    <Text style={styles.headerText}>TICKET DE RECAUDO</Text>
                    <Pressable onPress={()=>setModalTicketRecaudo(false)} style={styles.btnClose}>
                        <Icon name='cancel' color={colors.black} size={28}></Icon>
                    </Pressable>
                </View>
                <Image 
                    style={styles.imgBoleta}
                    resizeMode="contain"
                    source={{uri:urlsGenerales.S23b + '?codCredito=' + pagos.codCredito  + '&random=' + randomString()}} 
                />
            </View>
        </Modal>

        <Modal visible={modalImprBluetooth} style={{flex:1}}>
            <View style={[styles.crtModalImg,{marginTop:insets.top, marginBottom:insets.bottom}]}>
                <View style={styles.headerBox}>
                    <Text style={styles.headerText}>BOLETA DE PAGOS</Text>
                    <Pressable onPress={()=>setModalImprBluetooth(false)} style={styles.btnClose}>
                        <Icon name='cancel' color={colors.black} size={28}></Icon>
                    </Pressable>
                </View>
                <Image 
                    style={styles.imgBoleta}
                    resizeMode="contain"
                    source={{uri:urlsGenerales.BOLETA_PAGOSb + '?codCredito=' + pagos.codCredito  + '&random=' + randomString()}} 
                />
            </View>
        </Modal>

        <Spinner
            visible={loading}
            color={colors.white}
        />
        </>
    )
}

const styles = StyleSheet.create({
    
    labelTitle: {
        width: '90%',
        fontWeight: 'bold',
        fontSize: 23,
        marginTop: '4%',
        marginBottom: '1%'
    },

    labelSubTitle: {
        width: '90%',
        fontSize: 16
    },

    labelDescrip: {
        fontWeight: 'bold',
        width: '90%',
        fontSize: 18,
        marginTop: '6%'
    },

    btn: {
        borderRadius: 10,
        padding: 10,
        flexDirection: 'row',
        marginRight: 10
    },

    textBox: {
        backgroundColor: '#ccc',
        width: '90%',
        padding: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 20,
        color: colors.logo_azul,
        marginTop: '6%',
        marginBottom: '4%'
    },

    btnAgregarDisable: {
        flexDirection: 'row',
        backgroundColor: colors.gris_fondo_cell,
        padding: 8,
        borderRadius: 5,
        margin: 5
    },

    titleModal: {fontWeight: 'bold', fontSize: 18, marginTop: '5%', marginBottom: '2%'},

    labelPlazo: {
        fontWeight: 'bold',
        fontSize: 16
    },

    labelConfirmar: {
        fontSize: 16,
        width: '80%'
    },

container: {
    flex: 1,
    // paddingHorizontal:'5%'
    // alignItems: 'center',
},

labelOpciones: {
    fontWeight: 'bold',
    fontSize: 20,
    marginVertical:16,
    width: '90%'
},

botonFooter: {
    width: '50%',
    paddingVertical:16
},

labelBoton: {
    fontWeight: '400',
    marginTop:2,
    fontSize: 12,
    color: colors.negro,
    textAlign: 'center'
},
crtModalImg:{
    flex: 1,
    display:'flex',
    flexDirection:'column',
    backgroundColor:colors.gray
},
imgBoleta:{
    width: windowWidth* 0.9, // Adjust the percentage as needed
    // height: windowHeight,
    aspectRatio:0.5,
    alignSelf: 'center',
    // flex:1,
},
headerBox:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    height:40,
    backgroundColor:colors.white,
    paddingHorizontal: '5%',
    borderBottomWidth: 0.5,
    borderBottomColor: colors.logo_gris
},
btnClose:{
    display:'flex',
    width: 50,
    justifyContent:'center'
},
headerText:{
    fontSize:20,
    fontWeight: 'bold',
    color: colors.verde_primary,
    alignSelf: 'center'
},
btnEliminar: {
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 1,
    },
    shadowOpacity: 0.10,
    shadowRadius: 1,
    elevation: 1,
    borderRadius: 4,
    backgroundColor: colors.white,
    padding: 16,
    paddingHorizontal:24,
    marginVertical:16,
    width:'auto',
    alignSelf:'center'
},
btnLabel: {
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 16,
    marginStart: 5
},
lblMov:{
    fontSize:20,
    fontWeight:'bold',
    textAlign:'left',
    marginVertical:16,
},
crtBox:{
    // backgroundColor: colors.white,
    // shadowColor: colors.black,
    // shadowOffset: {
    //     width: 0,
    //     height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 2,
    // elevation: 2,
    // borderRadius: 4,
    paddingVertical:16,
    // marginVertical:16,
    marginHorizontal:'5%'
},
row:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
},
mount:{
    fontSize:32,
    fontWeight:'bold',
    color:colors.logo_azul
},
mount2:{
    fontSize:12,
    fontWeight:'bold',
    color:'#222222',
    alignSelf:'center'
},
lblmount:{
    color:'#222222',
    fontSize:12,
},
lblTitle:{
    fontSize:12,
    textAlign:'left',
    fontWeight:'bold'
},
boxInformationVersion:{
    padding:16,
    backgroundColor:colors.naranja,
    borderRadius:4,
},
})
