import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import {useFocusEffect, useRoute} from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../../shared/TiposDocumento";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';

export default function CarteraActual() {

    const navigation = useNavigation();

    const route = useRoute();
    const cobrador = route.params?.cobrador;

    const [loading, setLoading] = useState(false);

    const [listCartera, setListCartera] = useState([]);

    const [capital, setCapital] = React.useState(0);
    const [intereses, setIntereses] = React.useState(0);
    const [recaudo, setRecaudo] = React.useState(0);
    const [carteraEsp, setCarteraEsp] = React.useState(0);
    const [cantClientes, setCantClientes] = React.useState(0);
    const [carteraCob, setCarteraCob] = React.useState(0);

    const obtenerCarteraActual = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            const token = await StorageUtil.getTokenStorage(); 
            formData.append('codUsuario', cobrador.codUsuarioHijo);

            const response = await axios.post(urlsGenerales.REP_CARTERA_ACTUAL, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            setListCartera(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.REP_CARTERA_ACTUAL);
            console.log(error);

            setListCartera([]);

        } finally {
            setLoading(false);
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                await obtenerCarteraActual();
            };
            fetchData();
        }, [])
    );

    useEffect(() => {
        if (listCartera.length > 0) {
            const carteraActual = listCartera[0];
            setCapital(carteraActual.capital);
            setIntereses(carteraActual.intereses);
            setRecaudo(carteraActual.recaudado);
            setCarteraEsp(carteraActual.carteraEsperada);
            setCantClientes(carteraActual.cantidadDeClientes);
            setCarteraCob(carteraActual.carteraxCobrar);
        } else {
            setCapital(0);
            setIntereses(0);
            setRecaudo(0);
            setCarteraEsp(0);
            setCantClientes(0);
            setCarteraCob(0);
        }

    }, [listCartera]);

    return (
        <View style={styles.container}>

            <Text style={[styles.title]}>Resumen de cartera</Text>

            <Text style={{ fontSize: 12, marginHorizontal:'5%' }}>Información acerca de la cartera por cobrar y detalles sobre el estado
                de cartera activa
            </Text>

            
            <View>
                <Text style={styles.lblTitle}>Capital invertido</Text>
                <Text style={styles.lblAmount}>S/ {capital}</Text>
            </View>

            <View style={styles.boxShadow}>
                <Text style={styles.lblBox}>Información de cartera</Text>
                
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Intereses</Text>
                    <Text style={[styles.lblBoxAmount]}>S/ {intereses}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Recaudado</Text>
                    <Text style={styles.lblBoxAmount}>S/ {recaudo}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Esperado</Text>
                    <Text style={styles.lblBoxAmount}>S/ {carteraEsp}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>({cantClientes}) Clientes por cobrar</Text>
                    <Text style={styles.lblBoxAmount}>S/ {carteraCob}</Text>
                </View>
            </View>

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    
    container: {
        flex: 1,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
        marginHorizontal:'5%'
    },
    lblTitle:{
        fontSize:12,
        fontWeight:'500',
        textAlign:'center',
        marginTop:16,
        textTransform:'uppercase',
        marginBottom:4
    },
    lblAmount:{
        fontSize:24,
        fontWeight:'bold',
        color:colors.logo_azul,
        textAlign:'center',
    },
    boxShadow:{
        display:'flex',
        borderRadius:8,
        justifyContent:'center', 
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
        padding:16,
        marginHorizontal:'5%',
        marginVertical:16,
        paddingBottom:8
    },
    lblBox:{
        fontSize:18,
        fontWeight:'bold',
        marginBottom:16,
    },
    row:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        marginBottom:8
    },
    lblBoxAmount:{
        fontSize:16,
        fontWeight:'bold',
        color:colors.logo_azul,
    },
    lblBoxText:{
        color:colors.gris_borde_item_lista
    },
})
