import {useFocusEffect, useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {Image, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../../constants/colors';
import routes from '../../constants/routes';
import stylesAdmin from '../../constants/stylesAdmin';
import stylesGenerales from '../../constants/stylesGenrales';
import axios from 'axios';
import urlsGenerales from '../../constants/urlsGenerales';
import {FlatList} from 'react-native-gesture-handler';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import {textoSpinner} from '../../shared/TiposDocumento';
import {StorageUtil} from '../../shared/StorageUtil';

export default function Delegados() {

    const navigation = useNavigation();

    const [listDelegados, setListDelegados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = React.useState('');

    
    

    const handleSearch = (text: string) => {
        setSearchText(text);
    }

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 12, marginVertical: 20, textAlign:'center'}}>No hay usuarios registrados</Text>
        );
    };

    const renderItem = ({item}) => {

        const seleccionarDelegado = () => {
            navigation.navigate(routes.DELEGAR_CUENTAS, {delegado: item});
        }

        return (
            <TouchableOpacity
                style={styles.crtItem}
                onPress={seleccionarDelegado}>
                
                <View style={{flex:1,alignSelf:'center'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>{item.nombre} {item.apellido}</Text>
                </View>
                <Icon name='chevron-right' size={24} style={{alignSelf:'center'}}></Icon>

            </TouchableOpacity>
        );
    };

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                try {
                    const formData = new FormData();
                    const codSuperAdm = await StorageUtil.getCodUsuarioStorage();

                    formData.append('codSuperAdm', codSuperAdm!);
                    setLoading(true);
                    const token = await StorageUtil.getTokenStorage();
                    const response = await axios.post(urlsGenerales.LISTAR_USUARIOS_ADMIN, formData, {
                        withCredentials: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log(response)
                    setListDelegados(response.data);
                } catch (error) {
                    console.log('Error en -> ', urlsGenerales.LISTAR_USUARIOS_ADMIN);
                    console.log(error);
                    setListDelegados([]);
                } finally {
                    setLoading(false);
                }
            };
                fetchData();
        }, [])
    );

    // useEffect(() => {
    //     console.log("notReload",notReload)
    // }, [notReload])
    
    
    return (
        <View style={styles.container}>
            <View style={[styles.row,styles.mrg,{justifyContent:'space-between',paddingVertical:24,paddingBottom:16, display:'flex'}]}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({listDelegados.length}{listDelegados.length==1?' delegado':' delegados'})</Text></Text>
            </View>

                <View style={styles.crtSearch}>
                    <View style={styles.searchIcon}>
                        <Icon name='search' color={colors.negro}></Icon>
                    </View>
                    <TextInput 
                        placeholder='Buscar'
                        style={styles.searchInput}
                        onChangeText={handleSearch}
                        value={searchText} 
                        selectionColor={colors.negro}
                        placeholderTextColor={colors.gris_fondo_cell}
                    />
                    {searchText !== '' &&
                        <TouchableOpacity 
                            style={styles.clearSearch}
                            onPress={limpiarSearchText}>
                            <Icon name='clear' color={colors.negro}></Icon>
                        </TouchableOpacity>
                    }
                </View>
            
            <View style={[styles.row,styles.mrg]}>
                <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}>Seleccione administrador al que deseas asignar cobradores.</Text>
            </View>

            <FlatList
                data={listDelegados.filter((item) => item.nombre.toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                ListEmptyComponent={renderEmpty}
                style={{marginTop: 15, width: '100%', paddingBottom: '2%'}}
            />

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    mrg:{
        marginHorizontal:'5%'
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        alignSelf:'center'
    },
    label: {
        textAlign: 'left',
        width: 400
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    img: {
        width: 150,
        height: 150,
        marginTop: 30,
        marginBottom: '7%'
    },
    crtSearch:{
        height: 48,
        position: 'relative',
        marginHorizontal: '5%',
        marginBottom:24,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.20,
        shadowRadius: 2,
        elevation: 4,
    },
    searchIcon:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
        left:0,
        top:12
    },
    searchInput:{
        height:48,
        borderRadius:4,
        backgroundColor:colors.white,
        width:'100%',
        color:colors.black,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    clearSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
        top:12
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontWeight:'300',
        fontSize:14,
    },
})
