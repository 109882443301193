import React, { useEffect, useState, useMemo } from 'react';
import {Modal, StyleSheet, Platform, TouchableWithoutFeedback, View} from 'react-native';
import {Calendar} from "react-native-calendars";
import {LocaleConfig} from "react-native-calendars/src";
import colors from './../constants/colors';

type Props = {
    visible: boolean;
    minDate?: string;
    maxDate?: string;
    onCancel: () => void;
    onPress: (fecha: string) => void;
    selectedDate?: string;
    currentDate?: string;
};

LocaleConfig.locales['fr'] = {
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.']
};

LocaleConfig.locales['es'] = {
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sept.', 'Oct.', 'Nov.', 'Dic.'],
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
    dayNamesShort: ['Dom.', 'Lun.', 'Mar.', 'Mie.', 'Jue.', 'Vie.', 'Sab.']
};

LocaleConfig.defaultLocale = 'es';

export default function ModalCalendar(props: Props) {

    const [dateModal, setDateModal] = useState('')
    const handleConfirm = (day: string) => {
        props.onPress(day);
    };

    useEffect(() => {
        if(props.visible){
            setDateModal(props.currentDate?convert(new Date(props.currentDate)):convert(new Date()))
        }
    }, [props.visible])
    

    const convert = (fecha) => {
        var año = fecha.getFullYear();
        var mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Agregar 1 porque los meses se cuentan desde 0
        var día = ('0' + fecha.getDate()).slice(-2);
        // Formatear la fecha
        var fechaFormateada = año + '-' + mes + '-' + día;
        return fechaFormateada
    }

    const marked = useMemo(() => {
        return {
          [dateModal]: {
            selected: true,
            disableTouchEvent: true,
            selectedTextColor: colors.white
          }
        };
    }, [dateModal]);

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.visible}
        >
            <TouchableWithoutFeedback onPress={props.onCancel}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Calendar
                            theme={{
                                selectedDayBackgroundColor: colors.negro,
                                selectedDayTextColor: colors.white,
                                todayTextColor: colors.logo_celeste,
                                textDisabledColor: '#d9e1e8',
                                dotColor: '#00adf5',
                                arrowColor: colors.negro,
                                monthTextColor: colors.negro,
                                textDayFontWeight: '400',
                                textMonthFontWeight: 'bold',
                                textDayHeaderFontWeight: 'bold',
                            }}
                            style={{ width: 300 }}
                            current={props.currentDate?convert(new Date(props.currentDate)):Date()}
                            // minDate={props.minDate}
                            // maxDate={props.maxDate}
                            onDayPress={day => {
                                handleConfirm(day.dateString);
                                setDateModal(day.dateString);
                            }}
                            // monthFormat={'MMMM'}
                            hideArrows={false}
                            hideExtraDays={true}
                            disableMonthChange={true}

                            firstDay={1}
                            markedDates={marked}
                        />
                        { Platform.OS === 'web' && 
                            <style>
                                {
                                `.css-9pa8cd {
                                        position: relative;
                                    }`
                                }
                            </style>
                        }
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalView: {
        width:'90%',
        backgroundColor: 'white',
        padding:16,
        alignItems: 'center',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
    }
})
