import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';

import colors from '../../constants/colors';
import stylesAdmin from '../../constants/stylesAdmin';
import stylesGenerales from '../../constants/stylesGenrales';
import axios from "axios";
import urlsGenerales from "../../constants/urlsGenerales";
import {useFocusEffect, useNavigation, useRoute} from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../shared/TiposDocumento";
import {NumberUtils} from "../../shared/NumberUtils";
import {StorageUtil} from "../../shared/StorageUtil";
import CustomAlert from '../../shared/CustomAlert';
import routes from '../../constants/routes';
import { color } from 'react-native-elements/dist/helpers';

export default function DetalleLiquidacion() {

    const navigation = useNavigation();
    const route = useRoute();
    // @ts-ignore
    const liquidacion = route.params?.liquidacion;

    const [loading, setLoading] = useState(false);

    const [listDetalle, setListDetalle] = useState([]);

    const [fechaDesde, setFechaDesde] = React.useState('2023-04-20');
    const [fechaHasta, setFechaHasta] = React.useState('2023-04-20');

    const [cajaBase, setCajaBase] = React.useState(0);
    const [recaudo, setRecaudo] = React.useState(0);
    const [creditos, setCreditos] = React.useState(0);
    const [entradas, setEntradas] = React.useState(0);
    const [gastos, setGastos] = React.useState(0);
    const [entregas, setEntregas] = React.useState(0);
    const [totalCaja, setTotalCaja] = React.useState(0);
    const [botonDesbloquear, setBotonDesbloquear] = React.useState(true);

    const obtenerDetalleLiquidacion = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('codCaja', liquidacion.codCaja);

            console.log("codCaja visualizando => ",liquidacion.codCaja)
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.DETALLE_LIQUIDACION, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            setListDetalle(response.data);
            const fechaInput = liquidacion.fecha;
            const [anio, mes, dia] = fechaInput.split("-");
            const fechaComparar = new Date(Number(anio), Number(mes) - 1, Number(dia));
            const fechaActual = new Date();
            fechaActual.setHours(0, 0, 0, 0);
            if (fechaComparar.getTime() < fechaActual.getTime()) {
                setBotonDesbloquear(false);
            }else{
                setBotonDesbloquear(true);
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.DETALLE_LIQUIDACION);
            console.log(error);

            setListDetalle([]);

        } finally {
            setLoading(false);
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                await obtenerDetalleLiquidacion();
            };
            fetchData();
        }, [])
    );

    useEffect(() => {

        if (listDetalle.length > 0) {
            setCajaBase(listDetalle[0]["cajaBase(Anterior)"]);
            setRecaudo(listDetalle[0].recaudado);
            setCreditos(listDetalle[0].credito);
            setEntradas(listDetalle[0].inversion);
            setGastos(listDetalle[0].gasto);
            setEntregas(listDetalle[0].entrega);
            setTotalCaja(listDetalle[0].totalCajaBase);
        } else {
            setCajaBase(0);
            setRecaudo(0);
            setCreditos(0);
            setEntradas(0);
            setGastos(0);
            setEntregas(0);
            setTotalCaja(0);
        }

    }, [listDetalle]);

    const handleAlertClose = (title: string, subtitle: string) => {
        CustomAlert.show({
            title: title,
            message: subtitle,
            buttons: [
                {
                    text: 'OK',
                    onPress: () => {
                        navigation.goBack();
                    },
                },
            ]
        });
    };

    const desbloquearCaja = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            
            const cobradorStorage = await StorageUtil.getCobradorStorage();
            formData.append('codUsuario', cobradorStorage.codUsuarioHijo);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.DESBLOQUEAR_CAJA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                handleAlertClose('Aviso', 'Caja desbloqueada con éxito.');
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.DESBLOQUEAR_CAJA);
            console.log(error);

        } finally {
            setLoading(false);
        }
    }

    return (
        <ScrollView contentContainerStyle={styles.container}
                    showsHorizontalScrollIndicator={false}>

            {/* <Text style={[stylesAdmin.labelTitle, {marginBottom: '3%'}]}>Detalle de Liquidación</Text> */}
            <View>
                <Text style={styles.lblTitle}>Caja Total</Text>
                <Text style={styles.lblAmount}>S/ {NumberUtils.formatThousands(totalCaja)}</Text>
            </View>

            <View style={styles.boxShadow}>
                <Text style={styles.lblBox}>Información</Text>
                
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Caja anterior</Text>
                    <Text style={[styles.lblBoxAmount,{color:colors.verde_primary}]}>S/ {NumberUtils.formatThousands(cajaBase)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Recaudo</Text>
                    <Text style={styles.lblBoxAmount}>S/ {NumberUtils.formatThousands(recaudo)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Creditos</Text>
                    <Text style={styles.lblBoxAmount}>S/ {NumberUtils.formatThousands(creditos)}</Text>
                </View>
            </View>

            <View style={styles.boxShadow}>
                <Text style={styles.lblBox}>Entradas / Salidas</Text>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Inversión</Text>
                    <Text style={styles.lblBoxAmount}>S/ {NumberUtils.formatThousands(entradas)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Entregas</Text>
                    <Text style={styles.lblBoxAmount}>S/ {NumberUtils.formatThousands(entregas)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.lblBoxText}>Gastos</Text>
                    <Text style={styles.lblBoxAmount}>S/ {NumberUtils.formatThousands(gastos)}</Text>
                </View>
            </View>

            {/*<Text style={[stylesAdmin.labelHagaClick, {marginTop: '2%', marginBottom: '1%'}]}>Rango de Fecha*/}
            {/*    Liquidada</Text>*/}

            {/*<View style={[stylesGenerales.row, {marginBottom: '5%'}]}>*/}
            {/*    <Text style={styles.celdaDer1}>Desde : {fechaDesde}</Text>*/}
            {/*    <Text style={styles.celdaDer1}>Hasta : {fechaHasta}</Text>*/}
            {/*</View>*/}

            

            {
            botonDesbloquear?(
                <TouchableOpacity style={styles.btnLocked}
                onPress={desbloquearCaja}>
                    <Text style={styles.textLocked}>Desbloquear caja</Text>
                </TouchableOpacity>
            ):(
                <></>
            )
            }

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        // alignItems: 'center'
        flex:1,
    },

    celdaIzq1: {
        width: '50%',
        color: colors.white,
        padding: 10,
        backgroundColor: colors.logo_gris,
        fontWeight: 'bold',
        fontSize: 16,
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    celdaIzq2: {
        width: '50%',
        color: colors.white,
        padding: 10,
        backgroundColor: colors.verde_primary,
        fontWeight: 'bold',
        fontSize: 16,
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    celdaDer1: {
        width: '50%',
        padding: 10,
        backgroundColor: colors.gris_fondo_cell,
        fontWeight: 'bold',
        fontSize: 16,
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },
    lblTitle:{
        fontSize:12,
        fontWeight:'500',
        textAlign:'center',
        marginTop:16,
        textTransform:'uppercase',
        marginBottom:4
    },
    lblAmount:{
        fontSize:24,
        fontWeight:'bold',
        color:colors.logo_azul,
        textAlign:'center',
    },
    boxShadow:{
        display:'flex',
        borderRadius:8,
        justifyContent:'center', 
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
        padding:16,
        marginHorizontal:'5%',
        marginVertical:16,
        paddingBottom:8
    },
    lblBox:{
        fontSize:18,
        fontWeight:'bold',
        marginBottom:16,
    },
    row:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        marginBottom:8
    },
    lblBoxAmount:{
        fontSize:16,
        fontWeight:'bold',
        color:colors.logo_azul,
    },
    lblBoxText:{
        color:colors.gris_borde_item_lista
    },
    
    btnLocked:{
        marginHorizontal:'5%',
        height:48,
        backgroundColor:colors.gris_borde_item_lista,
        borderRadius:4,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        marginTop:16
    },
    textLocked:{
        fontSize:16,
        fontWeight:'bold',
        color:colors.white,
        marginRight:8
    }
})
