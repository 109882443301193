import React, {useEffect, useState} from 'react';
import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../../../constants/colors';
import routes from '../../../constants/routes';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import {DateUtils} from "../../../shared/DateUtils";
import {useFocusEffect, useNavigation, useRoute} from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../../shared/TiposDocumento";
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import {NumberUtils} from "../../../shared/NumberUtils";
import { StorageUtil } from '../../../shared/StorageUtil';

export default function ListaRecaudoDelDia() {

    const route = useRoute();
    const navigation = useNavigation();

    const cobrador = route.params?.cobrador;

    const [loading, setLoading] = useState(false);

    const [cantLista, setCantLista] = React.useState(0);
    const [esperado, setEsperado] = React.useState('0');
    const [totalHoy, setTotalHoy] = React.useState('0');

    const [recaudosList, setRecaudosList] = useState([]);

    const [searchText, setSearchText] = React.useState('');

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    };

    const [dateNow, setDate] = React.useState(new Date());

    const getDateString = () => {
        return String(dateNow.getDate()).padStart(2, '0') + ' de ' +
            DateUtils.getMonthName(dateNow.getMonth() + 1) + ' de ' + dateNow.getFullYear();
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('codUsuario', cobrador.codUsuarioHijo);
                const token = await StorageUtil.getTokenStorage();
                const response = await axios.post(urlsGenerales.REP_LISTA_RECAUDO_DEL_DIA, formData, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });

                setCantLista(response.data.cantRecaudo);
                setEsperado(response.data.esperadoHoy);
                setTotalHoy(response.data.totalHoy);
                setRecaudosList(response.data.listaPagos);
                console.log("---->",response.data);

            } catch (error) {
                console.log('Error en -> ', urlsGenerales.REP_LISTA_RECAUDO_DEL_DIA)
                console.log(error);
                setRecaudosList([]);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [])
    

    const renderItem = ({item}) => {

        const handlePress = () => {
            navigation.navigate(routes.VER_ABONOS, {codCredito: item.codCredito, disabledFn:true});
        }

        return (
            // <TouchableOpacity
            //     style={{
            //         width: '90%',
            //         flexDirection: 'row',
            //         borderRadius: 10,
            //         borderWidth: 0.5,
            //         borderColor: colors.gris_borde_item_lista,
            //         padding: 10,
            //         marginLeft: '5%',
            //         marginBottom: '1%'
            //     }}
            //     onPress={handlePress}>
            //     <Text style={{width: '75%'}}>{item.nombre} {item.apellido}</Text>
            //     <Text style={{width: '25%'}}>S/ {item.monto}</Text>
            // </TouchableOpacity>
            <TouchableOpacity style={{
                flexDirection: 'row',
                borderBottomWidth: 0.5,
                borderBottomColor: '#222222',
                marginBottom: 8,
                paddingVertical:8,
                paddingBottom:16,
                paddingHorizontal:'5%'
            }}
            onPress={handlePress}
            >
                <View style={{flex:1,paddingRight:16}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={{fontSize:14,fontWeight:'400',marginBottom:4}}>{item.nombre} {item.apellido}</Text>
                </View>
                <View style={{alignSelf:'center'}}>
                    <Text style={{fontSize:16,fontWeight:'bold'}}>S/ {Number(item.monto).toFixed(2)}</Text>
                </View>
            </TouchableOpacity>
        );
    };

    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 14, marginVertical: 20, textAlign:'center', fontWeight:'400'}}>{searchText!=''?'No se encontraron resultados':'No hay pagos del día :c'}</Text>
        );
    };

    return (
        <>
        <View style={styles.container}>
            
            
            <View style={styles.crtSum}>
                <View style={styles.crtDate}>
                    <Text style={styles.lblDate}>{getDateString()}</Text>
                </View>
                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                    <View>
                        <Text style={styles.lblRecaudo}>Total Recaudado</Text>
                        <Text style={styles.sum}>S/ {Number(totalHoy).toFixed(2)}</Text>
                    </View>
                    
                    <View>
                        <Text style={[styles.lblRecaudo,{textAlign:'right'}]}>Esperado</Text>
                        <Text style={styles.sum}>S/ {Number(esperado).toFixed(2)}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.crtList}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({cantLista}{cantLista==1?' pago':' pagos'})</Text></Text>
            </View>
            <View style={[styles.row]}>
                <View style={styles.iconSearch}>
                    <Icon name='search' color={colors.negro}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    placeholderTextColor={colors.gris_fondo_cell}
                    style={styles.inputSearch}
                    onChangeText={handleSearch}
                    value={searchText}
                    selectionColor={colors.negro}
                />
                {
                searchText !== '' &&
                    <TouchableOpacity
                        style={styles.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.negro}></Icon>
                    </TouchableOpacity>
                }
            </View>

            {/* <Text style={stylesAdmin.labelTitle}>Listado de recaudo del día</Text>

            <Text style={{width: '90%', fontSize: 18, marginTop: '2%'}}>Fecha:</Text>
            <Text style={[styles.textBox,{borderTopLeftRadius: 5},,{borderTopRightRadius: 5},]}>{getDateString()}</Text>

            <Text style={stylesAdmin.labelTable}>Datos de recaudo del día</Text>

            <View style={[stylesGenerales.row, {marginTop: '3%'}]}>
                <Text style={[stylesAdmin.cellHeader, {borderTopLeftRadius: 5}, {width: '30%'}]}>Cant. Lista</Text>
                <Text style={[stylesAdmin.cellHeader, {width: '35%'}]}>Esperado</Text>
                <Text style={[stylesAdmin.cellHeader, {borderTopRightRadius: 5}, {width: '35%'}]}>Total Hoy</Text>
            </View>
            <View style={stylesGenerales.row}>
                <Text style={[stylesAdmin.cellBody, {width: '30%'}]}>{cantLista}</Text>
                <Text style={[stylesAdmin.cellBody, {width: '35%'}]}>{esperado}</Text>
                <Text style={[stylesAdmin.cellBody, {width: '35%'}]}>{totalHoy}</Text>
            </View>

            <Text style={stylesAdmin.labelTitle}>Ruta de recaudo</Text>
            <View style={stylesGenerales.containerSearch}>
                <View style={stylesGenerales.btnSearch}>
                    <Icon name='search' color={colors.logo_gris}></Icon>
                </View>
                <TextInput placeholder='Buscar'
                           style={stylesGenerales.textInputSearch}
                           onChangeText={handleSearch}
                           value={searchText}></TextInput>
                {searchText !== '' &&
                    <TouchableOpacity style={stylesGenerales.btnSearch}
                                      onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.logo_gris}></Icon>
                    </TouchableOpacity>}
            </View> */}

            <FlatList
                data={recaudosList.filter((item) => (item.nombre+" "+item.apellido).toLowerCase().includes(searchText.toLowerCase()) ||
                    item.apellido.toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(index) => index.codCredito}
                ListEmptyComponent={renderEmpty}
                style={{width: '100%', marginTop: 15}}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    crtSum:{
        paddingHorizontal:'5%',
        paddingTop:16,
        paddingBottom:16,
        backgroundColor:colors.verde_primary,
        display:'flex',
        // flexDirection:'row',
        // justifyContent:'space-between'
    },
    lblRecaudo:{
        fontSize:14,
        color:colors.white,
        marginBottom:8
    },
    sum:{
        fontSize:18,
        fontWeight:'bold',
        color:colors.white,
    },
    crtDate:{
        borderRadius:20,
        paddingHorizontal:16,
        height:40,
        // borderWidth:1,
        // borderColor:colors.white,
        // alignSelf:'center',
        display:'flex',
        justifyContent:'center'
    },
    lblDate:{
        color:colors.white,
        alignSelf:'center',
        fontWeight:'bold',
        fontSize:14,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
    },
    crtList:{
        paddingVertical:16,
        paddingHorizontal:'5%'
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontWeight:'300',
        fontSize:14,
    },
    lblMonto:{
        fontSize:16,
        fontWeight:'bold'
    },
    row: {
        display:'flex',
        flexDirection: 'row',
        marginHorizontal:'5%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.20,
        shadowRadius: 2,
        elevation: 4,
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor:colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },
})
