import {Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React, {useState} from "react";
import {CreditoType} from "../../types/CreditoType";
import colors from "../../constants/colors";
import {Icon} from "react-native-elements";
import stylesGenerales from "../../constants/stylesGenrales";
import {AbonoType} from "../../types/AbonoType";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../shared/TiposDocumento";
import {StringUtils} from "../../shared/StringUtils";

type Props = {
    visible: boolean;
    credito: CreditoType;
    abonos: AbonoType[];
    onCancel: () => void;
};

export default function ModalVerAbono(props: Props) {

    const [loading, setLoading] = useState(false);

    const obtenerPlazo = (): string => {
        return StringUtils.getDescPlazoBD(props.credito.cantCuotas, props.credito.codFrecuencia,
            props.credito.diaFrecuencia, props.credito.diaFrecuencia2);
    }

    const obtenerSaldo = (): number => {
        return Number(props.credito.totalDeuda) - obtenerImportePagado();
    }

    const obtenerNroCuotasPagadas = (): number => {
        return props.abonos.length === 0 ? 0 : props.abonos.length;
    }

    const obtenerNroCuotasPorPagar = (): number => {
        return Number(props.credito.cantCuotas) - props.abonos.length;
    }

    const obtenerImportePagado = (): number => {
        return props.abonos.reduce((total, abono) => total + parseFloat(abono.monto), 0);
    }

    const listarAbonos = () => {
        if (props.abonos.length === 0) {
            return (
                <View
                    style={[stylesGenerales.row, styles.rowAbono]}
                >
                    <Text style={styles.textRigthAbono}>No existen abonos registrados.</Text>
                    <Text style={styles.textRigthAbono}></Text>
                </View>
            )
        } else {
            return props.abonos.map((abono) => (
                <View
                    style={[stylesGenerales.row, styles.rowAbono]}
                >
                    <Text style={styles.textRigthAbono}>{abono.fecha}</Text>
                    <Text style={styles.textLeftAbono}>S/ {abono.monto}</Text>
                </View>
            ));
        }
    }

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.visible}
        >
            {/*<TouchableWithoutFeedback onPress={props.onCancel}>*/}
            <View style={styles.centeredView}>
                <View style={styles.modalView}>

                    <ScrollView contentContainerStyle={{alignItems: 'center', maxWidth: '100%'}}>

                        <Text style={styles.titleModal}>
                            Nombre: {props.credito.nombre} {props.credito.apellido}</Text>

                        <Text style={styles.subTitlesModal}>Fecha. {props.credito.fecha}</Text>
                        <Text style={styles.subTitlesModal}>Capital inicial. S/ {props.credito.valorCredito}</Text>
                        <Text style={styles.subTitlesModal}>Plazo. {obtenerPlazo()}</Text>
                        <Text style={styles.subTitlesModal}>Total deuda. S/ {props.credito.totalDeuda}</Text>
                        <Text style={styles.subTitlesModal}>Saldo. S/ {obtenerSaldo()}</Text>

                        <View style={[stylesGenerales.row, {marginTop: '4%'}]}>
                            <View style={[stylesGenerales.cell, {borderTopLeftRadius: 5}]}>
                                <Text style={[stylesGenerales.textCellHeader, {borderTopLeftRadius: 5}]}>
                                    # Cuotas pagadas
                                </Text>
                            </View>
                            <View style={[stylesGenerales.cell, {borderTopRightRadius: 5}]}>
                                <Text style={[stylesGenerales.textCellHeader, {borderTopRightRadius: 5}]}>
                                    # Cuotas por pagar
                                </Text>
                            </View>
                        </View>

                        <View style={[stylesGenerales.row, {marginBottom: 15}]}>
                            <View style={stylesGenerales.cell}>
                                <Text style={stylesGenerales.textCellBody}>
                                    {obtenerNroCuotasPagadas()}
                                </Text>
                            </View>
                            <View style={stylesGenerales.cell}>
                                <Text style={stylesGenerales.textCellBody}>
                                    {obtenerNroCuotasPorPagar()}
                                </Text>
                            </View>
                        </View>

                        <Text style={[styles.subTitlesModal, {marginBottom: '3%'}]}>Abonos del crédito</Text>

                        {listarAbonos()}

                        <Text style={[styles.subTitlesModal, {marginTop: '4%'}]}>
                            Pagado: S/ {obtenerImportePagado()}
                        </Text>

                        <TouchableOpacity style={[styles.buttonOptions,
                            {backgroundColor: colors.logo_gris, marginTop: '5%'}]}
                                          onPress={props.onCancel}>
                            <Text style={styles.textButtonOptions}>Cerrar</Text>
                            <Icon name={'cancel'} color={colors.white}/>
                        </TouchableOpacity>

                        <Spinner
                            visible={loading}
                            color={colors.verde_primary}
                            textContent={textoSpinner}
                            textStyle={stylesGenerales.textSpinner}
                        />

                    </ScrollView>
                </View>
            </View>
            {/*</TouchableWithoutFeedback>*/}
        </Modal>
    );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        paddingBottom: 15,
        paddingTop: 20,
        width: '80%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 5
    },

    titleModal: {
        fontWeight: 'bold',
        fontSize: 20,
        width: '90%',
        marginBottom: '3%'
    },

    subTitlesModal: {
        fontWeight: "bold",
        fontSize: 16,
        width: '90%'
    },

    buttonOptions: {
        flexDirection: "row",
        padding: 8,
        borderRadius: 5,
        width: '49%',
        justifyContent: "center"
    },

    textButtonOptions: {
        fontSize: 15,
        fontWeight: "normal",
        color: colors.white,
        marginRight: '10%'
    },

    rowAbono: {
        justifyContent: "space-between",
        backgroundColor: '#e0e3e4',
        paddingHorizontal: '2%',
        paddingVertical: '1%',
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    textRigthAbono: {
        padding: 10,
        fontSize: 16,
        fontWeight: "normal"
    },

    textLeftAbono: {
        backgroundColor: colors.white,
        borderRadius: 5,
        paddingHorizontal: '2%',
        fontSize: 16,
        fontWeight: "bold",
        textAlignVertical: "center"
    }
});

