import { StyleSheet } from "react-native";
import colors from "./colors";

const stylesGenerales = StyleSheet.create({
  // HomeScreen
  grid: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#ccc",
    width: "90%",
  },

  gridItemLeft: {
    width: "47%",
    paddingHorizontal: 12,
    marginLeft: "2%",
    marginRight: "1%",
    marginVertical: "1%",
    backgroundColor: "#fff",
    borderRadius: 8,
  },

  gridItemRight: {
    width: "47%",
    paddingHorizontal: 12,
    marginLeft: "1%",
    marginRight: "2%",
    marginVertical: "1%",
    backgroundColor: "#fff",
    borderRadius: 8,
  },

  labelTitleGridItem: {
    fontSize: 15,
    color: colors.verde_primary,
  },

  labelNroGridItem: {
    fontSize: 14,
    paddingVertical: "2%",
  },

  // estilos tabla
  row: {
    flexDirection: "row",
    width: "90%",
  },

  cell: {
    flex: 1,
    borderWidth: 0.5,
    borderColor: colors.logo_gris,
  },

  cellBodyEmpty: {
    width: "33.33%",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderColor: colors.logo_gris,
    borderWidth: 0.5,
    backgroundColor: colors.gris_fondo_cell,
    fontSize: 15,
    fontWeight: "bold",
  },

  // Listados y subtitulo

  textListado: {
    width: "90%",
    justifyContent: "flex-start",
    fontWeight: "bold",
    fontSize: 20,
    marginTop: 15,
  },

  labelSubTitle: {
    width: "90%",
    justifyContent: "flex-start",
    marginVertical: 10,
    fontSize: 15,
  },

  inputSearch: {
    borderRadius: 5,
    width: "90%",
    height: 40,
    padding: 10,
    borderWidth: 0.5,
    marginTop: 10,
  },

  inputText: {
    borderRadius: 4,
    height: 48,
    borderWidth: 0.5,
    marginTop: 4,
    paddingHorizontal: 16,
  },

  emptyList: {
    justifyContent: "center",
    width: "90%",
    backgroundColor: "#ebeeef",
    borderRadius: 10,
    marginStart: "5%",
    paddingStart: "5%",
    borderWidth: 0.5,
    borderColor: "#2a2b2b",
  },

  // Listados Screen

  labelInput: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 16,
  },

  textCellHeader: {
    fontWeight: "bold",
    fontSize: 15,
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: colors.white,
    backgroundColor: colors.verde_primary,
  },

  textCellBody: {
    fontWeight: "bold",
    fontSize: 15,
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: colors.negro,
    backgroundColor: "#e0e3e4",
  },

  // RecaudoPorFecha && CreditosPorFecha
  btn: {
    backgroundColor: colors.verde_primary,
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    marginTop: "2%",
    marginBottom: "4%",
  },

  btnDisabled: {
    backgroundColor: colors.gris_fondo_cell,
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    marginTop: "2%",
    marginBottom: "4%",
  },

  btnWithoutColor: {
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
  },

  textBtn: {
    color: colors.white,
    fontWeight: "bold",
    fontSize: 13,
    textAlignVertical: "center",
  },

  // LoginScreen
  btnLogin: {
    backgroundColor: colors.verde_primary,
    borderRadius: 10,
    paddingHorizontal: 40,
    paddingVertical: 10,
    marginTop: "2%",
  },

  btnLarge: {
    width: "90%",
    padding: 5,
    flexDirection: "row",
    borderRadius: 8,
  },

  // Reutilizables

  // TextInput Search
  containerSearch: {
    width: "90%",
    height: 35,
    flexDirection: "row",
    borderWidth: 0.5,
    borderRadius: 5,
    marginTop: 2,
  },

  textInputSearch: {
    width: "84%",
  },

  btnSearch: {
    width: "8%",
    justifyContent: "center",
  },

  // Img principal c/pantalla
  imageTitle: {
    width: 80,
    height: 80,
    marginTop: 30,
    marginBottom: 30,
  },

  // Spinner
  textSpinner: {
    color: colors.white,
    marginTop: "-12%",
  },
});

export default stylesGenerales;
