import React, {useState} from 'react';
import {FlatList, ListRenderItem, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {TextInput} from 'react-native-gesture-handler';
import stylesGenerales from '../constants/stylesGenrales';
import {Icon} from 'react-native-elements';
import colors from '../constants/colors';
import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import routes from '../constants/routes';
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../shared/TiposDocumento";
import {StorageUtil} from "../shared/StorageUtil";
import axios from "axios";
import urlsGenerales from "../constants/urlsGenerales";
import {RutaType} from "../types/RutaType";
import stylesAdmin from "../constants/stylesAdmin";

export default function EnrutarEn() {

    const navigation = useNavigation();
    const route = useRoute();

    const {cliente} = route.params;

    const [searchText, setSearchText] = React.useState('');

    const [loading, setLoading] = useState(false);
    const [listRutas, setListRutas] = useState<RutaType[]>([]);

    const handleEnrutar = async (posicion: any) => {
        try {
            setLoading(true);

            const codUsuario = await StorageUtil.getCodUsuarioStorage();

            const formData = new FormData();
            formData.append('codUsuario', codUsuario!);
            formData.append('codCliente', cliente.codCliente);
            formData.append('posicion', posicion);

            console.log(formData);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ENRUTAR, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                console.log('Crédito enrutado correctamente');
                navigation.navigate(routes.CLIENTES);
            }

        } catch (e) {
            console.log('Error en -> ', urlsGenerales.ENRUTAR);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const enrutarAlFinal = async () => {
        navigation.navigate(routes.CLIENTES);
    }

    const obtenerRutas = async () => {
        try {
            setLoading(true);

            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            const formData = new FormData();
            formData.append('codUsuario', codUsuario!);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_RUTAS, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                const listResponse: RutaType[] = response.data;
                const listRutaResponse: RutaType[] = listResponse.filter(item => item.codCliente != cliente.codCliente);
                const listRutasOrdenado = listRutaResponse.sort((a, b) => a.posicion.localeCompare(b.posicion));
                setListRutas(listRutasOrdenado);
            }

        } catch (e) {
            console.log('Error en -> ', urlsGenerales.LISTAR_RUTAS);
            console.log(e);
            setListRutas([]);

        } finally {
            setLoading(false);
        }
    }

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                await obtenerRutas();
            };
            fetchData();
        }, [])
    );

    const renderEmpty = () => {
        return (
            <View style={stylesGenerales.emptyList}>
                <Text style={{fontSize: 15, marginVertical: 20}}>No hay clientes en la ruta.</Text>
            </View>
        );
    };

    const renderItem: ListRenderItem<RutaType> = ({item}) => {

        const enrutarPosicion = async () => {
            await handleEnrutar(item.posicion);
        }

        return (
            <TouchableOpacity onPress={enrutarPosicion}
                              style={{
                                  width: '100%',
                                  flexDirection: 'row',
                                  borderRadius: 10,
                                  borderWidth: 0.5,
                                  borderColor: colors.gris_borde_item_lista,
                                  paddingVertical: 10,
                                  paddingHorizontal: 20,
                                  marginBottom: '1%',
                                  alignItems: 'center'
                              }}
            >
                <Text>{item.nombre} {item.apellido}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <View style={styles.container}>

            <Text style={styles.labelCliente}>ID de cliente:</Text>
            <Text style={styles.valueCliente}>{cliente.codCliente}</Text>

            <Text style={styles.labelCliente}>Nombre de cliente:</Text>
            <Text style={styles.valueCliente}>{cliente.nombre} {cliente.apellido}</Text>

            <Text style={[styles.valueCliente, {marginTop: '4%', marginBottom: '3%'}]}>Enrutar antes de :</Text>
            <Text style={stylesAdmin.labelHagaClick}>* Haga click en "Enrutar al final" para
                enrutar en la última posición ó haga click en una posición para enrutar antes de
                esa posición el crédito seleccionado.
            </Text>

            <View style={[stylesGenerales.containerSearch, {marginTop: '4%'}]}>
                <View style={stylesGenerales.btnSearch}>
                    <Icon name='search' color={colors.logo_gris}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    style={stylesGenerales.textInputSearch}
                    onChangeText={handleSearch}
                    value={searchText}></TextInput>
                {searchText !== '' &&
                    <TouchableOpacity
                        style={stylesGenerales.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.logo_gris}></Icon>
                    </TouchableOpacity>}
            </View>

            <FlatList
                data={listRutas.filter((item) => item.nombre.toLowerCase().includes(searchText.toLowerCase()))}
                renderItem={renderItem}
                keyExtractor={(item) => item.codRuta}
                ListEmptyComponent={renderEmpty}
                style={{width: '90%', marginTop: 15}}
            />

            <View style={{
                width: '100%',
                backgroundColor: colors.verde_primary,
                marginTop: 10,
                flexDirection: 'row',
            }}>
                <TouchableOpacity
                    style={{
                        flexDirection: 'column',
                        alignContent: 'center',
                        padding: 10,
                        flex: 1,
                        borderColor: colors.logo_gris,
                        borderWidth: 0.5
                    }}
                    onPress={enrutarAlFinal}>
                    <Icon name='arrow-circle-down' color={colors.white}></Icon>
                    <Text style={{
                        textAlign: 'center',
                        marginVertical: 4,
                        fontSize: 15,
                        color: colors.white
                    }}>Enrutar al final</Text>
                    {/* Luego de presionarlo se va directamente a RUTA - ruta de créditos*/}
                </TouchableOpacity>
            </View>

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },

    labelCliente: {
        width: '90%',
        marginTop: '4%',
        fontWeight: "normal"
    },

    valueCliente: {
        width: '90%',
        fontSize: 19,
        fontWeight: "bold"
    },

    rowListRuta: {
        padding: 10
    }
});
