import React, {useRef, useState} from 'react';
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {SelectList} from 'react-native-dropdown-select-list';
import {Icon} from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../../shared/TiposDocumento";
import ModalCalendar from "../../../components/ModalCalendar";
import {useRoute} from "@react-navigation/native";

export default function BorrarGastosEntregasIngresos() {

    const [selected, setSelected] = React.useState('');
    const [searchText, setSearchText] = React.useState('');

    // @ts-ignore
    const route = useRoute();
    const cobrador = route.params?.cobrador;

    const [loading, setLoading] = React.useState(false);
    const [fecha, setFecha] = useState('');
    const fechaRef = useRef(null);
    const [mostrarCalendario, setMostrarCalendario] = useState(false);

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const consultar = () => {
        console.log('Consultar')
    }

    const opciones = [
        {key: '0', value: 'Gastos'},
        {key: '1', value: 'Entrega de dinero'},
        {key: '2', value: 'Entrada / Inversión'},
    ]

    const handleFechaDesde = (fechaDesdeModal: string) => {
        setMostrarCalendario(false);
        setFecha(fechaDesdeModal);
    }

    return (
        <View style={styles.container}>
            <Text style={stylesAdmin.labelTitle}>Opción de eliminación de gastos y entradas</Text>

            <Text style={{width: '90%', fontSize: 17, marginVertical: '3%'}}>
                Esta opción le premite borrar gastos/entradas por fecha.</Text>

            <Text style={stylesGenerales.labelInput}>Seleccionar fecha:</Text>
            <TextInput
                placeholder='yyyy-mm-dd'
                style={stylesGenerales.inputText}
                value={fecha}
                onPressIn={() => setMostrarCalendario(true)}
                ref={fechaRef}
                onFocus={() => {
                    setMostrarCalendario(true);
                    // @ts-ignore
                    fechaRef.current.blur();
                }}
            ></TextInput>
            <ModalCalendar
                visible={mostrarCalendario}
                onPress={handleFechaDesde}
                onCancel={() => setMostrarCalendario(false)}
            />

            <Text style={stylesGenerales.labelInput}>Seleccionar tipo:</Text>
            <View style={styles.selectTipDoc}>
                <SelectList
                    data={opciones}
                    setSelected={setSelected}
                    search={false}
                    defaultOption={opciones[0]}/>
            </View>

            <TouchableOpacity style={stylesGenerales.btn} onPress={consultar}>
                <Icon name='search' color={colors.white}></Icon>
                <Text style={{
                    color: colors.white,
                    fontWeight: 'bold',
                    fontSize: 15,
                    marginStart: '1%'
                }}>Consultar</Text>
            </TouchableOpacity>

            <Text style={[stylesAdmin.labelTable, {marginTop: '4%'}]}>
                Seleccione un gasto/entrada a borrar</Text>
            <View style={[stylesGenerales.containerSearch, {marginBottom: '3%'}]}>
                <View style={stylesGenerales.btnSearch}>
                    <Icon name='search' color={colors.logo_gris}></Icon>
                </View>
                <TextInput placeholder='Buscar'
                           style={stylesGenerales.textInputSearch}
                           onChangeText={handleSearch}
                           value={searchText}></TextInput>
                {searchText !== '' &&
                    <TouchableOpacity style={stylesGenerales.btnSearch}
                                      onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.logo_gris}></Icon>
                    </TouchableOpacity>}
            </View>

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center'
    },

    selectTipDoc: {
        margin: 10,
        width: '90%'
    },
})
