import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import colors from '../constants/colors';
import routes from '../constants/routes';

export default function Listados() {

    const navigation = useNavigation();

    const opcionRecaudoPorFecha = () => {
        navigation.navigate(routes.RECAUDO_POR_FECHA);
    }

    const opcionCreditosPorFecha = () => {
        navigation.navigate(routes.CREDITOS_POR_FECHA);
    }

    return (
        <View style={styles.container}>
            <Image style={styles.img} source={require('../assets/list.png')}></Image>

            <Text style={styles.titleOptions}>Opciones</Text>

            <TouchableOpacity style={styles.containetBtn} onPress={opcionRecaudoPorFecha}>
                <Text style={styles.textBtn}>Recaudo por fecha</Text>
                <View style={styles.containerIcon}>
                    <Icon name='list'></Icon>
                </View>
            </TouchableOpacity>

            <TouchableOpacity style={[
                styles.containetBtn,
                { borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }
            ]}
                onPress={opcionCreditosPorFecha}>
                <Text style={styles.textBtn}>Créditos por fecha</Text>
                <View style={styles.containerIcon}>
                    <Icon name='list'></Icon>
                </View>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },

    label: {
        textAlign: 'left',
        width: 400
    },

    input: {
        height: 40,
        width: 400,
        margin: 10,
        borderWidth: 0.5,
        padding: 10,
        borderRadius: 5
    },

    img: {
        width: 200,
        height: 200,
        marginTop: 30,
        marginBottom: 25
    },

    titleOptions: {
        backgroundColor: colors.verde_primary,
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        width: '90%',
        paddingHorizontal: '3%',
        paddingVertical: '1%',
        marginTop: 15,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },

    containetBtn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '90%',
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    textBtn: {
        fontSize: 15,
        paddingHorizontal: '3%',
        paddingVertical: '4%',
    },

    containerIcon: { justifyContent: 'center', paddingEnd: '4%' },
});