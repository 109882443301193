import AsyncStorage from '@react-native-async-storage/async-storage';

export class StorageUtil {

    static async getTokenStorage() {
        const tokenUsuario = await AsyncStorage.getItem('token');
        return tokenUsuario;
    }

    static async getPassStorage() {
        const passUsuario = await AsyncStorage.getItem('pass');
        return passUsuario;
    }

    static async getCodUsuarioStorage() {
        const codUsuario = await AsyncStorage.getItem('codUsuario');
        return codUsuario;
    }

    static async getUserStorage() {
        const userStorage = await AsyncStorage.getItem('usu');
        return userStorage;
    }

    static async getPrivilegioStorage() {
        const privilegioStorage = await AsyncStorage.getItem('privilegio');
        return privilegioStorage;
    }

    static async setCodClienteStorage(codCliente: string) {
        await AsyncStorage.setItem('codCliente', codCliente);
    }

    static async getCodClienteStorage() {
        const codClienteStorage = await AsyncStorage.getItem('codCliente');
        return codClienteStorage;
    }

    /**
     * Función para guardar el valor de mostrar la pantalla Enrutar
     * @param {string} a - 1: SI, 0: NO
     */
    static async setRutaStorage(ruta: string) {
        await AsyncStorage.setItem('ruta', ruta);
    }

    /**
     * Función para obtener el valor de mostrar la pantalla Enrutar
     * @return {string} a - 1: SI, 0: NO
     */
    static async getRutaStorage() {
        const rutaStorage = await AsyncStorage.getItem('ruta');
        return rutaStorage;
    }

    static async setCobradorStorage(cobrador: any) {
        await AsyncStorage.setItem("cobrador", JSON.stringify(cobrador));
    }

    static async getCobradorStorage() {
        const cobradorJson = await AsyncStorage.getItem("cobrador");
        return JSON.parse(cobradorJson!);
    }
}
