import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, Image, View} from 'react-native';

import colors from '../../constants/colors';
import stylesAdmin from '../../constants/stylesAdmin';
import stylesGenerales from '../../constants/stylesGenrales';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import {DateUtils} from "../../shared/DateUtils";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../shared/TiposDocumento";
import axios from "axios";
import urlsGenerales from "../../constants/urlsGenerales";
import routes from "../../constants/routes";
import {StorageUtil} from "../../shared/StorageUtil";
import {CobradorType} from "../../types/CobradorType";

type BalanceType = {
    credito: string;
    cajaBase: string;
    recaudado: string;
    totalCaja: string;
    listIngresos: any[];
    listSalidas: any[];
    estadoCaja: string;
}

export default function LiquidarRuta() {

    const navigation = useNavigation();

    const [cobrador, setCobrador] = useState<CobradorType>({
        codUsuario: "",
        nombre: "",
        apellido: "",
        codUsuarioHijo: "",
        nombreHijo: "",
        apellidoHijo: "",
        documentoHijo: "",
        telefonoHijo: "",
        correoHijo: "",
        idHijo: "",
        contraHijo: ""
    });
    const [importeCajaBase, setImporteCajaBase] = React.useState("");

    const [balance, setBalance] = useState<BalanceType>({
        cajaBase: "",
        credito: "",
        listIngresos: [],
        listSalidas: [],
        recaudado: "",
        totalCaja: ""
    });

    const [loading, setLoading] = React.useState(false);

    const siguiente = () => {
        navigation.navigate(routes.VER_LIQUIDAR_RUTA, {balance: balance});
    }

    const consultarBalance = async (codUsuario: string) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', codUsuario);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.BALANCE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }
            console.log(response.data)
            setBalance(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.BALANCE);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                const cobradorStorage = await StorageUtil.getCobradorStorage();
                setCobrador(cobradorStorage);
                await consultarBalance(cobradorStorage.codUsuarioHijo);
            };
            fetchData();
        }, [])
    );

    useEffect(() => {
        setImporteCajaBase(balance.cajaBase);
    }, [balance]);


    const getDateString = () => {
        const dateNow = new Date();
        return String(dateNow.getDate()).padStart(2, '0') + ' de ' +
            DateUtils.getMonthName(dateNow.getMonth() + 1) + ' de ' +
            dateNow.getFullYear();
    }

    return (
        <ScrollView contentContainerStyle={styles.container}
                    showsHorizontalScrollIndicator={false}>
            
            
            <View style={styles.boxInfo}>
                <View style={{}}>
                    <Image 
                        style={[styles.circleStatus]}
                        resizeMode="center"
                        source={require('../../assets/client.png')}
                    />
                </View>
                <View style={{ marginLeft:16}}>
                    <Text style={{fontSize:14, color:colors.white}}>Ruta de</Text>
                    <Text style={{fontSize:16, color:colors.white, fontWeight:'bold'}}>{cobrador?.nombreHijo} {cobrador?.apellidoHijo}</Text>
                </View>
            </View>
            <View style={{marginHorizontal:'5%', marginTop:16}}>
                <View style={{display:'flex',flexDirection:'column',alignSelf:'flex-end' , marginBottom:8}}>
                    <Text style={[styles.lblBox,{fontSize:14, color:colors.negro, textAlign:'right'}]}>Fecha</Text>
                    <View style={styles.crtDate}>
                        <Text style={styles.lblDate}>{DateUtils.getDateStringYYYYMMDD()}</Text>
                    </View>
                </View>
                
                <Text style={[styles.lblBox,{fontSize:14, color:colors.negro, marginBottom:2}]}>Caja base</Text>
                <View style={[styles.cajaBox,{backgroundColor:colors.gris_borde_item_lista, marginTop:0, marginBottom:0}]}>
                    <View style={{flex:1,}}>
                        <Text style={[styles.lblMountBox,{fontSize:20, marginBottom:0}]}>S/ {Number(importeCajaBase).toFixed(2)}</Text>
                    </View>
                    <View style={{alignSelf:'flex-end'}}>
                        <Text style={[styles.lblMountBox,{fontSize:12, color:colors.white, textAlign:'right', marginBottom:0}]}>{DateUtils.getYesterdayStringYYYYMMDD()}</Text>
                        <Text style={[styles.lblBox,{fontSize:10, color:colors.white}]}>
                        Última liquidación
                        </Text>
                    </View>
                </View>
                
            </View>


            {
            balance.estadoCaja == "2" ?
            <Text style={{ fontSize: 12, marginTop:32, color:"red", textAlign:'center'}}>Esta caja se encuentra bloqueada con fecha de hoy.
            </Text>
            :
            <TouchableOpacity 
                style={styles.btnLocked}
                onPress={siguiente}
            >
                <Text style={styles.textLocked}>Ver caja</Text>
            </TouchableOpacity>
            }
            <Spinner
                visible={loading}
                color={colors.white}
            />
        </ScrollView>
    )
}


const styles = StyleSheet.create({
    container: {
        flex:1,
    },
    boxInfo:{
        backgroundColor:colors.verde_primary,
        paddingHorizontal:'5%',
        display:'flex',
        flexDirection:'row',
        paddingVertical:16,
    },
    circleStatus:{
        width:40,
        height:40,
        borderRadius:20,
    },
    cajaBox:{
        backgroundColor:colors.logo_celeste,
        borderRadius:8,
        marginVertical:8,
        padding:16,
        flexDirection:'row',
        paddingLeft:8
    },
    lblBox:{
        fontSize: 14,
        color: colors.white,
        fontWeight:'500',
    },
    lblMountBox:{
        fontSize: 20,
        fontWeight: 'bold',
        color:colors.white,
        marginBottom: 4,
    },
    btnLocked:{
        marginHorizontal:'5%',
        height:48,
        backgroundColor:colors.verde_primary,
        borderRadius:4,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        marginTop:32
    },
    textLocked:{
        fontSize:16,
        fontWeight:'bold',
        color:colors.white,
        marginRight:8
    },
    crtDate:{
        // borderRadius:20,
        // paddingHorizontal:16,
        // height:40,
        // width:200,
        // borderWidth:1,
        borderColor:colors.negro,
        display:'flex',
        justifyContent:'center',
        marginTop:4
    },
    lblDate:{
        color:colors.negro,
        alignSelf:'center',
        fontWeight:'bold',
        fontSize:16,
    },
})
