import { StyleSheet, View, Text, TouchableOpacity, Animated } from 'react-native'
import React,{ useRef } from 'react'
import colors from '../constants/colors';
import { RectButton } from 'react-native-gesture-handler';
import Swipeable from 'react-native-gesture-handler/Swipeable';


const SwipeableComponent = ({children, onClick}) => {
    const swipeableRow = useRef(null)

    const renderLeftActions = (progress, dragX) => {
        const trans = dragX.interpolate({
          inputRange: [0, 50, 100, 101],
          outputRange: [-20, 0, 0, 1],
        });
        return (
          <RectButton style={styles.actionRow} onPress={()=>{
            onClick && onClick()
            swipeableRow?.current?.close();
          }}>
            <Animated.Text
              style={[
                styles.actionText,
                {
                  transform: [{ translateX: trans }],
                },
              ]}>
                
              Abonar
            </Animated.Text>
          </RectButton>
        );
    };

    return (
        <Swipeable
            ref={swipeableRow}
            renderLeftActions={renderLeftActions} 
            containerStyle={styles.containerSwipeableRow}
            // style={styles.containerSwipeableRow}
        > 
        {/* <>
            <View style={{flex:1}}>
                {children}
            </View>
            <TouchableOpacity style={styles.actionRow} onPress={()=>{
                onClick && onClick()
            }}>
            <Text
                style={[
                    styles.actionText,
                ]}>
                    
                Abonar
                </Text>
            </TouchableOpacity>
        </> */}
        {children}
        </Swipeable>
    )
}

const styles = StyleSheet.create({
    containerSwipeableRow:{
        marginBottom:12,
        // display:'flex',
        // flexDirection:'row'
    },
    actionText: {
        color: colors.white,
        alignSelf:'center',
        fontWeight: 'bold',
        padding:16,
        // padding:8,
        fontSize:12,
    },
    actionRow: {
        minHeight: 64,
        maxHeight: 64,
        display:'flex',
        justifyContent:'center', 
        backgroundColor:colors.verde_primary,
        marginLeft:'5%',
        borderRadius:8,
        // borderRadius:4,
        // alignSelf:'center'
    },
})

export default SwipeableComponent