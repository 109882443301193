import { StyleSheet } from "react-native";
import colors from "./colors";

const stylesMenu = StyleSheet.create({
  backButton: {
    padding: 10,
    borderRadius: 10,
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 5,
  },
});

export default stylesMenu;
