import {StyleSheet} from "react-native";
import colors from "./colors";

const stylesTablas = StyleSheet.create({

    cellHeader: {
        fontWeight: 'bold',
        fontSize: 15,
        paddingHorizontal: 10,
        paddingVertical: 5,
        color: colors.white,
        backgroundColor: colors.verde_primary,
        borderWidth: 0.5,
        borderColor: colors.logo_gris,
        flex: 1
    },

    borderRight: {
        borderTopRightRadius: 10,
    },

    borderLeft: {
        borderTopLeftRadius: 10,
    },

    // ROW TIPO HOJA
    leafLeft: {
        flex: 1,
        backgroundColor: colors.verde_primary,
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        textAlignVertical: 'center',
        paddingStart: 15,
        paddingVertical: '2%',
        borderTopLeftRadius: 10
    },

    leafRight: {
        flex: 1,
        backgroundColor: '#d9dedf',
        fontWeight: 'bold',
        fontSize: 15,
        textAlignVertical: 'center',
        paddingStart: 15,
        paddingVertical: '2%',
        borderBottomRightRadius: 10
    }
});

export default stylesTablas;
