import React from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';

export default function CambiarSuperclave() {

    const cambiarSuperclave = () => {
        console.log('Cambiar superclave amiguito');
    }

    return (
        <View style={styles.container}>

            <Text style={stylesAdmin.labelTitle}>
                Opción de modificación de clave de Superusuario</Text>
            <Text style={{ width: '90%', fontSize: 17, marginVertical: '3%' }}>
                Esta opción le premite modificar la Superclave de la ruta,
                debes ser Superusuario para realizar este cambio.</Text>

            <Text style={stylesGenerales.labelInput}>Ingresa la nueva Superclave</Text>
            <TextInput style={stylesGenerales.inputText}></TextInput>

            <TouchableOpacity
                style={{
                    backgroundColor: colors.rojo,
                    flexDirection: 'row',
                    borderRadius: 10,
                    padding: 10,
                    marginTop: '2%',
                    marginBottom: '4%'
                }}
                onPress={cambiarSuperclave}>
                <Icon name='refresh' color={colors.white}></Icon>
                <Text style={{
                    color: colors.white,
                    fontWeight: 'bold',
                    fontSize: 15,
                    marginStart: '1%'
                }}>Cambiar Superclave</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center'
    },
})