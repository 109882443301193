import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { IconProvider } from './navigations/IconContext';
import LoginNavigator from './navigations/LoginNavigator';

export default function App() {
  return (
    <NavigationContainer>
      <IconProvider>
        <LoginNavigator />
      </IconProvider>
    </NavigationContainer>
  );
}