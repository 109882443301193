import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useFocusEffect, useRoute} from "@react-navigation/native";
import React, {useEffect, useState} from "react";
import Spinner from "react-native-loading-spinner-overlay";
import colors from "../../constants/colors";
import COLORS from "../../constants/colors";
import {textoSpinner} from "../../shared/TiposDocumento";
import stylesGenerales from "../../constants/stylesGenrales";
import {NumberUtils} from "../../shared/NumberUtils";
import {Icon} from "react-native-elements";
import ModalAgregarMov from "../../components/ModalAgregarMov";
import ModalQuitarMov from "../../components/ModalQuitarMov";
import ModalMensaje from "../../components/ModalMensaje";
import ModalOperacionAdmin from "../../components/ModalOperacionAdmin";
import {AuthenticationService} from "../../shared/AuthenticationService";
import {StorageUtil} from "../../shared/StorageUtil";
import axios from "axios";
import urlsGenerales from "../../constants/urlsGenerales";
import {CajaService} from "../../shared/CajaService";
import CustomAlert from '../../shared/CustomAlert';
import { useNavigation } from "@react-navigation/native";
import routes from "../../constants/routes";

export default function VerRutaALiquidar() {

    const navigation = useNavigation();

    const route = useRoute();

    // @ts-ignore
    const balanceRoute = route.params?.balance;
    const [balance, setBalance] = useState(balanceRoute);

    const [loading, setLoading] = React.useState(false);

    const [importeCajaBase, setImporteCajaBase] = React.useState(balance.cajaBase);
    const [importeCobrado, setImporteCobrado] = React.useState(balance.recaudado);
    const [importeCreditos, setImporteCreditos] = React.useState(balance.credito);
    const [importeTotalCaja, setImporteTotalCaja] = React.useState(balance.totalCaja);

    const [visibleAgregarEntrada, setVisibleAgregarEntrada] = useState(false);
    const [visibleQuitarEntrada, setVisibleQuitarEntrada] = useState(false);
    const [visibleAgregarGasto, setVisibleAgregarGasto] = useState(false);
    const [visibleAgregarEntrega, setVisibleAgregarEntrega] = useState(false);
    const [visibleQuitarGasto, setVisibleQuitarGasto] = useState(false);
    const [codigoEntradaQuitar, setCodigoEntradaQuitar] = useState('');
    const [codigoGastoQuitar, setCodigoGastoQuitar] = useState('');
    const [modalConfirmarVisible, setModalConfirmarVisible] = useState(false);
    const [listIngresos, setListIngresos] = useState(balance.listIngresos);
    const [listGastos, setListGastos] = useState(balance.listSalidas);

    useEffect(() => {
        setImporteCajaBase(balance.cajaBase);
        setImporteCobrado(balance.recaudado);
        setImporteCreditos(balance.credito);
        setImporteTotalCaja(balance.totalCaja);
        setListIngresos(balance.listIngresos.sort((a, b) => a.codDetalleCaja.localeCompare(b.codDetalleCaja)));
        setListGastos(balance.listSalidas.filter(item => item.detalle !== 'Ingreso de Pago de cuota'));
    }, [balance]);

    const listarGastos = () => {
        if (listGastos.length === 0) {
            return (
                <View style={{marginTop:8}}>
                    <Text style={{textAlign:'center'}}>
                        No hay salidas
                    </Text>
                </View>
            )
        } else {
           
            
            return listGastos.map((item,index) => (
                <View style={[styles.row,{paddingHorizontal:0, borderBottomWidth:0.5}]} key={index}>
                    <TouchableOpacity
                        style={styles.rowItem}
                        onLongPress={() => {
                            setVisibleQuitarGasto(true)
                            setCodigoGastoQuitar(item.codDetalleCaja)
                        }}
                    >                        
                        <Text style={[styles.itemDetail,{color: item.movimiento === 'Entrega'? colors.rojo:'black'}]}>{item.detalle}</Text>
                        <Text style={[styles.itemMonto,{color: item.movimiento === 'Entrega'? colors.rojo:'black'}]}>S/ {Number(item.monto).toFixed(2)}</Text>
                    </TouchableOpacity>
                    
                </View>
            ))
        }
    }

    const listarEntradas = () => {
        if (listIngresos.length === 0) {
            return (
                <View style={{marginTop:8}}>
                    <Text style={{textAlign:'center'}}>No hay entradas</Text>
                </View>
            )
        } else {
            return listIngresos.map((item, index) => (
                <View style={[styles.row,{paddingHorizontal:0,borderBottomWidth:0.5}]} key={index}>
                    <TouchableOpacity
                        style={styles.rowItem}
                        onLongPress={() => {
                            setVisibleQuitarEntrada(true); 
                            setCodigoEntradaQuitar(item.codDetalleCaja)
                        }}
                    >                        
                        <Text style={styles.itemDetail}>{item.detalle}</Text>
                        <Text style={styles.itemMonto}>S/ {Number(item.monto).toFixed(2)}</Text>
                    </TouchableOpacity>
                    
                </View>
            ))
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
            };
            fetchData();
        }, [])
    );

    
    const [visibleMensaje, setVisibleMensaje] = useState(false);
    const [opcionBorrar, setOperacionBorrar] = useState('');
    const [titleMensaje, setTitleMensaje] = useState('');
    const [subTitleMensaje, setSubTitleMensaje] = useState('');


    const validarCajaAbierta = async () => {
        await setLoading(true);
        const cobrador = await StorageUtil.getCobradorStorage();
        const codUsuarioHijo=cobrador.codUsuarioHijo;
        const estadoCajaValida = await CajaService.validarEstadoCajaAbierta(codUsuarioHijo!, navigation);
        await setLoading(false);
        return estadoCajaValida;
    }

    const agregarEntrada = async (detalle: string, valor: string) => {
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                await handleAgregarEntrada(detalle, valor);
            } else {
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const borrarEntrada = async () => {
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                setOperacionBorrar('ENTRADA');
                setVisibleQuitarEntrada(false);
                setModalConfirmarVisible(true);
            } else {
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const agregarGasto = async (detalle: string, valor: string) => {
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                await handleAgregarGasto(detalle, valor);
            } else {
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const agregarEntrega = async (detalle: string, valor: string) => {
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                await handleAgregarEntrega(detalle, valor);
            } else {
                CustomAlert.cajaBloq();
                return;
            }
        }
    }

    const borrarGasto = async () => {
        const estadoCajaValida = await validarCajaAbierta()
        
        if(estadoCajaValida != 'redirigir'){
            if (estadoCajaValida) {
                setOperacionBorrar('GASTO');
                setVisibleQuitarGasto(false);
                setModalConfirmarVisible(true);
            } else {
                CustomAlert.cajaBloq();
                return;
            }
        }
    }
    const BloqueoCajaPress = () => {
       
        setTitleMensaje('Caja');
        setSubTitleMensaje('La caja fue bloqueada exitosamente');
        setVisibleMensaje(true);
    }

    const consultarBalance = async () => {
        try {
            setLoading(true);

            const formData = new FormData();
            const token = await StorageUtil.getTokenStorage();
            const cobradorStorage = await StorageUtil.getCobradorStorage();
            formData.append('codUsuario', cobradorStorage.codUsuarioHijo);

            const response = await axios.post(urlsGenerales.BALANCE, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }
            
            console.log(response.data)
            setBalance(response.data);

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.BALANCE);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    // Nuevos modals
    const handleAgregarEntrada = async (detalle: string, valor: string) => {
        setVisibleAgregarEntrada(false);

        try {
            setLoading(true);

            const formData = new FormData();
            const cobrador = await StorageUtil.getCobradorStorage();
            const codUsuarioHijo=cobrador.codUsuarioHijo;
            formData.append('codUsuario', codUsuarioHijo!);
            formData.append('monto', valor);
            formData.append('tipo', '1');
            formData.append('detalle', detalle);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.INSERTAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleAgregarGasto = async (detalle: string, valor: string) => {
        setVisibleAgregarGasto(false);

        try {
            setLoading(true);

            const formData = new FormData();
            const cobrador = await StorageUtil.getCobradorStorage();
            const codUsuarioHijo=cobrador.codUsuarioHijo;
            formData.append('codUsuario', codUsuarioHijo!);
            formData.append('monto', valor);
            formData.append('tipo', '0');
            formData.append('detalle', detalle);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.INSERTAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                console.log('Mov registrado correctamente');
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleAgregarEntrega = async (detalle: string, valor: string) => {
        setVisibleAgregarEntrega(false);

        try {
            setLoading(true);

            const formData = new FormData();
            const cobrador = await StorageUtil.getCobradorStorage();
            const codUsuarioHijo=cobrador.codUsuarioHijo;
            formData.append('codUsuario', codUsuarioHijo!);
            formData.append('monto', valor);
            formData.append('tipo', '5');
            formData.append('detalle', detalle);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.INSERTAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                console.log('Mov registrado correctamente');
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }


    const handleQuitarEntrada = async (usuario: string, clave: string) => {
        setVisibleQuitarEntrada(false);

        try {
            setLoading(true);

            const formData = new FormData();
            const cobrador = await StorageUtil.getCobradorStorage();
            const codUsuarioHijo=cobrador.codUsuarioHijo;
            formData.append('codUsuario', codUsuarioHijo!);
            formData.append('codDetalleCaja',codigoEntradaQuitar);
            formData.append('usu', usuario);
            formData.append('pass', clave);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ELIMINAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleQuitarGasto = async (usuario: string, clave: string) => {
        setVisibleQuitarGasto(false);

        try {
            setLoading(true);

            const formData = new FormData();
            const cobrador = await StorageUtil.getCobradorStorage();
            const codUsuarioHijo=cobrador.codUsuarioHijo;
            formData.append('codUsuario', codUsuarioHijo!);   
            formData.append('codDetalleCaja', codigoGastoQuitar);
            formData.append('usu', usuario);
            formData.append('pass', clave);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.ELIMINAR_MOVIMIENTO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await consultarBalance();
            }

        } catch (e) {
            console.log('Error en -> ', e);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleConfirmModal = async (usuario: string, clave: string) => {
        setModalConfirmarVisible(false);

        setLoading(true);
        const validCredentials = await AuthenticationService.validarCredenciales(usuario, clave);
        if (!validCredentials) {
            CustomAlert.errorCredenciales();
            setLoading(false);
            return;
        }
        setLoading(false);

        switch (opcionBorrar) {
            case 'ENTRADA':
                handleQuitarEntrada(usuario, clave);
                break;
            case 'GASTO':
                handleQuitarGasto(usuario, clave);
                break;
            default:
                break;
        }
    }

    const handleBloquearCaja = async () => {
        try {
            setLoading(true);
            
            const formData = new FormData();
            const token = await StorageUtil.getTokenStorage();
            const cobradorStorage = await StorageUtil.getCobradorStorage();
            formData.append('codUsuario', cobradorStorage.codUsuarioHijo);
            

            await axios.post(urlsGenerales.BLOQUEAR_CAJA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            BloqueoCajaPress();

        } catch (e) {
            console.log('Error en -> ', urlsGenerales.BLOQUEAR_CAJA);
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
        <ScrollView 
                contentContainerStyle={{paddingBottom:48}}
                showsVerticalScrollIndicator={false}
            >
            <View style={[styles.row]}>
                <View style={[styles.totalCaja]}>
                    <Text style={styles.txtCaja}>Total Caja</Text>
                    <Text style={styles.sum}>S/ {Number(importeTotalCaja).toFixed(2)}</Text>
                </View>
            </View>


            <View style={styles.row}>
                
                <View style={[styles.cajaBox,{backgroundColor:COLORS.logo_naranja}]}>
                    {/* <View style={{flex:1}}>
                        <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                    </View> */}
                    <Text style={[styles.lblMountBox,{fontSize:12}]}>S/ {Number(importeCajaBase).toFixed(2)}</Text>
                    <Text style={[styles.lblBox,{fontSize:12}]}>
                        Caja base
                    </Text>
                </View>
                <View style={[styles.cajaBox,{marginHorizontal:16,backgroundColor:COLORS.logo_celeste}]}>
                    {/* <View style={{flex:1}}>
                        <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                    </View> */}
                    <Text style={[styles.lblMountBox,{fontSize:12}]}>S/ {Number(importeCobrado).toFixed(2)}</Text>
                    <Text style={[styles.lblBox,{fontSize:12}]}>
                        Recaudado
                    </Text>
                </View>
                <View style={[styles.cajaBox,{backgroundColor:COLORS.logo_azul}]}>
                    {/* <View style={{flex:1}}>
                        <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                    </View> */}
                    <Text style={[styles.lblMountBox,{fontSize:12 }]}>S/ {Number(importeCreditos).toFixed(2)}</Text>
                    <Text style={[styles.lblBox,{fontSize:12}]}>
                        Créditos
                    </Text>
                </View>
            </View>
            <View style={[styles.row,{marginTop:16}]}>
                <Icon name='warning' color={colors.logo_naranja} style={{alignSelf:'center'}}></Icon>
                <Text style={{alignSelf:'center',marginLeft:8, fontSize:12,}}> Manten presionado para eliminar una entrada o salida</Text>
            </View>

            <View style={[styles.row,{flexDirection:'column'}]}>
                        <View style={[styles.ctrList]}>
                            <View style={[styles.row,{paddingHorizontal:0}]}>
                                <Text style={styles.crtListText}>Entradas</Text>
                                        <TouchableOpacity onPress={() => setVisibleAgregarEntrada(true)}>
                                            <Icon name='add-circle' size={32} color={COLORS.verde_primary}></Icon>
                                        </TouchableOpacity>
                            </View>
                            <View style={styles.list}>
                                {listarEntradas()}
                            </View>
                        </View>
                        <View style={[styles.ctrList]}>
                            <View style={[styles.row,{paddingHorizontal:0}]}>
                                <Text style={styles.crtListText}>Salidas</Text>
                                    <TouchableOpacity onPress={()=>setVisibleAgregarGasto(true)} style={{marginRight:16}}>
                                        <Icon name='add-circle' size={32} color={COLORS.verde_primary}></Icon>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => setVisibleAgregarEntrega(true)}>
                                        <Icon name='add-circle' size={32} color={COLORS.rojo}></Icon>
                                    </TouchableOpacity>
                                    
                                
                            </View>
                            <View style={styles.list}>
                                {listarGastos()}
                            </View>
                        </View>
                    </View>

            <TouchableOpacity style={styles.btnLocked}
                              onPress={handleBloquearCaja}>
                <Text style={styles.textLocked}>Bloquear caja</Text>
            </TouchableOpacity>
            
        </ScrollView>
        
        <ModalAgregarMov
            visible={visibleAgregarEntrada}
            title='Ingreso'
            subtitle='Ingresa el detalle y valor del ingreso'
            onCancel={() => setVisibleAgregarEntrada(false)}
            onConfirm={agregarEntrada}
        />
        <ModalQuitarMov
            visible={visibleQuitarEntrada}
            title='Eliminar'
            subtitle='¿Está seguro que desea eliminar este ingreso?'
            onCancel={() => setVisibleQuitarEntrada(false)}
            onConfirm={borrarEntrada}
        />
        <ModalAgregarMov
            visible={visibleAgregarGasto}
            title='Gastos'
            subtitle='Ingresa el detalle y valor del gasto'
            onCancel={() => setVisibleAgregarGasto(false)}
            onConfirm={agregarGasto}
        />
        <ModalQuitarMov
            visible={visibleQuitarGasto}
            title='Eliminar'
            subtitle='¿Está seguro que deseas eliminar este gasto?'
            onCancel={() => setVisibleQuitarGasto(false)}
            onConfirm={borrarGasto}
        />
        <ModalAgregarMov
            visible={visibleAgregarEntrega}
            title='Entrega'
            subtitle={'Ingresa el detalle y valor entregado\n\nEl campo detalle complete con datos de entrega como donde, quien, etc.'}
            onCancel={() => setVisibleAgregarEntrega(false)}
            onConfirm={agregarEntrega}
        />
        
        <ModalOperacionAdmin
            visible={modalConfirmarVisible}
            title='Aviso'
            subtitle='Ingrese sus credenciales para confirmar la operación'
            onCancel={() => setModalConfirmarVisible(false)}
            onConfirm={handleConfirmModal}
        />
            
        <Spinner
            visible={loading}
            color={colors.white}
        />
        </>
    );
}

const styles = StyleSheet.create({
    row:{
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
    },
    totalCaja:{
        paddingVertical:8,
        marginVertical:8,
        flex:1,
    },
    txtCaja:{
        fontSize:14,
        marginBottom:8
    },
    sum:{
        fontSize:32,
        fontWeight:'bold',
    },
    cajaBox:{
        backgroundColor:colors.white,
        borderRadius:8,
        marginVertical:8,
        padding:8,
        paddingTop:16,
        // height:80,
        flex:1,
        display:'flex',
        flexDirection:'column'
    },
    lblBox:{
        fontSize: 14,
        color: colors.white,
    },
    lblMountBox:{
        fontSize: 20,
        fontWeight: 'bold',
        color:colors.white,
        marginBottom: 4,
    },
    viewPays:{
        flexDirection:'row',
        alignSelf:'center',
        paddingVertical:8,
        justifyContent:'center',
        alignItems:'center'
    },
    textViewPays:{
        fontSize:12,
        textAlign:'center',
        fontWeight:'bold',
        color:colors.negro,
        marginLeft:4
    },
    ctrList:{
        marginTop:24
    },
    crtListText:{
        fontWeight: 'bold',
        fontSize: 16,
        flex:1,
        alignSelf:'center'
    },
    list:{
        marginTop:4,
    },
    lblindex:{
        fontWeight:'bold',
        alignSelf:'center'
    },
    rowItem:{
        paddingVertical:16, 
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        flex:1,
        display:'flex',
        flexDirection:'row'
    },
    itemDetail:{
        fontSize:14,
        flex:1,
        marginRight:8,
    },
    itemMonto:{
        fontSize:14,
        fontWeight:'bold',
        alignSelf:'center'
    },
    info:{
        fontSize:12,
        fontWeight:'bold'
    },
    btnLocked:{
        marginHorizontal:'5%',
        height:48,
        backgroundColor:colors.rojo,
        borderRadius:4,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        marginTop:32
    },
    textLocked:{
        fontSize:16,
        fontWeight:'bold',
        color:colors.white,
        marginRight:8
    }
});
