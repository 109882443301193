export const tiposDocumento = [
    { key: '0', value: 'DNI' },
    { key: '1', value: 'CE' },
    { key: '2', value: 'Pasaporte' }
];

export const diasSemana = [
    { key: '1', value: 'Lunes' },
    { key: '2', value: 'Martes' },
    { key: '3', value: 'Miércoles' },
    { key: '4', value: 'Jueves' },
    { key: '5', value: 'Viernes' },
    { key: '6', value: 'Sabado' },
    { key: '7', value: 'Domingo' },
]

export const tiposPlazoCredito = [
    { key: '0', value: 'Diario' },
    { key: '1', value: 'Semanal' },
    { key: '2', value: 'Quincenal' },
    { key: '3', value: 'Mensual' }
]

export const textoSpinner = 'Cargando...';