import {Modal, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {Icon} from "react-native-elements";
import COLORS from "../constants/colors";
import colors from "../constants/colors";

type Props = {
    visible: boolean;
    title?: string;
    subtitle?: string;
    onCancel: () => void;
    onConfirm: () => void;
};


export default function ModalMensaje(props: Props) {

    const handleCancel = () => {
        props.onCancel();
    }
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.visible}
            onRequestClose={props.onCancel}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>

                    <Text style={styles.modalHeader}>{props.title}</Text>
                    <View style={{borderBottomWidth: 1, borderBottomColor: colors.gris_fondo_cell}}/>
                    <Text style={styles.titleModal}>{props.subtitle}</Text>
                    <View style={{flexDirection: 'row', marginTop: 16, justifyContent:'flex-end',paddingHorizontal:16}}>
                        <TouchableOpacity
                            style={styles.btnEliminar}
                            onPress={handleCancel}>
                            <Text style={styles.btnLabel}>OK</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    centeredView: {
        paddingHorizontal:'5%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        position:'relative',
        width:'100%',
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },

    modalHeader: {
        width: '100%',
        padding: 16,
        fontWeight: 'bold',
        fontSize: 20,
        color: colors.negro,
        textAlign: 'left',
    },

    titleModal: {fontSize: 16, marginVertical:16, paddingHorizontal:16,},

    btnAgregar: {
        flexDirection: 'row',
        backgroundColor: COLORS.verde_primary,
        padding: 8,
        borderRadius: 5,
        margin: 5
    },

    btnEliminar: {
        flexDirection: 'row',
        paddingHorizontal:16,
        paddingVertical:16,
        alignSelf:'center',
        justifyContent:'flex-end',
        borderRadius:4,
    },

    btnLabel: {
        color: COLORS.negro,
        fontWeight:'bold',
        fontSize: 15,
        textAlign: 'center',
    },
})
