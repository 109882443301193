export default {
    // Frecuencia
    DIARIO: '0',
    SEMANAL: '1',
    QUINCENAL: '2',
    MENSUAL: '3',

    INGRESO_DINERO: '0',
    SALIDA_DINERO: '1',

    // estado creditos
    CRED_INACTIVO: '0',
    CRED_ACTIVO: '1',
    CRED_APLAZADO: '2',
    CRED_VENCIDO: '3',
    CRED_PAGADO: '4',
}   
