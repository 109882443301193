import {Linking, Modal, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View} from "react-native";
import React from "react";
import {CreditoType} from "../../types/CreditoType";
import {Icon} from "react-native-elements";
import colors from "../../constants/colors";
import {StringUtils} from "../../shared/StringUtils";

type Props = {
    visible: boolean;
    credito: CreditoType;
    onCancel: () => void;
};

export default function ModalVerCliente(props: Props) {

    const obtenerPlazo = (): string => {
        return StringUtils.getDescPlazoBD(props.credito.cantCuotas, props.credito.codFrecuencia,
            props.credito.diaFrecuencia, props.credito.diaFrecuencia2);
    }

    const handlerEnviar = () => {
        const mensaje = 'Hola, te saluda el equipo de CFP y este es el estado de tu Credito:\n\n*Fecha Credito: '+props.credito.fecha+'*\n*Fecha Venc.: '+props.credito.fecVencimiento+'*\n*Capital inicial: '+props.credito.valorCredito+'*\n*Cuotas Pagadas: '+props.credito.cantCuotasPagadas+'*\n*Plazo: '+obtenerPlazo()+'*\n*Val. Cuota: '+props.credito.valorCuota+'*\n*Saldo: S/ '+props.credito.saldoCredito+'*\n*Estado: '+props.credito.detEstado+'*\n\n*Conserve su crédito pagando oportunamente :)*';
        //const phoneUrl = `whatsapp://send?phone=${props.credito.telefono}&text=${encodeURIComponent(mensaje)}`;
        let v_tele = props.credito.telefono;

        if(props.credito.telefono.length === 9){
            v_tele = '51'+props.credito.telefono;
        }
        const phoneUrl = `https://wa.me/${v_tele}?text=${encodeURIComponent(mensaje)}`;
        Linking.openURL(phoneUrl);
        /* Linking.canOpenURL(phoneUrl)
            .then((supported) => {
                if (supported) {
                    console.log("Abriendo WhatsApp");
                    return Linking.openURL(phoneUrl);
                } else {
                    console.log("No es posible abrir WhatsApp");
                }
            })
            .catch((error) => console.log("error whatsapp => ",error)); */
    }

    const handleLlamar = () => {
        const phoneUrl = `tel:${props.credito.telefono}`;
        Linking.openURL(phoneUrl);
    }

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.visible}
        >
            <TouchableWithoutFeedback onPress={props.onCancel}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>

                        <Text style={styles.titleModal}>
                            Nombre: {props.credito.nombre} {props.credito.apellido}</Text>

                        <Text style={styles.subTitlesModal}>Tel. {props.credito.telefono}</Text>
                        <Text style={styles.subTitlesModal}>Dir. {props.credito.direccion}</Text>
                        <Text
                            style={styles.subTitlesModal}>Ref. {props.credito.alias ? props.credito.alias : '---'}</Text>

                        <View style={styles.viewBody}>

                            <Text style={styles.textBody}>Fecha: {props.credito.fecha}</Text>
                            <Text style={styles.textBody}>Capital inicial: {props.credito.valorCredito}</Text>
                            <Text style={styles.textBody}>Interes: {props.credito.interes} %</Text>
                            <Text style={styles.textBody}>Plazo: {obtenerPlazo()}</Text>
                            <Text style={styles.textBody}>Val. Cuota: {props.credito.valorCuota}</Text>
                            <Text style={styles.textBody}>Saldo: {props.credito.saldoCredito}</Text>

                            <Text style={styles.textBody}>Estado: {props.credito.detEstado}</Text>

                            <View style={styles.rowButtons}>

                                <TouchableOpacity onPress={handlerEnviar}
                                                  style={[styles.buttonOptions, {backgroundColor: colors.logo_azul}]}
                                >
                                    <Text style={styles.textButtonOptions}>
                                        WhatsApp</Text>
                                    <Icon name={'sms'} color={colors.white}/>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={handleLlamar}
                                                  style={[styles.buttonOptions, {backgroundColor: colors.verde_primary}]}
                                >
                                    <Text style={styles.textButtonOptions}>
                                        Llamar</Text>
                                    <Icon name={'phone'} color={colors.white}/>
                                </TouchableOpacity>

                            </View>

                        </View>

                        <TouchableOpacity style={[styles.buttonOptions,
                            {backgroundColor: colors.logo_gris, marginTop: '5%'}]}
                                          onPress={props.onCancel}>
                            <Text style={styles.textButtonOptions}>Cerrar</Text>
                            <Icon name={'cancel'} color={colors.white}/>
                        </TouchableOpacity>

                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        paddingBottom: 15,
        paddingTop: 20,
        width: '80%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 5,
    },

    titleModal: {
        fontWeight: 'bold',
        fontSize: 20,
        width: '90%',
        marginBottom: '3%'
    },

    subTitlesModal: {
        fontWeight: "bold",
        fontSize: 16,
        width: '90%'
    },

    viewBody: {
        width: '90%',
        borderWidth: 0.5,
        borderColor: colors.gris_fondo_cell,
        borderRadius: 5,
        paddingHorizontal: '4%',
        paddingTop: '3%',
        paddingBottom: '4%',
        marginTop: '4%'
    },

    textBody: {
        fontWeight: "normal",
        fontSize: 16,
        width: '90%',
        marginBottom: '1%'
    },

    rowButtons: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: '100%',
        marginTop: '3%'
    },

    buttonOptions: {
        flexDirection: "row",
        padding: 8,
        borderRadius: 5,
        width: '49%',
        justifyContent: "center"
    },

    textButtonOptions: {
        fontSize: 15,
        fontWeight: "normal",
        color: colors.white,
        marginRight: '10%'
    }
})

