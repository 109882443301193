import React from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View, ScrollView } from 'react-native';
import { SelectList } from 'react-native-dropdown-select-list';
import { Icon } from 'react-native-elements';
import colors from '../../constants/colors';
import stylesAdmin from '../../constants/stylesAdmin';
import stylesGenerales from '../../constants/stylesGenrales';

export default function RestringirFunciones() {

    const [selected, setSelected] = React.useState('');
    const [aplazo, setAplazo] = React.useState('');

    const aplicarRestriccion = () => {
        console.log('Aplicar restricción')
    }

    const tiposRestriccion = [
        { key: '0', value: 'Seleccionar' },
        { key: '1', value: 'Sin Restricciones' },
        { key: '2', value: 'Sin Restricciones + Cartera' },
        { key: '3', value: 'Solo Escritura' },
        { key: '4', value: 'Solo Recaudo' },
        { key: '5', value: 'Solo Escritura + Restrict Credito' },
    ]

    const opcionesAplazo = [
        { key: '0', value: 'Permitir' },
        { key: '1', value: 'No Permitir' },
    ]

    return (
        <ScrollView
            contentContainerStyle={styles.container}
            showsHorizontalScrollIndicator={false}>

            <Text style={stylesAdmin.labelTitle}>Opción de restricción de funciones en la ruta</Text>

            <Text style={{ width: '90%', fontSize: 17, marginVertical: '3%' }}>
                Esta opción le premite cambiar permisos y funciones permitidas a los
                cobradores o usuarios de la ruta.</Text>

            <Text style={{ width: '90%', fontSize: 17, marginBottom: '3%' }}>
                <Text style={{ fontWeight: 'bold' }}>Importante:</Text>
                Para aplicar los cambios en la ruta debe Salvar datos
                (Sincronizar) desde la App de cobrador.
            </Text>

            <Text style={stylesGenerales.labelInput}>Estado actual de restricción</Text>
            <View style={{
                backgroundColor: colors.gris_fondo_cell,
                width: '90%',
                marginTop: '2%',
                marginBottom: '3%'
            }}>
                <Text style={{
                    fontWeight: 'bold',
                    fontSize: 22,
                    color: colors.verde_primary,
                    textAlign: 'center',
                    borderWidth: 0.5,
                    borderColor: colors.logo_gris,
                    borderRadius: 6,
                    paddingVertical: '5%',
                }}>
                    Sin Restricción
                </Text>
            </View>

            <Text style={stylesGenerales.labelInput}>Seleccionar tipo restricción:</Text>
            <View style={styles.selectTipDoc}>
                <SelectList
                    data={tiposRestriccion}
                    setSelected={setSelected}
                    search={false} />
            </View>

            {selected === '5' &&
                <Text style={stylesGenerales.labelInput}>
                    Límites de Crédito
                </Text>
            }

            {selected === '5' &&
                <View style={{
                    width: '90%',
                    backgroundColor: colors.white,
                    borderWidth: 0.5,
                    borderColor: colors.logo_gris,
                    borderRadius: 5,
                    paddingVertical: '3%',
                    alignItems: 'center',
                    marginTop: '2%'
                }}>
                    <Text style={stylesGenerales.labelInput}>Capital Máximo</Text>
                    <TextInput style={stylesGenerales.inputText}></TextInput>
                    <Text style={stylesGenerales.labelInput}>Interés Máximo</Text>
                    <TextInput style={stylesGenerales.inputText} keyboardType='numeric'></TextInput>
                    <Text style={stylesGenerales.labelInput}>Aplazo de créditos</Text>
                    <View style={styles.selectTipDoc}>
                        <SelectList
                            data={opcionesAplazo}
                            setSelected={setAplazo}
                            search={false}
                            defaultOption={opcionesAplazo[0]} />
                    </View>
                </View>}


            <TouchableOpacity
                style={{
                    backgroundColor: colors.rojo,
                    flexDirection: 'row',
                    borderRadius: 10,
                    padding: 10,
                    marginTop: '2%',
                    marginBottom: '4%'
                }}
                onPress={aplicarRestriccion}>
                <Icon name='refresh' color={colors.white}></Icon>
                <Text style={{
                    color: colors.white,
                    fontWeight: 'bold',
                    fontSize: 15,
                    marginStart: '1%'
                }}>Aplicar restricción</Text>
            </TouchableOpacity>

            <View style={styles.containerInstrucciones}>

                <Text style={styles.textInstrucciones}>Instrucciones</Text>

                <View style={styles.containerItem}>
                    <Text style={styles.textVineta}>•</Text>
                    <Text style={styles.textItem}>
                        <Text style={{ fontWeight: 'bold' }}>Sin Restricciones:</Text>
                        {' '}
                        Indica que el cobrador puede ingresar Créditos, Pagos/Abonos,
                        Gastos, Entregas e Inversiones. Pero no tendrá acceso a la información
                        acerca de la cartera general por cobrar. (Puede borrar abonos, créditos,
                        gastos y entradas Únicamente del día actual)
                    </Text>
                </View>

                <View style={styles.containerItem}>
                    <Text style={styles.textVineta}>•</Text>
                    <Text style={styles.textItem}>
                        <Text style={{ fontWeight: 'bold' }}>Sin Restricciones + Cartera:</Text>
                        {' '}
                        El cobrador puede ingresar Créditos, Pagos/Abonos, Gastos, Entregas e Inversiones
                        y además puede ver el estado de la cartera por cobrar. (Puede borrar abonos,
                        créditos, gastos y entradas Únicamente del día actual)
                    </Text>
                </View>

                <View style={styles.containerItem}>
                    <Text style={styles.textVineta}>•</Text>
                    <Text style={styles.textItem}>
                        <Text style={{ fontWeight: 'bold' }}>Solo Escritura:</Text>
                        {' '}
                        El cobrador podrá ingresar Abonos y Créditos, también podrá agregar Gastos,
                        Entregas y Entradas de dinero. ( NO Puede borrar abonos, créditos, gastos y
                        entradas - SOLO CON SUPERCLAVE)
                    </Text>
                </View>

                <View style={styles.containerItem}>
                    <Text style={styles.textVineta}>•</Text>
                    <Text style={styles.textItem}>
                        <Text style={{ fontWeight: 'bold' }}>Solo Recaudo:</Text>
                        {' '}
                        El cobrador solo tendrá opción de agregar abonos/pagos. ( NO Puede borrar abonos,
                        aplazar créditos, modificar/borrar créditos, gastos o entradas - SOLO CON SUPERCLAVE)
                    </Text>
                </View>

                <View style={styles.containerItem}>
                    <Text style={styles.textVineta}>•</Text>
                    <Text style={styles.textItem}>
                        <Text style={{ fontWeight: 'bold' }}>Solo Escritura + Restrict Credito:</Text>
                        {' '}
                        El cobrador podrá ingresar Abonos y Créditos, también podrá agregar Gastos, Entregas y
                        Entradas de dinero. ( NO Puede borrar abonos, créditos, gastos o entradas -
                        SOLO CON SUPERCLAVE) + Se restringe los montos máximos de Capital e Interés permitido
                        y Activar/Desactivar el aplazo de Créditos.
                    </Text>
                </View>

            </View>
        </ScrollView>
    )

}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center'
    },

    containerInstrucciones: {
        width: '90%',
        backgroundColor: colors.gris_fondo_cell,
        marginBottom: '8%',
        marginTop: '3%',
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: colors.logo_gris
    },

    containerItem: {
        width: '100%',
        flexDirection: 'row',
        paddingStart: '8%',
        paddingEnd: '4%',
        marginBottom: '6%'
    },

    textVineta: {
        fontWeight: 'bold', fontSize: 18, paddingEnd: '2%', width: '5%'
    },

    textItem: { fontSize: 17, textAlign: 'justify', width: '95%' },

    textInstrucciones: {
        fontWeight: 'bold',
        fontSize: 22,
        width: '90%',
        marginBottom: '4%',
        marginTop: '5%',
        paddingStart: '5%',
    },

    selectTipDoc: {
        margin: 10,
        width: '90%'
    }
})