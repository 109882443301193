import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import axios from 'axios';
import {format, parse} from 'date-fns';
import React,{useState} from 'react';
import {Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {SelectList} from 'react-native-dropdown-select-list';
import {Icon} from 'react-native-elements';
import {TextInput} from 'react-native-gesture-handler';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import colors from '../../../constants/colors';
import routes from '../../../constants/routes';
import urlsGenerales from '../../../constants/urlsGenerales';
import {StringUtils} from '../../../shared/StringUtils';
import {diasSemana, tiposPlazoCredito} from '../../../shared/TiposDocumento';
import { StorageUtil } from '../../../shared/StorageUtil';
import {DateUtils} from "../../../shared/DateUtils";

export default function AsignarCreditoAdmin() {

    const navigation = useNavigation();
    const route = useRoute();
    const {cliente} = route.params;

    const [loading, setLoading] = useState(false);

    const nombreCliente = cliente.nombre + ' ' + cliente.apellido;

    const [frecuencia, setFrecuencia] = useState('');
    const [diaSemana, setDiaSemana] = useState('');
    const [diaQuincenaInicio, setDiaQuincenaInicio] = useState('');
    const [diaQuincenaFin, setDiaQuincenaFin] = useState('');
    const [diaMes, setDiaMes] = useState('');

    const [fecha, setFecha] = useState(DateUtils.getDateStringYYYYMMDD());

    const [valorCredito, setValorCredito] = useState('');
    const [cantCuotas, setCantCuotas] = useState('1');
    const [diaFrecuencia, setDiaFrecuencia] = useState('');
    const [diaFrecuencia2, setDiaFrecuencia2] = useState('');

    const [modalVisible, setModalVisible] = useState(false);
    const [modalConfirmarVisible, setModalConfirmarVisible] = useState(false);

    const [valorCuotaAdi, setValorCuotaAdi] = useState(0)
    const [totalDeuda, setTotalDeuda] = useState('');
    const [valorCuota, setValorCuota] = useState('');
    const [interes, setInteres] = useState('');
    
    const [errorCapital, setErrorCapital] = useState('');
    const [errorInteres, setErrorInteres] = useState('');
    const [errorCuotas, setErrorCuotas] = useState('');
    const [errorFrecuencia, setErrorFrecuencia] = useState('');
    const dateNow = new Date();

    const mostrarModal = () => {

        setErrorFrecuencia('')

        if (valorCredito.trim() === '' || interes.trim() === '') {
            if(valorCredito.trim() === ''){
                setErrorCapital('Campo requerido')
            }
            if(interes.trim() === ''){
                setErrorInteres('Campo requerido')
            }
            return;
        }


        const interesMultiplicar = (Number(interes) + 100) / 100;
        const valorDeudaTotal = Math.floor(Number(valorCredito) * interesMultiplicar);
        const valorCuotaInicial = Math.floor(valorDeudaTotal / Number(cantCuotas));

        setValorCuota(valorCuotaInicial.toFixed(1).toString());
        setModalVisible(true);
    };

    const changeCantCuotas = (text: string) => {
        setErrorCuotas('')
        let nrocta = text
        if(text== null  || text == undefined || text == '0'){
            nrocta = '1'
        }
        setCantCuotas(nrocta);
        const valorCuotaTemp = Math.floor(Number(totalDeuda) / Number(nrocta));
        const valorCuotaAdicional = Number(totalDeuda) % Number(nrocta);
        setValorCuota(valorCuotaTemp.toFixed(1).toString());
        setValorCuotaAdi(valorCuotaAdicional);
    }

    const changeValorCredito = (text: string) => {
        setErrorCapital('')
        setValorCredito(text);
        const interesMultiplicar = (Number(interes) + 100) / 100;
        const valorCuotaInicial = Math.round(Number(text) * interesMultiplicar);
        setValorCuota(valorCuotaInicial.toFixed(2).toString());
        setTotalDeuda(valorCuotaInicial.toFixed(2).toString());
    }

    const changeInteres = (text: string) => {
        setErrorInteres('')
        setInteres(text);
        const interesMultiplicar = (Number(text) + 100) / 100;
        const valorCuotaInicial = Math.round(Number(valorCredito) * interesMultiplicar);
        setValorCuota(valorCuotaInicial.toFixed(2).toString());
        setTotalDeuda(valorCuotaInicial.toFixed(2).toString());
    }

    const aceptarPlazo = () => {
        if(cantCuotas.trim() === ''){
            setErrorCuotas('Campo requerido')
            return
        }
        switch (frecuencia) {
            case '0':
                setDiaFrecuencia('0');
                setDiaFrecuencia2("0");
                break;
            case '1':
                const descDiaSemana = diaSemana;
                setDiaFrecuencia(descDiaSemana);
                setDiaFrecuencia2("0");
                break;
            case '2':
                setDiaFrecuencia(diaQuincenaInicio);
                setDiaFrecuencia2(diaQuincenaFin);
                break;
            case '3':
                setDiaFrecuencia(diaMes.toString());
                setDiaFrecuencia2("0");
                break;
        }
        setErrorFrecuencia('')
        setModalVisible(false);
    }


    useFocusEffect(
        React.useCallback(() => {
            const fetchData = () => {
                const fechaTemp = String(dateNow.getDate()).padStart(2, '0') + '/' +
                    String(dateNow.getMonth() + 1).padStart(2, '0') + '/' + dateNow.getFullYear();

                setFecha(fechaTemp);
            };
            fetchData();
        }, [])
    );

    const doCrearCredito = async () => {
        await setModalConfirmarVisible(false);

        await setLoading(true);

        try {
            const formData = new FormData();
            formData.append('codUsuario', cliente.codUsuario);
            formData.append('codCliente', cliente.codCliente);
            formData.append('fecha', format(parse(fecha, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'));
            formData.append('valorCredito', valorCredito);
            formData.append('interes', interes);
            formData.append('cantCuotas', cantCuotas);
            formData.append('valorCuota', valorCuota);
            formData.append('codFrecuencia', frecuencia);
            formData.append('diaFrecuencia', diaFrecuencia);
            formData.append('diaFrecuencia2', diaFrecuencia2);
            formData.append('totalDeuda', totalDeuda);

            const token = await StorageUtil.getTokenStorage(); 
            const response = await axios.post(urlsGenerales.CREAR_CREDITO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                const cobradorStorage = await StorageUtil.getCobradorStorage();
                navigation.navigate(routes.AGREGAR_CREDITO, {cobrador: cobradorStorage});
            }

        } catch (error) {
            console.log('Se cae en error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const showModalConfirmCrear = () => {
        if (valorCredito.trim() === '' || interes.trim() === '' || frecuencia.trim()==='') {
            if(valorCredito.trim() === ''){
                setErrorCapital('Campo requerido')
            }
            if(interes.trim() === ''){
                setErrorInteres('Campo requerido')
            }
            if(frecuencia.trim() === ''){
                setErrorFrecuencia('Campo requerido')
            }
            return;
        }
        setModalConfirmarVisible(true);
    }

    return (
        <>
        <ScrollView contentContainerStyle={styles.container}>
            <View>
                <Text style={styles.desCredito}>Para crear un nuevo credito al cliente <Text style={{fontWeight:'bold'}}>{nombreCliente}</Text> por favor de completar todos los campos.</Text>

                <Text style={styles.labelInput}>Fecha</Text>
                    <TextInput
                        style={styles.inputText}
                        placeholder='dd/mm/aaaa'
                        value={fecha}
                        onChangeText={setFecha}
                        editable={false}
                    ></TextInput>
                <View>
                <Text style={styles.labelInput}>Crédito</Text>
                <TextInput
                    style={[styles.inputText,errorCapital.length>0?{borderColor:colors.rojo}:{}]}
                    placeholder='0'
                    keyboardType='numeric'
                    value={valorCredito}
                    onChangeText={changeValorCredito}></TextInput>
                    
                    {
                        errorCapital.length>0?<Text style={styles.lblError}>{errorCapital}</Text>:null
                    }
                </View>

                <View style={styles.rowInput}>
                    <View style={{width:100,marginRight:16}}>
                        <Text style={styles.labelInput}>Interés (%)</Text>
                        <TextInput
                            style={[styles.inputText,errorInteres.length>0?{borderColor:colors.rojo}:{}]}
                            placeholder='0'
                            keyboardType="numeric"
                            value={interes}
                            onChangeText={changeInteres}></TextInput>
                        {
                            errorInteres.length>0?<Text style={styles.lblError}>{errorInteres}</Text>:null
                        }
                    </View>
                    <View style={{flex:1}}>
                        <Text style={styles.labelInput}>Plazo</Text>
                        <TouchableOpacity
                            style={[styles.inputText,{justifyContent:'center'},errorFrecuencia.length>0?{borderColor:colors.rojo}:{}]}
                            onPress={mostrarModal}>
                            <Text style={styles.labelPlazo}>{StringUtils.getDescPlazo(frecuencia, cantCuotas, diaFrecuencia,
                                diaQuincenaInicio, diaQuincenaFin)}</Text>
                        </TouchableOpacity>
                        {
                            errorFrecuencia.length>0?<Text style={styles.lblError}>{errorFrecuencia}</Text>:null
                        }
                    </View>
                </View>

                <View style={styles.crtInfo}>
                    <View style={[styles.boxInfo,{backgroundColor:colors.logo_naranja}]}>
                        <View style={{flex:1}}>
                            <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                        </View>
                        <Text style={styles.lblPay}>S/ {Number(valorCuota).toFixed(2)}</Text>
                        <Text style={styles.lblInfo}>Valor Cuota</Text>
                    </View>
                    <View style={{width:16,}}></View>
                    <View style={[styles.boxInfo,{backgroundColor:colors.logo_celeste}]}>
                        <View style={{flex:1}}>
                            <Icon name='attach-money' color={colors.white} size={16} style={{alignSelf:'flex-start', marginLeft:-4}}></Icon>
                        </View>
                        <Text style={styles.lblPay}>S/ {Number(totalDeuda).toFixed(2)}</Text>
                        <Text style={[styles.lblInfo]}>Total a Pagar</Text>
                    </View>
                </View>

                <TouchableOpacity
                        style={styles.btnLogin}
                        onPress={showModalConfirmCrear}>
                        <Text style={styles.btnLoginText}>
                        Crear crédito y Enrutar
                        </Text>
                </TouchableOpacity>
            </View>
        </ScrollView>

            <Modal
                animationType="fade"
                transparent={true}
                visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalHeader}>Plazo</Text>
                        <View style={{borderBottomWidth: 1,height:1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>
                        <Text style={styles.titleModal}>Selecciona el plazo de pago para el crédito</Text>

                        <View style={{flexDirection: 'row',paddingHorizontal:16}}>
                            <View style={{flex:1, marginRight:16}}>
                                <Text style={styles.labelInput}>Cant cuotas</Text>
                                <TextInput 
                                    style={[styles.inputText,errorCuotas.length>0?{borderColor:colors.rojo}:{}]}
                                    placeholder='Ingrese detalle'
                                    keyboardType='numeric'
                                    value={cantCuotas}
                                    onChangeText={changeCantCuotas}
                                />
                                {
                                    errorCuotas.length>0?<Text style={styles.lblError}>{errorCuotas}</Text>:null
                                }
                            </View>
                            <View style={{flex:1, backgroundColor: colors.white}}>
                                <Text style={styles.labelInput}>Valor cuota</Text>
                                <TextInput 
                                    style={[styles.inputText,{borderColor:colors.gris_fondo_cell,backgroundColor:colors.gris_fondo_cell}]}
                                    editable={false}
                                    value={!cantCuotas||cantCuotas==0?Number(0).toFixed(2).toString():Number(valorCuota).toFixed(2).toString()}
                                />
                            </View>
                        </View>

                        {
                            (valorCuotaAdi != 0 && !Number.isNaN(valorCuotaAdi)) &&
                            <Text
                                style={{
                                    marginVertical: 4, 
                                    fontWeight: 'bold',
                                    fontSize: 12, 
                                    color: colors.verde_primary,
                                    paddingHorizontal:16,
                                }}
                            >[ Se adicionara una  cuota de S/ {valorCuotaAdi.toFixed(2)} ]</Text>
                        }

                        <View style={{paddingHorizontal:16}}>
                            <Text style={styles.labelInput}>Tipo</Text>
                            <View style={{
                                width: '100%',
                                marginTop: 4,
                            }}>
                                <SelectList
                                    data={tiposPlazoCredito}
                                    setSelected={setFrecuencia}
                                    boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                                    inputStyles={{alignSelf:'center',}}
                                    dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                                    search={false}
                                    defaultOption={tiposPlazoCredito[frecuencia?Number(frecuencia):0]}/>
                            </View>
                        </View>

                        {frecuencia === '1' &&
                            <View style={{
                                paddingHorizontal:16,
                            }}>
                                <Text style={[styles.labelInput,{marginBottom:4}]}>Los días</Text>
                                <SelectList
                                    data={diasSemana}
                                    setSelected={setDiaSemana}
                                    boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                                    inputStyles={{alignSelf:'center',}}
                                    dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                                    search={false}
                                    defaultOption={diasSemana[0]}/>
                            </View>
                        }

                        {frecuencia === '2' &&
                            <View style={{paddingHorizontal:16, marginTop:16}}>
                                <View style={{flexDirection: 'row',display:'flex'}}>
                                    <Text style={{alignSelf:'center', marginRight:'5%'}}>Los días</Text>
                                    <TextInput
                                        style={[styles.inputText,{width: '20%',}]}
                                        value={diaQuincenaInicio}
                                        onChangeText={setDiaQuincenaInicio}
                                        keyboardType='numeric'>
                                    </TextInput>
                                    <Text style={{alignSelf:'center', marginHorizontal:'5%'}}>
                                        y
                                    </Text>
                                    <TextInput
                                        style={[styles.inputText,{width: '20%',}]}
                                        value={diaQuincenaFin}
                                        onChangeText={setDiaQuincenaFin}
                                        keyboardType='numeric'>
                                    </TextInput>
                                </View>
                            </View>
                        }

                        {frecuencia === '3' &&
                            <View style={{flexDirection: 'row', paddingHorizontal:16, marginTop:16,}}>
                                <Text style={{alignSelf:'center', marginRight:'5%'}}>El día</Text>
                                <TextInput
                                    style={[styles.inputText,{width: '20%',}]}
                                    keyboardType='numeric'
                                    value={diaMes}
                                    onChangeText={setDiaMes}></TextInput>
                                <Text style={{alignSelf: 'center', marginHorizontal: '5%'}}>del mes</Text>
                            </View>
                        }

                        <View style={{ marginTop:32, paddingHorizontal:16}}>
                            <TouchableOpacity
                                style={[styles.btnLogin, {marginTop:0,marginBottom:0}]}
                                onPress={aceptarPlazo}>
                                <Text style={[styles.btnLoginText]}>Confirmar plazo</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal
                animationType="fade"
                transparent={true}
                visible={modalConfirmarVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalHeader}>Confirmar</Text>
                        <View style={{borderBottomWidth: 1,height:1, borderBottomColor: colors.gris_fondo_cell,marginTop:16}}/>
                        <Text style={[styles.titleModal,{textAlign:'center',}]}>Vas a generar un credito a:</Text>
                        <Text  
                            numberOfLines={1} // Mostrar solo una línea
                            ellipsizeMode="tail" // Truncar con tres puntos suspensivos al final 
                            style={[styles.titleModal,{marginBottom:16,textAlign:'center', marginTop:8,fontWeight:'bold',fontSize:16, width:'90%', marginLeft:'5%'}]}>{nombreCliente}</Text>

                        <View style={styles.crtBoxConfirm}>
                            <View style={styles.rowBox}>
                                <Text style={styles.labelConfirmar}>Fecha</Text>
                                <Text style={[styles.labelConfirmar,{fontWeight:'bold',color:colors.negro}]}>{fecha}</Text>
                            </View>
                            <View style={styles.rowBox}>
                                <Text style={styles.labelConfirmar}>Crédito</Text>
                                <Text style={[styles.labelConfirmar,{fontWeight:'bold',color:colors.negro}]}>S/ {Number(valorCredito).toFixed(2)}</Text>
                            </View>
                            <View style={styles.rowBox}>
                                <Text style={styles.labelConfirmar}>Plazo</Text>
                                <Text style={[styles.labelConfirmar,{fontWeight:'bold',color:colors.negro}]}>{StringUtils.getDescPlazo(frecuencia, cantCuotas, diaFrecuencia,
                                diaQuincenaInicio, diaQuincenaFin)}</Text>
                            </View>
                            <View style={[styles.rowBox,{marginBottom:0}]}>
                                <Text style={styles.labelConfirmar}>Total</Text>
                                <Text style={[styles.labelConfirmar,{fontWeight:'bold',color:colors.negro}]}>S/ {Number(totalDeuda).toFixed(2)}</Text>
                            </View>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: 32, paddingHorizontal:16,justifyContent:'space-between'}}>
                            <TouchableOpacity
                                style={styles.btnEliminar}
                                onPress={() => setModalConfirmarVisible(false)}>
                                <Text style={{
                                    color: colors.negro,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}>Cancelar</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.btnAgregar}
                                onPress={doCrearCredito}>
                                <Text style={{
                                    color: colors.white,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}>Confirmar</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal:'5%'
    },

    containerBtn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 20
    },

    input: {
        height: 40,
        width: 400,
        margin: 10,
        borderWidth: 0.5,
        padding: 10,
        borderRadius: 5
    },

    inputTotal: {
        height: 60,
        width: 400,
        margin: 10,
        borderWidth: 0.5,
        padding: 10,
        borderRadius: 5,
        fontSize: 22,
        fontWeight: 'bold'
    },

    btn: {
        backgroundColor: colors.logo_azul,
        borderRadius: 5,
        shadowOpacity: 5,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        flexDirection: 'row'
    },

    btnLabel: {
        color: colors.white,
        fontWeight: 'bold',
        fontSize: 15,
        marginStart: 5
    },

    // LABELS ENCABEZADO
    underlined: {
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        fontSize: 20,
        width: 400,
        textAlign: 'left',
        marginTop: 20
    },

    labelTitle: {
        fontWeight: 'bold',
        fontSize: 30,
        width: 400,
        textAlign: 'left',
        marginBottom: 5
    },

    labelSubtitle: {
        width: 400,
        textAlign: 'left',
    },

    espacioIzq: {
        marginLeft: 20
    },

    espaceSubtitle: {
        marginBottom: 20
    },

    
    centeredView: {
        paddingHorizontal:'5%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalView: {
        position:'relative',
        width:'100%',
        backgroundColor: colors.white,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4,
        borderRadius: 4,
        paddingBottom:16,
    },
    modalHeader: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18, 
        marginTop:16, 
        paddingHorizontal:16,
    },
    
    titleModal: {
        fontSize: 14,
        textAlign: 'left',
        marginTop:16,
        paddingHorizontal:16,

    },

    btnAgregar: {
        backgroundColor: colors.verde_primary,
        height:48,
        borderRadius: 4,
        flex:1,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },


    btnEliminar: {
        backgroundColor: colors.white,
        height:48,
        flex:1,
        borderRadius: 4,
        marginRight:16,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },


    desCredito:{
        marginTop:24,
        lineHeight:24
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    rowInput:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    labelPlazo: {
        fontWeight: '500',
        fontSize: 14,
    },
    crtInfo:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        marginTop:32
    },
    boxInfo:{
        flex:1,
        padding:8, 
        borderRadius:8,
        height:80,
    },
    lblInfo:{
        fontSize: 12,
        color: colors.white,
        fontWeight: '400',
        marginTop: 4,
    },
    lblPay:{
        fontSize: 20,
        color: colors.white,
        fontWeight: 'bold'
    },
    btnLogin:{
        height:48,
        backgroundColor:colors.verde_primary,
        shadowColor: colors.negro,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.4,
        shadowRadius: 2.00,
        elevation: 4,
        borderRadius:4,
        display:'flex',
        justifyContent:'center',
        marginTop:32,
        marginBottom:40
    },
    btnLoginText:{
        fontWeight:'bold',
        alignSelf:'center',
        color:colors.white,
    },
    lblError:{
        fontSize:12,
        color:colors.rojo,
        marginTop:2
    },
    crtBoxConfirm:{
        backgroundColor:colors.gris_fondo_cell,
        marginHorizontal:'5%',
        padding:16,
        borderRadius:4,
        marginTop:8,
    },
    rowBox:{
        display:'flex',
        flexDirection:'row',
        marginBottom:8,
        justifyContent:'space-between'
    },
    labelConfirmar: {
        fontSize: 14,
    },
});
