import React, {useState} from 'react';
import {Image, ListRenderItem, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';

import stylesGenerales from '../constants/stylesGenrales';
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../shared/TiposDocumento";
import {StorageUtil} from "../shared/StorageUtil";
import axios from "axios";
import urlsGenerales from "../constants/urlsGenerales";
import {RutaType} from "../types/RutaType";
import {useFocusEffect} from "@react-navigation/native";
import DraggableFlatList from 'react-native-draggable-flatlist';
import { useNavigation } from '@react-navigation/native';
import routes from '../constants/routes';

export default function Ruta() {

    const navigation = useNavigation();

    const [loading, setLoading] = useState(false);
    const [listRutas, setListRutas] = useState<RutaType[]>([]);

    const obtenerRutas = async () => {
        try {
            setLoading(true);

            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            const formData = new FormData();
            formData.append('codUsuario', codUsuario!);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.LISTAR_RUTAS, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data)

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                setListRutas(response.data);
            }

        } catch (e) {
            console.log('Error en -> ', urlsGenerales.LISTAR_RUTAS);
            console.log(e);
            setListRutas([]);

        } finally {
            setLoading(false);
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                await obtenerRutas();
            };
            fetchData();
        }, [])
    );

    const modificarRuta = async () => {

        try {
            setLoading(true);

            const codUsuario = await StorageUtil.getCodUsuarioStorage();
            const codClientes = listRutas.map((item) => item.codCliente).join(",");

            const formData = new FormData();
            formData.append('codUsuario', codUsuario!);
            formData.append('codClientes', codClientes);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.MODIFICAR_RUTA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {
                await obtenerRutas();
            }

        } catch (e) {
            console.log('Error en -> ', urlsGenerales.MODIFICAR_RUTA);
            console.log(e);

        } finally {
            setLoading(false);
        }
    }

    const handleDragEnd = ({data: newData}) => {
        setListRutas(newData);
    }

    const renderItemDrag = ({item,  drag, isActive}) => {
        return (
            <TouchableOpacity 
                style={[styles.item,{ backgroundColor: isActive ? colors.gris_fondo_cell : 'white'}]}
                onLongPress={drag}
            >   
                <Text 
                    numberOfLines={1} // Mostrar solo una línea
                    ellipsizeMode="tail"
                    style={[styles.itemName]}
                >
                    {item.nombre} {item.apellido}
                </Text>
            
                <Icon name='grid-view' color={isActive?colors.white:colors.gris_fondo_cell} size={16}></Icon>
            </TouchableOpacity>
        );
    };

    return (
        <View style={styles.container}>
            <Image style={styles.img} source={require('../assets/route.png')}></Image>
            
            <Text style={[styles.title]}>Ruta de clientes <Text style={{fontSize:12, fontWeight:'500'}}>({listRutas.length}{listRutas.length==1?' ruta':' rutas'})</Text></Text>

            {
                listRutas.length === 0 ? (
                    <View style={{
                        flex: 1,
                        marginTop:24
                    }}>
                        <Text style={{textAlign: 'center'}}>No hay
                            clientes en la ruta</Text>
                    </View>
                ) : (
                    <View style={{flex: 1, paddingHorizontal:'5%', marginTop:24}}>
                        <DraggableFlatList
                            data={listRutas}
                            renderItem={renderItemDrag}
                            keyExtractor={(item) => item.codRuta}
                            onDragEnd={handleDragEnd}
                            dragItemActivationTreshold={100}
                            dragItemReleaseAnimationDuration={200}
                        />
                    </View>
                )
            }

            {
                listRutas.length>0?
                <View style={{
                    width: '100%',
                    backgroundColor: colors.verde_primary,
                    marginTop: 10,
                    flexDirection: 'row',
                }}>
                    <TouchableOpacity
                        style={{
                            flexDirection: 'column',
                            alignContent: 'center',
                            padding: 8,
                            flex: 1,
                        }}
                        onPress={modificarRuta}>
                        <Icon name='save' color={colors.white} size={24} style={{alignSelf:'center'}}></Icon>
                        <Text style={{
                            textAlign: 'center',
                            marginVertical: 4,
                            color: colors.white,
                            fontWeight:'bold'
                        }}>Guardar Posiciones</Text>
                    </TouchableOpacity>
                </View>:
                null
            }
            

            <Spinner
                visible={loading}
                color={colors.verde_primary}
                textContent={textoSpinner}
                textStyle={stylesGenerales.textSpinner}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    img: {
        width: 100,
        height: 100,
        alignSelf:'center',
        marginVertical:16
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingHorizontal:'5%'
    },
    item:{
        paddingHorizontal: 8,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.white,
        marginBottom: 12,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.10,
        shadowRadius: 1,
        elevation: 1,
        borderRadius: 4,
        paddingVertical:16,
    },
    itemName:{
        flex:1,
        marginRight:8,
        textTransform:'capitalize'
    }
});
