import {diasSemana} from "./TiposDocumento";

export class StringUtils {

    static getDescPlazo(frecuencia: string, cantCuotas: string,
                        diaFrecuencia: string, diaQuincenaInicio: string, diaQuincenaFin: string): string {

        let descripcion = '';

        switch (frecuencia) {
            case '':
                descripcion = 'Seleccionar Plazo';
                break;
            case '0':
                descripcion = `Diario, ${cantCuotas} cuotas`;
                break;
            case '1':
                const nombreDia = diasSemana.find(s => s.key == diaFrecuencia)?.value;
                descripcion = `${cantCuotas} Semana(s), el día ${nombreDia}`;
                break;
            case '2':
                descripcion = `${cantCuotas} Quincenas, los días ${diaQuincenaInicio} y ${diaQuincenaFin}`;
                break;
            case '3':
                descripcion = `${cantCuotas} Mese(s), ell día ${diaFrecuencia}`;
                break;
            default:
                break;
        }

        return descripcion;
    }

    static getDescPlazoBD(cantCuotas: string, codFrecuencia: string, diaFrecuencia: string,
                          diaFrecuencia2: string): string {

        let descripcion = '';

        switch (codFrecuencia) {
            case '0':
                descripcion = `Diario, ${cantCuotas} cuota(s)`;
                break;
            case '1':
                const nombreDia = diasSemana.find(s => s.key == diaFrecuencia)?.value;
                descripcion = `${cantCuotas} Semana(s), El día ${nombreDia}`;
                break;
            case '2':
                descripcion = `${cantCuotas} Quincenas, los días ${diaFrecuencia} y ${diaFrecuencia2}`;
                break;
            case '3':
                descripcion = `${cantCuotas} Mese(s), el día ${diaFrecuencia}`;
                break;
        }

        return descripcion;
    }

    static typePay(codFrecuencia: string, diaFrecuencia: string, diaFrecuencia2: string): string {
        let descripcion = '';

        switch (codFrecuencia) {
            case '0':
                descripcion = `Diario`;
            break;
            case '1':
                const nombreDia = diasSemana.find(s => s.key == diaFrecuencia)?.value;
                descripcion = `Semanal el día ${nombreDia?nombreDia:'0'}`;
            break;
            case '2':
                descripcion = `Quincenal los días ${diaFrecuencia} y ${diaFrecuencia2}`;
            break;
            case '3':
                descripcion = `Mensual el día ${diaFrecuencia}`;
            break;
        }

        return descripcion;
    }
}
