import React, {useEffect, useState} from 'react';
import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {Icon} from 'react-native-elements';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../../shared/TiposDocumento";
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import codigos from "../../../constants/codigos";
import {SelectList} from "react-native-dropdown-select-list";
import {useFocusEffect, useRoute} from "@react-navigation/native";
import {NumberUtils} from "../../../shared/NumberUtils";
import stylesTablas from "../../../constants/stylesTablas";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';

export interface CredEstadoType {
    activos: Credito[];
    inactivos: Credito[];
    enMora: Credito[];
    vencidos: Credito[];
    pagados: Credito[];
}

export interface Credito {
    codCredito: string;
    codUsuario: string;
    codCliente: string;
    fecha: string;
    valorCredito: string;
    interes: string;
    cantCuotas: string;
    valorCuota: string;
    codFrecuencia: string;
    diaFrecuencia: string;
    diaFrecuencia2: string;
    totalDeuda: string;
    estado: string;
    timestamp: string;
    nombre: string;
    apellido: string;
    codEstado: string;
    saldo: string;
}

export default function CreditosSegunEstado() {

    const navigation = useNavigation();

    const route = useRoute();
    const cobrador = route.params?.cobrador;

    const [loading, setLoading] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [saldo, setSaldo] = useState(0);

    const [descEstado, setDescEstado] = useState('registrados');

    const [respondeCreditos, setResponseCreditos] = useState<CredEstadoType>({
        activos: [],
        inactivos: [],
        enMora: [],
        vencidos: [],
        pagados: [],
    });
    const [listCreditos, setListCreditos] = useState<Credito[]>([]);

    const estados = [
        {key: '1', value: 'Activos'},
        {key: '0', value: 'Inactivos'},
        {key: '2', value: 'Aplazados'},
        {key: '3', value: 'Vencidos'},
        {key: '4', value: 'Pagados'}
    ];
    const [estado, setEstado] = React.useState('');

    const [searchText, setSearchText] = React.useState('');

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const limpiarSearchText = () => {
        setSearchText('');
    }

    const obtenerCreditosSegunEstado = async () => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo!);
            const token = await StorageUtil.getTokenStorage(); 
            const response = await axios.post(urlsGenerales.REP_CREDITOS_SEGUN_ESTADO, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            if (response.data) {

                console.log(response.data);

                setResponseCreditos(response.data);
            }

        } catch (error) {
            console.log('Error en -> ', urlsGenerales.REP_CREDITOS_SEGUN_ESTADO);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                await obtenerCreditosSegunEstado();
            };
            fetchData();
        }, [])
    );

    useEffect(() => {
        switch (estado) {
            case codigos.CRED_INACTIVO:
                setDescEstado('en estado Inactivo');
                setCantidad(respondeCreditos.inactivos.length);
                setListCreditos(respondeCreditos.inactivos);
                setSaldo(respondeCreditos.inactivos.length > 0 ? respondeCreditos.inactivos.reduce((acumulador, elemento) => {
                    return acumulador + parseFloat(elemento.saldo);
                }, 0) : 0);
                break;
            case codigos.CRED_ACTIVO:
                setDescEstado('en estado Activo');
                setCantidad(respondeCreditos.activos.length);
                setListCreditos(respondeCreditos.activos);
                setSaldo(respondeCreditos.activos.length > 0 ? respondeCreditos.activos.reduce((acumulador, elemento) => {
                    return acumulador + parseFloat(elemento.saldo);
                }, 0) : 0);
                break;
            case codigos.CRED_APLAZADO:
                setDescEstado('en estado Aplazado');
                setCantidad(respondeCreditos.enMora.length);
                setListCreditos(respondeCreditos.enMora);
                setSaldo(respondeCreditos.enMora.length > 0 ? respondeCreditos.enMora.reduce((acumulador, elemento) => {
                    return acumulador + parseFloat(elemento.saldo);
                }, 0) : 0);
                break;
            case codigos.CRED_VENCIDO:
                setDescEstado('en estado Vencido');
                setCantidad(respondeCreditos.vencidos.length);
                setListCreditos(respondeCreditos.vencidos);
                setSaldo(respondeCreditos.vencidos.length > 0 ? respondeCreditos.vencidos.reduce((acumulador, elemento) => {
                    return acumulador + parseFloat(elemento.saldo);
                }, 0) : 0);
                break;
            case codigos.CRED_PAGADO:
                setDescEstado('en estado Pagado');
                setCantidad(respondeCreditos.pagados.length);
                setListCreditos(respondeCreditos.pagados);
                setSaldo(respondeCreditos.pagados.length > 0 ? respondeCreditos.pagados.reduce((acumulador, elemento) => {
                    return acumulador + parseFloat(elemento.saldo);
                }, 0) : 0);
                break;
            default:
                break;
        }
    }, [estado]);

    const renderEmpty = () => {
        return (
            <Text style={{fontSize: 12, marginVertical: 20, textAlign:'center'}}>No existen créditos {descEstado}.</Text>
        );
    };

    const renderItem = ({item}) => {
        return (
            <TouchableOpacity
                style={styles.crtItem}
            >
                
                <View style={{flex:1,alignSelf:'center'}}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>{item.nombre} {item.apellido}</Text>
                    <Text style={styles.code}><Text style={{fontWeight:'bold'}}>#{item.codCredito}</Text> - {item.fecha}</Text>
                </View>
                <Text style={{alignSelf:'center', fontWeight:'bold', fontSize:16}}>
                    S/ {Number(item.totalDeuda).toFixed(2)}
                </Text>

            </TouchableOpacity>
        );
    };

    return (
        <View style={styles.container}>

            <View style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingHorizontal:'5%', marginBottom:16}}>
                <Text style={[styles.title]}>Lista <Text style={{fontSize:12, fontWeight:'500'}}>({cantidad}{cantidad==1?' credito':' creditos'})</Text></Text>
                <View style={{alignSelf:'center'}}>
                    <Text style={{fontSize:12, textAlign:'right',paddingTop:8,color:colors.logo_azul,fontWeight:'400'}}>Saldos</Text>
                    <Text style={{fontSize:20,fontWeight:'bold',color:colors.logo_azul}}>S/ {NumberUtils.formatThousands(saldo)}</Text>
                </View>
            </View>
            <View style={styles.selectEstado}>
                <SelectList
                    data={estados}
                    search={false}
                    boxStyles={{borderRadius:4, borderColor:colors.negro,borderWidth:0.5, height:48,justifyContent:'space-between'}}
                    inputStyles={{alignSelf:'center',fontWeight:'bold'}}
                    dropdownStyles={{borderRadius:4,borderColor:colors.negro,borderWidth:0.5}}
                    setSelected={(value: number) => {
                        setEstado(value);
                    }}
                    defaultOption={estados[0]}
                />
            </View>

            <View style={[styles.row,styles.mrg]}>
                <View style={styles.iconSearch}>
                    <Icon name='search' color={colors.negro}></Icon>
                </View>
                <TextInput
                    placeholder='Buscar'
                    style={styles.inputSearch}
                    onChangeText={handleSearch}
                    value={searchText}
                    selectionColor={colors.negro}
                />
                {
                searchText !== '' &&
                    <TouchableOpacity
                        style={styles.btnSearch}
                        onPress={limpiarSearchText}>
                        <Icon name='clear' color={colors.negro}></Icon>
                    </TouchableOpacity>
                }
            </View>
            <FlatList
                data={listCreditos}
                renderItem={renderItem}
                keyExtractor={(item, index) => item.codCredito + index.toString()}
                ListEmptyComponent={renderEmpty}
                style={{width: '100%', marginTop: 15}}
            />

            <Spinner
                visible={loading}
                color={colors.white}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
    },
    selectEstado:{
        width:'100%',
        paddingHorizontal:16,
        marginBottom:0
    },
    row:{
        display:'flex',
        flexDirection:'row',
    },
    inputSearch:{
        height:48,
        borderRadius:4,
        backgroundColor: colors.white,
        width:'100%',
        color:colors.negro,
        paddingLeft:48,
        paddingRight:48,
        zIndex:1,
        fontWeight:'500'
    },
    iconSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingLeft:16,
    },
    mrg:{
        marginHorizontal:'5%',
        marginTop:16,
    },
    btnSearch:{
        position:'absolute',
        zIndex:2,
        alignSelf:'center',
        paddingRight:16,
        right:0,
    },
    crtItem: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: '#222222',
        marginBottom: 8,
        paddingVertical:8,
        paddingBottom:16,
        paddingHorizontal:'5%',
        alignItems:'center'
    },
    name:{
        fontSize:14,
        marginBottom:4
    },
    code:{
        fontWeight:'300',
        fontSize:12,
        marginTop:4,
    },
})
