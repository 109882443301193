import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, StyleSheet, Text, TextInput, View, TouchableOpacity} from 'react-native';
import colors from '../../../constants/colors';
import stylesAdmin from '../../../constants/stylesAdmin';
import stylesGenerales from '../../../constants/stylesGenrales';
import ModalCalendar from "../../../components/ModalCalendar";
import {DateUtils} from "../../../shared/DateUtils";
import ButtonReporteBuscar from "../../../components/ButtonReporteBuscar";
import Spinner from "react-native-loading-spinner-overlay";
import {textoSpinner} from "../../../shared/TiposDocumento";
import {useRoute} from "@react-navigation/native";
import axios from "axios";
import urlsGenerales from "../../../constants/urlsGenerales";
import { StorageUtil } from '../../../shared/StorageUtil';
import { useNavigation } from '@react-navigation/native';
import routes from '../../../constants/routes';
import moment from 'moment';
import { Icon } from 'react-native-elements';
type ResumenPorFechaType = {
    cantidadPagos: string;
    recaudo: string;
    cantidadCreditos: string;
    credito: string;
    cantidadInversionEntradas: string;
    inversionEntrada: string;
    cantidadGastos: string;
    gastos: string;
    cantidadEntegas: string;
    entregas: string;
}

export default function ResumenPorFecha() {

    const navigation = useNavigation();

    const route = useRoute();
    const cobrador = route.params?.cobrador;

    const [loading, setLoading] = useState(false);

    const [fechaDesde, setFechaDesde] = useState(DateUtils.getDateStringYYYYMMDD());
    const [fechaHasta, setFechaHasta] = useState(DateUtils.getDateStringYYYYMMDD());

    const fechaDesdeRef = useRef(null);
    const fechaHastaRef = useRef(null);

    const [mostrarFechaDesde, setMostrarFechaDesde] = useState(false);
    const [mostrarFechaHasta, setMostrarFechaHasta] = useState(false);

    const [resumen, setResumen] = useState<ResumenPorFechaType[]>([]);

    const [cantRecaudo, setCantRecaudo] = useState('0');
    const [totalRecaudo, setTotalRecaudo] = useState('0');
    const [cantCreditos, setCantCreditos] = useState('0');
    const [totalCreditos, setTotalCreditos] = useState('0');
    const [cantInversion, setCantInversion] = useState('0');
    const [totalInversion, setTotalInversion] = useState('0');
    const [cantGastos, setCantGastos] = useState('0');
    const [totalGastos, setTotalGastos] = useState('0');
    const [cantEntregas, setCantEntregas] = useState('0');
    const [totalEntregas, setTotalEntregas] = useState('0');

    const [totalGastosEntregas, setTotalGastosEntregas] = useState(0);
    const [first,setFirst] = useState(true)

    const obtenerResumenPorFecha = async () => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('codUsuario', cobrador.codUsuarioHijo!);
            formData.append('fecha1', fechaDesde);
            formData.append('fecha2', fechaHasta);
            const token = await StorageUtil.getTokenStorage();
            const response = await axios.post(urlsGenerales.REP_RESUMEN_POR_FECHA, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data == 'redirigir') {
                navigation.replace(routes.LOGIN);
                            return;
            }

            setResumen(response.data);

        } catch (e) {
            console.log('Se cae en error -> ', urlsGenerales.REP_RESUMEN_POR_FECHA);
            console.error(e);

            setResumen([]);

        } finally {
            setLoading(false);
            setFirst(false)
        }
    }

    useEffect(() => {
        setCantRecaudo(resumen.length > 0 ? resumen[0].cantidadPagos : '0');
        setTotalRecaudo(resumen.length > 0 ? resumen[0].recaudo : '0');
        setCantCreditos(resumen.length > 0 ? resumen[0].cantidadCreditos : '0');
        setTotalCreditos(resumen.length > 0 ? resumen[0].credito : '0');
        setCantInversion(resumen.length > 0 ? resumen[0].cantidadInversionEntradas : '0');
        setTotalInversion(resumen.length > 0 ? resumen[0].inversionEntrada : '0');
        setCantGastos(resumen.length > 0 ? resumen[0].cantidadGastos : '0');
        setTotalGastos(resumen.length > 0 ? resumen[0].gastos : '0');
        setCantEntregas(resumen.length > 0 ? resumen[0].cantidadEntegas : '0');
        setTotalEntregas(resumen.length > 0 ? resumen[0].entregas : '0');
        setTotalGastosEntregas(resumen.length > 0 ? Number(resumen[0]?.entregas) + Number(resumen[0]?.gastos) : 0);
    }, [resumen]);

    const handleFechaDesde = (fechaDesdeModal: string) => {
        setMostrarFechaDesde(false);
        setFechaDesde(fechaDesdeModal);
    }

    const handleFechaHasta = (fechaHastaModal: string) => {
        setMostrarFechaHasta(false);
        setFechaHasta(fechaHastaModal);
    }

    return (
        <ScrollView contentContainerStyle={styles.container}
                    showsHorizontalScrollIndicator={false}>
                        

            <Text style={[styles.title]}>Resumen por fecha</Text>

            <View style={styles.rowDate}>
                <View style={{flex:1, marginRight:9}}>
                    <Text style={styles.labelInput}>Desde</Text>
                    <TextInput
                        style={styles.inputText}
                        placeholder='yyyy-mm-dd'
                        value={fechaDesde}
                        onPressIn={() => setMostrarFechaDesde(true)}
                        ref={fechaDesdeRef}
                        onFocus={() => {
                            setMostrarFechaDesde(true);
                            // @ts-ignore
                            fechaDesdeRef.current.blur();
                        }}
                    >
                    </TextInput>
                </View>
                <View style={{flex:1, marginLeft:8}}>
                    <Text style={[styles.labelInput]}>Hasta</Text>
                    <TextInput
                        style={styles.inputText}
                        placeholder='yyyy-mm-dd'
                        value={fechaHasta}
                        onPressIn={() => setMostrarFechaHasta(true)}
                        ref={fechaHastaRef}
                        onFocus={() => {
                            setMostrarFechaHasta(true);
                            // @ts-ignore
                            fechaHastaRef.current.blur();
                        }}
                    >
                    </TextInput>
                </View>
            </View>
            
            <ModalCalendar
                visible={mostrarFechaDesde}
                onPress={handleFechaDesde}
                currentDate={moment(fechaDesde).format()}
                onCancel={() => setMostrarFechaDesde(false)}
            />

            <ModalCalendar
                visible={mostrarFechaHasta}
                onPress={handleFechaHasta}
                currentDate={moment(fechaHasta).format()}
                onCancel={() => setMostrarFechaHasta(false)}
            />

            
            <TouchableOpacity style={styles.btn} onPress={obtenerResumenPorFecha}>
                <Icon name='search' color={colors.white}></Icon>
                <Text style={{
                    color: colors.white,
                    fontWeight: 'bold',
                }}>Consultar</Text>
            </TouchableOpacity>
            
            {
                first ? null : 
                <>
                <View style={styles.boxShadow}>
                    <Text style={styles.lblBox}>Información</Text>
                    <View style={styles.row}>
                        <Text style={styles.lblBoxText}>({cantRecaudo}) Recaudo</Text>
                        <Text style={styles.lblBoxAmount}>S/ {totalRecaudo}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.lblBoxText}>({cantCreditos}) Creditos</Text>
                        <Text style={styles.lblBoxAmount}>S/ {totalCreditos}</Text>
                    </View>
                </View>

                <View style={[styles.boxShadow,{marginTop:0}]}>
                    <Text style={styles.lblBox}>Entradas / Salidas</Text>
                    <View style={styles.row}>
                        <Text style={styles.lblBoxText}>({cantInversion}) Inversión</Text>
                        <Text style={styles.lblBoxAmount}>S/ {totalInversion}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.lblBoxText}>({cantEntregas}) Entregas</Text>
                        <Text style={styles.lblBoxAmount}>S/ {totalEntregas}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.lblBoxText}>({cantGastos}) Gastos</Text>
                        <Text style={styles.lblBoxAmount}>S/ {totalGastos}</Text>
                    </View>
                </View>
                </>
            }
            
            <Spinner
                visible={loading}
                color={colors.white}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        // flex: 1,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop:16,
        marginBottom:8,
        marginHorizontal:'5%'
    },
    labelInput:{
        fontSize:14,
        fontWeight:'bold',
        marginTop:16
    },
    inputText:{
        borderRadius: 4,
        height: 48,
        borderWidth: 0.5,
        marginTop: 4,
        paddingHorizontal:16,
    },
    rowDate:{
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:'5%'
    },
    btn:{
        backgroundColor:colors.verde_primary,
        display:'flex',
        flexDirection:'row',
        paddingHorizontal:16,
        height:48,
        marginHorizontal:'5%',
        alignItems:'center',
        borderRadius:4,
        marginTop:16,
        alignContent:'center',
        justifyContent:'center'
    },
    boxShadow:{
        display:'flex',
        borderRadius:8,
        justifyContent:'center', 
        backgroundColor: colors.white,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
        padding:16,
        marginHorizontal:'5%',
        marginVertical:16,
        paddingBottom:8
    },
    lblBox:{
        fontSize:18,
        fontWeight:'bold',
        marginBottom:16,
    },
    row:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        marginBottom:8
    },
    lblBoxAmount:{
        fontSize:16,
        fontWeight:'bold',
        color:colors.logo_azul,
    },
    lblBoxText:{
        color:colors.gris_borde_item_lista
    },
})
