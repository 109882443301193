export class DateUtils {

    static getMonthName(monthNumber: number) {
        let monthName = '';

        switch (monthNumber) {
            case 1:
                monthName = 'Enero';
                break;
            case 2:
                monthName = 'Febrero';
                break;
            case 3:
                monthName = 'Marzo';
                break;
            case 4:
                monthName = 'Abril';
                break;
            case 5:
                monthName = 'Mayo';
                break;
            case 6:
                monthName = 'Junio';
                break;
            case 7:
                monthName = 'Julio';
                break;
            case 8:
                monthName = 'Agosto';
                break;
            case 9:
                monthName = 'Setiembre';
                break;
            case 10:
                monthName = 'Octubre';
                break;
            case 11:
                monthName = 'Noviembre';


                break;
            case 12:
                monthName = 'Diciembre';
                break;
            default:
                monthName = 'Inválido';
                break;
        }

        return monthName;
    }

    static getDateStringYYYYMMDD(): string {
        const dateNow = new Date();
        return dateNow.getFullYear() + '-' + String(dateNow.getMonth() + 1).padStart(2, '0')
            + '-' + String(dateNow.getDate()).padStart(2, '0')
    }

    static getYesterdayStringYYYYMMDD(): string {
        const dateNow = new Date();
        dateNow.setDate(dateNow.getDate() - 1);
        return (
            dateNow.getFullYear() +
            '-' +
            String(dateNow.getMonth() + 1).padStart(2, '0') +
            '-' +
            String(dateNow.getDate()).padStart(2, '0')
        );
    }

    static formatDateYYYYMMDD(fecha: string): string {
        if(fecha && fecha.length>0){
            const [dia, mes, anio] = fecha.split("-").reverse().map(Number);
            return dia + ' de ' + this.getMonthName(mes) + ' de ' + anio;
        }else{
            return '';
        }
        
    }

    static formatDateDDMMYYYY(fecha: string): string {
        const [dia, mes, anio] = fecha.split("-").map(Number);
        return dia + ' de ' + this.getMonthName(mes) + ' de ' + anio;
    }

    static formatTODDMMYYYY(fecha: string): string {
        const [dia, mes, anio] = fecha.split("-").reverse().map(Number);
        return dia.toString().padStart(2, '0') + '-' + mes.toString().padStart(2, '0') + '-' + anio;
    }
}
